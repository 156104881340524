import React from "react";
import PropTypes from "prop-types";

import "./HostSummarySection.scss";
import { removeUnderscores } from "../../../../helpers/functions";
import { Container, Header, MetricItem } from "../../../shared";

const HostSummarySection = ({ finding, hostSummaryAttributes }) => {
  return (
    <Container className='host-summary' medium>
      <Header>Host Summary</Header>
      <div className='metric-container'>
        {Object.keys(hostSummaryAttributes)?.map((key) => {
          return (
            <MetricItem
              small
              className='host-summary-metric-item'
              key={key}
              label={removeUnderscores(hostSummaryAttributes[key])}
              value={finding.attributes[key]}
            />
          );
        })}
      </div>
    </Container>
  );
};

HostSummarySection.propTypes = {
  finding: PropTypes.objectOf(PropTypes.any).isRequired,
  hostSummaryAttributes: PropTypes.object.isRequired,
};

HostSummarySection.defaultProps = {
  hostSummaryAttributes: [],
};

export default HostSummarySection;
