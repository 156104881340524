import React, {useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { subMonths } from 'date-fns'
import { AxisLeft, AxisBottom } from '@visx/axis'
import { scaleLinear, scaleTime } from '@visx/scale'
import { Area, LinePath, Line, Bar } from '@visx/shape'
import { curveMonotoneX } from '@visx/curve'
import { Group } from '@visx/group'
import { extent, bisector } from 'd3-array'
import { GridRows } from '@visx/grid'
import { LinearGradient } from '@visx/gradient'
import { Text } from '@visx/text'
import { withTooltip } from '@visx/tooltip'
import { localPoint } from '@visx/event'
import { formatDate, getRatingColorArray } from '../../utils/functions'
import { formatDisplayDate } from '../../utils/dates'
import RatingPill from '../shared/rating-items/RatingPill'
import last from 'ramda/src/last'
import compose from 'ramda/src/compose'
import './OverviewLineChart.scss'

const NoAnalysesLineChart = ({
    className,
    yMax,
    xMax,
    handleOnClickTimeFilter,
    showHoverData,
    width,
    height,
    margin,
    yScale,
    currentTimeFilter,
  }) => {
    const xScale = scaleTime({
      domain: extent([
        subMonths(new Date(), parseInt(currentTimeFilter) || 12),
        new Date(),
      ]),
      range: [0, xMax],
    })
    return (
      <div className={classnames('v3-line-chart', className)} style={{ width }}>
        {showHoverData && (
          <div className='chart-header'>
            <div className='time-filter-wrapper'>
              <div className='time-filter-nav'>
                <div
                  onClick={() => handleOnClickTimeFilter('3')}
                  className={classnames({ active: currentTimeFilter === '3' })}
                >
                  3M
                </div>
                <div
                  onClick={() => handleOnClickTimeFilter('6')}
                  className={classnames({ active: currentTimeFilter === '6' })}
                >
                  6M
                </div>
                <div
                  onClick={() => handleOnClickTimeFilter('12')}
                  className={classnames({ active: currentTimeFilter === '12' })}
                >
                  1Y
                </div>
                <div
                  onClick={() => handleOnClickTimeFilter('all')}
                  className={classnames({ active: currentTimeFilter === 'all' })}
                >
                  ALL
                </div>
              </div>
            </div>
            <div className='active-item-wrapper'></div>
          </div>
        )}
        <svg width={width} height={height}>
          <AxisLeft
            top={margin.top}
            left={margin.left}
            scale={yScale}
            hideAxisLine
            numTicks={3}
            stroke='#D5DDE4'
            tickStroke='#D5DDE4'
            tickClassName='tick-label'
          />
          <AxisBottom
            top={height - margin.bottom}
            hideAxisLine
            left={margin.left}
            scale={xScale}
            numTicks={5}
            stroke='#D5DDE4'
            tickStroke='#D5DDE4'
            tickFormat={(date, index) => {
              if (!index || formatDate(date, 'M') === '1') { return formatDate(date, 'MMM `yy') }
              return formatDate(date, 'MMM')
            }}
            tickClassName='tick-label'
          />
          <Group top={margin.top} left={margin.left}>
            <rect
              width={xMax}
              height={yMax}
              fill='#FFF'
              stroke='#D5DDE4'
              strokeWidth='1'
              rx='10'
            ></rect>
            <GridRows
              scale={yScale}
              width={xMax}
              tickValues={[0, 2.5, 5, 7.5, 10]}
              stroke='rgba(213, 221, 228, 0.5)'
            />
            <Group top={yMax / 2 - 10} left={160}>
              <rect width={xMax - 320} height={20} fill='#FFF'></rect>
              <Text
                x={(xMax - 320) / 2}
                y={10}
                className='no-analyses-text'
                verticalAnchor='middle'
                textAnchor='middle'
              >
                No analyses during this time period
              </Text>
            </Group>
          </Group>
        </svg>
      </div>
    )
  }
  
  NoAnalysesLineChart.propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
    margin: PropTypes.shape({
      top: PropTypes.number,
      bottom: PropTypes.number,
      left: PropTypes.number,
      right: PropTypes.number,
    }),
    showHoverData: PropTypes.bool,
    className: PropTypes.string,
    yMax: PropTypes.number,
    xMax: PropTypes.number,
    handleOnClickTimeFilter: PropTypes.func.isRequired,
    yScale: PropTypes.func.isRequired,
    currentTimeFilter: PropTypes.string,
  }
  
  const GraphHashLine = ({
    graphHashLineVisible,
    hashLineX,
    yMax,
    xMax,
    yScale,
  }) => {
    if (!graphHashLineVisible) return null
  
    const ySegmentLength = yScale(0) - yScale(1)
    const atLimit = hashLineX === xMax || hashLineX === 0
  
    return (
      <g id='hash-line'>
        <Line
          // this logic (calculating y) is to avoid the hash line from overflowing the graph
          from={{ x: hashLineX, y: atLimit ? 0 + ySegmentLength : 0 }}
          to={{ x: hashLineX, y: atLimit ? yMax - ySegmentLength : yMax }}
          stroke='#173A56'
          strokeWidth={1}
          style={{ pointerEvents: 'none' }}
          strokeDasharray='5,2'
          opacity='1.0'
        />
      </g>
    )
  }
  
  GraphHashLine.propTypes = {
    graphHashLineVisible: PropTypes.bool,
    hashLineX: PropTypes.number,
    yMax: PropTypes.number,
    xMax: PropTypes.number,
    yScale: PropTypes.func,
  }
  
  /**
   *  Custom chart based off of common component LineChart for use only on report overview page.
   *
   * `gradientId` prop is required in order for gradient to work. `yy` is added to bottom ticks for first value and any Jans.
   *
   **/
  
  const OverviewLineChart = ({
    gradientId,
    className,
    width,
    height,
    margin,
    x,
    y,
    data,
    infoOnly,
    showHoverData,
    showTooltip,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    onClick,
    hashLineVisible,
    hideAnalysisDetails,
    disableClick,
  }) => {
    const [date, setDate] = useState('')
    const [rating, setRating] = useState(0)
    const [hashLineX, setHashLineX] = useState(0.0)
    const [graphHashLineVisible, setGraphHashLineVisible] = useState(false)
    const [currentTimeFilter, setCurrentTimeFilter] = useState('all')
    const [currentViewData, setCurrentViewData] = useState(data)

    useEffect(() => {
      if (data?.length) {
        setCurrentViewData(data)
      }
    }, [data])

    useEffect(() => {
      setGraphHashLineVisible(hashLineVisible)
    }, [hashLineVisible])

    const xMax = width - margin.left - margin.right
    const yMax = height - margin.top - margin.bottom
  
    const handleOnClickTimeFilter = timeFrame => {
      setGraphHashLineVisible(false)
      setCurrentTimeFilter(timeFrame)
      !disableClick && hideAnalysisDetails()
      if (timeFrame === 'all') {
        setCurrentViewData(data)
      } else {
        setCurrentViewData(
          data.filter(
            item =>
              new Date(item.date_updated) >= subMonths(new Date(), timeFrame),
          ),
        )
      }
    }
  
    const xScale = scaleTime({
      domain: extent(currentViewData, x),
      range: [0, xMax],
    })
    const yScale = scaleLinear({
      domain: [-1, 11],
      range: [yMax, -0.5],
    })
  
    if (!currentViewData?.length) {
      return (
        <NoAnalysesLineChart
          className={className}
          xMax={xMax}
          yMax={yMax}
          handleOnClickTimeFilter={handleOnClickTimeFilter}
          showHoverData={showHoverData}
          width={width}
          height={height}
          margin={margin}
          yScale={yScale}
          currentTimeFilter={currentTimeFilter}
        />
      )
    }
  
    const ratingColor = getRatingColorArray(compose(y, last)(currentViewData))
  
    const bisectDate = bisector(x).left
  
    const getHoverDataItem = d => {
      const newDate = x(d)
      if (date === newDate) return
      setDate(newDate)
      setRating(y(d))
    }
  
    const handleTooltip = event => {
      const { x: xCoord } = localPoint(event)
      const x0 = xScale.invert(xCoord - margin.left)
      // get array index of mouse x-coordinate in relation to the data
      const index = bisectDate(currentViewData, x0, 1)
      const d0 = currentViewData[index - 1]
      const d1 = currentViewData[index] || d0 // the `|| d0` clause handles data arrays of 1 item.
      // return data item that is closest to the mouse
      const d = x0 - x(d0) > x(d1) - x0 ? d1 : d0
      getHoverDataItem && getHoverDataItem(d)
  
      showTooltip({
        tooltipData: d,
        tooltipLeft: xScale(x(d)),
        tooltipTop: yScale(y(d)),
      })
    }
  
    const handleMouseLeave = () => {
      setDate('')
      setRating(0)
      hideTooltip()
    }
  
    const getHashLineItem = d => {
      setHashLineX(xScale(x(d)))
      setGraphHashLineVisible(true)
    }
  
    const handleOnClick = event => {
      const { x: xCoord } = localPoint(event)
      const x0 = xScale.invert(xCoord - margin.left)
      // get array index of mouse x-coordinate in relation to the data
      const index = bisectDate(currentViewData, x0, 1)
      const d0 = currentViewData[index - 1]
      const d1 = currentViewData[index] || d0 // the `|| d0` clause handles data arrays of 1 item.
      // return data item that is closest to the mouse
      const d = x0 - x(d0) > x(d1) - x0 ? d1 : d0
  
      getHoverDataItem && getHoverDataItem(d)
      getHashLineItem(d)
  
      onClick({
        data: d,
        onClickLeft: xScale(x(d)),
        onClickTop: yScale(y(d)),
      })
    }
    
    return (
      <div className={classnames('v3-line-chart', className)} style={{ width }}>
        {showHoverData && (
          <div className='chart-header'>
            <div className='time-filter-wrapper'>
            </div>
            <div className='active-item-wrapper'>
              {date && rating !== null && (
                <>
                  <div className='active-item-figure'>
                    <div className='active-item-label'>Date:</div>
                    <div className='active-date'>
                      {formatDisplayDate(date.toISOString())}
                    </div>
                  </div>
                  <div className='active-item-figure'>
                    <div className='active-item-label'>Score:</div>
                    <div className='active-score'>
                      {<RatingPill composite rating={rating} />}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        <svg width={width} height={height}>
          <defs>
            <filter id='glow' height='300%' width='300%' x='-100%' y='-100%'>
              <feMorphology
                operator='dilate'
                radius='4'
                in='SourceAlpha'
                result='thicken'
              />
              <feGaussianBlur in='thicken' stdDeviation='7' result='blurred' />
              <feFlood floodColor='rgb(255,255,255)' result='glowColor' />
              <feComposite
                in='glowColor'
                in2='blurred'
                operator='in'
                result='softGlow_colored'
              />
              <feMerge>
                <feMergeNode in='softGlow_colored' />
                <feMergeNode in='SourceGraphic' />
              </feMerge>
            </filter>
          </defs>
          <LinearGradient
            id={gradientId}
            from={`rgba(${ratingColor}, 0.25)`}
            to={`rgba(${ratingColor}, 0)`}
          />
          <AxisLeft
            top={margin.top}
            left={margin.left}
            scale={yScale}
            hideAxisLine
            numTicks={3}
            stroke='#D5DDE4'
            tickStroke='#D5DDE4'
            tickClassName='tick-label'
          />
          <AxisBottom
            top={height - margin.bottom}
            hideAxisLine
            left={margin.left}
            scale={xScale}
            numTicks={5}
            stroke='#D5DDE4'
            tickStroke='#D5DDE4'
            tickFormat={(date, index) => {
              if (!index || formatDate(date, 'M') === '1') { return formatDate(date, 'MMM `yy') }
              return formatDate(date, 'MMM')
            }}
            tickClassName='tick-label'
          />
          <Group top={margin.top} left={margin.left}>
            <rect
              width={xMax}
              height={yMax}
              fill='#FFF'
              stroke='#D5DDE4'
              strokeWidth='1'
              rx='10'
            ></rect>
            <GridRows
              scale={yScale}
              width={xMax}
              tickValues={[0, 2.5, 5, 7.5, 10]}
              stroke='rgba(213, 221, 228, 0.5)'
            />
            <Area
              data={currentViewData}
              x={(d) => xScale(x(d)) ?? 0}
              y0={(d) => yScale(y(d)) ?? 0}
              y1={d => yScale.range()[0]}
              strokeWidth={2}
              stroke={'transparent'}
              fill={`url('#${gradientId}')`}
              curve={curveMonotoneX}
            />
            {currentViewData?.length === 1 && (
              <circle
                cx={xScale(x(currentViewData[0]))}
                cy={yScale(y(currentViewData[0]))}
                r={3}
                fill='#173A56'
                style={{ pointerEvents: 'none' }}
              />
            )}
            <LinePath
              data={currentViewData}
              x={(d) => xScale(x(d)) ?? 0}
              y={(d) => yScale(y(d)) ?? 0}
              curve={curveMonotoneX}
              stroke='#173A56'
              strokeWidth={2}
            />
            <GraphHashLine
              graphHashLineVisible={graphHashLineVisible}
              hashLineX={hashLineX}
              yMax={yMax}
              xMax={xMax}
              yScale={yScale}
            />
            {showHoverData && (
              <Bar
                x={0}
                y={0}
                width={xMax}
                height={yMax}
                fill='transparent'
                rx={10}
                onMouseMove={handleTooltip}
                onMouseLeave={handleMouseLeave}
                onClick={disableClick ? null : handleOnClick}
              />
            )}
            {infoOnly && (
              <Group top={35} left={40}>
                <rect
                  width={xMax - 80}
                  height={yMax - 70}
                  fill='#FFF'
                  stroke='#D5DDE4'
                  strokeWidth='1'
                  rx='10'
                ></rect>
                <Text
                  x={(xMax - 80) / 2}
                  y={25}
                  className='info-only-text'
                  verticalAnchor='middle'
                  textAnchor='middle'
                >
                  Information Only
                </Text>
                <Text
                  x={(xMax - 80) / 2}
                  y={45}
                  className='info-only-sub-text'
                  verticalAnchor='middle'
                  textAnchor='middle'
                >
                  Does Not Impact Ratings
                </Text>
              </Group>
            )}
            {tooltipData &&
	            (
               <circle
                 cx={tooltipLeft}
                 cy={tooltipTop}
                 r={5}
                 fill='#173A56'                  
                 style={{ pointerEvents: 'none' }}
              />
            )}
          </Group>
        </svg>
      </div>
    )
  }
  
  OverviewLineChart.defaultProps = {
    width: 400,
    height: 170,
    margin: { left: 30, right: 10, top: 10, bottom: 25 },
    x: d => new Date(d.date_updated),
    y: d => d.overall_domain_rating_numeric,
  }
  
  OverviewLineChart.propTypes = {
    bottom: PropTypes.number,
    className: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    disableClick: PropTypes.bool,
    getHoverDataItem: PropTypes.func,
    gradientId: PropTypes.string.isRequired,
    hashLineVisible: PropTypes.bool.isRequired,
    height: PropTypes.number.isRequired,
    hideAnalysisDetails: PropTypes.func.isRequired,
    hideHashLine: PropTypes.func,
    hideTooltip: PropTypes.func.isRequired,
    infoOnly: PropTypes.bool,
    left: PropTypes.number,
    margin: PropTypes.shape({
      bottom: PropTypes.number,
      left: PropTypes.number,
      right: PropTypes.number,
      top: PropTypes.number,
    }).isRequired,
    onClick: PropTypes.func.isRequired,
    right: PropTypes.number,
    showHoverData: PropTypes.bool.isRequired,
    showTooltip: PropTypes.func.isRequired,
    tooltipData: PropTypes.object,
    tooltipLeft: PropTypes.number,
    tooltipTop: PropTypes.number,
    top: PropTypes.number,
    width: PropTypes.number.isRequired,
    x: PropTypes.func.isRequired,
    y: PropTypes.func.isRequired,
  }
  
  export default withTooltip(OverviewLineChart)
  