import {
  ADD_VENDOR_ACTION_PLAN_PDF_ID, CLEAR_VENDOR_ACTION_PLAN_PDF_IDS, UPDATE_ID_LISTS
} from '../actions/PdfServiceHelper';

const initialState = {
  vendorActionPlanPdfIdObjects: [],
}

function pdfServiceHelper(state = initialState, action) {
  switch(action.type){
    case ADD_VENDOR_ACTION_PLAN_PDF_ID:
      return {
        ...state,
        vendorActionPlanPdfIdObjects: [
          ...state.vendorActionPlanPdfIdObjects,
          action.idObj
        ]
      }
    case CLEAR_VENDOR_ACTION_PLAN_PDF_IDS:
      return {
        ...state,
        vendorActionPlanPdfIdObjects: []
      };
    case UPDATE_ID_LISTS:
      return {
        ...state,
        vendorActionPlanPdfIdObjects: action.newVendorActionPlanPdfIdObjects
      }
    default:
      return state;
  }
}

export default pdfServiceHelper;