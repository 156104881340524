import React from 'react'
import ContentContainer from './components/shared/ContentContainer'
import Header from './components/shared/Header'
import Eula from './components/Eula'
import './EulaNotice.scss'

const EulaNotice = () => {
  return (
    <ContentContainer>
      <div className="eula-container">
        <Header as="h1">End User License Agreement</Header>
        <div className="eula-subtext-container">
          <span>RiskRecon</span>
          <span>Updated April 2024</span>
        </div>
        <Eula />
      </div>
    </ContentContainer>
  )
}

export default EulaNotice
