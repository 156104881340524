import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./MetricItem.scss";

const MetricItem = ({
  label,
  value,
  red,
  className,
  valueClassName,
  small,
}) => {
  return (
    <div className={classnames("v3-metric-item", className, { small })}>
      <div className="metric-label">{label}</div>
      <div
        className={`metric-value ${valueClassName}${
          String(value).toLowerCase() === "n/a" ? "na" : ""
        }`}
      >
        {value}
      </div>
    </div>
  );
};

MetricItem.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  red: PropTypes.bool,
  small: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]).isRequired,
  valueClassName: PropTypes.string,
};

export default MetricItem;
