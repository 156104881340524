import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import IconButton from '../buttons/IconButton'
import CloseSVG from '../CloseSVG'
import WarningTriangleSVG from '../WarningTriangleSVG'
import './ToastAlert.scss'

const ToastAlert = ({
  displayType,
  infoType,
  title,
  message,
  onClose,
  showToast,
  persistent,
  dangerous,
}) => {
  const autoDismiss = useRef(null)

  useEffect(() => {
    if (showToast && !persistent) {
      autoDismiss.current = setTimeout(() => onClose(), 7000)
    }
    return () => clearTimeout(autoDismiss.current)
  }, [showToast, persistent, onClose])

  if (!showToast) return null

  if (displayType === 1) {
    let icon
    switch (
      infoType // switch out once correct icons get finished/put in design
    ) {
      case 'primary':
        icon = <WarningTriangleSVG fill="#FFFFFF" />
        break
      case 'success':
        icon = <WarningTriangleSVG fill="#FFFFFF" />
        break
      case 'danger':
        icon = <WarningTriangleSVG fill="#FFFFFF" />
        break
      case 'warning':
        icon = <WarningTriangleSVG fill="#0E2435" />
        break
      default:
        icon = <WarningTriangleSVG fill="#FFFFFF" />
        break
    }
    return (
      <div className="toast-container toast-width-1">
        <div className={'toast-alert-v1'} data-testid="v3-toast-v1">
          <div
            className={`toast-alert-v1-left ${infoType}-v1`}
            data-testid="v3-toast-message-v1"
          >
            <div className="toast-alert-v1-icon">{icon}</div>
            <div className="toast-alert-v1-title">{title}</div>
          </div>
          <div className={`toast-alert-v1-right ${infoType}`}>
            {dangerous ? (
              <div
                className="toast-alert-v1-message"
                dangerouslySetInnerHTML={{ __html: dangerous }}
              />
            ) : (
              <div className="toast-alert-v1-message">{message}</div>
            )}
            <IconButton
              className="toast-alert-v1-close-button"
              icon={CloseSVG}
              onClick={onClose}
              color="dark-blue"
            />
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="toast-container toast-width-2">
        <div className={`toast-alert-v2 ${infoType}`} data-testid="v3-toast-v2">
          <IconButton
            className="toast-alert-v2-close-button"
            icon={CloseSVG}
            onClick={onClose}
            color="dark-blue"
          />
          <div className="toast-alert-v2-title">{title}</div>
          {dangerous ? (
            <div
              className="toast-alert-v2-message"
              dangerouslySetInnerHTML={{ __html: dangerous }}
            />
          ) : (
            <div className="toast-alert-v2-message">{message}</div>
          )}
        </div>
      </div>
    )
  }
}

ToastAlert.defaultProps = {
  displayType: 1,
  infoType: 'success',
  showToast: false,
  persistent: false,
  message: '',
}

ToastAlert.propTypes = {
  dangerous: PropTypes.string,
  displayType: PropTypes.oneOf([1, 2]).isRequired,
  infoType: PropTypes.oneOf(['primary', 'success', 'danger', 'warning'])
    .isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  persistent: PropTypes.bool.isRequired,
  showToast: PropTypes.bool.isRequired,
  title: PropTypes.string,
}

export default ToastAlert
