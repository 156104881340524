import React from 'react'
import { customerRequestKeys } from '../../../../constants/FindingSidebar'
import { convertBoolToText, truncateString } from '../../../../helpers/TextHelpers'
import Tooltip from '../../../shared/ToolTip'
import "./CustomerRequestDetails.scss";

function CustomerRequestDetails ({ customer }) {
  const renderColumnValue = (key, customer) => {
    // this intentionally put on top because this will occasionally return falsey
    if (key === 'found_in_current_action_plan') {
      return convertBoolToText(customer[key])
    }
    if (!customer[key]) {
      return '-'
    } else if (key === 'comment') {
      return truncateString(customer[key], 19)
    } else if (key === 'overdue') {
      return convertBoolToText(customer[key])
    } else {
      return <Tooltip title={customer[key]} disabled={customer[key].length < 15}>{truncateString(`${customer[key]}`, 15)}</Tooltip>
    }
  }

  return (
    <div>
      <div className='customer-request-details clickable'>
        {Object.keys(customerRequestKeys).map((k, index) => (
          <div key={index} className={`${k}-column`}>{renderColumnValue(k, customer)}</div>
        ))}
      </div>
    </div>
  )
}

export default CustomerRequestDetails
