import React from "react";
import DatePicker from "react-datepicker";
import TextArea from "../../../shared/TextArea";
import Button from "../../../shared/Button";
import Select from "react-select";
import QuestionMarkOSVG from "../../../../assets/images/icons/QuestionMarkOSVG";
import "react-datepicker/dist/react-datepicker.css";
import { Header, Container, Tooltip} from "../../../../components/shared";
import "./UpdateStatus.scss";

const yourStatusToolTipContent =
  "Please note, issues with a status of Investigating or Fixing for more than 90 days will be moved to a status of No Action. Also, any issue that is verified as resolved in a subsequent scan will be automatically assigned a Fixed status.";

function UpdateStatus(props) {
  const {
    toe,
    onCloseUpdateStatus,
    handleSelectStatusChange,
    handleSelectOptionChange,
    selectedStatus,
    selectedOption,
    handleCommentChange,
    commentValue,
    fixDate,
    handleFixDateChange,
    submitForm,
    errors,
  } = props;

  const compensatingControlStatuses =
    toe.data.attributes.available_status_responses.compensating_control;
  const falsePositiveStatuses =
    toe.data.attributes.available_status_responses.false_positive;
  const statuses = toe.data.attributes.available_statuses;
  const findingStatuses = statuses.map((option) => {
    return { label: option.name, value: option.key };
  });
  const compensatingControlOptions = compensatingControlStatuses.map(
    (option) => {
      return {
        label: option.text,
        value: option.key,
        required_comment: option.comment_required,
      };
    }
  );
  const falsePositiveOptions = falsePositiveStatuses.map((option) => {
    return {
      label: option.text,
      value: option.key,
      required_comment: option.comment_required,
    };
  });

  return (
    <Container medium className='update-status'>
      <Header>Update Finding Status</Header>
      <div className='rr-card issue-status-card'>
        <form
          className='form-compensating-control rr-form'
          name='compensatingControlForm'
          onSubmit={submitForm}
        >
          <div className='status-row'>
            <div className='status-item'>
              <label className='label-text'>
                <span className='label-text'>Your Status</span>
                <Tooltip
                  className="status-tooltip"
                  title={yourStatusToolTipContent}
                  disabled={false}
                >
                  <QuestionMarkOSVG size="20px" style={{ fill: "#BDBDBD" }} />
                </Tooltip>
              </label>
              <Select
                className='rr-form-select'
                value={selectedStatus}
                options={findingStatuses}
                onChange={handleSelectStatusChange}
                placeholder={"Select Status"}
                closeMenuOnSelect
              />
              {errors.selectedStatus && (
                <span className='form-input-hint is-error'>
                  {errors.selectedStatus}
                </span>
              )}
            </div>
            <div className='status-item date-input'>
              <label className='label-text'>Date (Optional)</label>
              <DatePicker
                selected={fixDate}
                minDate={new Date()}
                onChange={handleFixDateChange}
                placeholderText='Select Due Date'
              />
            </div>
          </div>
          {selectedStatus.value === "compensating_control" && (
            <div className='status-row fixed-responses'>
              <div className='status-item full-width'>
                <label className='label-text'>Select Your Response</label>
                <Select
                  className='rr-form-select'
                  value={selectedOption}
                  options={compensatingControlOptions}
                  onChange={handleSelectOptionChange}
                  placeholder={"Select One"}
                  closeMenuOnSelect
                />
                {errors.selectedOption && (
                  <span className='form-input-hint is-error'>
                    {errors.selectedOption}
                  </span>
                )}
              </div>
            </div>
          )}
          {selectedStatus.value === "false_positive" && (
            <div className='status-row fixed-responses'>
              <div className='status-item full-width'>
                <label className='label-text'>Select Your Response</label>
                <Select
                  className='rr-form-select'
                  value={selectedOption}
                  options={falsePositiveOptions}
                  onChange={handleSelectOptionChange}
                  placeholder={"Select One"}
                  closeMenuOnSelect
                />
                {errors.selectedOption && (
                  <span className='form-input-hint is-error'>
                    {errors.selectedOption}
                  </span>
                )}
              </div>
            </div>
          )}
          <div className='status-row message-wrapper'>
            <TextArea
              className='rr-form-input'
              label={`Comment ${
                selectedOption
                  ? selectedOption.required_comment
                    ? ""
                    : "(Optional)"
                  : ""
              }`}
              error={errors["commentValue"]}
              name='comment'
              form='compensatingControlForm'
              value={commentValue}
              onChange={handleCommentChange}
              placeholder='Add any additional notes'
              comment='Your comment will not be shared with the customer. Use for internal purposes only.'
            />
          </div>
          <div className='submit-wrapper'>
            <Button className='submit-response-button btn--blue' type='submit'>
              Save
            </Button>
            <div
              className='cancel-button'
              onClick={() => onCloseUpdateStatus()}
            >
              Cancel
            </div>
          </div>
        </form>
      </div>
    </Container>
  );
}

export default UpdateStatus;
