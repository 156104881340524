import axios from 'axios'
import jwt_decode from 'jwt-decode'
import request from '../reference/axiosWrapper'
import { setFlash } from './flashActions'
import { fetchToeSummary } from './dashboardActions'
import { setRouteToMfa, setUserMfa } from '../slices/MfaSlice'
import { applicationId, authURL } from '../utils/environmentHelpers'
import {
  LOGGED_IN,
  UNAUTHORIZED,
  FETCHING_USER,
  RESET_PASSWORD_SUCCESS,
  SET_CURRENT_EULA_ACCEPTED,
} from './Types'

const AUTH_PREFIX = authURL(window.location.host)

// Action Creators
export const fetchingUser = (payload) => ({ type: FETCHING_USER, payload })
export const resetPasswordSuccess = (payload) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload,
})
export const setUserLoggedIn = (user) => ({ type: LOGGED_IN, user })

// Thunks
export function login(email, password) {
  return async (dispatch) => {
    const data = {
      grant_type: 'password',
      username: email,
      password: password,
    }
    const requestOptions = {
      method: 'POST',
      url: AUTH_PREFIX + 'v2/oauth/token',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(data),
    }

    try {
      const success = await axios(requestOptions)
      if (success?.data?.mfa_route) {
        dispatch(setRouteToMfa(success.data))
      } else {
        // bypass MFA challenge and log user in
        const accessToken = success.data?.access_token
        const refreshToken = success.data?.refresh_token
        window.sessionStorage.setItem('accessToken', accessToken)
        window.sessionStorage.setItem('refreshToken', refreshToken)
        const decodedToken = jwt_decode(accessToken)
        const {
          authy_id,
          authy_verified_at,
          configured_mfa_channel,
          last_mfa_strategy,
          enforce_mfa,
          sso_enabled,
          mfa_enabled,
        } = decodedToken.user

        await dispatch(fetchingUser(true))
        await dispatch(setUserLoggedIn(decodedToken.user))
        await dispatch(
          setUserMfa({
            authy_id,
            authy_verified_at,
            configured_mfa_channel,
            last_mfa_strategy,
            enforce_mfa,
            sso_enabled,
            mfa_enabled,
          })
        )
        await dispatch(fetchToeSummary())
        dispatch(fetchingUser(false))
      }
    } catch (error) {
      if (error?.response?.status === 412) {
        dispatch(
          setFlash(
            [
              'RiskRecon is increasing our security and requiring all users to reset their password on a quarterly basis. Please click "Forgot Password" to reset your password.',
            ],
            'error'
          )
        )
      } else {
        dispatch(setFlash(['Username or password is incorrect'], 'error'))
      }
      console.log(error)
    }
  }
}

export function requestForgotPassword(email) {
  return async (dispatch) => {
    const data = {
      email: email,
      application: applicationId(window.location.host)
    }

    const requestOptions = {
      method: 'POST',
      url: AUTH_PREFIX + 'v2/users/password',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(data),
    }

    await axios(requestOptions)
    dispatch(
      setFlash(
        [
          'If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.',
        ],
        'info'
      )
    )
  }
}

export const requestResetPassword = (password, passwordConfirmation, resetPasswordToken) => {
  return async (dispatch) => {
    const data = {
      password: password,
      password_confirmation: passwordConfirmation,
      reset_password_token: resetPasswordToken,
    }

    const requestOptions = {
      method: 'PUT',
      url: AUTH_PREFIX + 'v2/users/password',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(data),
    }

    try {
      await axios(requestOptions)
      await dispatch(resetPasswordSuccess(true))
      await dispatch(setFlash(['Reset password successful'], 'success'))
    } catch {
      dispatch(setFlash(['You cannot reuse a prior password.'], 'error'))
    }
  }
}

export const logout = () => (dispatch) => {
  try {
    window.sessionStorage.removeItem('accessToken')
    window.location = '/logout'
  } catch (err) {
    console.log(err, 'issue switching location')
  }
  dispatch({ type: UNAUTHORIZED })
}

const setCurrentEulaAcceptedSuccess = () => ({
  type: SET_CURRENT_EULA_ACCEPTED,
})

export const setCurrentEulaAccepted = () => async (dispatch) => {
  const requestOptions = {
    method: 'PUT',
    url: `v2/users/accept_current_eula`,
  }
  try {
    await request(requestOptions)
    return dispatch(setCurrentEulaAcceptedSuccess())
  } catch (error) {
    console.warn(error)
  }
}
