import React from "react";
import EventLogItem from "./EventLogItem";
import { Container, Header } from "../../../../components/shared";
import "./EventLog.scss";

function EventLog({ finding }) {
  return (
    <Container medium className="event-log-container">
      <Header>Event Log</Header>
      <div className="event-log-body">
        {finding.attributes.action_log_events && finding.attributes.action_log_events?.length > 0 ? (
          finding.attributes.action_log_events.map((logItem, index) => (
            <EventLogItem key={index} logItem={logItem} />
          ))
        ) : (
          <p>No events for this item.</p>
        )}
      </div>
    </Container>
  );
}

export default EventLog;
