import request from "../reference/axiosWrapper";
import { NEW_BANNERS, UPDATE_BANNER, SET_ACTIVE, RESET_TIMERS } from "./Types";

export const updateBanners = (bannerIds) => {
  const banners = bannerIds.map((banner) => {
    return { id: banner }
  })
  return { type: NEW_BANNERS, banners };
};

export const cycleBanner = (id) => {
  return {
    type: UPDATE_BANNER,
    id,
  };
};

export const resetBannerTimers = () => {
  return { type: RESET_TIMERS }
}

export const setActiveBanner = (id) => {
  return { type: SET_ACTIVE, id };
};

export const fetchBanners = () => {
  return async (dispatch) => {
    try {
      const requestOptions = {
        method: "GET",
        url: "v2/vendor/banner_list",
      };
      request(requestOptions).then((response) => {
        if (response.status && response.status === 200) {
          const {
            data: {
              data: { banner_ids = [] },
            },
          } = response;
          if (!!banner_ids.length) {
            dispatch(updateBanners(banner_ids));
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
};
