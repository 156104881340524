import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { Container, Transition, Table } from 'semantic-ui-react';
import Pagination from '../shared/Pagination'
// import '../../../../assets/styles/Report/ActionPlan/FindingModal/ThreatIntellIssueRow.css';
import { truncateString } from '../../helpers/TextHelpers';
import { threatIntelIssueLastColumnName } from '../../constants/ActionPlan';

class ThreatIntellIssueRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      issues: this.props.issues !== undefined ? this.props.issues : [],
      currentIssues: this.props.issues !== undefined ? this.props.issues.slice(0,10) : [],
      showDetails: false,
      activePage: 1,
      totalPages: this.props.issues !== undefined ? Math.ceil(this.props.issues.length/10) : 0,
      boundaryRange: 1,
      siblingRange: 1,
      showEllipsis: true,
      showFirstAndLastNav: true,
      showPreviousAndNextNav: true,
      totalNumItems: this.props.issues !== undefined ? this.props.issues.length : 0,
      itemsPerPage: 10,
    };

  }

  handleShowRowDetails = () => {
    this.setState({showDetails: !this.state.showDetails});
  }

  renderIssueRow(issue, rowIndex) {
		return (
			<Table.Row key={`${issue.asn}-${rowIndex}`}>
        <Table.Cell className="issue-date">{moment(issue.intell_date).format('MM/DD/YYYY')}</Table.Cell>
        <Table.Cell className="issue-type">{issue.type}</Table.Cell>
        <Table.Cell className="issue-family">{issue.family}</Table.Cell>
        <Table.Cell className="issue-alert-source">{issue.source}</Table.Cell>
        <Table.Cell className="issue-cc-server-hostname">{truncateString(issue.notes, 65)}</Table.Cell>
		  </Table.Row>
		)
  }

  handlePaginationChange = (e, { activePage }) => {
    const issues = this.props.issues;
    const itemsPerPage = this.state.itemsPerPage;
    const startItem = (activePage - 1) * itemsPerPage;
    const endItem = this.state.totalNumItems >= startItem + itemsPerPage + 1 ? startItem + itemsPerPage : this.state.totalNumItems;
    this.setState({
      activePage,
      currentIssues: issues.slice(startItem, endItem),
    });
  }

  renderIssuesTable = (issues) => {
    if (issues.length > 0) {
      return (
        <div>
          <Table basic="very" className="rr-table threat-intell-issues-table">
               <Table.Header>
                 <Table.Row>
                   <Table.HeaderCell className="date-header">Date</Table.HeaderCell>
                   <Table.HeaderCell className="type-header">Type</Table.HeaderCell>
                   <Table.HeaderCell className="family-header">Family</Table.HeaderCell>
                   <Table.HeaderCell className="alert-source-header">Alert Source</Table.HeaderCell>
                   <Table.HeaderCell className="other-header">{threatIntelIssueLastColumnName[this.props.criteria]}</Table.HeaderCell>
                 </Table.Row>
               </Table.Header>
               <Table.Body>
               {this.state.currentIssues.map((row, idx) => this.renderIssueRow(row, idx))}
              </Table.Body>
          </Table>
          {this.state.totalNumItems > 20 ? <div className="pagination-wrapper">
          <Pagination
            activePage={this.state.activePage}
            onPageChange={this.handlePaginationChange}
            totalPages={this.state.totalPages}
          />
        </div> : ''}
      </div>
      )
    }

    return (
      <div className="threat-intell-table_container">
        None
      </div>
    )
  }

	render() {
    return (
      <Container id="issues-row_container">
        <div className="issues_criteria-row flex justify-space-between clickable" onClick={() => this.handleShowRowDetails()}>
          <div className="criteria-row_name">{this.props.prettyCriteriaName}</div>
          <div className="criteria-row_right-info flex">
            <div className="criteria-row_alert-count">{`${this.state.issues.length} Alerts`}</div>
            <div className={`criteria-row_more-details expand-caret-wrapper blue-text clickable flex align-center ${this.state.showDetails ? 'expanded' : 'closed'}`}><FontAwesomeIcon icon={faAngleLeft} size="1x"></FontAwesomeIcon></div>
          </div>
        </div>
        <Transition visible={this.state.showDetails} animation='slide down' duration={200}>
          {this.renderIssuesTable(this.state.issues)}
        </Transition>
      </Container>
    );
  };
}

export default ThreatIntellIssueRow;
