import React, { useState, useEffect } from 'react';
import { Table, Dimmer, Loader } from 'semantic-ui-react';
import Pagination from '../../shared/Pagination'
// import '../../../assets/styles/FindingSidebar/IssueDetailsPane.scss';
import HostSummarySection from './HostProfile/HostSummarySection';
import LoadingErrorMessage from '../../shared/LoadingErrorMessage';
import { prettyDomainName, setClassColorByConcern } from '../../../helpers/TextHelpers';
import { findingSidebarAttributes } from '../../../constants/FindingSidebar';
import { Container, Header, TableBody, TableCellData, TableCellHeader, TableHead, TableRow } from '../../shared';
import './HostIssuesPane.scss'

const HostIssueTable = ({ findings, domain }) => {
  const [activePage, setActivePage] = useState(1)
  const [currentFindings, setCurrentFindings] = useState([])
  const [totalItems, setTotalItems] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const itemsPerPage = 5
  
  useEffect(() => {
    if(findings.length > 0) {
      setTotalPages(Math.ceil(findings.length/5))
      setTotalItems(findings.length)
      setCurrentFindings(findings.slice(0,5))
    }
  },[findings])

  const handlePaginationChange = (e, { activePage }) => {
    const startItem = (activePage - 1) * itemsPerPage;
    const endItem = totalItems >= startItem + itemsPerPage + 1 ? startItem + itemsPerPage : totalItems;
    setActivePage(activePage)
    setCurrentFindings(findings.slice(startItem, endItem))
  }

  const renderHostFindingRow = (finding, index) => {
    return (
      <TableRow key={index}>
        <TableCellData >{finding._source.finding_type}</TableCellData>
        <TableCellData >{finding._source.finding_detail}</TableCellData>
        <TableCellData className={`host-issues-pane-table ${setClassColorByConcern('severity', finding._source.severity)}`}>{finding._source.severity}</TableCellData>
        <TableCellData align="right" className={` ${setClassColorByConcern('priority', finding._source.priority)}`}>{finding._source.priority}</TableCellData>
      </TableRow>
    );
  }
    return(
       <Container medium className="host-issues-pane-table-container">
          <Header>{prettyDomainName(domain)}</Header>        
          <Table singleLine={false} className="host-issues-pane-table">
            <TableHead>
              <TableRow>
                <TableCellHeader width={200}>Issue</TableCellHeader>
                <TableCellHeader width={400}>Detail</TableCellHeader>
                <TableCellHeader width={100}>Severity</TableCellHeader>
                <TableCellHeader align="right" width={50}>Priority</TableCellHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentFindings?.length > 0 && currentFindings?.map((finding, idx) => renderHostFindingRow(finding, idx))}
            </TableBody>
          </Table>
          {totalPages > 1 && (
              <Pagination
              activePage={activePage}
              onPageChange={handlePaginationChange}
              totalPages={totalPages}
            />
          )}        
       </Container>
    )
}

const HostIssuesPane = ({ finding, findingsByHost, fetchingFindingsByHost, fetchingFindingsByHostFailure }) => {
  const renderTables = () => {
    if (Object.keys(findingsByHost).length < 1) {
      return <p className="no-findings">No findings for this host</p>
    }
    if(fetchingFindingsByHost){
      return (
        <Dimmer.Dimmable dimmed={true} className="table-loader">
          <Dimmer active={true} inverted>
            <Loader>Loading</Loader>
          </Dimmer>
        </Dimmer.Dimmable>
      )
    } else if (fetchingFindingsByHostFailure) {
      return <LoadingErrorMessage width={`100%`} />
    } else {
      return (
        Object.keys(findingsByHost).map((key, idx) => (
          <HostIssueTable key={idx} domain={key} findings={findingsByHost[key]} />
        ))
      )
    }
  }

    return (
      <div className="host-issues-tab-pane-container">
        <HostSummarySection finding={finding} hostSummaryAttributes={findingSidebarAttributes[finding.security_criteria || 'it-profile'].host_summary_attributes} />
        <div className="host-issue-tables-wrapper">
          {renderTables()}
        </div>
      </div>
    );
  
}

HostIssuesPane.propTypes = {

};

export default HostIssuesPane;
