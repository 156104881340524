import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import TableCellData from './TableCellData'
import ScoreChange from '../../rating-items/ScoreChange'
import './ScoreChangeTableCell.scss'

const ScoreChangeTableCell = ({
  className,
  value,
  align,
  ...rest
}) => {
  return (
    <TableCellData
      className={classnames('v3-score-change-td', className, align)}
      align={align}
      {...rest}
    >
      <ScoreChange scoreDelta={value} />
    </TableCellData>
  )
}

ScoreChangeTableCell.defaultProps = {
  className: '',
  align: 'left',
}

ScoreChangeTableCell.propTypes = {
  align: PropTypes.string.isRequired,
  className: PropTypes.string,
  value: PropTypes.number.isRequired,
}

export default ScoreChangeTableCell
