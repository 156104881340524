import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import ReactSelect, { components } from 'react-select'
import './Select.scss'
import ChevronUpSVG from './ChevronUpSVG'
import CrossedCircle from './CrossedCircle'

const DisabledDropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <CrossedCircle className={'disabled-icon'} fill={'#435A70'} />
    </components.DropdownIndicator>
  )
}

const DefaultDropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronUpSVG className="default-icon" fill={'#435A70'} />
    </components.DropdownIndicator>
  )
}

const DefaultRotatedDropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronUpSVG className="default-icon rotate" fill={'#435A70'} />
    </components.DropdownIndicator>
  )
}
/**
 * Styled wrapper of [React Select](https://github.com/JedWatson/react-select).
 */
const Select = ({
  selected,
  onChange,
  options,
  isDisabled,
  isOptionDisabled,
  getOptionLabel,
  isSearchable,
  className,
  error,
  showDisabledIcon,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      borderColor: 'rgba(87, 117, 146, 0.25)',
      border: '1px solid rgba(87, 117, 146, 0.25)',
      shadow: '0px 5px 14px rgba(14, 36, 53, 0.08)',
      margin: '0px',
      borderTopLeftRadius: '0px',
      borderTopRightRadius: '0px',
    }),
    singleValue: (styles, { isDisabled }) => {
      return {
        ...styles,
        color: isDisabled ? 'rgba(23, 58, 86, 0.25)' : '#173A56',
        fontSize: '14px',
      }
    },
    indicatorsContainer: (styles, { isDisabled }) => {
      return {
        ...styles,
        fill: isDisabled ? 'rgba(14, 36, 53, 0.25)' : '#0E2435',
      }
    },
    indicatorSeparator: () => {
      return { display: 'none' }
    },
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? '#173A56'
          : isFocused
          ? '#F9FAFB'
          : null,
        color: isDisabled ? '#ccc' : isSelected ? '#FFFFFF' : '#435A70',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        fontWeight: isDisabled ? null : isSelected ? '600' : 'default',
        fontSize: '14px',
        ':hover': {
          fontWeight: '600',
        },
      }
    },
    control: (styles, { isDisabled, isFocused, menuIsOpen }) => ({
      ...styles,
      backgroundColor: '#ffffff',
      borderColor: isDisabled
        ? 'rgba(23, 58, 86, 0.25)'
        : error
        ? 'rgba(179, 0, 21, 0.75)'
        : 'rgba(87, 117, 146, 0.25)',
      shadow: isFocused
        ? '0 0 0 0'
        : menuIsOpen
        ? '0px 5px 14px rgba(14, 36, 53, 0.08)'
        : null,
      height: '44px',
      borderBottomLeftRadius: menuIsOpen ? '0px' : '4px',
      borderBottomRightRadius: menuIsOpen ? '0px' : '4px',
      borderBottom: isDisabled
        ? '1px solid rgba(23, 58, 86, 0.25)'
        : menuIsOpen
        ? '1px solid transparent' // need to stop shifting of placeholder/selected value
        : error
        ? '1px solid rgba(179, 0, 21, 0.75)'
        : '1px solid rgba(87, 117, 146, 0.25)',
      boxSizing: 'inherit',
      cursor: isDisabled ? 'default' : 'pointer',
      ':hover': {
        borderColor: error
          ? 'rgba(179, 0, 21, 0.75)'
          : 'rgba(87, 117, 146, 0.25)',
        borderBottom: error
          ? '1 px solid rgba(179, 0, 21, 0.75)'
          : menuIsOpen
          ? '1px solid transparent'
          : '1px solid rgba(87, 117, 146, 0.25)',
      },
    }),
  }
  return (
    <div
      className={classnames(
        `v3-select ${className} ${isDisabled ? 'disabled' : ''}`,
        { error: !!error }
      )}
    >
      <ReactSelect
        value={selected}
        onChange={onChange}
        options={options}
        isDisabled={isDisabled}
        isOptionDisabled={isOptionDisabled}
        getOptionLabel={getOptionLabel}
        isSearchable={isSearchable}
        styles={customStyles}
        onMenuOpen={() => setIsOpen(true)}
        onMenuClose={() => setIsOpen(false)}
        components={{
          DropdownIndicator:
            showDisabledIcon && isDisabled
              ? DisabledDropdownIndicator
              : isOpen
              ? DefaultRotatedDropdownIndicator
              : DefaultDropdownIndicator,
        }}
        {...rest}
      />
      {!!error && <div className="error-text">{error}</div>}
    </div>
  )
}

Select.defaultProps = {
  isDisabled: false,
  isOptionDisabled: false,
  isSearchable: false,
  options: [{ value: '', label: 'Select...' }],
  error: '',
  showDisabledIcon: false,
}

Select.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isOptionDisabled: PropTypes.func,
  getOptionLabel: PropTypes.func,
  isSearchable: PropTypes.bool.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  selected: PropTypes.object,
  showDisabledIcon: PropTypes.bool.isRequired,
  value: PropTypes.string,
}

export default Select
