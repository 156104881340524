import strIsEmpty from 'validator/lib/isEmpty'
import isEmail from 'validator/lib/isEmail'

const loginValidator = (state) => {
  let validations = {
    email: false,
    password: false
  }

  validations.email = (!strIsEmpty(state.email) && isEmail(state.email))
  validations.password = !strIsEmpty(state.password)

  return {
    validations,
    valid: validations.email && validations.password
  }
}

export default loginValidator
