import React from 'react'
import { format } from 'date-fns'
import * as Sentry from '@sentry/react'

export const getRatingType = rating => {
  if (typeof rating !== 'number' || rating < 0) return 'info'
  if (rating < 4) return 'f'
  if (rating < 5.5) return 'd'
  if (rating < 7) return 'c'
  if (rating < 8.5) return 'b'
  return 'a'
}

export const formatDate = (date, frmt) => {
    try {
      return format(date, frmt)
    } catch (error) {
      console.error(`Date value supplied: ${date}`, 'Please supply valid date.', error)
      Sentry.captureException(error)
      return 'N/A'
    }
  }

  export const ratingColorArrays = {
    info: [255, 255, 255],
    f: [179, 0, 21],
    d: [251, 155, 11],
    c: [242, 201, 76],
    b: [47, 128, 237],
    a: [39, 174, 96],
  }

  export const getRatingColorArray = rating => ratingColorArrays[getRatingType(rating)]
