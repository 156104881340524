import React from 'react'
import PropTypes from 'prop-types'
import {
  Tooltip as Tooltippy,
} from 'react-tippy'
import 'react-tippy/dist/tippy.css'
import './ToolTip.scss'

/**
 * See `https://github.com/tvkhoa/react-tippy#props` for api/props. `className` is for the div container, not the tooltip itself... see 'theme' in docs
 */

const Tooltip = ({
  children,
  title,
  className,
  disabled,
  html,
  style,
  distance,
  arrowSize,
  position,
  ...rest
}) => {
  return (
    <Tooltippy
      title={!disabled ? title : undefined}
      arrow
      theme='v3'
      className={className}
      arrowSize={arrowSize}
      distance={distance}
      disabled={disabled}
      html={html}
      style={style}
      position={position}
      {...rest}
    >
      {children}
    </Tooltippy>
  )
}

Tooltip.defaultProps = {
  distance: 15,
  arrowSize: 'big',
  position: 'top',
}

Tooltip.propTypes = {
  arrowSize: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  distance: PropTypes.number.isRequired,
  html: PropTypes.node,
  position: PropTypes.string,
  style: PropTypes.object,
  theme: PropTypes.string,
  title: PropTypes.string,
}

export default Tooltip
