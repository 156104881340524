import React, { useState } from 'react'
import SetupMFAConfirm from './SetupMFAConfirm'
import SetupMFASMS from './SetupMFASMS'
import { COUNTRY_PHONE_CODES } from '../../../utils/phoneNumbers'
import './mfa.scss'

const SetupMFA = () => {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [authyId, setAuthyId] = useState(undefined)
  const [countryCode, setCountryCode] = useState(COUNTRY_PHONE_CODES[0])

  if (authyId) {
    return (
      <SetupMFAConfirm
        phoneNumber={phoneNumber}
        authyId={authyId}
        setAuthyId={setAuthyId}
      />
    )
  }
  return (
    <>
      <SetupMFASMS
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        countryCode={countryCode}
        setCountryCode={setCountryCode}
        setAuthyId={setAuthyId}
      />
    </>
  )
}

export default SetupMFA
