/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import DomainFindingRow from './DomainFindingsRow'
import Loader from '../loaders/LoaderIcon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { removeUnderscores } from '../../helpers/TextHelpers'
import Pagination from '../shared/Pagination'
import { useDispatch, useSelector } from 'react-redux'
import { resetPagination } from '../../actions/dashboardActions'
import classnames from 'classnames'

const initialSortColumn = 'priority'
const initialSortDirection = 'asc'

const DomainFindingsTable = props => {
  const {
    showFindingSidebarAsync,
    tableHeaders,
    findingsData,
    isCriteriaOnly,
    fetchDomainFindings,
    fetchCriteriaFindings,
    tableSortableColumnHeaders,
    tableSortKeys,
    selectedValues,
    loading,
    selectedTab
  } = props

  const [sortColumn, setSortColumn] = useState(initialSortColumn)
  const [sortColumnHeader, setSortColumnHeader] = useState(initialSortColumn)
  const [sortDirection, setSortDirection] = useState(initialSortDirection)
  const [page, setPage] = useState(findingsData.meta.current_page)
  const domainFindings = useSelector((state) => state.dashboard.domainFindingInfo)
  const { selectorChanged = false } = domainFindings
  const dispatch = useDispatch()

  function fetchFindings () {
    const { criteria, domain, selectedValues, expressionAttributes } = props
    const customers = selectedValues.map((customer) => customer.value)
    if (isCriteriaOnly) {
      fetchCriteriaFindings(customers, domain, criteria, selectorChanged ? 1 : page, sortColumn, sortDirection, expressionAttributes, selectedTab)
    } else {
      fetchDomainFindings(customers, domain, criteria, selectorChanged ? 1 : page, sortColumn, sortDirection, expressionAttributes, selectedTab)
    }
  }

  const handlePaginationChange = (e, { activePage }) => {
    dispatch(resetPagination(false))
    setPage(activePage)
  }

  const handleSort = (sortKey, header) => {
    if (sortColumn !== sortKey) {
      setSortDirection(initialSortDirection)
    } else {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
    }
    setSortColumn(sortKey)
    setSortColumnHeader(header)
  }

  useEffect(() => {
    fetchFindings()
  }, [page, sortColumn, sortDirection, selectedValues, selectedTab])

  const renderTableHeaders = (header, index) => {
    const sortKey = tableSortKeys[index]
    const isSortable = tableSortableColumnHeaders.includes(sortKey)
    let tableHeaderClassNames = isSortable ? 'clickable' : ''
    const rightAligned = ['days_open', 'priority']
    return (
      <th key={index} className={classnames(header, tableHeaderClassNames, { right: rightAligned.includes(header) })} onClick={() => isSortable ? handleSort(sortKey, header) : null}>
        {removeUnderscores(header)}
        {sortColumnHeader === header ? <FontAwesomeIcon className={sortDirection} icon={faAngleDown} size='lg' /> : ''}
      </th>
    )
  }
  const loadingClass = loading ? 'loading' : ''
  return (
    <div className={`domain-table-wrapper ${loadingClass}`}>
      {loading && <div className='loader-overlay'><Loader active /></div>}
      <table className='rr-table domain-table'>
        <thead>
          <tr>
            <th />
            {tableHeaders.map((header, idx) => renderTableHeaders(header, idx))}
          </tr>
        </thead>
        <tbody>
          {findingsData.findings.length > 0
            ? findingsData.findings.map((finding, idx) => (
              <DomainFindingRow
                key={finding + idx}
                finding={finding}
                rowIndex={idx}
                showFindingSidebarAsync={showFindingSidebarAsync}
                isCriteriaOnly={isCriteriaOnly}
              />
            ))
            : <tr><td className='centered' colSpan={tableHeaders.length + 1}>No data to display for this criteria</td></tr>
          }
        </tbody>
      </table>
      <div className='pagination-wrapper'>
        <Pagination
          activePage={selectorChanged ? 1 : page}
          onPageChange={handlePaginationChange}
          totalPages={findingsData.meta.total_pages}
        />
      </div>
    </div>
  )
}

export default DomainFindingsTable
