import React, { useState } from "react";
import PropTypes from "prop-types";
import { parseISO } from "date-fns";

import {
  Header,
  TableCellData,
  TableRow,
  ViewDetailsCell,
} from "../../../shared";

import "./VulnerabilityDetails.scss";
import {
  setClassColorByConcern,
  formatDate,
  toFixedExceptTen,
  cveScoreToRating,
} from "../../../../helpers/functions";

const VulnerabilityDetails = ({ vulnerability }) => {
  const [showDetails, setShowDetails] = useState(false);

  const handleShowRowDetails = () => {
    setShowDetails(!showDetails);
  };

  const renderVulnerabilityDetails = (vulnerability) => {
    const urls =
      typeof vulnerability.urls === "string"
        ? vulnerability.urls.split(",")
        : vulnerability.urls;
    return (
      <TableCellData colSpan='5'>
        <div className='vulnerability-expanded-details-container'>
          <Header as='h4'>Summary</Header>
          <p className='finding-details-value'>{vulnerability.summary}</p>
          <Header as='h4'>Links</Header>
          {urls.map((url) => (
            <a key={url} href={url} rel='noopener noreferrer' target='_blank'>
              {url}
            </a>
          ))}
        </div>
      </TableCellData>
    );
  };

  const handleAnchorClick = (id) => (e) => {
    e.stopPropagation();
    window.open(
      `https://cve.mitre.org/cgi-bin/cvename.cgi?name=${id}`,
      "_blank"
    );
  };

  const rating = cveScoreToRating(vulnerability.score);

  return (
    <>
      <TableRow
        accordionParent
        expanded={showDetails}
        onClick={() => handleShowRowDetails()}
      >
        <TableCellData>
          <span
            className='vulnerability-info-link'
            onClick={handleAnchorClick(vulnerability.cve_id)}
          >
            {vulnerability.cve_id}
          </span>
        </TableCellData>
        <TableCellData
          className={setClassColorByConcern(
            "cvss_score",
            vulnerability.score
          )}
        >
          {toFixedExceptTen(vulnerability.score)}
        </TableCellData>
        <TableCellData
          className={setClassColorByConcern("cvss_rating", rating)}
        >
          {rating.toLowerCase()}
        </TableCellData>
        <TableCellData>
          {formatDate(
            parseISO(vulnerability?.published_datetime?.split("T")[0]),
            "MM/dd/yyyy"
          )}
        </TableCellData>
        <ViewDetailsCell expanded={showDetails} />
      </TableRow>
      <TableRow accordionChild expanded={showDetails}>
        {showDetails && renderVulnerabilityDetails(vulnerability)}
      </TableRow>
    </>
  );

}

VulnerabilityDetails.propTypes = {
  vulnerability: PropTypes.shape({
    cve_id: PropTypes.string,
    published_datetime: PropTypes.string,
    score: PropTypes.number,
  }).isRequired,
};

export default VulnerabilityDetails;
