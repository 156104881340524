import React from 'react'
import PropTypes from 'prop-types'

const CheckCircleSVG = ({
  size,
  className
}) =>
  <svg className={className} width={size} height={size} viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
    <path fill='none' d='M0 0h24v24H0V0z' />
    <path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7a.996.996 0 111.41-1.41L10 14.17l6.88-6.88a.996.996 0 111.41 1.41l-7.59 7.59a.996.996 0 01-1.41 0z' />
  </svg>

CheckCircleSVG.defaultProps = {
  size: '24'
}

CheckCircleSVG.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string.isRequired
}

export default CheckCircleSVG
