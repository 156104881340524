import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './TextInput.scss'

const TextInput = forwardRef(function TextInput(props, ref) {
  const {
    value,
    onClick,
    onIconClick,
    type,
    name,
    disabled,
    placeholder,
    id,
    label,
    fullBorder,
    invalidText,
    validText,
    onChange,
    icon,
    textLinkText,
    textLinkHref,
    className,
    noLabel,
    error,
    autoFocus,
    autoComplete,
    ...rest
  } = props

  return (
    <div
      className={classnames('vp-text-input', className, {
        'full-border': fullBorder,
        error: !!error,
      })}
    >
      <input
        type={type}
        name={name}
        ref={ref}
        onClick={onClick}
        disabled={disabled}
        placeholder={placeholder}
        id={id}
        value={value}
        onChange={onChange}
        autoFocus={autoFocus}
        autoComplete={autoComplete}
        {...rest}
      />
      {!noLabel && (
        <label htmlFor={id} className={classnames({ disabled: disabled })}>
          {label}
        </label>
      )}
      <div className="icon-wrapper" onClick={() => onIconClick()}>
        {icon}
      </div>
      <div className="text-link-wrapper">
        <a href={textLinkHref}>{textLinkText}</a>
      </div>
      {!fullBorder && <div className="focus-indicator" />}
      {!!error && <div className="error-text">{error}</div>}
      {/* {(value && validText) && <div className="valid-text">{validText}</div>} */}
    </div>
  )
})

TextInput.defaultProps = {
  value: '',
  type: 'text',
  disabled: false,
  fullBorder: false,
  error: '',
  autoComplete: '',
}

TextInput.propTypes = {
  autoFocus: PropTypes.bool,
  autoComplete: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.string.isRequired,
  fullBorder: PropTypes.bool.isRequired,
  icon: PropTypes.element,
  id: PropTypes.string,
  invalidText: PropTypes.string,
  label: PropTypes.string,
  noLabel: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
  textLinkHref: PropTypes.string,
  textLinkText: PropTypes.string,
  type: PropTypes.string.isRequired,
  validText: PropTypes.string,
  value: PropTypes.string.isRequired,
}

export default TextInput
