import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import isMatch from 'lodash/isMatch'
import { clearFlash } from '../../actions/flashActions'

export class FlashMessage extends React.Component {
  componentDidUpdate(prevProps, prevState) {
    if (this.props.flash.messages.length > 0 && !isMatch(prevProps.flash, this.props.flash.messages)) {
      this._clearFlash()
    }
  }

  _clearFlash = debounce(this.props.clearFlash, 7000)

  render() {
    const { flash } = this.props
    const messages = flash.messages.map((message, idx) => {
      return <li key={idx} className="toast__message">{message}</li>
    })

    if (flash.messages.length > 0) {
      return (
        <div className={`toast toast--${flash.kind}`}>
          <div className='toast__content'>
            <p className="toast__kind">{flash.kind}</p>
            <ul className="toast__list">
              { messages }
            </ul>
          </div>
          <div className="toast__close" onClick={this.props.clearFlash}>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.642 15.642" xlink="http://www.w3.org/1999/xlink" enableBackground="new 0 0 15.642 15.642">
              <path fillRule="evenodd" d="M8.882,7.821l6.541-6.541c0.293-0.293,0.293-0.768,0-1.061  c-0.293-0.293-0.768-0.293-1.061,0L7.821,6.76L1.28,0.22c-0.293-0.293-0.768-0.293-1.061,0c-0.293,0.293-0.293,0.768,0,1.061  l6.541,6.541L0.22,14.362c-0.293,0.293-0.293,0.768,0,1.061c0.147,0.146,0.338,0.22,0.53,0.22s0.384-0.073,0.53-0.22l6.541-6.541  l6.541,6.541c0.147,0.146,0.338,0.22,0.53,0.22c0.192,0,0.384-0.073,0.53-0.22c0.293-0.293,0.293-0.768,0-1.061L8.882,7.821z"></path>
            </svg>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}

FlashMessage.propTypes = {
  flash: PropTypes.object.isRequired,
  clearFlash: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  return {
    flash: state.flash
  }
}

export default connect(mapStateToProps, {
  clearFlash
})(FlashMessage)
