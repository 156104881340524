import React, { useEffect, useState, memo } from "react";
import { connect } from "react-redux";
import {
  fetchBanners,
  cycleBanner,
  setActiveBanner,
  resetBannerTimers
} from "../../actions/displayBanners";
import ShowBanner from "../../assets/banners/ShowBanner";

const BannerCard = memo(function BannerCard(props) {
  const {
    banners,
    cycleBanner,
    setActiveBanner,
    showingCurrent,
    resetBannerTimers
  } = props;
  

  const time = 12000;
  const [currentBanner, setCurrent] = useState(0);
  useEffect(() => {
    const next = (currentBanner + 1) % banners.length;
    const id = setTimeout(() => setCurrent(next), time);
    setActiveBanner(banners[currentBanner].id);
    banners.map((banner) => {
      if(!!banner.timerExpired) {
        resetBannerTimers()
      }
      return null
    })

    return () => {
      clearTimeout(id);
      cycleBanner(banners[currentBanner].id);
    };
    /* eslint-disable react-hooks/exhaustive-deps */
    /*
      Disable linter due to adding the dependencies will cause the ui to re-render continuously.
      'currentBanner' must remain in the deps array so it will pass the count for the banners to
      display properly.
    */
  }, [currentBanner]);

    const showTimers = () => {
      if (banners.length < 2) return;
      return (
        <div className="widget-card-button-box-svgs">
          {banners.map((Banner) => (
            <svg width="20" height="20" key={Banner.id}>
              <circle
                cx="10"
                cy="10"
                r="5"
                className={
                  !!Banner.timerExpired || Banner.id === showingCurrent
                    ? "decayed"
                    : "fresh"
                }
              />
              <circle
                cx="10"
                cy="10"
                r="5"
                className="active"
                id=""
                style={{
                  display:
                    Banner.id === showingCurrent && !Banner.timerExpired
                      ? "block"
                      : "none",
                }}
              />
            </svg>
          ))}
        </div>
      );
    };

    if (!banners.length) {
      return <div />;
    }

    return (
      <div className="widget-card-banner">
        {showTimers()}
        <ShowBanner timers={showTimers()} />
      </div>
    );

});

const mapDispatchToProps = {
  fetchBanners,
  cycleBanner,
  setActiveBanner,
  resetBannerTimers
};

const mapStateToProps = (state) => {
  return {
    banners: state.displayBanners.banners,
    displayBanners: state.displayBanners.bannerCount,
    showingCurrent: state.displayBanners.currentDisplayingBanner,
    userData: state.user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BannerCard);
