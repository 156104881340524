import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import step1 from '../../assets/svg/mfa/step1.svg'
import step1Line from '../../assets/svg/mfa/step1Line.svg'
import step3Line from '../../assets/svg/mfa/step3Line.svg'
import step2 from '../../assets/svg/mfa/step2.svg'
import configuredTBT from '../../assets/svg/mfa/configuredTBT.svg'
import LoadingSVG from '../../assets/svg/LoadingSVG'
import request from '../../reference/axiosWrapper'
import Button from '../shared/Button'
import TextInput from '../shared/TextInput'
import './ConfigureTBT.scss'

const QRCode = ({ loaded, path }) => {
  if (loaded) {
    if (path) {
      return <img id='tbt-qr-code' src={path} alt='QR Code' />
    } else {
      return <p>Failed to Load.</p>
    }
  } else {
    return <LoadingSVG size={'50'} />
  }
}

QRCode.propTypes = {
  loaded: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
}

const ConfigureTBT = ({ isConfigured, setConfigured }) => {
  const [code, setCode] = useState('')
  const [error, setError] = useState('')
  const [qrPath, setQrPath] = useState('')
  const [qrPathLoaded, setQrPathLoaded] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const getQRPath = async () => {
    try {
      const requestOptions = {
        method: 'POST',
        url: 'v2/user/mfa/setup/authenticator',
      }
      const response = await request(requestOptions)
      if (response.status === 200) {
        setQrPath(response.data.qr_code)
        setQrPathLoaded(true)
      } else {
        setQrPathLoaded(true)
      }
    } catch {
      setQrPathLoaded(true)
    }
  }

  const handleChange = (e) => setCode(e.target.value)

  const validFields = () => {
    var valid = true
    if (code?.length === 0) {
      valid = false
    }
    return valid
  }

  const submit = async () => {
    setSubmitting(true)
    try {
      const requestOptions = {
        method: 'POST',
        url: 'v2/users/mfa_confirm',
        data: {
          one_time_code: code,
          mfa_strategy: 'rotating_token',
        },
      }
      const response = await request(requestOptions)
      if (response.status === 204) {
        setConfigured('rotating_token')
      } else if (response.status === 422) {
        setError('Invalid code entered.')
        setSubmitting(false)
      } else {
        setError('Invalid code entered.')
        setSubmitting(false)
      }
    } catch {
      setError('Invalid code entered.')
      setSubmitting(false)
    }
  }

  const trySubmit = (e) => {
    e.preventDefault()
    if (validFields()) {
      submit()
    }
  }

  useEffect(() => {
    if (!isConfigured) {
      getQRPath()
    }
  }, [isConfigured])

  if (isConfigured) {
    return (
      <div className='configured-screen'>
        <p>Time Based Tokens Configured</p>
        <img
          style={{ margin: '0 auto', display: 'block' }}
          src={configuredTBT}
          alt='Configured'
        />
      </div>
    )
  }

  return (
    <>
      <div className='configure-steps'>
        <div className='step-block'>
          <p>
            <strong>Step 1:</strong> Open Authenticator
          </p>
          <div className='step-image-container'>
            <img src={step1} alt='Step 1' />
            <img className='step-line' src={step1Line} alt='Step 1 Line' />
          </div>
        </div>
        <div className='step-block'>
          <p>
            <strong>Step 2:</strong> Add Token
          </p>
          <div className='step-image-container'>
            <img src={step2} alt='Step 2' />
            <img className='step-line' src={step1Line} alt='Step 2 Line' />
          </div>
        </div>
        <div className='step-block'>
          <p>
            <strong>Step 3:</strong> Scan QR Code
          </p>
          <div className='step-image-container'>
            <QRCode loaded={qrPathLoaded} path={qrPath} />
            <img className='step3-line' src={step3Line} alt='Step 3 Line' />
          </div>
        </div>
      </div>
      <div>
        <p>
          <strong>Step 4:</strong> Enter Verification Token
        </p>
        <form onSubmit={trySubmit} id='configure-tbt-form'>
          <TextInput
            value={code}
            label='Enter Verification Token'
            id='enter-verification-token'
            name='enter-verification-token'
            onChange={handleChange}
            placeholder='Enter Token'
            fullBorder
            error={error}
            autoComplete='off'
          />
          <Button
            type='submit'
            color='blue outline'
            loading={submitting}
            disabled={!validFields() || submitting}
          >
            Verify
          </Button>
        </form>
      </div>
    </>
  )
}

ConfigureTBT.propTypes = {
  isConfigured: PropTypes.bool.isRequired,
  setConfigured: PropTypes.func.isRequired,
}

export default ConfigureTBT
