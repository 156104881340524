/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { login } from '../../actions/UserActions'
import loginValidator from '../../validators/login'
import TextInput from '../shared/TextInput'
import Button from '../shared/Button'
import { connect } from 'react-redux'
import Header from '../shared/Header'
import Container from '../shared/Container'
import EyeVisibleSVG from '../../assets/svg/EyeVisibleSVG'
import EyeNotVisibleSVG from '../../assets/svg/EyeNotVisibleSVG'

function Login({ login }) {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  })
  const [showPassword, setShowPassword] = useState(false)
  const [emailIsValid, setEmailIsValid] = useState(false)
  const [formIsValid, setFormIsValid] = useState(false)
  const [showFinalStep, setShowFinalStep] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const passwordRef = useRef()

  useEffect(() => {
    if (showFinalStep) {
      passwordRef.current?.focus()
    }
  }, [showFinalStep])

  useEffect(() => {
    const { validations, valid } = loginValidator(formData)
    setFormIsValid(valid)
    setEmailIsValid(validations.email)
  }, [formData.email, formData.password])

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleStep = (e) => {
    e.preventDefault()
    setShowFinalStep(!showFinalStep)
  }

  const submitForm = async (e) => {
    e.preventDefault()
    if (formIsValid) {
      setSubmitting(true)
      await login(formData.email, formData.password)
      setSubmitting(false)
    }
  }

  const { email, password } = formData
  return (
    <Container className='unauth-container' id='email-container'>
      <Header className='unauth-header'>Welcome</Header>
      <p className='unauth-subheader'>Sign in by following the steps below.</p>
      <form className='unauth-login-form' onSubmit={submitForm}>
        {!showFinalStep ? (
          <TextInput
            label='Email'
            type='email'
            name='email'
            placeholder='Ex: Riskrecon@riskrecon.com'
            value={email}
            onChange={onChange}
            autoFocus={true}
          />
        ) : (
          <TextInput
            label='Password'
            type={`${showPassword ? 'text' : 'password'}`}
            name='password'
            ref={passwordRef}
            value={password}
            onChange={onChange}
            autoComplete='none'
            icon={
              showPassword ? (
                <EyeVisibleSVG className='show-hide-password' size='16' />
              ) : (
                <EyeNotVisibleSVG className='show-hide-password' size='16' />
              )
            }
            onIconClick={() => setShowPassword(!showPassword)}
          />
        )}
        {showFinalStep ? (
          <Button
            disabled={!formIsValid || submitting}
            color='blue outline'
            className='unauth-submit'
            type='submit'
            loading={submitting}
          >
            Login
          </Button>
        ) : (
          <Button
            tabIndex={0}
            onClick={(e) => handleStep(e)}
            disabled={!emailIsValid}
            color='blue outline'
            className='unauth-submit'
            type='button'
          >
            Continue
          </Button>
        )}
      </form>
      {showFinalStep && (
        <>
          <div className='unauth-links'>
            <Link to='/forgot-password'>Forgot Password?</Link>
          </div>
          <div className='unauth-email-edit'>
            <p className=''>
              {formData.email} <a onClick={(e) => handleStep(e)}>(Edit)</a>
            </p>
          </div>
        </>
      )}
    </Container>
  )
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
}

export default connect(null, { login })(Login)