import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import request from '../../reference/axiosWrapper'
import { LoadingSVG } from '../../assets/svg'
import Radio from './Radio'
import ConfigureSMS from './ConfigureSMS'
import ConfigureTBT from './ConfigureTBT'
import CheckCircleSVG from '../../assets/svg/CheckCircleSVG.js'
import QuestionMarkOSVG from '../../assets/svg/QuestionMarkOSVG'
import ToastAlert from '../shared/toast-alerts/ToastAlert'
import Tooltip from '../shared/tooltips/Tooltip'
import Loading from '../loaders/Loading'
import './TwoStepVerification.scss'
import { useDispatch, useSelector } from 'react-redux'
import {
  enableMFA,
  disableMFA,
  setMFAConfigurationError,
} from '../../slices/MfaSlice'

const Configured = () => {
  return (
    <div className='configured'>
      <CheckCircleSVG />
      <p>CONFIGURED</p>
    </div>
  )
}

const DisplayConfigFlow = ({
  configureScreen,
  configured,
  setConfigured,
  show,
}) => {
  if (!show) return <div />
  switch (configureScreen) {
    case 'sms': {
      const isConfigured = configured === 'sms'
      return (
        <ConfigureSMS
          isConfigured={isConfigured}
          setConfigured={setConfigured}
        />
      )
    }
    case 'rotating_token': {
      const isConfigured = configured === 'rotating_token'
      return (
        <ConfigureTBT
          isConfigured={isConfigured}
          setConfigured={setConfigured}
        />
      )
    }
    default:
      return <div />
  }
}

DisplayConfigFlow.propTypes = {
  configureScreen: PropTypes.string.isRequired,
  configured: PropTypes.string.isRequired,
  setConfigured: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
}

const TwoStepVerification = () => {
  const [configureScreen, setConfigureScreen] = useState('')
  const [configured, setConfigured] = useState('')
  const [loaded, setLoaded] = useState(false)
  const [success, setSuccess] = useState(false)
  const [enabled, setEnabled] = useState(false)
  const [displayToast, setDisplayToast] = useState(false)

  const {
    awaiting_mfa_enable_disable_response,
    mfa_configuration_error,
    enforce_mfa,
    mfa_enabled,
  } = useSelector((state) => state.mfa)

  const dispatch = useDispatch()

  useEffect(() => {
    setLoaded(false)
    setSuccess(false)
    setEnabled(mfa_enabled)

    if (!mfa_enabled && !enforce_mfa) {
      setLoaded(true)
      setSuccess(true)
      setConfigureScreen('')
      setConfigureScreen('')
      return
    }

    const getDetails = async () => {
      try {
        const requestOptions = {
          method: 'GET',
          url: 'v2/users/mfa_user_details',
        }

        const response = await request(requestOptions)

        if (response?.status === 200) {
          setConfigureScreen(response?.data?.last_mfa_strategy)
          setConfigured(response?.data?.last_mfa_strategy)
          setLoaded(true)
          setSuccess(true)
        } else {
          setLoaded()
        }
      } catch {
        setLoaded(true)
      }
    }
    getDetails()
  }, [mfa_enabled, enforce_mfa])

  useEffect(() => {
    if (mfa_configuration_error) {
      setDisplayToast(true)
    }
  }, [mfa_configuration_error])

  const changeScreen = (e) => setConfigureScreen(e.target.value)
  const configure = (configured) => setConfigured(configured)
  const toggleMFAEnabled = () => {
    if (enabled) {
      dispatch(disableMFA())
    } else {
      dispatch(enableMFA())
    }
  }

  const closeToast = () => {
    setDisplayToast(false)
    dispatch(setMFAConfigurationError(false))
  }

  const tooltipContent = mfa_enabled
    ? 'MFA is currently enabled. To Disable, click the status toggle on the right.'
    : 'MFA is currently disabled. To Enable, click the status toggle on the right.'

  const settingsDisabled = !enforce_mfa && !mfa_enabled
  const settingsEnabled = enforce_mfa || mfa_enabled

  return (
    <div id='multi-factor-verification'>
      <Loading overlay isLoading={awaiting_mfa_enable_disable_response} />
      <div className='heading'>
        <div className={`mfa-header ${settingsDisabled ? 'disable' : ''}`}>
          <p className='title'>MFA/Multi-factor Verification</p>
          {!enforce_mfa && (
            <Tooltip
              className='mfa-disabled-tooltip'
              title={tooltipContent}
              position='right'
            >
              <QuestionMarkOSVG size='20px' />
            </Tooltip>
          )}
          <p className='sub-title'>
            Select your preferred MFA/Multi-factor method.
          </p>
        </div>
        {!enforce_mfa && (
          <div className='mfa-toggle'>
            <div className='mfa-toggle-label'>
              <span className='status-label'>Status:</span>
              <span
                className={`status-value ${
                  mfa_enabled ? 'toggle-enabled' : 'toggle-disabled'
                }`}
              >
                {mfa_enabled ? 'Enabled' : 'Disabled'}
              </span>
            </div>
            <input
              type='checkbox'
              checked={enabled}
              onChange={() => toggleMFAEnabled()}
              id='toggle-mfa-checkbox'
              className='tgl tgl-light'
            />
            <label htmlFor='toggle-mfa-checkbox' className='tgl-btn' />
          </div>
        )}
      </div>
      {loaded ? (
        success ? (
          <div className='selections'>
            <div className={`left-side ${settingsDisabled ? 'disable' : ''}`}>
              <div className='radio-group'>
                <Radio
                  value='sms'
                  label='SMS Text Message'
                  id='smsid'
                  onChange={changeScreen}
                  checked={configureScreen === 'sms'}
                  disabled={settingsDisabled}
                />
                {configured === 'sms' && settingsEnabled && <Configured />}
              </div>
              <div className='radio-group'>
                <Radio
                  value='rotating_token'
                  label='Time Based Tokens'
                  id='TBTid'
                  onChange={changeScreen}
                  checked={configureScreen === 'rotating_token'}
                  disabled={settingsDisabled}
                />
                <span className='sub-text'>
                  {'(E.g. Google Authenticator)'}
                </span>
                {configured === 'rotating_token' && settingsEnabled && (
                  <Configured />
                )}
              </div>
              <p className='bottom-text'>
                Switching MFA/Multi-factor method will require reverification.
              </p>
            </div>
            <div className='right-side'>
              <DisplayConfigFlow
                configureScreen={configureScreen}
                configured={configured}
                setConfigured={configure}
                show={settingsEnabled}
              />
            </div>
          </div>
        ) : (
          <div>Failed To Load.</div>
        )
      ) : (
        <div
          style={{ display: 'flex', justifyContent: 'center', height: '200px' }}
        >
          <div style={{ alignSelf: 'center' }}>
            <LoadingSVG size='75' />
          </div>
        </div>
      )}
      {mfa_configuration_error && (
        <ToastAlert
          message='Something went wrong with the request. Please try again later.'
          onClose={() => closeToast()}
          infoType='danger'
          displayType={2}
          showToast={displayToast}
        />
      )}
    </div>
  )
}

export default TwoStepVerification
