import React from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'

const LoaderIcon = ({ title, minHeight = 300, background = 'none', showText = true }) => {
  const loaderStyles = {
    minHeight,
    background
  }
  return (
    <Dimmer.Dimmable className='loader-icon' style={loaderStyles}>
      <h2>{title || ''}</h2>
      <Dimmer active inverted>
        <Loader>{showText ? 'Loading' : ''}</Loader>
      </Dimmer>
    </Dimmer.Dimmable>
  )
}

export default LoaderIcon
