import React from 'react'
import DomainFindingsTable from './DomainFindingsTable'

const DomainCard = (props) => {
  const {
    showFindingSidebarAsync,
    domainFindingInfo,
    criteriaFindingInfo,
    domainCardKeyMappings,
    loadingDomainFindings,
    loadingCriteriaFindings,
    fetchDomainFindings,
    fetchCriteriaFindings,
    selectedValues,
    user,
    selectedTab
  } = props
  const {
    title,
    issue,
    recommendation,
    tableHeaders,
    criteria,
    domain,
    tableSortKeys,
    tableSortableColumnHeaders
  } = props.domainCardKeyMappings

  const isCriteriaOnly = domainCardKeyMappings.criteria ? true : false
  const expressionAttributes = domainCardKeyMappings.expressionAttributes
  const findingsData = isCriteriaOnly ? criteriaFindingInfo[criteria] : domainFindingInfo
  const loading = isCriteriaOnly ? loadingCriteriaFindings[criteria] : loadingDomainFindings

  return (
    <div className='dashboard-domain-card rr-card'>
      <div className='clearfix'>
        {/* Will be used in later iterations */}
        {/* <div className='btn btn--blue-outline btn--pdf btn--download-domain-report'>
          <img src={require('../../assets/images/btn-icon-pdf.png')} alt='' />
          {title}
        </div> */}
        <h2>{title}</h2>
      </div>
      <div className='domain-summary-wrapper'>
        <div>
          <div className='summary-heading'>Description</div>
          {issue}
        </div>
        <div>
          <div className='summary-heading'>Recommendation</div>
          {recommendation}
        </div>
      </div>
      <DomainFindingsTable
        selectedTab={selectedTab}
        tableHeaders={tableHeaders}
        showFindingSidebarAsync={showFindingSidebarAsync}
        loading={loading}
        findingsData={findingsData}
        fetchDomainFindings={fetchDomainFindings}
        fetchCriteriaFindings={fetchCriteriaFindings}
        isCriteriaOnly={isCriteriaOnly}
        expressionAttributes={expressionAttributes}
        tableSortKeys={tableSortKeys}
        tableSortableColumnHeaders={tableSortableColumnHeaders}
        criteria={criteria}
        domain={domain}
        selectedValues={selectedValues}
        user={user}
      />
    </div>
  )
}

export default DomainCard
