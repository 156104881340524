import React from "react";
import moment from "moment";
import "./IssueStatus.scss";
import {
  Container,
  Header,
  MetricItem,
  Button,
} from "../../../../components/shared";

function IssueStatus({ handleCollabTabChange, finding }) {
  const prettifyResponseStatus = {
    no_action: "No Action",
    investigating: "Investigating",
    compensating_control: "Compensating Control",
    false_positive: "False Positive",
    not_fixing: "Not Fixing",
    fixing: "Fixing",
    fixed_resolved: "Fixed",
    fixed_not_resolved: "Fixed",
    fixed_pending_scan: "Fixed",
  };

  return (
    <Container medium className='issue-status-container'>
      <div className='issue-status'>
        <div style={{ display: "flex",justifyContent:"space-between" }}>
          <Header>My Status</Header>
          {finding.attributes.reporting_status !== "fixed_resolved" && (
            <div className='issue-actions'>
              <Button
                disabled={finding.isResolved}
                onClick={() => handleCollabTabChange("updateStatus")}
                color='red outline'
              >
                Edit
              </Button>
            </div>
          )}
        </div>
        <div className='issue-status-metric-items-container'>
          <div className='metric-item-left-col'>
            <MetricItem
              small
              className='top-metric-item'
              label='Your Status'
              value={
                prettifyResponseStatus[finding?.attributes?.reporting_status]
              }
            />
            <MetricItem
              small
              className='top-metric-item'
              label='Comment'
              value={finding.attributes.vendor_comment || "None"}
            />
          </div>
          <div className='metric-item-right-col'>
            <MetricItem
              small
              label='Due Date'
              value={
                finding.attributes.vendor_due_date
                  ? moment(finding.attributes.vendor_due_date).format(
                      "MM/DD/YYYY"
                    )
                  : "Unspecified"
              }
            />
          </div>
        </div>
      </div>
    </Container>
  );
}

export default IssueStatus;
