import React, { Component } from 'react';
import { findingSidebarAttributes } from '../../../constants/FindingSidebar'
import updateFindingValidator from '../../../validators/updateFinding'
import IssueManagementDescription from './IssueCollaboration/IssueManagementDescription'
import UpdateStatus from './IssueCollaboration/UpdateStatus'
import IssueStatus from './IssueCollaboration/IssueStatus'
import CustomerRequests from './IssueCollaboration/CustomerRequests'
import EventLog from './IssueCollaboration/EventLog'
import PropTypes from 'prop-types'
import 'react-datepicker/dist/react-datepicker-cssmodules.css'

class IssueCollaborationPane extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCollabTab: 'status',
      fixDate: null,
      selectedStatus: '',
      selectedOption: '',
      commentValue: '',
      errors: {}
    };
  }

  handleCollabTabChange = (pane) => {
    this.setState({ selectedCollabTab: pane });
  }

  handleFixDateChange = (date) => {
    this.setState({ fixDate: date });
  }

  handleSelectStatusChange = (status) => {
    this.setState({ selectedStatus: status, selectedOption: '' })
  }

  handleCommentChange = (e) => {
    let comment = e.target.value
    this.setState({ commentValue: comment })
  }

  handleSelectOptionChange = (option) => {
    this.setState({ selectedOption: option })
  }

  onCloseUpdateStatus = () => {
    this.setState({
      selectedCollabTab: 'status',
      fixDate: null,
      selectedStatus: '',
      selectedOption: '',
      commentValue: '',
      errors: {}
    })
    this.handleCollabTabChange('status')
  }

  formIsValid = () => {
    const { errors, valid } = updateFindingValidator(this.state)
    this.setState({ errors: errors})
    return valid
  }

  onFormSubmit = async (e) => {
    const { updateCriteriaFinding, updateDomainFinding, finding } = this.props

    e.preventDefault();
    if (this.formIsValid()) {
      let formattedFixDate = null
      if (this.state.fixDate) {
        let d = new Date(this.state.fixDate),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        formattedFixDate = [month, day, year].join('/');
      }

      const formbody = {
        vendor_status: this.state.selectedStatus.value,
        vendor_status_name: this.state.selectedStatus.label,
        vendor_comment: this.state.commentValue,
        vendor_due_date: formattedFixDate,
        vendor_status_response_key: this.state.selectedOption.value
      }

      if (finding.isCriteriaOnly) {
        await updateCriteriaFinding(finding, formbody, finding.attributes.reporting_status)
        this.onCloseUpdateStatus()
      } else {
        await updateDomainFinding(finding, formbody, finding.attributes.reporting_status)
        this.onCloseUpdateStatus()
      }
    }
  }

  render() {

    const { finding, languageFindings, queryWithLanguageFindings, toe } = this.props;
    const { selectedCollabTab, fixDate, selectedStatus, selectedOption, commentValue, errors } = this.state;

    if (Object.keys(languageFindings).length > 0) {
      const languageDetails = languageFindings[finding.attributes.security_criteria_key]
      if (languageDetails !== undefined) {
        return (
          <div className="issue-collaboration-pane-container">
            {selectedCollabTab === 'status' &&
              <div>
                <IssueManagementDescription finding={finding} languageOptions={findingSidebarAttributes[finding.attributes.security_criteria_key]} />
                <IssueStatus handleCollabTabChange={this.handleCollabTabChange} finding={finding} />
                <CustomerRequests finding={finding} />
                <EventLog finding={finding} />
              </div>
            }
            {selectedCollabTab === 'updateStatus' &&
              <div>
                <IssueManagementDescription finding={finding} languageOptions={findingSidebarAttributes[finding.attributes.security_criteria_key]} />
                <UpdateStatus
                  handleCollabTabChange={this.handleCollabTabChange}
                  finding={finding}
                  handleSelectStatusChange={this.handleSelectStatusChange}
                  handleSelectOptionChange={this.handleSelectOptionChange}
                  handleFixDateChange={this.handleFixDateChange}
                  handleCommentChange={this.handleCommentChange}
                  onCloseUpdateStatus={this.onCloseUpdateStatus}
                  selectedStatus={selectedStatus}
                  selectedOption={selectedOption}
                  commentValue={commentValue}
                  submitForm={this.onFormSubmit}
                  toe={toe}
                  errors={errors}
                  fixDate={fixDate}
                />
                <CustomerRequests finding={finding} />
              <EventLog finding={finding} />
              </div>
            }
          </div>
        );
      }
    }

    if (Object.keys(queryWithLanguageFindings).length > 0) {
      const languageDetails = queryWithLanguageFindings[finding.attributes.finding_detail]
      if (languageDetails !== undefined) {
        return (
          <div className="issue-collaboration-pane-container">
            {selectedCollabTab === 'status' &&
              <div>
                <IssueManagementDescription finding={finding} languageOptions={findingSidebarAttributes[finding.attributes.security_criteria_key]} />
                <IssueStatus handleCollabTabChange={this.handleCollabTabChange} finding={finding} />
                <CustomerRequests finding={finding} />
                <EventLog finding={finding} />
              </div>
            }
            {selectedCollabTab === 'updateStatus' &&
              <div>
                <IssueManagementDescription finding={finding} languageOptions={findingSidebarAttributes[finding.attributes.security_criteria_key]} />
                <UpdateStatus
                  handleCollabTabChange={this.handleCollabTabChange}
                  finding={finding}
                  handleSelectStatusChange={this.handleSelectStatusChange}
                  handleSelectOptionChange={this.handleSelectOptionChange}
                  handleFixDateChange={this.handleFixDateChange}
                  handleCommentChange={this.handleCommentChange}
                  onCloseUpdateStatus={this.onCloseUpdateStatus}
                  selectedStatus={selectedStatus}
                  selectedOption={selectedOption}
                  submitForm={this.onFormSubmit}
                  errors={errors}
                  toe={toe}
                  commentValue={commentValue}
                  fixDate={fixDate}
                />
                <CustomerRequests finding={finding} />
                <EventLog finding={finding} />
              </div>
            }
          </div>
        );
      }
    }
    return (<div className="issue-details-pane-container" />);
  }
}

IssueCollaborationPane.propTypes = {
  finding: PropTypes.objectOf(PropTypes.any).isRequired,
  queryWithLanguageFindings: PropTypes.objectOf(PropTypes.any).isRequired,
  languageFindings: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default IssueCollaborationPane;
