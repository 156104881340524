import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import './TableBody.scss'

const TableBody = ({
  children,
  className,
  myRef,
  ...rest
}) => {
  return (
    <tbody
      ref={myRef}
      className={classnames('v3-tbody', className)}
      {...rest}
    >
      {children}
    </tbody>
  )
}

TableBody.defaultProps = {
  // No default props
}

TableBody.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  className: PropTypes.string,
  myRef: PropTypes.oneOfType([PropTypes.object]),
}

export default TableBody
