import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { LoadingSVG } from '../../../assets/svg'
import './Button.scss'

/**
 * Use `Button` as primary button element.
 */
const Button = ({
  children,
  onClick,
  type,
  disabled,
  color,
  className,
  loading,
  ...rest
}) => {
  return (
    <button
      type={type}
      onClick={loading ? undefined : onClick}
      disabled={disabled}
      className={classnames('v3-button', color, className, { loading })}
      {...rest}
    >
      {loading ? <LoadingSVG size="24" /> : children}
    </button>
  )
}

Button.defaultProps = {
  type: 'button',
  disabled: false,
  color: 'green',
  className: '',
}

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array,
  ]),
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'green',
    'green outline',
    'red',
    'red outline',
    'blue',
    'blue outline',
    'dark-blue',
    'dark-blue outline',
    'dark',
    'dark outline',
    'silver outline',
    '',
  ]).isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.string.isRequired,
}

export default Button
