import React, { useEffect, useState } from 'react'
import jwt_decode from 'jwt-decode'
import axios from 'axios'
import Button from '../../shared/Button'
import Container from '../../shared/Container'
import Header from '../../shared/Header'
import TextInput from '../../shared/TextInput'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchingUser,
  setUserLoggedIn,
  logout,
} from '../../../actions/UserActions'
import { fetchToeSummary } from '../../../actions/dashboardActions'
import { setUserMfa } from '../../../slices/MfaSlice'
import './mfa.scss'
import { authURL } from '../../../utils/environmentHelpers'

const ConfirmSMS = () => {
  const [code, setCode] = useState('')
  const [error, setError] = useState('')
  const [resendingSMS, setResendingSMS] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const dispatch = useDispatch()
  const { temp_token } = useSelector((state) => state.mfa)

  useEffect(() => {
    sendCode()
    // eslint-disable-next-line
  }, [])

  const trySubmit = (e) => {
    e.preventDefault()
    if (validFields()) {
      submit()
    }
  }

  const validFields = () => {
    let valid = true
    if (code.length === 0) {
      valid = false
    }
    return valid
  }

  const submit = async () => {
    setSubmitting(true)
    try {
      const requestOptions = {
        method: 'POST',
        url: authURL(window.location.host) + 'v2/users/login_mfa_confirm',
        headers: {
          Authorization: `Bearer ${temp_token}`,
          'Content-Type': 'application/json',
        },
        data: {
          one_time_code: code,
          mfa_strategy: 'sms',
        },
      }
      const response = await axios(requestOptions)
      if (response.status === 200) {
        const accessToken = response.data?.access_token
        const refreshToken = response.data?.refresh_token
        window.sessionStorage.setItem('accessToken', accessToken)
        window.sessionStorage.setItem('refreshToken', refreshToken)
        const decodedToken = jwt_decode(accessToken)
        const {
          authy_id,
          authy_verified_at,
          configured_mfa_channel,
          enforce_mfa,
          sso_enabled,
          last_mfa_strategy,
          mfa_enabled,
        } = decodedToken.user
        await dispatch(fetchingUser(true))
        await dispatch(setUserLoggedIn(decodedToken.user))
        await dispatch(
          setUserMfa({
            authy_id,
            authy_verified_at,
            configured_mfa_channel,
            enforce_mfa,
            sso_enabled,
            last_mfa_strategy,
            mfa_enabled,
          })
        )
        await dispatch(fetchToeSummary())
        await dispatch(fetchingUser(false))
        setSubmitting(false)
      } else if (response.status === 422) {
        setError('Invalid code entered.')
        setSubmitting(false)
      } else {
        setError('Invalid code entered.')
        setSubmitting(false)
      }
    } catch (error) {
      console.log('error submitting SMS code: ', error)
      setError('Invalid code entered.')
      setSubmitting(false)
    }
  }

  const resend = async () => {
    setResendingSMS(true)
    setCode('')
    await sendCode()
    setResendingSMS(false)
  }

  const sendCode = async () => {
    try {
      const requestOptions = {
        method: 'POST',
        url: authURL(window.location.host) + 'v2/users/request_sms',
        headers: {
          Authorization: `Bearer ${temp_token}`,
          'Content-Type': 'application/json',
        },
      }
      await axios(requestOptions)
    } catch (error) {
      console.log('error requesting sms code: ', error)
    }
  }

  const disabledVerify = !validFields() || submitting
  const disabledResend = resendingSMS || submitting

  return (
    <Container id='configure-mfa verify-sms' className='mfa-screen'>
      <Header>Enter Verification Code</Header>
      <Header as='h3'>Enter the code to access your account</Header>
      <form onSubmit={trySubmit}>
        <div className='enter-code-group'>
          <TextInput
            value={code}
            id='code'
            name='code'
            noLabel
            fullBorder
            placeholder='Enter Code'
            error={error}
            onChange={(e) => setCode(e.target.value)}
            autoComplete='off'
            autoFocus={true}
          />
          <Button
            onClick={resend}
            color='blue outline'
            loading={resendingSMS}
            disabled={disabledResend}
          >
            Resend Code
          </Button>
        </div>
        <Button
          style={{ width: '100%' }}
          color='blue outline'
          disabled={disabledVerify}
          loading={submitting}
          type='submit'
        >
          Verify
        </Button>
      </form>
      <div className='sign-out'>
        <a href='/logout' onClick={() => dispatch(logout())}>
          Sign Out
        </a>
      </div>
    </Container>
  )
}

export default ConfirmSMS
