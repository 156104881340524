import React, { useEffect, useRef } from 'react'
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock'
import LoadingSVG from '../../assets/svg/LoadingSVG'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './Loading.scss'
/**
 *  The Loading with overlay relies on a parent element having a `position: relative` to determine size of overlay. The overlay does not have border-radius so it may need some added CSS when in a container with border-radius. If it is the direct descendent of a Container, the appropriate border-radius should be added.
 */
const Loading = ({
  size,
  isLoading,
  overlay,
  className,
  refresh,
  children,
  loadingText,
}) => {
  const overlayEl = useRef()

  useEffect(() => {
    const overlayElement = overlayEl?.current
    if (isLoading && className === 'global-loader') {
      disableBodyScroll(overlayElement)
    }

    if (overlayElement) {
      enableBodyScroll(overlayElement)
    }
    return () => clearAllBodyScrollLocks(overlayElement)
  }, [isLoading, className])

  if (!isLoading) return null

  if (refresh) {
    return (
      <div className={classnames('v3-refresh-loading', className)}>
        <LoadingSVG size="15" />
        <span>{children}</span>
      </div>
    )
  }

  const computedSize = (() => {
    switch (size) {
      case 'small':
        return '25'
      case 'large':
        return '75'
      default:
        return '50'
    }
  })()

  return (
    <div
      className={classnames('v3-loading', size, className, {
        'with-overlay': overlay,
      })}
    >
      <div ref={overlayEl} className="overlay">
        <LoadingSVG size={computedSize} />
        {loadingText && <div className="loading-text">{loadingText}</div>}
      </div>
    </div>
  )
}

Loading.defaultProps = {
  size: 'medium',
  isLoading: false,
  loadingText: 'Loading',
}

Loading.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  loadingText: PropTypes.string,
  overlay: PropTypes.bool,
  refresh: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
}

export default Loading
