import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  COUNTRY_PHONE_CODES,
  validPhoneNumber,
} from '../../../utils/phoneNumbers'
import Button from '../../shared/Button'
import Container from '../../shared/Container'
import Header from '../../shared/Header'
import Select from '../../shared/Select'
import TextInput from '../../shared/TextInput'
import './mfa.scss'
import { useSelector } from 'react-redux'
import axios from 'axios'
import Tooltip from '../../shared/tooltips/Tooltip'
import { authURL } from '../../../utils/environmentHelpers'

const SetupMFASMS = ({
  countryCode,
  setCountryCode,
  phoneNumber,
  setAuthyId,
  setPhoneNumber,
}) => {
  const [submitting, setSubmitting] = useState(false)
  const { temp_token } = useSelector((state) => state.mfa)

  const validFields = () => {
    let valid = true
    if (countryCode.value?.length === 0) {
      valid = false
    }
    if (phoneNumber?.length === 0) {
      valid = false
    } else if (!validPhoneNumber(phoneNumber)) {
      valid = false
    }
    return valid
  }

  const trySubmit = (e) => {
    e.preventDefault()
    setSubmitting(true)
    if (validFields()) {
      submit()
    } else {
      setSubmitting(false)
    }
  }

  const submit = async () => {
    try {
      const requestOptions = {
        method: 'POST',
        url: authURL(window.location.host) + 'v2/users/setup_sms',
        headers: {
          Authorization: `Bearer ${temp_token}`,
          'Content-Type': 'application/json',
        },
        data: {
          user: {
            cellphone: phoneNumber,
            country_code: countryCode.value,
          },
        },
      }
      const response = await axios(requestOptions)
      if (response.status === 200) {
        setAuthyId(response.data.authy_id)
      } else {
        setSubmitting(false)
      }
    } catch (error) {
      console.log('error submitting sms code: ', error)
      setSubmitting(false)
    }
  }

  const disabledButton = !validFields() || submitting

  const logout = () => window.sessionStorage.removeItem('state')
  const tooltipContent =
    'SMS verification not supported in China, please use Time Based Tokens (E.g. Google Authenticator) verification or a different Country Code'

  return (
    <Container id='setup-mfa' className='mfa-screen'>
      <Header>Setup Secure Verification Code</Header>
      <Header as='h3'>Please enter the information below to continue.</Header>
      <form onSubmit={trySubmit}>
        <Select
          options={COUNTRY_PHONE_CODES}
          isOptionDisabled={(option) => option.disableForMfa}
          getOptionLabel={(option) =>
            !option.disableForMfa ? (
              option.label
            ) : (
              <Tooltip title={tooltipContent}>
                {option.label} - SMS verification not supported
              </Tooltip>
            )
          }
          selected={countryCode}
          onChange={setCountryCode}
        />
        <TextInput
          value={phoneNumber}
          id='cellphone'
          name='cellphone'
          label='Phone Number'
          placeholder='Ex: 555-555-5555'
          autoFocus={true}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
        <Button
          style={{ width: '100%' }}
          type='submit'
          disabled={disabledButton}
          color='blue outline'
          loading={submitting}
        >
          Continue
        </Button>
      </form>
      <div className='sign-out'>
        <a href='/logout' onClick={logout}>
          Sign Out
        </a>
      </div>
    </Container>
  )
}

SetupMFASMS.propTypes = {
  countryCode: PropTypes.object.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  setAuthyId: PropTypes.func.isRequired,
  setCountryCode: PropTypes.func.isRequired,
  setPhoneNumber: PropTypes.func.isRequired,
}

export default SetupMFASMS
