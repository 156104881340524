import strIsEmpty from 'validator/lib/isEmpty'

const resetPasswordValidator = (state) => {
  let validations = {
    length: false,
    oneUpper: false,
    oneLower: false,
    oneNumerical: false,
    oneSpecial: false,
    passwordsMatch: false,
    fieldsNotEmpty: false
  }

  const lengthConstraints = str => str.length >= 8 && str.length <= 64
  const atLeastOneLowercase = new RegExp('(?=.*[a-z])')
  const atLeastOneUppercase = new RegExp('(?=.*[A-Z])')
  const atLeastOneNumericalValue = new RegExp('(?=.*\\d)')
  // OWASP defined special characters - https://owasp.org/www-community/password-special-characters
  const atLeastOneOWASPDefinedSpecialCharacter = new RegExp('(?=.*[ !"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~}\\\\])')

  validations.length = lengthConstraints(state.password)
  validations.oneLower = atLeastOneLowercase.test(state.password)
  validations.oneUpper = atLeastOneUppercase.test(state.password)
  validations.oneNumerical = atLeastOneNumericalValue.test(state.password)
  validations.oneSpecial = atLeastOneOWASPDefinedSpecialCharacter.test(state.password)
  validations.fieldsNotEmpty = !strIsEmpty(state.password) && !strIsEmpty(state.confirmPassword)
  validations.passwordsMatch = state.password === state.confirmPassword

  const fieldsValid = validations.length && validations.oneUpper && validations.oneLower && validations.oneNumerical && validations.oneSpecial && validations.passwordsMatch && validations.fieldsNotEmpty

  return {
    validations,
    valid: fieldsValid
  }
}

export default resetPasswordValidator
