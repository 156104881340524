import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import './TableHead.scss'

const TableHead = ({
  children,
  className,
  ...rest
}) => {
  return (
    <thead
      className={classnames('v3-thead', className)}
      {...rest}
    >
      {children}
    </thead>
  )
}

TableHead.defaultProps = {
  // No default props
}

TableHead.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.array]),
  className: PropTypes.string,
}

export default TableHead
