import React, { useState } from 'react'
import PropTypes from 'prop-types'
import request from '../../../reference/axiosWrapper'
import jwt_decode from 'jwt-decode'
import Button from '../../shared/Button'
import Container from '../../shared/Container'
import Header from '../../shared/Header'
import TextInput from '../../shared/TextInput'
import './mfa.scss'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { fetchingUser, setUserLoggedIn } from '../../../actions/UserActions'
import { setMFAConfigurationError, setUserMfa } from '../../../slices/MfaSlice'
import { fetchToeSummary } from '../../../actions/dashboardActions'
import { authURL } from '../../../utils/environmentHelpers'

const SetupMFAConfirm = ({ authyId, setAuthyId, phoneNumber }) => {
  const [code, setCode] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [submittingResend, setSubmittingResend] = useState(false)
  const dispatch = useDispatch()
  const { temp_token } = useSelector((state) => state.mfa)

  const validFields = () => {
    var valid = true
    if (code?.length === 0) {
      valid = false
    }
    return valid
  }

  const trySubmit = (e) => {
    e.preventDefault()
    if (validFields()) {
      setSubmitting(true)
      submit()
    }
  }

  const submit = async () => {
    try {
      const requestOptions = {
        method: 'POST',
        // this is different from v2/users/mfa_verify in that it
        // also authorizes / logs the user in and grants a token pair
        url: authURL(window.location.host) + 'v2/users/login_mfa_verify',
        headers: {
          Authorization: `Bearer ${temp_token}`,
          'Content-Type': 'application/json',
        },
        data: { authy_id: authyId, one_time_code: code },
      }

      const response = await axios(requestOptions)
      if (response.status === 200) {
        const accessToken = response.data?.access_token
        const refreshToken = response.data?.refresh_token
        window.sessionStorage.setItem('accessToken', accessToken)
        window.sessionStorage.setItem('refreshToken', refreshToken)
        const decodedToken = jwt_decode(accessToken)
        const {
          authy_id,
          authy_verified_at,
          configured_mfa_channel,
          enforce_mfa,
          sso_enabled,
          last_mfa_strategy,
          mfa_enabled,
        } = decodedToken.user
        await dispatch(fetchingUser(true))
        await dispatch(setUserLoggedIn(decodedToken.user))
        await dispatch(
          setUserMfa({
            authy_id,
            authy_verified_at,
            configured_mfa_channel,
            enforce_mfa,
            sso_enabled,
            last_mfa_strategy,
            mfa_enabled,
          })
        )
        await dispatch(fetchToeSummary())
        await dispatch(fetchingUser(false))
        setSubmitting(false)
      } else {
        setSubmitting(false)
      }
    } catch (error) {
      console.log('error verifying sms code: ', error)
      setMFAConfigurationError('Error')
      setSubmitting(false)
    }
  }

  const resendCode = async () => {
    setSubmittingResend(true)
    try {
      const requestOptions = {
        method: 'POST',
        url: 'v2/users/request_sms',
        data: {},
      }

      await request(requestOptions)
    } catch (error) {
      console.log('error requesting sms: ', error)
    }
    setSubmittingResend(false)
  }

  const logout = () => window.sessionStorage.removeItem('state')

  const disabledButton = !validFields() || submitting

  const digitsPhoneNumber = phoneNumber.replace(/-/g, '')

  const displayPhoneNumber = digitsPhoneNumber.slice(
    digitsPhoneNumber?.length - 2
  )

  return (
    <Container id='confirm-mfa' className='mfa-screen'>
      <Header>Enter Verification Code</Header>
      <div className='enter-code-text'>
        Enter the code we sent to
        <span title='Change Number' onClick={() => setAuthyId(null)}>
          {`********${displayPhoneNumber}`}
        </span>
        to access your account
      </div>
      <form onSubmit={trySubmit}>
        <div className='enter-code-group'>
          <TextInput
            value={code}
            id='code'
            name='code'
            noLabel
            fullBorder
            placeholder='Enter Code'
            autoFocus={true}
            onChange={(e) => setCode(e.target.value)}
            autoComplete='off'
          />
          <Button
            onClick={resendCode}
            loading={submittingResend}
            color='blue outline'
            disabled={submittingResend || submitting}
          >
            Resend Code
          </Button>
        </div>
        <Button
          style={{ width: '100%' }}
          color='blue outline'
          type='submit'
          loading={submitting}
          disabled={disabledButton}
        >
          Verify
        </Button>
        <div className='would-you-like'>
          Would you Like to enable other forms of MFA/Multi-factor? See My
          Account.
        </div>
      </form>
      <div className='sign-out'>
        <a href='/logout' onClick={logout}>
          Sign Out
        </a>
      </div>
    </Container>
  )
}

SetupMFAConfirm.propTypes = {
  authyId: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  setAuthyId: PropTypes.func.isRequired,
}

export default SetupMFAConfirm
