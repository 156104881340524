import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import './TableCellData.scss'

const TableCellData = ({
  children,
  className,
  color,
  align,
  width,
  dataValue,
  ...rest
}) => {
  return (
    <td
      className={classnames('v3-td', className, color, align)}
      data-value={dataValue || (typeof children === 'string' ? children : null)} // this is a little secret sauce to prevent the tds from resizing on hover due to font-weight change. https://stackoverflow.com/questions/5687035/css-bolding-some-text-without-changing-its-containers-size
      width={width}
      align={align}
      {...rest}
    >
      {children}
    </td>
  )
}

TableCellData.defaultProps = {
  className: '',
  color: '',
  align: 'left',
  width: null,
}

TableCellData.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element, PropTypes.array, PropTypes.node]),
  className: PropTypes.string,
  color: PropTypes.string,
  dataValue: PropTypes.string,
  width: PropTypes.string,
}

export default TableCellData
