export const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  valueContainer: styles => ({ ...styles, ':active': { 'border': '1px solid #6BC048#6BC048' } }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected ? 'rgba(151, 151, 151, 0.09)' : isFocused ? 'rgba(151, 151, 151, 0.09)' : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
          ? 'black'
          : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default'
    }
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: 'rgba(213, 221, 228, 1)',
      color: '#435A70'
    }
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: '#435A70'
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: '#435A70',
    ':hover': {
      backgroundColor: 'rgba(213, 221, 228, 1)',
      color: '#435A70'
    }
  })
}
