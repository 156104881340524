import React from 'react'
import Tooltip from '../shared/ToolTip'
import QuestionMarkOSVG from '../shared/QuestionMarkOSVG.js'
import PropTypes from 'prop-types'

function WidgetCard ({ children, centerTitle, title, cardType, showToolTip, toolTipText }) {
  return (
    <div className={`widget-card rr-card ${cardType}`}>
      <div className={`widget-card--title ${centerTitle ? 'centered' : ''}`}>
        {title} {showToolTip && <Tooltip className="widget-card-tooltip" title={toolTipText}><QuestionMarkOSVG /></Tooltip>}
      </div>
      {children}
    </div>
  )
}

WidgetCard.propTypes = {
  title: PropTypes.string.isRequired,
  centerTitle: PropTypes.bool,
  showToolTip: PropTypes.bool,
  toolTipText: PropTypes.string,
}

WidgetCard.defaultProps = {
  showToolTip: false,
  toolTipText: '',
}

export default WidgetCard
