import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { requestForgotPassword } from '../../actions/UserActions'
import Button from '../shared/Button'
import Container from '../shared/Container'
import Header from '../shared/Header'
import TextInput from '../shared/TextInput'
import PropTypes from 'prop-types'
import strIsEmpty from 'validator/lib/isEmpty'
import isEmail from 'validator/lib/isEmail'

function ForgotPassword({ requestForgotPassword }) {
  let history = useHistory()
  const [formData, setFormData] = useState({
    email: '',
  })

  const [emailIsValid, setEmailIsValid] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    if (!strIsEmpty(formData.email) && isEmail(formData.email)) {
      setEmailIsValid(true)
    } else setEmailIsValid(false)
  }, [formData.email])

  function onChange(e) {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  async function submitForm(e) {
    e.preventDefault()
    if (emailIsValid) {
      setSubmitting(true)
      await requestForgotPassword(formData.email)
      setSubmitting(false)
      history.push('/login')
    }
  }

  const { email } = formData

  return (
    <Container className='unauth-container'>
      <Header as='h1' className='unauth-header'>
        Forgot your Password?
      </Header>
      <p className='unauth-subheader'>
        Reset your password by following the steps below.
      </p>
      <form className='forgot-password-form' onSubmit={submitForm}>
        <TextInput
          label='Email'
          type='email'
          placeholder='Ex: Riskrecon@riskrecon.com'
          value={email}
          name='email'
          onChange={onChange}
          autoFocus={true}
        />
        <Button
          disabled={!emailIsValid || submitting}
          loading={submitting}
          color='blue outline'
          className='unauth-submit forgot-password'
          onClick={submitForm}
        >
          Send Email Instructions
        </Button>
      </form>
      <div className='unauth-links'>
        <p>Remember your password?&nbsp;</p>
        <Link to='/login'>Back to Login</Link>
      </div>
    </Container>
  )
}

ForgotPassword.propTypes = {
  requestForgotPassword: PropTypes.func.isRequired,
}

export default connect(null, { requestForgotPassword })(ForgotPassword)
