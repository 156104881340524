import { setFlash } from './flashActions';
import {downloadPdfSuccess} from './dashboardActions';

export const ADD_VENDOR_ACTION_PLAN_PDF_ID = 'ADD_VENDOR_ACTION_PLAN_PDF_ID';
export const CLEAR_VENDOR_ACTION_PLAN_PDF_IDS = 'CLEAR_VENDOR_ACTION_PLAN_PDF_IDS';
export const UPDATE_ID_LISTS = 'UPDATE_ID_LISTS';

export function addIdToList(idObj, type){
  switch(type){
    case 'actionPlanPdf':
      return {
        type: ADD_VENDOR_ACTION_PLAN_PDF_ID,
        idObj
      };
    default:
      console.log('Bad ID type: ' + type);
      break;
  }
}

export function clearIdsList(type){
  switch(type){
    case 'actionPlanPdf':
      return {
        type: CLEAR_VENDOR_ACTION_PLAN_PDF_IDS,
      };
    default:
      console.log('Bad ID type: ' + type);
      break;
  }
}

export const updateIdLists = (newVendorActionPlanPdfIdObjects) => dispatch => {
  return dispatch({
    type: UPDATE_ID_LISTS,
    newVendorActionPlanPdfIdObjects,
  });
}

export const failedToGetFile = (message) => dispatch => {
  dispatch(downloadPdfSuccess());
  return dispatch(setFlash([message], 'error'));
}

export const succeededToGetFile = (message) => dispatch => {
  dispatch(downloadPdfSuccess());
  return dispatch(setFlash([message], 'success'));
}