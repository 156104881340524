import React from "react";
import { removeUnderscores, setClassColorByConcern } from "../../../../helpers/TextHelpers";
import "./IssueManagementDescription.scss";
import { Container, Header, MetricItem } from "../../../../components/shared";
import { parseISO } from 'date-fns'
import { formatDate, formatPriority } from "../../../../helpers/functions";

function IssueManagementDescription({ finding, languageOptions }) {

  const displayIssueDescriptionValue = (finding, attribute) => {
    if (attribute === 'security_criteria_rating_numeric') {
      if (finding._embedded) {
        if (finding.security_criteria === 'patching_openssl') {
          const criteriaObject = finding._embedded['rel:security_criteria'].find(x => x.name === 'patching_vuln_open_ssl')
          return {
            value: criteriaObject.security_criteria_rating_numeric,
            valueClassName: setClassColorByConcern(attribute, criteriaObject.security_criteria_rating_numeric),
          }
        }
        const criteriaObject = finding._embedded['rel:security_criteria'].find(x => x.name === finding.security_criteria)
        return {
          value: criteriaObject.security_criteria_rating_numeric,
          valueClassName: setClassColorByConcern(attribute, criteriaObject.security_criteria_rating_numeric),
        }
      } else {
        return {
          value: '',
          valueClassName: null,
        }
      }
    }
  
    // Temporary measure so were not repeating data - Scott Romney
    if (finding.security_domain_key === 'web_encryption') {
      if (attribute === 'finding_data_value') {
        const splitAttribute = finding[attribute].split(',')
        if (splitAttribute?.length > 0) {
          return {
            value: splitAttribute[0].trim(),
            valueClassName: setClassColorByConcern(attribute, finding[attribute]),
          }
        }
      }
    }
  
    if (attribute === 'support_status') {
      return {
        value: 'End of Life',
        valueClassName: setClassColorByConcern(attribute, finding[attribute]),
      }
    }
  
    if (attribute === 'first_seen' || attribute === 'last_seen') {
      return {
        value: formatDate(parseISO(finding[attribute]?.split('T')[0]), 'MM/dd/yyyy'),
        valueClassName: null,
      }
    }
  
    if (attribute === 'priority') {
      return {
        value: formatPriority(finding[attribute]),
        valueClassName: setClassColorByConcern(attribute, finding[attribute]),
      }
    }
  
    if (attribute === 'finding_data_value') {
      return {
        value: finding[attribute],
        valueClassName: 'word-break',
      }
    }
  
    return {
      value: finding[attribute],
      valueClassName: setClassColorByConcern(attribute, finding[attribute]),
    }
  }

  return (
    <Container medium className="issue-management-description">
      <Header>Issue Description</Header>
      <div className="metric-container">
        {Object.keys(languageOptions.issue_management_attributes).map((key) => {
          const valueData = displayIssueDescriptionValue(finding.attributes, key)
          return (
            <MetricItem
              key={key}
              small
              label={removeUnderscores(languageOptions.issue_management_attributes[key])}
              value={valueData.value}
              valueClassName={valueData.valueClassName}
            />
          )
        })}
      </div>
    </Container>
  );
}

export default IssueManagementDescription;
