import { NEW_BANNERS, UPDATE_BANNER, SET_ACTIVE, RESET_TIMERS } from "../actions/Types";
const initialState = {
  bannerCount: false,
  currentDisplayingBanner: null,
  banners: [],
};

const displayBanners = (state = initialState, action) => {
  switch (action.type) {
    case NEW_BANNERS:
      return {
        ...state,
        banners: action.banners,
        currentDisplayingBanner: action.banners.length
          ? action.banners[0].id
          : null,
        bannerCount: true,
      };
    case SET_ACTIVE:
      return {
        ...state,
        currentDisplayingBanner: action.id,
      };
    case UPDATE_BANNER:
      return {
        ...state,
        currentDisplayingBanner: action.id,
        banners: state.banners.map((banner) => {
          if (banner.id !== action.id) {
            return {
              ...banner,
            };
          }
          return {
            ...banner,
            timerExpired: !banner.true,
          };
        }),
      };
    case RESET_TIMERS:
      return {
        ...state,
        banners: state.banners.map((banner) => {
          return {
            ...banner,
            timerExpired: false
          }
        })
      }
    default:
      return state;
  }
};

export default displayBanners;
