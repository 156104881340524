import React from 'react'
import PropTypes from 'prop-types'
import {
  Container,
  Header,
  MetricItem,
} from '../../../shared'
import './DomainSection.scss'

const DomainSection = ({ finding }) => (
  <Container medium className="domain-section">
    <Header>Domain</Header>
    <div className="domain-section-metrics-container">
      {finding.attributes.domain_name &&
        <MetricItem
          small
          label="Domain Name"
          value={finding.attributes.domain_name}
        />
      }
      {finding.attributes.registrant_name &&
        <MetricItem
          small
          label="Registrant Name"
          value={finding.attributes.registrant_name}
        />
      }
      {finding.attributes.registrant_org &&
        <MetricItem
          small
          label="Registrant Org"
          value={finding.attributes.registrant_org}
        />
      }
      {finding.attributes.registrant_email &&
        <MetricItem
          small
          label="Registrant Email"
          value={finding.attributes.registrant_email}
        />
      }
    </div>
  </Container>
)

DomainSection.propTypes = {
  finding: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default DomainSection
