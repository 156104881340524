import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import './Table.scss'

const Table = ({
  children,
  className,
  sortable,
  ...rest
}) => {
  return (
    <table
      className={classnames('v3-table', className, { sortable })}
      {...rest}
    >
      {children}
    </table>
  )
}

Table.defaultProps = {
  className: '',
}

Table.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.array]),
  className: PropTypes.string,
  sortable: PropTypes.bool,
}

export default Table
