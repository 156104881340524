import React from "react";
import { connect } from "react-redux";
import RRbanner from "./RiskRecon/RR_banner";

const ShowBanner = (props) => {
  const { showingCurrent = "" } = props;
  switch (showingCurrent) {
    case "RR":
      return <RRbanner />;
    default:
      return;
  }
};

const mapStateToProps = (state) => {
  return {
    showingCurrent: state.displayBanners.currentDisplayingBanner,
  };
};

export default connect(mapStateToProps)(ShowBanner);
