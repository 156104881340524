export function applicationId(hostname) {
  // currently the application_id is always the same
  // if we ever want to change that, this gives us an entrypoint to do so
  // based on the environment it's running
  return "2ac80adf2438d6f1e201402d0324aa84feded09d4c5ee912115e2a16d28440ad"
}

export function portalEnv(hostname) {
  const capture_groups = parseHost(hostname)
  var base

  if (capture_groups.dotenv === '.f5') {
    base = 'beta'
  } else if (capture_groups.dotenv === '.dev') {
    base = 'development'
  } else if (capture_groups.dotenv === '.gov') {
    base = 'fedramp'
  } else if (capture_groups.env?.includes('-dev')) {
    base = capture_groups.env.replace('-dev', '').replace('-', '')
  } else if (capture_groups.env === '-stg') {
    base = 'staging'
  } else if (capture_groups.env?.includes('-stg')) {
    base = capture_groups.env.replace('-stg', '').replace('-', '')
  } else {
    base = 'production'
  }

  if (capture_groups.domain.includes('.eu')) {
    return `${base}-eu`
  } else {
    return base
  }
};

export function authURL(hostname) {
  const capture_groups = parseHost(hostname)

  if (capture_groups.dotenv === '.f5') {
    return 'https://auth.f5.riskrecon.com/'
  } else if (capture_groups.dotenv === '.gov') {
    return `https://auth.gov.${capture_groups.domain}/`
  } else if (capture_groups.dotenv === '.dev') {
    return `https://auth.dev.${capture_groups.domain}/`
  } else if (capture_groups.name === 'vendor') {
    return `https://auth.${capture_groups.domain}/`
  } else {
    return `https://web-backend${capture_groups.env}.${capture_groups.domain}/`
  }
};

export function pdfURL(hostname) {
  const capture_groups = parseHost(hostname)

  if (capture_groups.dotenv === '.f5') {
    return 'https://distributed-api-tst.riskrecon.com/'
  } else if (capture_groups.dotenv === '.gov') {
    return `https://api.gov.${capture_groups.domain}/`
  } else if (capture_groups.dotenv === '.dev') {
    return `https://api.dev.${capture_groups.domain}/`
  } else if (capture_groups.env?.includes('-dev')) {
    return `https://distributed-api-dev.${capture_groups.domain}/`
  } else if (capture_groups.name === 'vendor') {
    return `https://api.${capture_groups.domain}/`
  } else {
    return `https://distributed-api${capture_groups.env}.${capture_groups.domain}/`
  }
};

export function portalURL(hostname) {
  const capture_groups = parseHost(hostname)

  if (capture_groups.dotenv === '.f5') {
    return 'https://portal.f5.riskrecon.com/'
  } else if (capture_groups.dotenv === '.gov') {
    return `https://portal.gov.${capture_groups.domain}/`
  } else if (capture_groups.dotenv === '.dev') {
    return `https://portal.dev.${capture_groups.domain}/`
  } else if (capture_groups.name === 'vendor') {
    return `https://portal.${capture_groups.domain}/`
  } else {
    return `https://customer-portal${capture_groups.env}.${capture_groups.domain}/`
  }
};


function parseHost(hostname) {
  return /(?<name>vendor-portal|vendor)(?<env>-[^.]+)?(?<dotenv>\.(f5|gov|dev))?\.(?<domain>.+)/.exec(hostname).groups
}
