import React from "react";
import RiskRecon_Banner from "../images/RiskRecon_Banner.png";
import "./rr_banner.scss";

const RR_banner = (props) => {
  return (
    <div
      className="widget-card-banner-rr-banner"
      style={{
        backgroundImage: `url(${RiskRecon_Banner})`,
        backgroundRepeat: "no-repeat",
      }}
    >
      <h1>Increase Your Cyber Readiness.</h1>
      <p>
        Know your cyber risk issues <i>before</i> they are shared with you.
        <br />
        Request a <b>FREE</b> ‘Own Enterprise Report’ for a greater view into
        your risk.
      </p>
      <div
        className="rr-button"
        onClick={() =>
          window.open("https://www.riskrecon.com/vendor-portal", "_blank")
        }
      >
        <label>Request Report</label>
      </div>
    </div>
  );
};

export default RR_banner;
