import React from 'react'
import PropTypes from 'prop-types'

const CancelSVG = ({
  className,
  fill,
  size,
  ...rest
}) =>
  <svg width={size} height={size} viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M0 6C0 2.682 2.682 0 6 0C9.318 0 12 2.682 12 6C12 9.318 9.318 12 6 12C2.682 12 0 9.318 0 6ZM7.734 8.58C7.968 8.814 8.346 8.814 8.58 8.58C8.808 8.346 8.808 7.962 8.58 7.734L6.846 6L8.58 4.266C8.814 4.032 8.814 3.654 8.58 3.42C8.346 3.186 7.968 3.186 7.734 3.42L6 5.154L4.266 3.42C4.032 3.186 3.654 3.186 3.42 3.42C3.30765 3.5321 3.24451 3.68429 3.24451 3.843C3.24451 4.00171 3.30765 4.1539 3.42 4.266L5.154 6L3.42 7.734C3.30765 7.8461 3.24451 7.99829 3.24451 8.157C3.24451 8.31571 3.30765 8.4679 3.42 8.58C3.654 8.814 4.032 8.814 4.266 8.58L6 6.846L7.734 8.58Z' fill={fill} />
  </svg>

CancelSVG.defaultProps = {
  className: '',
  fill: '#B30015',
  size: '12px'
}

CancelSVG.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  fill: PropTypes.string
}

export default CancelSVG
