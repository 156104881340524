/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { requestResetPassword } from '../../actions/UserActions'
import resetPasswordValidator from '../../validators/resetPassword'
import { Link, useHistory, useParams } from 'react-router-dom'
import TextInput from '../shared/TextInput'
import Button from '../shared/Button'
import ErrorMessage from '../shared/ErrorMessage'
import Container from '../shared/Container'
import Header from '../shared/Header'
import CheckCircleSVG from '../../assets/svg/CheckCircleSVG'
import RemoveCircleSVG from '../../assets/svg/RemoveCircleSVG'
import EyeNotVisibleSVG from '../../assets/svg/EyeNotVisibleSVG'
import EyeVisibleSVG from '../../assets/svg/EyeVisibleSVG'
import PropTypes from 'prop-types'

function ResetPassword({ requestResetPassword, user }) {
  let history = useHistory()
  let { reset_password_token } = useParams()
  const [submitting, setSubmitting] = useState(false)
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
  })

  const [errors, setErrors] = useState({})
  const [validationSteps, setValidationSteps] = useState({})
  const [formValid, setFormValid] = useState(false)
  const [showPasswordValidation, setShowPasswordValidation] = useState(false)
  const [showConfirmPasswordValidation, setShowConfirmPasswordValidation] =
    useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false)

  function onChange(e) {
    setFormData({ ...formData, [e.target.name]: e.target.value })
    setErrors({ ...errors, [e.target.name]: '' })
  }

  useEffect(() => {
    const { validations, valid } = resetPasswordValidator(formData)
    setValidationSteps(validations)
    setFormValid(valid)
  }, [formData.password, formData.confirmPassword])

  useEffect(() => {
    const { resetPasswordSuccess } = user
    if (resetPasswordSuccess) {
      history.push('/login')
    }
  })

  async function submitForm(e) {
    e.preventDefault()
    setSubmitting(true)
    await requestResetPassword(
      formData.password,
      formData.confirmPassword,
      reset_password_token
    )
    setSubmitting(false)
  }

  const { password, confirmPassword } = formData

  const passwordConfirmationSteps = [
    { name: 'length', text: 'Length between 8 and 64 characters' },
    { name: 'oneUpper', text: 'One uppercase letter' },
    { name: 'oneLower', text: 'One lowercase letter' },
    { name: 'oneNumerical', text: 'One number' },
    { name: 'oneSpecial', text: 'One special character (!?#%...)' },
  ]

  const validationCheckItems = Object.values(passwordConfirmationSteps).map(
    (k) => (
      <div key={k.name} className='validation-item'>
        {validationSteps[k.name] ? (
          <CheckCircleSVG className='satisfied' />
        ) : (
          <RemoveCircleSVG className='unsatisfied' />
        )}
        <p>{k.text}</p>
      </div>
    )
  )

  return (
    <Container className='unauth-container'>
      <Header className='unauth-header'>Change Password</Header>
      <p className='unauth-subheader'>
        Change your password by following the steps below.
      </p>
      <form
        id='unauth-reset-password-form'
        autoComplete='none'
        onSubmit={submitForm}
      >
        <TextInput
          label='New Password'
          type={`${showPassword ? 'text' : 'password'}`}
          value={password}
          name='password'
          className={'password'}
          onChange={onChange}
          error={errors.password}
          autoComplete='none'
          onFocus={() => setShowPasswordValidation(true)}
          onBlur={() => setShowPasswordValidation(false)}
          icon={
            showPassword ? (
              <EyeVisibleSVG className='show-hide-password' size='16' />
            ) : (
              <EyeNotVisibleSVG className='show-hide-password' size='16' />
            )
          }
          onIconClick={() => setShowPassword(!showPassword)}
        />
        <div
          className={`unauth-password-validation ${
            showPasswordValidation ? 'active' : ''
          }`}
        >
          <Header as='h2'>New Password Criteria</Header>
          <ul>
            <li>
              Needs to have at least one character added / deleted / or changed
              when reset.
            </li>
            <li>Should not match any previous passwords.</li>
          </ul>
          <hr />
          <Header as='h2' className='composition'>
            Your password composition must contain:
          </Header>
          <div className='unauth-validation-checks'>{validationCheckItems}</div>
        </div>
        <div
          className={`password-triangle ${
            showPasswordValidation ? 'active' : ''
          }`}
        >
          <div className='inner-triangle' />
        </div>
        <TextInput
          label='Confirm Password'
          type={`${showPasswordConfirmation ? 'text' : 'password'}`}
          ariaAutoComplete='none'
          value={confirmPassword}
          name='confirmPassword'
          className='confirmPassword'
          onChange={onChange}
          error={errors.confirmPassword}
          onFocus={() => setShowConfirmPasswordValidation(true)}
          onBlur={() => setShowConfirmPasswordValidation(false)}
          icon={
            showPasswordConfirmation ? (
              <EyeVisibleSVG className='show-hide-password' size='16' />
            ) : (
              <EyeNotVisibleSVG className='show-hide-password' size='16' />
            )
          }
          onIconClick={() =>
            setShowPasswordConfirmation(!showPasswordConfirmation)
          }
        />
        <div
          className={`unauth-password-confirmation-validation ${
            showConfirmPasswordValidation ? 'active' : ''
          }`}
        >
          <div className='unauth-validation-checks'>
            <div className='validation-item'>
              {validationSteps.passwordsMatch &&
              validationSteps.fieldsNotEmpty ? (
                <CheckCircleSVG className='satisfied' />
              ) : (
                <RemoveCircleSVG className='unsatisfied' />
              )}
              <p>Passwords match</p>
            </div>
          </div>
        </div>
        <div
          className={`confirm-password-triangle ${
            showConfirmPasswordValidation ? 'active' : ''
          }`}
        >
          <div className='inner-triangle' />
        </div>
        {errors.equalPassword && (
          <ErrorMessage message={errors.equalPassword} />
        )}
        <Button
          disabled={!formValid || submitting}
          loading={submitting}
          className='unauth-submit'
          type='submit'
          color='blue outline'
        >
          Change Password
        </Button>
      </form>
      <div className='unauth-links'>
        <Link to='/login'>Back to Login</Link>
      </div>
    </Container>
  )
}

ResetPassword.propTypes = {
  user: PropTypes.object.isRequired,
  requestResetPassword: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps, { requestResetPassword })(ResetPassword)
