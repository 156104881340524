import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './Checkbox.scss'

const Checkbox = ({
  id,
  checked,
  onChange,
  disabled,
  className,
}) => {
  return (
    <div
      className={classnames('checkbox-wrapper', className)}
    >
      <input
        type='checkbox'
        checked={checked}
        onChange={onChange}
        id={id}
        disabled={disabled}
      />
      <label htmlFor={id} />
    </div>
  )
}

Checkbox.defaultProps = {
  // placeholderText: 'Unspecified',
}

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
}

export default Checkbox
