import {
  CLEAR_FLASH,
  SET_FLASH
} from '../actions/Types'

const initialState = {
  kind: '',
  messages: []
}

const flash = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_FLASH:
      return initialState
    case SET_FLASH:
      return { ...state, kind: action.payload.kind, messages: state.messages.concat(action.payload.messages) }
    default:
      return state
  }
}

export default flash
