export const hostSummaryContent = [
  {
    label: 'Hostname',
    valueKey: 'host_name',
    width: '50'
  },
  {
    label: 'IP Address',
    valueKey: 'ip_address',
    width: '50'
  },
  {
    label: 'Hosting Provider',
    valueKey: 'hosting_provider',
    width: '50'
  },
  {
    label: 'Hosting Type',
    valueKey: 'hosting_type',
    width: '50'
  }
]

export const hostProfileSections = [
  {
    label: 'Host Summary',
    key: 'hostSummary',
    content: hostSummaryContent,
    showMore: false
  }
]

export const findingSidebarAttributes = {
  web_http_security_headers: {
    top_labels: ['Hostname', 'IP Address', 'hosting_provider'],
    top_keys: ['host_name', 'ip_address', 'hosting_provider'],
    host_summary_attributes: {
      host_name: 'Hostname',
      ip_address: 'IP Address',
      hosting_provider: 'hosting_provider'
    },
    issue_management_labels: ['issue', 'severity', 'asset_value', 'risk_priority'],
    issue_management_keys: ['finding_detail', 'severity', 'asset_value', 'priority'],
    issue_description_labels: ['issue', 'date_first_seen', 'date_last_seen', 'severity', 'asset_value', 'risk_priority'],
    issue_description_keys: ['finding_detail', 'first_seen', 'last_seen', 'severity', 'asset_value', 'priority'],
    issue_description_attributes: {
      finding_detail: 'issue',
      first_seen: 'date_first_seen',
      last_seen: 'date_last_seen',
      severity: 'severity',
      asset_value: 'asset_value',
      priority: 'risk_priority'
    },
    issue_management_attributes: {
      finding_detail: 'issue',
      asset_value: 'asset_value',
      priority: 'risk_priority',
      severity: 'issue_severity'
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_short',
    show_issue_description: false
  },
  unencrypted_sensitive_systems: {
    top_labels: ['Hostname', 'IP Address', 'hosting_provider'],
    top_keys: ['host_name', 'ip_address', 'hosting_provider'],
    host_summary_attributes: {
      host_name: 'Hostname',
      ip_address: 'IP Address',
      hosting_provider: 'hosting_provider'
    },
    issue_management_labels: ['issue', 'severity', 'asset_value', 'risk_priority'],
    issue_management_keys: ['finding_detail', 'severity', 'asset_value', 'priority'],
    issue_description_labels: ['issue', 'date_first_seen', 'date_last_seen', 'severity', 'asset_value', 'risk_priority'],
    issue_description_keys: ['finding_detail', 'first_seen', 'last_seen', 'severity', 'asset_value', 'priority'],
    issue_description_attributes: {
      finding_detail: 'issue',
      first_seen: 'date_first_seen',
      last_seen: 'date_last_seen',
      severity: 'severity',
      asset_value: 'asset_value',
      priority: 'risk_priority'
    },
    issue_management_attributes: {
      finding_detail: 'issue',
      asset_value: 'asset_value',
      priority: 'risk_priority',
      severity: 'issue_severity'
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_short',
    show_issue_description: false
  },
  malicious_code: {
    top_labels: ['Hostname', 'IP Address'],
    top_keys: ['host_name', 'ip_address'],
    host_summary_attributes: {
      host_name: 'Hostname',
      ip_address: 'IP Address'
    },
    issue_management_labels: ['issue', 'severity', 'asset_value', 'risk_priority'],
    issue_management_keys: ['finding_detail', 'severity', 'asset_value', 'priority'],
    issue_description_labels: ['issue', 'date_first_seen', 'date_last_seen', 'severity', 'asset_value', 'risk_priority'],
    issue_description_keys: ['finding_detail', 'first_seen', 'last_seen', 'severity', 'asset_value', 'priority'],
    issue_description_attributes: {
      finding_detail: 'issue',
      first_seen: 'date_first_seen',
      last_seen: 'date_last_seen',
      severity: 'severity',
      asset_value: 'asset_value',
      priority: 'risk_priority'
    },
    issue_management_attributes: {
      finding_detail: 'issue',
      asset_value: 'asset_value',
      priority: 'risk_priority',
      severity: 'issue_severity'
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_long',
    show_issue_description: false
  },
  'it-profile': {
    host_summary_attributes: {
      host_name: 'Hostname',
      ip_address: 'IP Address',
      hosting_provider: 'hosting_provider',
      hosting_type: 'hosting_type'
    }
  },
  'patching_app_server': {
    top_labels: ['Hostname', 'IP Address', 'hosting_provider'],
    top_keys: ['host_name', 'ip_address', 'hosting_provider'],
    host_summary_attributes: {
      host_name: 'Hostname',
      ip_address: 'IP Address',
      hosting_provider: 'hosting_provider'
    },
    issue_management_labels: ['issue', 'severity', 'asset_value', 'risk_priority'],
    issue_management_keys: ['finding_detail', 'severity', 'asset_value', 'priority'],
    issue_description_labels: ['issue', 'support_status', 'vulnerability_rating', 'severity', 'asset_value', 'risk_priority'],
    issue_description_keys: ['finding_detail', 'support_status', 'security_criteria_rating_numeric', 'severity', 'asset_value', 'priority'],
    issue_description_attributes: {
      finding_detail: 'issue',
      support_status: 'support_status',
      security_criteria_rating_numeric: 'vulnerability_rating',
      severity: 'severity',
      asset_value: 'asset_value',
      priority: 'risk_priority'
    },
    issue_management_attributes: {
      finding_detail: 'issue',
      asset_value: 'asset_value',
      priority: 'risk_priority',
      severity: 'issue_severity'
    },
    show_criteria_issues: false,
    show_vulnerability_info: true,
    issue_key: 'issue_short_vuln',
    issue_description: 'issue_long_vuln',
    solution: 'solution_short',
    show_issue_description: true
  },
  'patching_openssl': {
    top_labels: ['Hostname', 'IP Address', 'hosting_provider'],
    top_keys: ['host_name', 'ip_address', 'hosting_provider'],
    host_summary_attributes: {
      host_name: 'Hostname',
      ip_address: 'IP Address',
      hosting_provider: 'hosting_provider'
    },
    issue_management_labels: ['issue', 'severity', 'asset_value', 'risk_priority'],
    issue_management_keys: ['finding_detail', 'severity', 'asset_value', 'priority'],
    issue_description_labels: ['issue', 'support_status', 'vulnerability_rating', 'severity', 'asset_value', 'risk_priority'],
    issue_description_keys: ['finding_detail', 'support_status', 'security_criteria_rating_numeric', 'severity', 'asset_value', 'priority'],
    issue_description_attributes: {
      finding_detail: 'issue',
      support_status: 'support_status',
      security_criteria_rating_numeric: 'vulnerability_rating',
      severity: 'severity',
      asset_value: 'asset_value',
      priority: 'risk_priority'

    },
    issue_management_attributes: {
      finding_detail: 'issue',
      asset_value: 'asset_value',
      priority: 'risk_priority',
      severity: 'issue_severity'

    },
    show_criteria_issues: false,
    show_vulnerability_info: true,
    issue_key: 'issue_short_vuln',
    issue_description: 'issue_long_vuln',
    solution: 'solution_short',
    show_issue_description: true
  },
  'patching_vuln_open_ssl': {
    top_labels: ['Hostname', 'IP Address', 'hosting_provider'],
    top_keys: ['host_name', 'ip_address', 'hosting_provider'],
    host_summary_attributes: {
      host_name: 'Hostname',
      ip_address: 'IP Address',
      hosting_provider: 'hosting_provider'
    },
    issue_management_labels: ['issue', 'severity', 'asset_value', 'risk_priority'],
    issue_management_keys: ['finding_detail', 'severity', 'asset_value', 'priority'],
    issue_description_labels: ['issue', 'support_status', 'vulnerability_rating', 'severity', 'asset_value', 'risk_priority'],
    issue_description_keys: ['finding_detail', 'support_status', 'security_criteria_rating_numeric', 'severity', 'asset_value', 'priority'],
    issue_description_attributes: {
      finding_detail: 'issue',
      support_status: 'support_status',
      security_criteria_rating_numeric: 'vulnerability_rating',
      severity: 'severity',
      asset_value: 'asset_value',
      priority: 'risk_priority'
    },
    issue_management_attributes: {
      finding_detail: 'issue',
      asset_value: 'asset_value',
      priority: 'risk_priority',
      severity: 'issue_severity'
    },
    show_criteria_issues: false,
    show_vulnerability_info: true,
    issue_key: 'issue_short_vuln',
    issue_description: 'issue_long_vuln',
    solution: 'solution_short',
    show_issue_description: true
  },
  'patching_web_cms': {
    top_labels: ['Hostname', 'IP Address', 'hosting_provider'],
    top_keys: ['host_name', 'ip_address', 'hosting_provider'],
    host_summary_attributes: {
      host_name: 'Hostname',
      ip_address: 'IP Address',
      hosting_provider: 'hosting_provider'
    },
    issue_management_labels: ['issue', 'severity', 'asset_value', 'risk_priority'],
    issue_management_keys: ['finding_detail', 'severity', 'asset_value', 'priority'],
    issue_description_labels: ['issue', 'support_status', 'vulnerability_rating', 'severity', 'asset_value', 'risk_priority'],
    issue_description_keys: ['finding_detail', 'support_status', 'security_criteria_rating_numeric', 'severity', 'asset_value', 'priority'],
    issue_description_attributes: {
      finding_detail: 'issue',
      support_status: 'support_status',
      security_criteria_rating_numeric: 'vulnerability_rating',
      severity: 'severity',
      asset_value: 'asset_value',
      priority: 'risk_priority'
    },
    issue_management_attributes: {
      finding_detail: 'issue',
      asset_value: 'asset_value',
      priority: 'risk_priority',
      severity: 'issue_severity'
    },
    show_criteria_issues: false,
    show_vulnerability_info: true,
    issue_key: 'issue_short_eol',
    issue_description: 'issue_long_eol',
    solution: 'solution_short',
    show_issue_description: true
  },
  'patching_web_server': {
    top_labels: ['Hostname', 'IP Address', 'hosting_provider'],
    top_keys: ['host_name', 'ip_address', 'hosting_provider'],
    host_summary_attributes: {
      host_name: 'Hostname',
      ip_address: 'IP Address',
      hosting_provider: 'hosting_provider'
    },
    issue_management_labels: ['issue', 'severity', 'asset_value', 'risk_priority'],
    issue_management_keys: ['finding_detail', 'severity', 'asset_value', 'priority'],
    issue_description_labels: ['issue', 'support_status', 'vulnerability_rating', 'severity', 'asset_value', 'risk_priority'],
    issue_description_keys: ['finding_detail', 'support_status', 'security_criteria_rating_numeric', 'severity', 'asset_value', 'priority'],
    issue_description_attributes: {
      finding_detail: 'issue',
      support_status: 'support_status',
      security_criteria_rating_numeric: 'vulnerability_rating',
      severity: 'severity',
      asset_value: 'asset_value',
      priority: 'risk_priority'

    },
    issue_management_attributes: {
      finding_detail: 'issue',
      asset_value: 'asset_value',
      priority: 'risk_priority',
      severity: 'issue_severity'

    },
    show_criteria_issues: false,
    show_vulnerability_info: true,
    issue_key: 'issue_short_eol',
    issue_description: 'issue_long_eol',
    solution: 'solution_short',
    show_issue_description: true
  },
  'web_encryption_date_expire': {
    top_labels: ['Hostname', 'IP Address', 'hosting_provider'],
    top_keys: ['host_name', 'ip_address', 'hosting_provider'],
    host_summary_attributes: {
      host_name: 'Hostname',
      ip_address: 'IP Address',
      hosting_provider: 'hosting_provider'
    },
    issue_management_labels: ['issue', 'severity', 'asset_value', 'risk_priority'],
    issue_management_keys: ['finding_data_value', 'severity', 'asset_value', 'priority'],
    issue_description_labels: ['issue', 'date_first_seen', 'date_last_seen', 'severity', 'asset_value', 'risk_priority'],
    issue_description_keys: ['finding_data_value', 'first_seen', 'last_seen', 'severity', 'asset_value', 'priority'],
    issue_description_attributes: {
      finding_data_value: 'issue',
      first_seen: 'date_first_seen',
      last_seen: 'date_last_seen',
      severity: 'severity',
      asset_value: 'asset_value',
      priority: 'risk_priority'
    },
    issue_management_attributes: {
      finding_data_value: 'issue',
      asset_value: 'asset_value',
      priority: 'risk_priority',
      severity: 'issue_severity'
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_long',
    show_issue_description: false
  },
  'web_encryption_date_valid': {
    top_labels: ['Hostname', 'IP Address', 'hosting_provider'],
    top_keys: ['host_name', 'ip_address', 'hosting_provider'],
    host_summary_attributes: {
      host_name: 'Hostname',
      ip_address: 'IP Address',
      hosting_provider: 'hosting_provider'
    },
    issue_management_labels: ['issue', 'severity', 'asset_value', 'risk_priority'],
    issue_management_keys: ['finding_data_value', 'severity', 'asset_value', 'priority'],
    issue_description_labels: ['issue', 'date_first_seen', 'date_last_seen', 'severity', 'asset_value', 'risk_priority'],
    issue_description_keys: ['finding_data_value', 'first_seen', 'last_seen', 'severity', 'asset_value', 'priority'],
    issue_description_attributes: {
      finding_data_value: 'issue',
      first_seen: 'date_first_seen',
      last_seen: 'date_last_seen',
      severity: 'severity',
      asset_value: 'asset_value',
      priority: 'risk_priority'
    },
    issue_management_attributes: {
      finding_data_value: 'issue',
      asset_value: 'asset_value',
      priority: 'risk_priority',
      severity: 'issue_severity'
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_long',
    show_issue_description: false
  },
  'web_encryption_hash': {
    top_labels: ['Hostname', 'IP Address', 'hosting_provider'],
    top_keys: ['host_name', 'ip_address', 'hosting_provider'],
    host_summary_attributes: {
      host_name: 'Hostname',
      ip_address: 'IP Address',
      hosting_provider: 'hosting_provider'
    },
    issue_management_labels: ['issue', 'severity', 'asset_value', 'risk_priority'],
    issue_management_keys: ['finding_data_value', 'severity', 'asset_value', 'priority'],
    issue_description_labels: ['issue', 'date_first_seen', 'date_last_seen', 'severity', 'asset_value', 'risk_priority'],
    issue_description_keys: ['finding_data_value', 'first_seen', 'last_seen', 'severity', 'asset_value', 'priority'],
    issue_description_attributes: {
      finding_data_value: 'issue',
      first_seen: 'date_first_seen',
      last_seen: 'date_last_seen',
      severity: 'severity',
      asset_value: 'asset_value',
      priority: 'risk_priority'
    },
    issue_management_attributes: {
      finding_data_value: 'issue',
      asset_value: 'asset_value',
      priority: 'risk_priority',
      severity: 'issue_severity'
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_long',
    show_issue_description: false
  },
  'web_encryption_key_length': {
    top_labels: ['Hostname', 'IP Address', 'hosting_provider'],
    top_keys: ['host_name', 'ip_address', 'hosting_provider'],
    host_summary_attributes: {
      host_name: 'Hostname',
      ip_address: 'IP Address',
      hosting_provider: 'hosting_provider'
    },
    issue_management_labels: ['issue', 'severity', 'asset_value', 'risk_priority'],
    issue_management_keys: ['finding_data_value', 'severity', 'asset_value', 'priority'],
    issue_description_labels: ['issue', 'date_first_seen', 'date_last_seen', 'severity', 'asset_value', 'risk_priority'],
    issue_description_keys: ['finding_data_value', 'first_seen', 'last_seen', 'severity', 'asset_value', 'priority'],
    issue_description_attributes: {
      finding_data_value: 'issue',
      first_seen: 'date_first_seen',
      last_seen: 'date_last_seen',
      severity: 'severity',
      asset_value: 'asset_value',
      priority: 'risk_priority'
    },
    issue_management_attributes: {
      finding_data_value: 'issue',
      asset_value: 'asset_value',
      priority: 'risk_priority',
      severity: 'issue_severity'
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_long',
    show_issue_description: false
  },
  'web_encryption_protocol': {
    top_labels: ['Hostname', 'IP Address', 'hosting_provider'],
    top_keys: ['host_name', 'ip_address', 'hosting_provider'],
    host_summary_attributes: {
      host_name: 'Hostname',
      ip_address: 'IP Address',
      hosting_provider: 'hosting_provider'
    },
    issue_management_labels: ['issue', 'severity', 'asset_value', 'risk_priority'],
    issue_management_keys: ['finding_data_description', 'severity', 'asset_value', 'priority'],
    issue_description_labels: ['issue', 'date_first_seen', 'date_last_seen', 'severity', 'asset_value', 'risk_priority'],
    issue_description_keys: ['finding_data_description', 'first_seen', 'last_seen', 'severity', 'asset_value', 'priority'],
    issue_description_attributes: {
      finding_extra_data_value: 'issue',
      first_seen: 'date_first_seen',
      last_seen: 'date_last_seen',
      severity: 'severity',
      asset_value: 'asset_value',
      priority: 'risk_priority'
    },
    issue_management_attributes: {
      finding_extra_data_value: 'issue',
      asset_value: 'asset_value',
      priority: 'risk_priority',
      severity: 'issue_severity'
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_short',
    show_issue_description: false
  },
  'web_encryption_subject': {
    top_labels: ['Hostname', 'IP Address', 'hosting_provider'],
    top_keys: ['host_name', 'ip_address', 'hosting_provider'],
    host_summary_attributes: {
      host_name: 'Hostname',
      ip_address: 'IP Address',
      hosting_provider: 'hosting_provider'
    },
    issue_management_labels: ['issue', 'severity', 'asset_value', 'risk_priority'],
    issue_management_keys: ['finding_data_value', 'severity', 'asset_value', 'priority'],
    issue_description_labels: ['issue', 'date_first_seen', 'date_last_seen', 'severity', 'asset_value', 'risk_priority'],
    issue_description_keys: ['finding_data_value', 'first_seen', 'last_seen', 'severity', 'asset_value', 'priority'],
    issue_description_attributes: {
      finding_data_value: 'issue',
      first_seen: 'date_first_seen',
      last_seen: 'date_last_seen',
      severity: 'severity',
      asset_value: 'asset_value',
      priority: 'risk_priority'
    },
    issue_management_attributes: {
      finding_data_value: 'issue',
      asset_value: 'asset_value',
      priority: 'risk_priority',
      severity: 'issue_severity'
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_short',
    show_issue_description: false
  },
  'threatintel_cc_server': {
    top_labels: [],
    top_keys: [],
    host_summary_attributes: {
      host_name: 'Hostname',
      ip_address: 'IP Address',
      hosting_provider: 'hosting_provider'
    },
    issue_management_labels: ['issue', 'severity', 'asset_value', 'risk_priority'],
    issue_management_keys: ['criteria_issue', 'severity', 'asset_value', 'priority'],
    issue_description_labels: ['host_name', 'IP Address', 'asset_value', 'Shared IP'],
    issue_description_keys: ['host_name', 'ip_address', 'asset_value', 'shared_ip'],
    issue_description_attributes: {
      host_name: 'Hostname',
      ip_address: 'IP Address',
      asset_value: 'asset_value',
      shared_ip: 'Shared IP'
    },
    issue_management_attributes: {
      criteria_issue: 'issue',
      asset_value: 'asset_value',
      priority: 'risk_priority',
      severity: 'issue_severity'
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_short_vuln',
    issue_description: 'issue_long_vuln',
    solution: 'solution_short',
    show_issue_description: false
  },
  'threatintel_botnet_host': {
    top_labels: [],
    top_keys: [],
    host_summary_attributes: {
      host_name: 'Hostname',
      ip_address: 'IP Address',
      hosting_provider: 'hosting_provider'
    },
    issue_management_labels: ['issue', 'severity', 'asset_value', 'risk_priority'],
    issue_management_keys: ['criteria_issue', 'severity', 'asset_value', 'priority'],
    issue_description_labels: ['host_name', 'IP Address', 'asset_value', 'Shared IP'],
    issue_description_keys: ['host_name', 'ip_address', 'asset_value', 'shared_ip'],
    issue_description_attributes: {
      host_name: 'Hostname',
      ip_address: 'IP Address',
      asset_value: 'asset_value',
      shared_ip: 'Shared IP'
    },
    issue_management_attributes: {
      criteria_issue: 'issue',
      asset_value: 'asset_value',
      priority: 'risk_priority',
      severity: 'issue_severity'
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_short_vuln',
    issue_description: 'issue_long_vuln',
    solution: 'solution_short',
    show_issue_description: false
  },
  'threatintel_hostile_host_hacking': {
    top_labels: [],
    top_keys: [],
    host_summary_attributes: {
      host_name: 'Hostname',
      ip_address: 'IP Address',
      hosting_provider: 'hosting_provider'
    },
    issue_management_labels: ['issue', 'severity', 'asset_value', 'risk_priority'],
    issue_management_keys: ['criteria_issue', 'severity', 'asset_value', 'priority'],
    issue_description_labels: ['host_name', 'IP Address', 'asset_value', 'Shared IP'],
    issue_description_keys: ['host_name', 'ip_address', 'asset_value', 'shared_ip'],
    issue_description_attributes: {
      host_name: 'Hostname',
      ip_address: 'IP Address',
      asset_value: 'asset_value',
      shared_ip: 'Shared IP'
    },
    issue_management_attributes: {
      criteria_issue: 'issue',
      asset_value: 'asset_value',
      priority: 'risk_priority',
      severity: 'issue_severity'
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_short_vuln',
    issue_description: 'issue_long_vuln',
    solution: 'solution_short',
    show_issue_description: false
  },
  'threatintel_hostile_host_scanning': {
    top_labels: [],
    top_keys: [],
    host_summary_attributes: {
      host_name: 'Hostname',
      ip_address: 'IP Address',
      hosting_provider: 'hosting_provider'
    },
    issue_management_labels: ['issue', 'severity', 'asset_value', 'risk_priority'],
    issue_management_keys: ['criteria_issue', 'severity', 'asset_value', 'priority'],
    issue_description_labels: ['host_name', 'IP Address', 'asset_value', 'Shared IP'],
    issue_description_keys: ['host_name', 'ip_address', 'asset_value', 'shared_ip'],
    issue_description_attributes: {
      host_name: 'Hostname',
      ip_address: 'IP Address',
      asset_value: 'asset_value',
      shared_ip: 'Shared IP'
    },
    issue_management_attributes: {
      criteria_issue: 'issue',
      asset_value: 'asset_value',
      priority: 'risk_priority',
      severity: 'issue_severity'
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_short_vuln',
    issue_description: 'issue_long_vuln',
    solution: 'solution_short',
    show_issue_description: false
  },
  'threatintel_phishing_site': {
    top_labels: [],
    top_keys: [],
    host_summary_attributes: {
      host_name: 'Hostname',
      ip_address: 'IP Address',
      hosting_provider: 'hosting_provider'
    },
    issue_management_labels: ['issue', 'severity', 'asset_value', 'risk_priority'],
    issue_management_keys: ['criteria_issue', 'severity', 'asset_value', 'priority'],
    issue_description_labels: ['host_name', 'IP Address', 'asset_value', 'Shared IP'],
    issue_description_keys: ['host_name', 'ip_address', 'asset_value', 'shared_ip'],
    issue_description_attributes: {
      host_name: 'Hostname',
      ip_address: 'IP Address',
      asset_value: 'asset_value',
      shared_ip: 'Shared IP'
    },
    issue_management_attributes: {
      criteria_issue: 'issue',
      asset_value: 'asset_value',
      priority: 'risk_priority',
      severity: 'issue_severity'
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_short_vuln',
    issue_description: 'issue_long_vuln',
    solution: 'solution_short',
    show_issue_description: false
  },
  'threatintel_other': {
    top_labels: [],
    top_keys: [],
    host_summary_attributes: {
      host_name: 'Hostname',
      ip_address: 'IP Address',
      hosting_provider: 'hosting_provider'
    },
    issue_management_labels: ['issue', 'severity', 'asset_value', 'risk_priority'],
    issue_management_keys: ['criteria_issue', 'severity', 'asset_value', 'priority'],
    issue_description_labels: ['host_name', 'IP Address', 'asset_value', 'Shared IP'],
    issue_description_keys: ['host_name', 'ip_address', 'asset_value', 'shared_ip'],
    issue_description_attributes: {
      host_name: 'Hostname',
      ip_address: 'IP Address',
      asset_value: 'asset_value',
      shared_ip: 'Shared IP'
    },
    issue_management_attributes: {
      criteria_issue: 'issue',
      asset_value: 'asset_value',
      priority: 'risk_priority',
      severity: 'issue_severity'
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_short_vuln',
    issue_description: 'issue_long_vuln',
    solution: 'solution_short',
    show_issue_description: false
  },
  'threatintel_spamming_host': {
    top_labels: [],
    top_keys: [],
    host_summary_attributes: {
      host_name: 'Hostname',
      ip_address: 'IP Address',
      hosting_provider: 'hosting_provider'
    },
    issue_management_labels: ['issue', 'severity', 'asset_value', 'risk_priority'],
    issue_management_keys: ['criteria_issue', 'severity', 'asset_value', 'priority'],
    issue_description_labels: ['host_name', 'IP Address', 'asset_value', 'Shared IP'],
    issue_description_keys: ['host_name', 'ip_address', 'asset_value', 'shared_ip'],
    issue_description_attributes: {
      host_name: 'Hostname',
      ip_address: 'IP Address',
      asset_value: 'asset_value',
      shared_ip: 'Shared IP'
    },
    issue_management_attributes: {
      criteria_issue: 'issue',
      asset_value: 'asset_value',
      priority: 'risk_priority',
      severity: 'issue_severity'
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_short_vuln',
    issue_description: 'issue_long_vuln',
    solution: 'solution_short',
    show_issue_description: false
  },
  'shared_hosting': {
    top_labels: ['Hostname', 'IP Address'],
    top_keys: ['host_name', 'ip_address'],
    host_summary_attributes: {
      host_name: 'Hostname',
      ip_address: 'IP Address'
    },
    issue_management_labels: ['issue', 'severity', 'asset_value', 'risk_priority'],
    issue_management_keys: ['host_name', 'severity', 'asset_value', 'priority'],
    issue_description_labels: ['issue', 'date_first_seen', 'date_last_seen', 'severity', 'asset_value', 'risk_priority'],
    issue_description_keys: ['host_name', 'first_seen', 'last_seen', 'severity', 'asset_value', 'priority'],
    issue_description_attributes: {
      host_name: 'issue',
      first_seen: 'date_first_seen',
      last_seen: 'date_last_seen',
      severity: 'severity',
      asset_value: 'asset_value',
      priority: 'risk_priority'
    },
    issue_management_attributes: {
      host_name: 'issue',
      asset_value: 'asset_value',
      priority: 'risk_priority',
      severity: 'issue_severity'
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_long',
    show_issue_description: false
  },
  'config_web_cms_authentication': {
    top_labels: ['Hostname', 'IP Address', 'hosting_provider'],
    top_keys: ['host_name', 'ip_address', 'hosting_provider'],
    host_summary_attributes: {
      host_name: 'Hostname',
      ip_address: 'IP Address',
      hosting_provider: 'hosting_provider'
    },
    issue_management_labels: ['issue', 'severity', 'asset_value', 'risk_priority'],
    issue_management_keys: ['finding_detail', 'severity', 'asset_value', 'priority'],
    issue_description_labels: ['issue', 'date_first_seen', 'date_last_seen', 'severity', 'asset_value', 'risk_priority'],
    issue_description_keys: ['finding_detail', 'first_seen', 'last_seen', 'severity', 'asset_value', 'priority'],
    issue_description_attributes: {
      finding_detail: 'issue',
      first_seen: 'date_first_seen',
      last_seen: 'date_last_seen',
      severity: 'severity',
      asset_value: 'asset_value',
      priority: 'risk_priority'
    },
    issue_management_attributes: {
      finding_detail: 'issue',
      asset_value: 'asset_value',
      priority: 'risk_priority',
      severity: 'issue_severity'
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_short',
    show_issue_description: false
  },
  'web_threat_intel_alert_external': {
    top_labels: ['Hostname', 'IP Address'],
    top_keys: ['host_name', 'ip_address'],
    host_summary_attributes: {
      host_name: 'Hostname',
      ip_address: 'IP Address'
    },
    issue_management_labels: ['issue', 'severity', 'asset_value', 'risk_priority'],
    issue_management_keys: ['host_name', 'severity', 'asset_value', 'priority'],
    issue_description_labels: ['issue', 'date_first_seen', 'date_last_seen'],
    issue_description_keys: ['host_name', 'first_seen', 'last_seen'],
    issue_description_attributes: {
      host_name: 'issue',
      first_seen: 'date_first_seen',
      last_seen: 'date_last_seen'
    },
    issue_management_attributes: {
      host_name: 'issue',
      asset_value: 'asset_value',
      priority: 'risk_priority',
      severity: 'issue_severity'
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_long',
    show_issue_description: false
  },
  'dns_hijacking_protection': {
    top_labels: [],
    top_keys: [],
    host_summary_attributes: null,
    issue_management_labels: ['issue', 'severity', 'asset_value', 'risk_priority'],
    issue_management_keys: ['domain_name', 'severity', 'asset_value', 'priority'],
    issue_description_labels: ['issue', 'date_first_seen', 'date_last_seen', 'severity', 'asset_value', 'risk_priority'],
    issue_description_keys: ['domain_name', 'first_seen', 'last_seen', 'severity', 'asset_value', 'priority'],
    issue_description_attributes: {
      domain_name: 'issue',
      first_seen: 'date_first_seen',
      last_seen: 'date_last_seen',
      severity: 'severity',
      asset_value: 'asset_value',
      priority: 'risk_priority'
    },
    issue_management_attributes: {
      domain_name: 'issue',
      asset_value: 'asset_value',
      priority: 'risk_priority',
      severity: 'issue_severity'
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_long',
    show_issue_description: false
  },
  'email_authentication': {
    top_labels: [],
    top_keys: [],
    host_summary_attributes: null,
    issue_management_labels: ['issue', 'severity', 'asset_value', 'risk_priority'],
    issue_management_keys: ['domain_name', 'severity', 'asset_value', 'priority'],
    issue_description_labels: ['issue', 'date_first_seen', 'date_last_seen', 'severity', 'asset_value', 'risk_priority'],
    issue_description_keys: ['domain_name', 'first_seen', 'last_seen', 'severity', 'asset_value', 'priority'],
    issue_description_attributes: {
      domain_name: 'issue',
      first_seen: 'date_first_seen',
      last_seen: 'date_last_seen',
      severity: 'severity',
      asset_value: 'asset_value',
      priority: 'risk_priority'
    },
    issue_management_attributes: {
      domain_name: 'issue',
      asset_value: 'asset_value',
      priority: 'risk_priority',
      severity: 'issue_severity'
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_long',
    show_issue_description: false
  },
  'email_encryption_enabled': {
    top_labels: [],
    top_keys: [],
    host_summary_attributes: null,
    issue_management_labels: ['issue', 'severity', 'asset_value', 'risk_priority'],
    issue_management_keys: ['host_name', 'severity', 'asset_value', 'priority'],
    issue_description_labels: ['issue', 'date_first_seen', 'date_last_seen', 'severity', 'asset_value', 'risk_priority'],
    issue_description_keys: ['host_name', 'first_seen', 'last_seen', 'severity', 'asset_value', 'priority'],
    issue_description_attributes: {
      host_name: 'issue',
      first_seen: 'date_first_seen',
      last_seen: 'date_last_seen',
      severity: 'severity',
      asset_value: 'asset_value',
      priority: 'risk_priority'
    },
    issue_management_attributes: {
      host_name: 'issue',
      asset_value: 'asset_value',
      priority: 'risk_priority',
      severity: 'issue_severity'
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_long',
    show_issue_description: false
  },
  'domain_hijacking_protection': {
    top_labels: [],
    top_keys: [],
    host_summary_attributes: null,
    issue_management_labels: ['issue', 'severity', 'asset_value', 'risk_priority'],
    issue_management_keys: ['domain_name', 'severity', 'asset_value', 'priority'],
    issue_description_labels: ['issue', 'date_first_seen', 'date_last_seen', 'severity', 'asset_value', 'risk_priority'],
    issue_description_keys: ['domain_name', 'first_seen', 'last_seen', 'severity', 'asset_value', 'priority'],
    issue_description_attributes: {
      domain_name: 'issue',
      first_seen: 'date_first_seen',
      last_seen: 'date_last_seen',
      severity: 'severity',
      asset_value: 'asset_value',
      priority: 'risk_priority'
    },
    issue_management_attributes: {
      domain_name: 'issue',
      asset_value: 'asset_value',
      priority: 'risk_priority',
      severity: 'issue_severity'
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_long',
    show_issue_description: false
  },
  'unsafe_network_services': {
    top_labels: [],
    top_keys: [],
    host_summary_attributes: {
      host_name: 'Hostname',
      ip_address: 'IP Address'
    },
    issue_management_labels: ['issue', 'severity', 'asset_value', 'risk_priority'],
    issue_management_keys: ['finding_detail', 'severity', 'asset_value', 'priority'],
    issue_description_labels: ['issue', 'date_first_seen', 'date_last_seen', 'severity', 'asset_value', 'risk_priority'],
    issue_description_keys: ['finding_detail', 'first_seen', 'last_seen', 'severity', 'asset_value', 'priority'],
    issue_description_attributes: {
      finding_detail: 'issue',
      first_seen: 'date_first_seen',
      last_seen: 'date_last_seen',
      severity: 'severity',
      asset_value: 'asset_value',
      priority: 'risk_priority'
    },
    issue_management_attributes: {
      finding_detail: 'issue',
      asset_value: 'asset_value',
      priority: 'risk_priority',
      severity: 'issue_severity'
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_long',
    show_issue_description: false
  },
  'iot_devices': {
    top_labels: [],
    top_keys: [],
    host_summary_attributes: {
      host_name: 'Hostname',
      ip_address: 'IP Address'
    },
    issue_management_labels: ['issue', 'severity', 'asset_value', 'risk_priority'],
    issue_management_keys: ['finding_detail', 'severity', 'asset_value', 'priority'],
    issue_description_labels: ['issue', 'date_first_seen', 'date_last_seen', 'severity', 'asset_value', 'risk_priority'],
    issue_description_keys: ['finding_detail', 'first_seen', 'last_seen', 'severity', 'asset_value', 'priority'],
    issue_description_attributes: {
      finding_detail: 'issue',
      first_seen: 'date_first_seen',
      last_seen: 'date_last_seen',
      severity: 'severity',
      asset_value: 'asset_value',
      priority: 'risk_priority'
    },
    issue_management_attributes: {
      finding_detail: 'issue',
      asset_value: 'asset_value',
      priority: 'risk_priority',
      severity: 'issue_severity'
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_long',
    show_issue_description: false
  }
}

export const criteriumToExcludeHostInfo = [
  'web_threat_intel_alert_external',
  'email_authentication',
  'email_encryption_enabled'
]

export const customerRequestKeys = {
  customer_name: 'Name',
  due_date: 'Due Date',
  overdue: 'Overdue',
  comment: 'Comment',
  found_in_current_action_plan: 'Still in Action Plan'
}
