import React from 'react'
import PropTypes from 'prop-types'

const CheckSVG = ({
  className,
  fill,
  size,
  ...rest
}) =>
  <svg width={size} height={size} viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width={size} height={size} rx='6' fill={fill} />
    <path d='M5.29439 8.8169C5.0811 9.06103 4.73655 9.06103 4.52325 8.8169L2.55986 6.56964C2.45745 6.45269 2.3999 6.29391 2.3999 6.12833C2.3999 5.96274 2.45745 5.80396 2.55986 5.68701C2.77316 5.44288 3.11771 5.44288 3.331 5.68701L4.90609 7.48983L8.6688 3.1831C8.88209 2.93897 9.22664 2.93897 9.43993 3.1831C9.65323 3.42723 9.65323 3.8216 9.43993 4.06573L5.29439 8.8169Z' fill='white' />
  </svg>

CheckSVG.defaultProps = {
  className: '',
  fill: '#2F80ED',
  size: '12'
}

CheckSVG.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  fill: PropTypes.string
}

export default CheckSVG
