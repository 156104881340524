import { connect } from 'react-redux'
import FindingSidebar from '../components/FindingSidebar'
import {
  fetchFindingsByHost,
  fetchFindingLanguageInfo,
  fetchFindingSidebarInfo,
  fetchThreatIntellIssues,
  updateCriteriaFinding,
  updateDomainFinding
} from '../actions/Findings'

const mapStateToProps = state => ({
  findings_by_host: state.Findings.findings_by_host,
  fetching_findings_by_host: state.Findings.fetchingFindingsByHost,
  fetching_findings_by_host_failure: state.Findings.fetchingFindingsByHostFailure,
  fetching_findings_by_host_success: state.Findings.fetchingFindingsByHostSuccess,
  fetchingLanguageInfo: state.Findings.fetchingLanguageInfo,
  queryWithLanguageFindings: state.Findings.queryWithLanguageFindings,
  languageFindings: state.Findings.languageFindings,
  fetchLanguageInfoSuccess: state.Findings.fetchLanguageInfoSuccess,
  fetchingSidebarInfo: state.Findings.fetchingFindingsByHost || state.Findings.fetchingLanguageInfo,
  finding: state.Findings.currentFinding,
  selectedTopTab: state.Findings.selectedSidebarTopTab,
  selectedBottomTab: state.Findings.selectedSidebarBottomTab,
  sideBarShowHostOnly: state.Findings.sideBarShowHostOnly,
  fetchingHostInfo: state.Findings.fetchingHostInfo,
  fetchingSoftwarePatchingData: state.Findings.fetchingSoftwarePatchingData,
  fetchingThreatIntellIssues: state.Findings.fetchingThreatIntellIssues,
  fetchingHostProfileInfo: state.Findings.fetchingHostProfileInfo,
  fetchingIndividualFinding: state.Findings.fetchingIndividualFinding,
  fetchingIndividualFindingFailure: state.Findings.fetchingIndividualFindingFailure,
  toe: state.dashboard.toe
})

const mapDispatchToProps = dispatch => ({
  fetchFindingsByHost: (hostName) => dispatch(fetchFindingsByHost(hostName)),
  fetchFindingLanguageInfo: (issue, criteria) => dispatch(fetchFindingLanguageInfo(issue, criteria)),
  fetchFindingSidebarInfo: finding => dispatch(fetchFindingSidebarInfo(finding)),
  fetchThreatIntellIssues: (ip, hostName) => dispatch(fetchThreatIntellIssues(ip, hostName)),
  updateCriteriaFinding: (finding, body, reportingStatus) => dispatch(updateCriteriaFinding(finding, body, reportingStatus)),
  updateDomainFinding: (finding, body, reportingStatus) => dispatch(updateDomainFinding(finding, body, reportingStatus))
})

export default connect(mapStateToProps, mapDispatchToProps)(FindingSidebar)
