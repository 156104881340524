import * as Sentry from '@sentry/react'
import { format, parseISO } from 'date-fns'

const handleDateObject = (dateObj, fmt) => {
    if (fmt === 'withTime') {
      return format(dateObj, 'dd MMM yyyy h:mmaaa')
    }
    if (fmt === 'excludeDay') {
      return format(dateObj, 'MMM yyyy')
    }
    return format(dateObj, 'dd MMM yyy')
  }

  // formats ISO 8601 date strings in "2020-10-25T12:00:00.000Z" or "2020-10-25" formats and string dates in MM/DD/YYYY or MM/YYYY formats
const handleDateString = (dateStr, fmt) => {
    // Did we get back MM/YYYY or MM/DD/YYYY from BE?
    const count = dateStr.includes('/') ? dateStr.split('/').length - 1 : 0
    // MM/YYYY
    if (count === 1) {
      const month = dateStr.split('/')[0]
      const year = dateStr.split('/')[1]
      return format(new Date(Number(year), Number(month) - 1, 1), 'MMM yyyy')
    }
    // MM/DD/YYYY
    if (count === 2) {
      return fmt === 'excludeDay' ? format(new Date(dateStr), 'MMM yyyy') : format(new Date(dateStr), 'dd MMM yyyy')
    }
    // ISO 8601 Date or DateTime string
    if (!count && fmt === 'withTime') {
      return format(parseISO(dateStr), 'dd MMM yyyy h:mmaaa')
    }
    if (!count && fmt === 'excludeDay') {
      return format(new Date(parseISO(dateStr)), 'MMM yyyy')
    }
    return format(new Date(parseISO(dateStr)), 'dd MMM yyyy')
  }

export const formatDisplayDate = (date, fmt) => {
    if (!date) return
    try {
      if (date instanceof Date) {
        return handleDateObject(date, fmt)
      } else {
        return handleDateString(date, fmt)
      }
    } catch (error) {
      console.error(
        `Date value supplied: ${date}`,
        'Please supply one of the following valid dates: (JS Date Object, ISO 8601 Date / DateTime string, "MM/YYYY" or "MM/DD/YYYY").',
        error,
      )
      Sentry.captureException(error)
    }
  }
  