export function validPhoneNumber(phone) {
  const regex = /^([0-9][0-9][0-9])\W*([0-9][0-9]{2})\W*([0-9]{0,5})$/
  return regex.test(String(phone))
}

export const COUNTRY_PHONE_CODES = [
  {
    label: 'Country Code',
    value: '',
  }, {
    label: 'USA, Canada (+1)',
    value: '1',
  }, {
    label: 'Russia, Kazakhstan (+7)',
    value: '7',
  }, {
    label: 'Egypt (+20)',
    value: '20',
  }, {
    label: 'South Africa (+27)',
    value: '27',
  }, {
    label: 'Greece (+30)',
    value: '30',
  }, {
    label: 'Netherlands (+31)',
    value: '31',
  }, {
    label: 'Belgium (+32)',
    value: '32',
  }, {
    label: 'France (+33)',
    value: '33',
  }, {
    label: 'Spain (+34)',
    value: '34',
  }, {
    label: 'Hungary (+36)',
    value: '36',
  }, {
    label: 'Italy (+39)',
    value: '39',
  }, {
    label: 'Romania (+40)',
    value: '40',
  }, {
    label: 'Switzerland (+41)',
    value: '41',
  }, {
    label: 'Austria (+43)',
    value: '43',
  }, {
    label: 'United Kingdom (+44)',
    value: '44',
  }, {
    label: 'Guernsey (+44)',
    value: '44',
  }, {
    label: 'Isle of Man (+44)',
    value: '44',
  }, {
    label: 'Jersey (+44)',
    value: '44',
  }, {
    label: 'Denmark (+45)',
    value: '45',
  }, {
    label: 'Sweden (+46)',
    value: '46',
  }, {
    label: 'Norway (+47)',
    value: '47',
  }, {
    label: 'Poland (+48)',
    value: '48',
  }, {
    label: 'Germany (+49)',
    value: '49',
  }, {
    label: 'Peru (+51)',
    value: '51',
  }, {
    label: 'Mexico (+52)',
    value: '52',
  }, {
    label: 'Cuba (+53)',
    value: '53',
  }, {
    label: 'Argentina (+54)',
    value: '54',
  }, {
    label: 'Brazil (+55)',
    value: '55',
  }, {
    label: 'Chile (+56)',
    value: '56',
  }, {
    label: 'Colombia (+57)',
    value: '57',
  }, {
    label: 'Venezuela (+58)',
    value: '58',
  }, {
    label: 'Malaysia (+60)',
    value: '60',
  }, {
    label: 'Australia (+61)',
    value: '61',
  }, {
    label: 'Indonesia (+62)',
    value: '62',
  }, {
    label: 'Philippines (+63)',
    value: '63',
  }, {
    label: 'New Zealand (+64)',
    value: '64',
  }, {
    label: 'Singapore (+65)',
    value: '65',
  }, {
    label: 'Thailand (+66)',
    value: '66',
  }, {
    label: 'Japan (+81)',
    value: '81',
  }, {
    label: 'Korea (+South) (+82)',
    value: '82',
  }, {
    label: 'Vietnam (+84)',
    value: '84',
  }, {
    label: 'China (+86)',
    value: '86',
    // China country code not supported in Twilio
    disableForMfa: true,
  }, {
    label: 'Turkey (+90)',
    value: '90',
  }, {
    label: 'India (+91)',
    value: '91',
  }, {
    label: 'Pakistan (+92)',
    value: '92',
  }, {
    label: 'Afghanistan (+93)',
    value: '93',
  }, {
    label: 'Sri Lanka (+94)',
    value: '94',
  }, {
    label: 'Myanmar (+95)',
    value: '95',
  }, {
    label: 'Iran (+98)',
    value: '98',
  }, {
    label: 'Morocco (+212)',
    value: '212',
  }, {
    label: 'Algeria (+213)',
    value: '213',
  }, {
    label: 'Tunisia (+216)',
    value: '216',
  }, {
    label: 'Libya (+218)',
    value: '218',
  }, {
    label: 'Gambia (+220)',
    value: '220',
  }, {
    label: 'Senegal (+221)',
    value: '221',
  }, {
    label: 'Mauritania (+222)',
    value: '222',
  }, {
    label: 'Mali Republic (+223)',
    value: '223',
  }, {
    label: 'Guinea (+224)',
    value: '224',
  }, {
    label: 'Ivory Coast (+225)',
    value: '225',
  }, {
    label: 'Burkina Faso (+226)',
    value: '226',
  }, {
    label: 'Niger (+227)',
    value: '227',
  }, {
    label: 'Togo (+228)',
    value: '228',
  }, {
    label: 'Benin (+229)',
    value: '229',
  }, {
    label: 'Mauritius (+230)',
    value: '230',
  }, {
    label: 'Liberia (+231)',
    value: '231',
  }, {
    label: 'Sierra Leone (+232)',
    value: '232',
  }, {
    label: 'Ghana (+233)',
    value: '233',
  }, {
    label: 'Nigeria (+234)',
    value: '234',
  }, {
    label: 'Chad (+235)',
    value: '235',
  }, {
    label: 'Central African Republic (+236)',
    value: '236',
  }, {
    label: 'Cameroon (+237)',
    value: '237',
  }, {
    label: 'Cape Verde Islands (+238)',
    value: '238',
  }, {
    label: 'Sao Tome and Principe (+239)',
    value: '239',
  }, {
    label: 'Gabon (+241)',
    value: '241',
  }, {
    label: 'Congo (+242)',
    value: '242',
  }, {
    label: 'Congo, Democratic Republ (+243)',
    value: '243',
  }, {
    label: 'Angola (+244)',
    value: '244',
  }, {
    label: 'Guinea-Bissau (+245)',
    value: '245',
  }, {
    label: 'Seychelles (+248)',
    value: '248',
  }, {
    label: 'Sudan (+249)',
    value: '249',
  }, {
    label: 'Rwanda (+250)',
    value: '250',
  }, {
    label: 'Ethiopia (+251)',
    value: '251',
  }, {
    label: 'Somalia (+252)',
    value: '252',
  }, {
    label: 'Djibouti (+253)',
    value: '253',
  }, {
    label: 'Kenya (+254)',
    value: '254',
  }, {
    label: 'Tanzania (+255)',
    value: '255',
  }, {
    label: 'Uganda (+256)',
    value: '256',
  }, {
    label: 'Burundi (+257)',
    value: '257',
  }, {
    label: 'Mozambique (+258)',
    value: '258',
  }, {
    label: 'Zambia (+260)',
    value: '260',
  }, {
    label: 'Madagascar (+261)',
    value: '261',
  }, {
    label: 'Reunion (+262)',
    value: '262',
  }, {
    label: 'Zimbabwe (+263)',
    value: '263',
  }, {
    label: 'Namibia (+264)',
    value: '264',
  }, {
    label: 'Malawi (+265)',
    value: '265',
  }, {
    label: 'Lesotho (+266)',
    value: '266',
  }, {
    label: 'Botswana (+267)',
    value: '267',
  }, {
    label: 'Swaziland (+268)',
    value: '268',
  }, {
    label: 'Mayotte Island (+269)',
    value: '269',
  }, {
    label: 'Aruba (+297)',
    value: '297',
  }, {
    label: 'Faroe Islands (+298)',
    value: '298',
  }, {
    label: 'Greenland (+299)',
    value: '299',
  }, {
    label: 'Gibraltar (+350)',
    value: '350',
  }, {
    label: 'Portugal (+351)',
    value: '351',
  }, {
    label: 'Luxembourg (+352)',
    value: '352',
  }, {
    label: 'Ireland (+353)',
    value: '353',
  }, {
    label: 'Iceland (+354)',
    value: '354',
  }, {
    label: 'Albania (+355)',
    value: '355',
  }, {
    label: 'Malta (+356)',
    value: '356',
  }, {
    label: 'Cyprus (+357)',
    value: '357',
  }, {
    label: 'Finland (+358)',
    value: '358',
  }, {
    label: 'Bulgaria (+359)',
    value: '359',
  }, {
    label: 'Lithuania (+370)',
    value: '370',
  }, {
    label: 'Latvia (+371)',
    value: '371',
  }, {
    label: 'Estonia (+372)',
    value: '372',
  }, {
    label: 'Moldova (+373)',
    value: '373',
  }, {
    label: 'Armenia (+374)',
    value: '374',
  }, {
    label: 'Belarus (+375)',
    value: '375',
  }, {
    label: 'Andorra (+376)',
    value: '376',
  }, {
    label: 'Monaco (+377)',
    value: '377',
  }, {
    label: 'San Marino (+378)',
    value: '378',
  }, {
    label: 'Ukraine (+380)',
    value: '380',
  }, {
    label: 'Serbia (+381)',
    value: '381',
  }, {
    label: 'Montenegro (+382)',
    value: '382',
  }, {
    label: 'Croatia (+385)',
    value: '385',
  }, {
    label: 'Slovenia (+386)',
    value: '386',
  }, {
    label: 'Bosnia-Herzegovina (+387)',
    value: '387',
  }, {
    label: 'Macedonia (+389)',
    value: '389',
  }, {
    label: 'Czech Republic (+420)',
    value: '420',
  }, {
    label: 'Slovakia (+421)',
    value: '421',
  }, {
    label: 'Liechtenstein (+423)',
    value: '423',
  }, {
    label: 'Falkland Islands (+500)',
    value: '500',
  }, {
    label: 'Belize (+501)',
    value: '501',
  }, {
    label: 'Guatemala (+502)',
    value: '502',
  }, {
    label: 'El Salvador (+503)',
    value: '503',
  }, {
    label: 'Honduras (+504)',
    value: '504',
  }, {
    label: 'Nicaragua (+505)',
    value: '505',
  }, {
    label: 'Costa Rica (+506)',
    value: '506',
  }, {
    label: 'Panama (+507)',
    value: '507',
  }, {
    label: 'Haiti (+509)',
    value: '509',
  }, {
    label: 'Guadeloupe (+590)',
    value: '590',
  }, {
    label: 'Bolivia (+591)',
    value: '591',
  }, {
    label: 'Guyana (+592)',
    value: '592',
  }, {
    label: 'Ecuador (+593)',
    value: '593',
  }, {
    label: 'French Guiana (+594)',
    value: '594',
  }, {
    label: 'Paraguay (+595)',
    value: '595',
  }, {
    label: 'Martinique (+596)',
    value: '596',
  }, {
    label: 'Suriname (+597)',
    value: '597',
  }, {
    label: 'Uruguay (+598)',
    value: '598',
  }, {
    label: 'Netherlands Antilles (+599)',
    value: '599',
  }, {
    label: 'Timor-Leste (+670)',
    value: '670',
  }, {
    label: 'Brunei (+673)',
    value: '673',
  }, {
    label: 'Nauru (+674)',
    value: '674',
  }, {
    label: 'Papua New Guinea (+675)',
    value: '675',
  }, {
    label: 'Tonga (+676)',
    value: '676',
  }, {
    label: 'Solomon Islands (+677)',
    value: '677',
  }, {
    label: 'Vanuatu (+678)',
    value: '678',
  }, {
    label: 'Fiji Islands (+679)',
    value: '679',
  }, {
    label: 'Cook Islands (+682)',
    value: '682',
  }, {
    label: 'Samoa (+685)',
    value: '685',
  }, {
    label: 'New Caledonia (+687)',
    value: '687',
  }, {
    label: 'French Polynesia (+689)',
    value: '689',
  }, {
    label: 'Korea (+North) (+850)',
    value: '850',
  }, {
    label: 'HongKong (+852)',
    value: '852',
  }, {
    label: 'Macau (+853)',
    value: '853',
  }, {
    label: 'Cambodia (+855)',
    value: '855',
  }, {
    label: 'Laos (+856)',
    value: '856',
  }, {
    label: 'Bangladesh (+880)',
    value: '880',
  }, {
    label: 'International (+882)',
    value: '882',
  }, {
    label: 'Taiwan (+886)',
    value: '886',
  }, {
    label: 'Maldives (+960)',
    value: '960',
  }, {
    label: 'Lebanon (+961)',
    value: '961',
  }, {
    label: 'Jordan (+962)',
    value: '962',
  }, {
    label: 'Syria (+963)',
    value: '963',
  }, {
    label: 'Iraq (+964)',
    value: '964',
  }, {
    label: 'Kuwait (+965)',
    value: '965',
  }, {
    label: 'Saudi Arabia (+966)',
    value: '966',
  }, {
    label: 'Yemen (+967)',
    value: '967',
  }, {
    label: 'Oman (+968)',
    value: '968',
  }, {
    label: 'Palestine (+970)',
    value: '970',
  }, {
    label: 'United Arab Emirates (+971)',
    value: '971',
  }, {
    label: 'Israel (+972)',
    value: '972',
  }, {
    label: 'Bahrain (+973)',
    value: '973',
  }, {
    label: 'Qatar (+974)',
    value: '974',
  }, {
    label: 'Bhutan (+975)',
    value: '975',
  }, {
    label: 'Mongolia (+976)',
    value: '976',
  }, {
    label: 'Nepal (+977)',
    value: '977',
  }, {
    label: 'Tajikistan (+992)',
    value: '992',
  }, {
    label: 'Turkmenistan (+993)',
    value: '993',
  }, {
    label: 'Azerbaijan (+994)',
    value: '994',
  }, {
    label: 'Georgia (+995)',
    value: '995',
  }, {
    label: 'Kyrgyzstan (+996)',
    value: '996',
  }, {
    label: 'Uzbekistan (+998)',
    value: '998',
  }, {
    label: 'Bahamas (+1242)',
    value: '1242',
  }, {
    label: 'Barbados (+1246)',
    value: '1246',
  }, {
    label: 'Anguilla (+1264)',
    value: '1264',
  }, {
    label: 'Antigua and Barbuda (+1268)',
    value: '1268',
  }, {
    label: 'Virgin Islands, British (+1284)',
    value: '1284',
  }, {
    label: 'Cayman Islands (+1345)',
    value: '1345',
  }, {
    label: 'Bermuda (+1441)',
    value: '1441',
  }, {
    label: 'Grenada (+1473)',
    value: '1473',
  }, {
    label: 'Turks and Caicos Islands (+1649)',
    value: '1649',
  }, {
    label: 'Montserrat (+1664)',
    value: '1664',
  }, {
    label: 'Guam (+1671)',
    value: '1671',
  }, {
    label: 'Saint Lucia (+1758)',
    value: '1758',
  }, {
    label: 'Dominica (+1767)',
    value: '1767',
  }, {
    label: 'St. Vincent and The Gren (+1784)',
    value: '1784',
  }, {
    label: 'Puerto Rico (+1787)',
    value: '1787',
  }, {
    label: 'Dominican Republic (+1809)',
    value: '1809',
  }, {
    label: 'Dominican Republic2 (+1829)',
    value: '1829',
  }, {
    label: 'Dominican Republic3 (+1849)',
    value: '1849',
  }, {
    label: 'Trinidad and Tobago (+1868)',
    value: '1868',
  }, {
    label: 'Saint Kitts and Nevis (+1869)',
    value: '1869',
  }, {
    label: 'Jamaica (+1876)',
    value: '1876',
  },
]
