import React from 'react'
// todo: prop types

const ErrorMessage = (props) => {
  return (
    <div className={`status-wrapper ${props.type}`}>
      <p className='status-message'>{props.message}</p>
    </div>
  )
}

export default ErrorMessage
