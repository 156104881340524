// Risk Profile
export const domainKeys = ['software_patching', 'web_app_security', 'malicious_code', 'web_encryption', 'threat_intell', 'defensibility', 'email_security', 'dns_security']

// Domain Info Card
export const criteriaKeys = {
  'software_patching': ['patching_app_server', 'patching_openssl', 'patching_web_cms', 'patching_web_server'],
  'web_encryption': ['web_encryption_date_expire', 'web_encryption_date_valid', 'web_encryption_hash', 'web_encryption_key_length', 'web_encryption_protocol', 'web_encryption_subject'],
  'threat_intell': ['threatintel_cc_server', 'threatintel_botnet_host', 'threatintel_hostile_host_hacking', 'threatintel_hostile_host_scanning', 'threatintel_phishing_site', 'threatintel_other', 'threatintel_spamming_host'],
  'defensibility': ['shared_hosting'],
  'web_app_security': ['config_web_cms_authentication', 'unencrypted_sensitive_systems'],
  'dns_security': ['dns_hijacking_protection'],
  'email_security': ['email_authentication', 'email_encryption_enabled']
}

export const softwarePatchingPositions = {
  'patching_app_server': 0,
  'patching_openssl': 1,
  'patching_vuln_open_ssl': 1,
  'patching_web_cms': 2,
  'patching_web_server': 3
}

export const advisorOverview = 'your customers has identified the issues listed in this report as risk-significant, based on analysis of issue severity and the sensitivity of the system in which the issues exist. This information was gathered by RiskRecon from publicly available data sources and is a subset of the total information gathered by RiskRecon.'
export const moreAdvisorOverview = 'To obtain access to the full RiskRecon assessment for your own enterprise, email RiskRecon at support@riskrecon.com.'
// export const moreAdvisorOverview = "For a full explanation of RiskRecon's passive cyber analysis methods visit www.riskrecon.com/methodology. To obtain access to the full RiskRecon assessment for your own enterprise, email RiskRecon at support@riskrecon.com.";

export const domainOverviews = {
  'software_patching': 'RiskRecon used passive analysis to identify systems running end of support software that have security vulnerabilities. Operating unpatched software increases the likelihood of unauthorized access or degradation of system performance.  From the issues identified, your customers selected the software patching issues detailed in this section as important to address due to the issue severity and the sensitivity of the system in which the issue exists.',
  'web_encryption': 'RiskRecon used passive techniques to analyze web encryption security configurations. Correctly configured web encryption is essential to ensuring that communications are protected from eavesdropping and that people can verify the authenticity of the system. From the issues identified, your customers selected those detailed in this section as important to address due to the issue severity and the sensitivity of the system in which the issue exists.',
  'threat_intell': 'RiskRecon analyzed IP reputation and threat intellignce databases to identify suspicious system activity. Observed malicious activity may indicate the system is compromised or is being used for unauthorized purposes. Of the issues identified, your customers selected those detailed in this section as important to investigate and address due to the issue severity and the sensitivity of the system in which the issue exists.',
  'defensibility': 'RiskRecon used passive techniques to analyze the defensibility of the system hosting configuration. In doing so, RiskRecon specifically sought to identify any systems using IP addresses shared with other companies.  Where possible, companies should host systems using IP addresses that are dedicated to their use only. Systems that use a shared IP address are more difficult to defend because network layer control options are limited, such as IP address filtering and intrusion monitoring. Systems using shared IP addresses are also often blacklisted due to malicious activity of other tenants using the same IP address. Of the issues identified, your customers selected those detailed in this section as important to address due to the sensitivity of the system in which the issue exists.',
  'web_app_security': 'RiskRecon used passive techniques to analyze web applications for the presence of common application security controls. From the issues identified, your customers selected those detailed in this section as important to address due to the issue severity and the sensitivity of the system in which the issue exists.',
  'malicious_code': 'RiskRecon used passive techniques to assess the presence of injected malicious code on public facing websites. The passive detection was combined with threat intelligence to identify known malicious code used for the purpose of data theft. The presence of malicious code is an indicator that the website is compromised and that any data entered into the website by customers is at risk.',
  'dns_security': 'RiskRecon used passive techniques to analyze the security configuration of domain name services. Proper DNS security configuration helps ensure the availability and control of domains. Of the issues identified, your customers selected those listed in this section as important to address due to the issue severity and sensitivity of the domain in which the issue exists.',
  'email_security': 'RiskRecon used passive techniques to analyze the security configuration of email services. Email servers should be configured to encrypt email communications to protect email messages from unauthorized access. Domains should be configured to prove the authenticity of email messages to prevent spoofing. Of the issues identified, your customers selected those listed in this section as important to address due to the issue severity and sensitivity of the system in which the issue exists.'
}

export const domainRecommendation = {
  software_patching: 'From the total set of issues discovered, your customers has identified the application server patching issues listed in this section as important to address based on the issue severity and the sensitivity of the system in which the issue exists. In each case, the software should be updated to the manufacturer\'s latest version. Processes should be implemented to keep software versions and patches current',
  web_encryption: 'From the total set of issues discovered, your customers  identified the expired certificates listed in this section as important to address based on the sensitivity of the system in which the issue exists.  Replace each expired certificate with a newly issued certificate that expires later than the current date. Implement processes to replace certificates prior to expiration.',
  threat_intell: 'From the total set of issues discovered, your customers identified the issues listed in this section as important to investigate and resolve based on the issue severity and the sensitivity of the system in which the issue exists. Resolving suspected command and control server issues ensures that the system is does not harm other systems. In cases where the malicious activity is from to another system sharing the same IP address, it is recommended that the system use a dedicated IP address to help ensure the positive reputation and accessibility of the system going forward.',
  defensibility: 'From the total set of issues discovered, your customers  identified the web server patching issues listed in this section as important to address based on the issue severity and the sensitivity of the system in which the issue exists. In each case, the software should be updated to the manufacturer\'s latest version. Processes should be implemented to keep software versions and patches current. ',
  web_app_security: 'From the total set of issues discovered, your customers  identified the web applications listed in this section as important to address based on the sensitivity of the system in which the issue exists.  Administration interfaces should be protected using two-factor authentication or should be accessible only from trusted IP addresses',
  malicious_code: 'The detection of malicious code is a strong indicator that the monitored website has been compromised. Quick action should be taken to remove the detected code in order to stop theft of personal and/or payment card data. Once the malicious code has been removed, additional actions are needed to prevent future code injections. These actions include but are not limited to patching of the web and application server, removing and/or patching application plugins and restricting access to admin portals.',
  dns_security: 'From the total set of issues discovered, your customers identified the domains listed in this section as important to address. To remedy the issue, work with your domain name registrar to implement the \'clientTransferProhibited\' domain status flag for each of the domains. The \'clientTransferProhibited\' flag directs the domain registrar to perform strong authentication of any agent attempting to modify the domain, helping prevent unauthorized domain configuration changes.',
  email_security: 'From the total set of issues discovered, your customers identified the email server authentication issues listed in this section as important to address based on the sensitivity of the system in which the issue exists. To remedy the issue, implement DMARC (Domain-based Message Authentication, Reporting & Conformance). A DMARC policy allows senders and receivers of email to prove the identity of the sender, ensuring that all received emails are authentic, being sent from an authorized email server. Fraudsters often spoof the email sender in order to social engineer recipients. Additional information is available at https://dmarc.org/. '
}

export const domainTableColumnHeaders = {
  software_patching: ['issue', 'IP Address', 'host_name', 'first_seen', 'asset_value', 'severity', 'priority'],
  web_encryption: ['issue', 'host_name', 'detail', 'days_open', 'asset_value', 'severity', 'priority'],
  threat_intell: ['issue', 'IP Address', 'host_name', 'days_active', 'Shared IP', 'asset_value', 'severity', 'priority'],
  defensibility: ['IP Address', 'host_name', 'cotenant_count', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority'],
  web_app_security: ['URL', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority'],
  dns_security: ['domain', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority'],
  email_security: ['email_server', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority']
}

export const domainTableColumnSortKeys = {
  software_patching: ['finding_detail', 'ip_address', 'host_name', 'first_seen', 'asset_value_numeric', 'severity_numeric', 'priority'],
  web_encryption: ['security_criteria', 'host_name', 'domain_name', 'first_seen', 'asset_value_numeric', 'severity_numeric', 'priority'],
  threat_intell: ['security_criteria', 'ip_address', 'host_name', 'first_seen', 'shared_ip', 'asset_value', 'severity_numeric', 'priority'],
  defensibility: ['ip_address', 'host_name', 'finding_extra_data_value', 'first_seen', 'last_seen', 'asset_value', 'severity', 'priority'],
  web_app_security: ['finding_data_value', 'first_seen', 'last_seen', 'asset_value', 'severity', 'priority'],
  dns_security: ['domain_name', 'first_seen', 'last_seen', 'asset_value', 'severity', 'priority'],
  email_security: ['domain_name', 'first_seen', 'last_seen', 'asset_value', 'severity', 'priority']
}

export const domainTableSortableColumnHeaders = {
  software_patching: ['finding_detail', 'ip_address', 'host_name', 'first_seen', 'asset_value_numeric', 'severity_numeric', 'priority'],
  web_encryption: ['security_criteria', 'host_name', 'first_seen', 'asset_value_numeric', 'severity_numeric', 'priority'],
  threat_intell: ['security_criteria', 'ip_address', 'host_name', 'first_seen', 'shared_ip', 'asset_value', 'severity_numeric', 'priority'],
  defensibility: ['ip_address', 'host_name', 'finding_extra_data_value', 'first_seen', 'last_seen', 'asset_value', 'severity', 'priority'],
  web_app_security: ['finding_data_value', 'first_seen', 'last_seen', 'asset_value', 'severity', 'priority'],
  dns_security: ['domain_name', 'first_seen', 'last_seen', 'asset_value', 'severity', 'priority'],
  email_security: ['domain_name', 'first_seen', 'last_seen', 'asset_value', 'severity', 'priority']
}

export const criteriaSortKeys = {
  shared_hosting: ['ip_address', 'host_name', 'finding_extra_data_value', 'first_seen', 'first_seen', 'asset_value_numeric', 'severity_numeric', 'priority'],
  web_threat_intel_alert_external: ['ip_address', 'host_name', 'first_seen', 'first_seen'],
  config_web_cms_authentication: ['finding_data_value', 'first_seen', 'first_seen', 'asset_value_numeric', 'severity_numeric', 'priority'],
  dns_hijacking_protection: ['domain_name', 'first_seen', 'first_seen', 'asset_value_numeric', 'severity_numeric', 'priority'],
  email_authentication: ['domain_name', 'first_seen', 'first_seen', 'asset_value_numeric', 'severity_numeric', 'priority'],
  email_encryption_enabled: ['host_name', 'domains_impacted', 'example_domains', 'first_seen', 'first_seen', 'asset_value_numeric', 'severity_numeric', 'priority']
}

export const criteriaColumns = {
  'patching_app_server': ['software', 'IP Address', 'host_name', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority'],
  'patching_vuln_open_ssl': ['software', 'IP Address', 'host_name', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority'],
  'patching_openssl': ['software', 'IP Address', 'host_name', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority'],
  'patching_web_cms': ['software', 'IP Address', 'host_name', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority'],
  'patching_web_server': ['software', 'IP Address', 'host_name', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority'],
  'web_encryption_date_expire': ['host_name', 'date_expired', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority'],
  'web_encryption_date_valid': ['host_name', 'date', 'first_seen', 'last_seen', 'asset_value', 'severity', 'priority'],
  'web_encryption_hash': ['host_name', 'host_algorithm', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority'],
  'web_encryption_key_length': ['host_name', 'date', 'first_seen', 'last_seen', 'asset_value', 'severity', 'priority'],
  'web_encryption_protocol': ['host_name', 'protocol', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority'],
  'web_encryption_subject': ['host_name', 'subject', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority'],
  'threatintel_cc_server': ['IP Address', 'host_name', 'first_seen', 'last_seen', 'Shared IP', 'asset_value', 'severity', 'priority'],
  'threatintel_botnet_host': ['IP Address', 'host_name', 'first_seen', 'last_seen', 'Shared IP', 'asset_value', 'severity', 'priority'],
  'threatintel_hostile_host_hacking': ['IP Address', 'host_name', 'first_seen', 'last_seen', 'Shared IP', 'asset_value', 'severity', 'priority'],
  'threatintel_hostile_host_scanning': ['IP Address', 'host_name', 'first_seen', 'last_seen', 'Shared IP', 'asset_value', 'severity', 'priority'],
  'threatintel_phishing_site': ['IP Address', 'host_name', 'first_seen', 'last_seen', 'Shared IP', 'asset_value', 'severity', 'priority'],
  'threatintel_other': ['IP Address', 'host_name', 'first_seen', 'last_seen', 'Shared IP', 'asset_value', 'severity', 'priority'],
  'threatintel_spamming_host': ['IP Address', 'host_name', 'first_seen', 'last_seen', 'Shared IP', 'asset_value', 'severity', 'priority'],
  'config_web_cms_authentication': ['issue', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority'],
  'web_http_security_headers': ['Settings'],
  'web_threat_intel_alert_external': ['IP Address', 'host_name', 'first_seen', 'days_open'],
  'dns_hijacking_protection': ['domain', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority'],
  'dns_hosting_providers': ['Hosting Provider', 'Domains'],
  'domain_hijacking_protection': ['domain', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority'],
  'email_authentication': ['email_domain', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority'],
  'email_encryption_enabled': ['email_server', 'domains_impacted', 'example_domain', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority'],
  'email_hosting_providers': ['Email Provider', 'Domains'],
  'shared_hosting': ['IP Address', 'host_name', 'cotenant_count', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority'],
  'governance_customer_base': [],
  'governance_regulatory_requirements': [],
  'governance_security_certifications': [],
  'attack_surface_web_ip': [],
  'attack_surface_web_hostname': [],
  'host_hosting_providers': ['Hosting Provider', 'Systems'],
  'hosting_countries': ['Country Name', 'Web IP Count'],
  'defensibility_hosting_providers': ['Hosting Provider', 'System'],
  'data_loss_6': ['Description'],
  'data_loss_12': ['Description'],
  'data_loss_24': ['Description'],
  'data_loss_36': ['Description'],
  'data_loss_36plus': ['Description'],
  'unsafe_network_services': ['Issue', 'IP Address', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority'],
  'iot_devices': ['Issue', 'IP Address', 'host_name', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority'],
  'unencrypted_sensitive_systems': ['host_name', 'days_open', 'asset_value', 'severity', 'priority']
}

export const criteriaSortableColumns = {
  'config_web_cms_authentication': ['URL', 'first_seen', 'asset_value', 'severity', 'priority'],
  'web_http_security_headers': ['Settings'],
  'web_threat_intel_alert_external': ['IP Address', 'host_name', 'first_seen'],
  'dns_hijacking_protection': ['domain', 'first_seen', 'asset_value', 'severity', 'priority'],
  'dns_hosting_providers': ['Hosting Provider', 'Domains'],
  'domain_hijacking_protection': ['domain', 'first_seen', 'asset_value', 'severity', 'priority'],
  'email_authentication': ['email_domain', 'first_seen', 'asset_value', 'severity', 'priority'],
  'email_encryption_enabled': ['email_server', 'first_seen', 'asset_value', 'severity', 'priority'],
  'email_hosting_providers': ['Email Provider', 'Domains'],
  'shared_hosting': ['IP Address', 'host_name', 'cotenant_count', 'first_seen', 'asset_value', 'severity', 'priority'],
  'governance_customer_base': [],
  'governance_regulatory_requirements': [],
  'governance_security_certifications': [],
  'attack_surface_web_ip': [],
  'attack_surface_web_hostname': [],
  'host_hosting_providers': ['Hosting Provider', 'Systems'],
  'hosting_countries': ['Country Name', 'Web IP Count'],
  'defensibility_hosting_providers': ['Hosting Provider', 'System'],
  'data_loss_6': ['Description'],
  'data_loss_12': ['Description'],
  'data_loss_24': ['Description'],
  'data_loss_36': ['Description'],
  'data_loss_36plus': ['Description'],
  'unencrypted_sensitive_systems': ['host_name', 'days_open', 'asset_value', 'severity', 'priority']
}

export const criteriaValuesForDomainDetailedTable = {
  'patching_app_server': ['c1', 'c2', 'c3', 'c4', 'c5', 'c6', 'c7', 'c8'],
  'patching_vuln_open_ssl': ['c1', 'c2', 'c3', 'c4', 'c5', 'c6', 'c7', 'c8'],
  'patching_openssl': ['c1', 'c2', 'c3', 'c4', 'c5', 'c6', 'c7', 'c8'],
  'patching_web_cms': ['c1', 'c2', 'c3', 'c4', 'c5', 'c6', 'c7', 'c8'],
  'patching_web_server': ['c1', 'c2', 'c3', 'c4', 'c5', 'c6', 'c7', 'c8'],
  'web_encryption_date_expire': ['c1', 'c2', 'c3', 'c4', 'c5', 'c6', 'c7'],
  'web_encryption_date_valid': ['c1', 'c2', 'c3', 'c4', 'c5', 'c6', 'c7'],
  'web_encryption_hash': ['c1', 'c2', 'c3', 'c4', 'c5', 'c6', 'c7'],
  'web_encryption_key_length': ['c1', 'c2', 'c3', 'c4', 'c5', 'c6', 'c7'],
  'web_encryption_protocol': ['c1', 'c2', 'c3', 'c4', 'c5', 'c6', 'c7'],
  'web_encryption_subject': ['c1', 'c2', 'c3', 'c4', 'c5', 'c6', 'c7'],
  'threatintel_cc_server': ['ip_address', 'host_name', 'first_seen', 'last_seen', 'shared_ip', 'asset_value', 'severity', 'priority'],
  'threatintel_botnet_host': ['ip_address', 'host_name', 'first_seen', 'last_seen', 'shared_ip', 'asset_value', 'severity', 'priority'],
  'threatintel_hostile_host_hacking': ['ip_address', 'host_name', 'first_seen', 'last_seen', 'shared_ip', 'asset_value', 'severity', 'priority'],
  'threatintel_hostile_host_scanning': ['ip_address', 'host_name', 'first_seen', 'last_seen', 'shared_ip', 'asset_value', 'severity', 'priority'],
  'threatintel_phishing_site': ['ip_address', 'host_name', 'first_seen', 'last_seen', 'shared_ip', 'asset_value', 'severity', 'priority'],
  'threatintel_other': ['ip_address', 'host_name', 'first_seen', 'last_seen', 'shared_ip', 'asset_value', 'severity', 'priority'],
  'threatintel_spamming_host': ['ip_address', 'host_name', 'first_seen', 'last_seen', 'shared_ip', 'asset_value', 'severity', 'priority'],
  'config_web_cms_authentication': ['c1', 'c2', 'c3', 'c4', 'c5', 'c6'],
  'web_http_security_headers': ['c1'],
  'web_threat_intel_alert_external': ['ip_address', 'host_name', 'first_seen', 'days_open'],
  'dns_hijacking_protection': [],
  'dns_hosting_providers': ['c1', 'c2'],
  'domain_hijacking_protection': ['c1', 'c2', 'c3', 'c4', 'c5', 'c6'],
  'email_authentication': ['c1', 'c2', 'c3', 'c4', 'c5', 'c6'],
  'email_encryption_enabled': ['c1', 'c8', 'c7', 'c2', 'c3', 'c4', 'c5', 'c6'],
  'email_hosting_providers': ['c1', 'c2'],
  'governance_customer_base': [],
  'governance_regulatory_requirements': [],
  'governance_security_certifications': [],
  'shared_hosting': ['c1', 'c2', 'c3', 'c4', 'c5', 'c6', 'c7', 'c8'],
  'attack_surface_web_ip': [],
  'attack_surface_web_hostname': [],
  'defensibility_hosting_providers': ['c1', 'c2'],
  'host_hosting_providers': ['c1', 'c2'],
  'hosting_countries': ['c1', 'c2'],
  'data_loss_6': ['c1'],
  'data_loss_12': ['c1'],
  'data_loss_24': ['c1'],
  'data_loss_36': ['c1'],
  'data_loss_36plus': ['c1'],
  'unsafe_network_services': ['display_name', 'ip_address', 'host_name', 'first_seen', 'last_seen', 'days_open', 'port', 'category', 'asset_value', 'severity', 'priority'],
  'iot_devices': ['display_name', 'ip_address', 'host_name', 'first_seen', 'last_seen', 'days_open', 'port', 'category', 'asset_value', 'severity', 'priority'],
  'unencrypted_sensitive_systems': []
}

export const criteriaValues = {
  'patching_app_server': ['finding_detail', 'ip_address', 'host_name', 'first_seen', 'asset_value', 'severity', 'priority', 'reporting_status'],
  'patching_os': ['finding_detail', 'ip_address', 'host_name', 'first_seen', 'asset_value', 'severity', 'priority', 'reporting_status'],
  'patching_openssl': ['finding_detail', 'ip_address', 'host_name', 'first_seen', 'asset_value', 'severity', 'priority', 'reporting_status'],
  'patching_web_cms': ['finding_detail', 'ip_address', 'host_name', 'first_seen', 'asset_value', 'severity', 'priority', 'reporting_status'],
  'patching_vuln_open_ssl': ['finding_detail', 'ip_address', 'host_name', 'first_seen', 'asset_value', 'severity', 'priority', 'reporting_status'],
  'patching_web_server': ['finding_detail', 'ip_address', 'host_name', 'first_seen', 'asset_value', 'severity', 'priority', 'reporting_status'],
  'web_encryption_date_expire': ['criteria_issue', 'host_name', 'finding_data_value', 'days_open', 'asset_value', 'severity', 'priority', 'reporting_status'],
  'web_encryption_date_valid': ['criteria_issue', 'host_name', 'finding_data_value', 'last_seen', 'asset_value', 'severity', 'priority', 'reporting_status'],
  'web_encryption_hash': ['criteria_issue', 'host_name', 'finding_data_value', 'days_open', 'asset_value', 'severity', 'priority', 'reporting_status'],
  'web_encryption_key_length': ['criteria_issue', 'host_name', 'finding_data_value', 'last_seen', 'asset_value', 'severity', 'priority', 'reporting_status'],
  'web_encryption_protocol': ['criteria_issue', 'host_name', 'finding_extra_data_value', 'days_open', 'asset_value', 'severity', 'priority', 'reporting_status'],
  'web_encryption_subject': ['criteria_issue', 'host_name', 'finding_data_value', 'days_open', 'asset_value', 'severity', 'priority', 'reporting_status'],
  'threatintel_cc_server': ['criteria_issue', 'ip_address', 'host_name', 'days_open', 'shared_ip', 'asset_value', 'severity', 'priority', 'reporting_status'],
  'threatintel_botnet_host': ['criteria_issue', 'ip_address', 'host_name', 'days_open', 'shared_ip', 'asset_value', 'severity', 'priority', 'reporting_status'],
  'threatintel_hostile_host_hacking': ['criteria_issue', 'ip_address', 'host_name', 'days_open', 'shared_ip', 'asset_value', 'severity', 'priority', 'reporting_status'],
  'threatintel_hostile_host_scanning': ['criteria_issue', 'ip_address', 'host_name', 'days_open', 'shared_ip', 'asset_value', 'severity', 'priority', 'reporting_status'],
  'threatintel_phishing_site': ['criteria_issue', 'ip_address', 'host_name', 'days_open', 'shared_ip', 'asset_value', 'severity', 'priority', 'reporting_status'],
  'threatintel_other': ['criteria_issue', 'ip_address', 'host_name', 'days_open', 'shared_ip', 'asset_value', 'severity', 'priority', 'reporting_status'],
  'threatintel_spamming_host': ['criteria_issue', 'ip_address', 'host_name', 'days_open', 'shared_ip', 'asset_value', 'severity', 'priority', 'reporting_status'],
  'shared_hosting': ['ip_address', 'host_name', 'cotenant_count', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority', 'reporting_status'],
  'web_threat_intel_alert_external': ['ip_address', 'host_name', 'first_seen', 'days_open', 'reporting_status'],
  'config_web_cms_authentication': ['finding_data_value', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority', 'reporting_status'],
  'dns_hijacking_protection': ['domain_name', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority', 'reporting_status'],
  'email_authentication': ['domain_name', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority', 'reporting_status'],
  'email_encryption_enabled': ['host_name', 'domains_impacted', 'example_domain', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority', 'reporting_status'],
  'domain_hijacking_protection': ['domain_name', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority', 'reporting_status'],
  'web_http_security_headers': ['host_name', 'finding_data_value', 'days_open', 'asset_value', 'severity', 'priority', 'reporting_status'],
  'malicious_code': ['finding_extra_data_value', 'finding_data_value', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority', 'reporting_status'],
  'unsafe_network_services': ['finding_detail', 'ip_address', 'host_name', 'first_seen', 'asset_value', 'severity', 'priority', 'reporting_status'],
  'iot_devices': ['finding_detail', 'ip_address', 'host_name', 'first_seen', 'asset_value', 'severity', 'priority', 'reporting_status'],
  'unencrypted_sensitive_systems': ['host_name', 'days_open', 'asset_value', 'severity', 'priority', 'reporting_status']
}
export const criteriaIssues = {
  'patching_app_server': 'RiskRecon identified one or more systems running unpatched application application servers that have security vulnerabilities. Operating software unpatched software increases the likelihood of unauthorized access and system instability.',
  'patching_openssl': 'RiskRecon identified one or more systems running unpatched OpenSSL software that have security vulnerabilities. Operating software unpatched software increases the likelihood of unauthorized access and system instability.',
  'patching_web_cms': 'RiskRecon identified one or more systems running unpatched content management system software that have security vulnerabilities. Operating software unpatched software increases the likelihood of unauthorized access and system instability.',
  'patching_web_server': 'RiskRecon identified one or more systems running unpatched web server software that have security vulnerabilities. Operating software unpatched software increases the likelihood of unauthorized access and system instability.',
  'web_encryption_date_expire': 'RiskRecon identified one or more web encryption implementations that have an expired x.509 certificate. Expired certificates are not trustworthy and cause the browser to display security warnings to the user.',
  'web_encryption_date_valid': 'RiskRecon identified one or more web encryption implementations that have an encryption certificate date that is not yet valid. Certificates with a valid date later than the current date are not trustworthy and cause the browser to display security warnings to the user.',
  'web_encryption_hash': 'RiskRecon identified one or more web encryption implementations that use the insecure SHA-1 hashing algorithm. SHA-1 is considered cryptographically weak and may enable miscreants to spoof the identity of systems through fraudulent encyrption certificates.',
  'web_encryption_key_length': 'RiskRecon identified one or more web encryption implementations that support use of encryption key lengths of 112 bits or less. Key lengths of 112 bits or less are susceptible to brute force attack, exposing communications to unauthorized access.',
  'web_encryption_protocol': 'RiskRecon identified one or more web encryption implementations that support use of insecure protocols such as SSLv2, SSLv3, and TLS 1.0. Insecure protocols have fundamental flaws that allow miscreants to break the encryption process, exposing the authorized parties to risk of communications intercept and fraud.',
  'web_encryption_subject': 'RiskRecon identified one or more web encryption implementations that have invalid X.509 encryption certificate subjects. Systems with an invalid certificate subject are not trustworthy and cause the browser to display security warnings to the user.',
  'threatintel_cc_server': 'RiskRecon identified one or more company systems implicated in command and control server activity in the last 30 days. Such actvity is an indicator that the system is compromised or is being used for unauthorized purposes. In cases where the system shares an IP address with other companies, it may be that a system of another company is providing malware command and control services. In either case, emanating malicious activity commonly results in the system IP address and hostname being blacklisted to protect other systems and users.',
  'threatintel_botnet_host': 'RiskRecon identified one or more systems associated with the company acting as a member of a botnet, connecting to command and control servers in the last 30 days. Such actvity is an indicator that the system is compromised or that the system is sharing an IP address with a system that is infected with malware. In either case, emanating malicious activity commonly results in the system IP address and hostname being blacklisted to protect other systems and users.',
  'threatintel_hostile_host_hacking': 'RiskRecon observed one or more systems associated with the company attempting to gain unauthorized access to honeypot systems. Honeypot systems are operated on the Internet with intentional vulnerabilities to attract exploit attempts, thus discovering malicious actors. Hacking activities are illegal and often result in the system being blacklisted by other companies to prevent harm.',
  'threatintel_hostile_host_scanning': 'RiskRecon identified one or more company systems conducting unauthorized port scans of other systems. Port scanning is an indicator that the system may be involved in nefarious activities and often result in the system being blacklisted by other companies to prevent harm.',
  'threatintel_phishing_site': 'RiskRecon identified one or more company systems implicated in hosting phishing sites, which are impostor web sites attempting to lure people into interacting with the system for purposes of commiting fraud.',
  'threatintel_other': 'RiskRecon identified one or more company systems that are listed in public blacklists for unspecified undesirable activity. Oranizations commonly block access to and from systems listed in blacklists to prevent potential harm.',
  'threatintel_spamming_host': 'RiskRecon identified one or more company systems that have been flagged for sending large volumes of unsolicited email- SPAM. Systems flagged as spamming are often blacklisted by other email systems, preventing receipt of emails sent by the system.',
  'shared_hosting': 'RiskRecon identified one or more systems using IP addresses that are shared with other companies. Systems that use a shared IP address are more difficult to defend because network layer control options are limited, such as IP address filtering and intrusion monitoring. Systems that use a shared IP address are also at risk of being blacklisted due to malicious behavior of other tenants using the same IP address.',
  'config_web_cms_authentication': 'RiskRecon identified one or more web applications with publicly accessible content management system interfaces, secured only with a user id and a password. Miscreants can gain control over systems by guessing the administration user id and password.',
  'dns_hijacking_protection': 'RiskRecon identified one or more domains that do not have basic configurations in place to help prevent domain hijacking. Without proper configurations, miscreants could gain control of the insecure domains.',
  'email_authentication': "RiskRecon identified one or more domains that are lacking controls to prove the authenticity of email messages sent from the domain. Not implementing to controls to prove email authenticity exposes the organization's customers to fraud, allowing miscreants to send fraudulent email in the name of the company that appear to be authentic.",
  'email_encryption_enabled': 'RiskRecon identified one or more email servers that do not encrypt email messages in transit. Sending emails in plaintext exposes the message contents to unauthorized access.'
}

export const criteriaRecommendations = {
  'patching_app_server': "From the total set of issues discovered, your customers has identified the application server patching issues listed in this section as important to address based on the issue severity and the sensitivity of the system in which the issue exists. In each case, the software should be updated to the manufacturer's latest version. Processes should be implemented to keep software versions and patches current.",
  'patching_openssl': "From the total set of issues discovered, your customers identified the OpenSSL patching issues listed in this section as important to address based on the issue severity and the sensitivity of the system in which the issue exists. In each case, the software should be updated to the manufacturer's latest version. Processes should be implemented to keep software versions and patches current. ",
  'patching_web_cms': "From the total set of issues discovered, your customers identified the content management system patching issues listed in this section as important to address based on the issue severity and the sensitivity of the system in which the issue exists. In each case, the software should be updated to the manufacturer's latest version. Processes should be implemented to keep software versions and patches current. ",
  'patching_web_server': "From the total set of issues discovered, your customers  identified the web server patching issues listed in this section as important to address based on the issue severity and the sensitivity of the system in which the issue exists. In each case, the software should be updated to the manufacturer's latest version. Processes should be implemented to keep software versions and patches current. ",
  'web_encryption_date_expire': 'From the total set of issues discovered, your customers  identified the expired certificates listed in this section as important to address based on the sensitivity of the system in which the issue exists.  Replace each expired certificate with a newly issued certificate that expires later than the current date. Implement processes to replace certificates prior to expiration.',
  'web_encryption_date_valid': 'From the total set of issues discovered, your customers  identified the certificates listed in this section as important to address based on the sensitivity of the system in which the issue exists.  Replace each expired certificate with a newly issued certificate that expires later than the current date. Implement processes to replace certificates prior to expiration.',
  'web_encryption_hash': 'From the total set of issues discovered, your customers identified the issues listed in this section as important to address based on the sensitivity of the system in which the issue exists. Correct the issue by disabling support for SHA-1, and replace it with strong hash algorithms such as SHA-2 or SHA-3 when it comes available. ',
  'web_encryption_key_length': 'From the total set of issues discovered, your customers identified the issues in this section as important to address based on the sensitivity of the system in which the issue exists. To remedy the issue, reconfigure the web encryption implementation to support key lengths that are a minimum of 128-bits in length, using 256-bit keys or higher where possible. Use of longer encryption keys increases the difficulty of gaining unauthorized access to encrypted data and communications.',
  'web_encryption_protocol': 'From the total set of issues discovered, your customers identified the issues listed in this section as important to address based on the sensitivity of the system in which the issue exists. Correct the issue by disabling support for insecure protocols such as SSLv2, SSLv3, TLS 1.0, and TLS 1.1. ',
  'web_encryption_subject': 'From the total set of issues discovered, your customers identified the issues listed in this section as important to address based on the sensitivity of the system in which the issue exists. Correct the issue by replacing the X.509 encryption certificate with one that has a valid subject name for the system to which the certificate is deployed.',
  'threatintel_cc_server': 'From the total set of issues discovered, your customers identified the issues listed in this section as important to investigate and resolve based on the issue severity and the sensitivity of the system in which the issue exists. Resolving suspected command and control server issues ensures that the system is does not harm other systems. In cases where the malicious activity is from to another system sharing the same IP address, it is recommended that the system use a dedicated IP address to help ensure the positive reputation and accessibility of the system going forward.',
  'threatintel_botnet_host': 'From the total set of issues discovered, your customers identified the botnet issues listed in this section as important to investigate and resolve based on the sensitivity of the system in which the issue exists. Infected hosts should be cleansed of malicious software and systems should be Internet filters should be implemented to block communications to malicious systems and all systems should be protected with endpoint malware defenses.',
  'threatintel_hostile_host_hacking': "From the total set of issues discovered, your customers identified the hostile host activity listed in this section as important to investigate based on the sensitivity of the system in which the issue exists. In the case where the system is sharing an IP address with other tenants, the hacking activity may be being conducted by others using the same IP address. In such cases, the company should relocate the system to a dedicated IP address where the company can better control the reputation of the system's IP address.",
  'threatintel_hostile_host_scanning': "From the total set of issues discovered, your customers identified the hostile host activity listed in this section as important to investigate based on the sensitivity of the system in which the issue exists. In the case where the system is sharing an IP address with other tenants, the port scanning activity may be being conducted by others using the same IP address. In such cases, the company should relocate the system to a dedicated IP address where the company can better control the reputation of the system's IP address.",
  'threatintel_phishing_site': "From the total set of issues discovered, your customers identified the systems listed in this section as important to investigate based on the sensitivity of the system in which the issue exists. In the case where the system is sharing an IP address with other tenants, the phishing activity may be being conducted by others using the same IP address. In such cases, the company should relocate the system to a dedicated IP address where the company can better control the reputation of the system's IP address.",
  'threatintel_other': "From the total set of issues discovered, your customers identified the systems listed in this section as important to investigate based on the sensitivity of the system in which the issue exists. In the case where the system is sharing an IP address with other tenants, the phishing activity may be being conducted by others using the same IP address. In such cases, the company should relocate the system to a dedicated IP address where the company can better control the reputation of the system's IP address.",
  'threatintel_spamming_host': 'From the total set of issues discovered, your customers identified the systems listed in this section as important to investigate based on the sensitivity of the system in which the issue exists. In the case where the system is using an email server shared by other organizations, the spam activity may be being conducted by others using the same email server. In such cases, the company should relocate the system to a dedicated email server where the company can better control the reputation of the system.',
  'shared_hosting': 'From the total set of issues discovered, your customers identified the systems listed in this section as important to address based on system sensitivity. Where possible, these systems should use a dedicated IP address that is not shared with other companies. Doing so will enable the company to better control the reputation of the system and to more effectively operate network layer security controls.',
  'config_web_cms_authentication': 'From the total set of issues discovered, your customers  identified the web applications listed in this section as important to address based on the sensitivity of the system in which the issue exists.  Administration interfaces should be protected using two-factor authentication or should be accessible only from trusted IP addresses.',
  'dns_hijacking_protection': "From the total set of issues discovered, your customers identified the domains listed in this section as important to address. To remedy the issue, work with your domain name registrar to implement the 'clientTransferProhibited' domain status flag for each of the domains. The 'clientTransferProhibited' flag directs the domain registrar to perform strong authentication of any agent attempting to modify the domain, helping prevent unauthorized domain configuration changes.",
  'email_authentication': 'From the total set of issues discovered, your customers identified the email server authentication issues listed in this section as important to address based on the sensitivity of the system in which the issue exists. To remedy the issue, implement DMARC (Domain-based Message Authentication, Reporting & Conformance). A DMARC policy allows senders and receivers of email to prove the identity of the sender, ensuring that all received emails are authentic, being sent from an authorized email server. Fraudsters often spoof the email sender in order to social engineer recipients. Additional information is available at https://dmarc.org/. ',
  'email_encryption_enabled': 'From the total set of issues discovered, your customers identified the email encryption issues listed in this section as important to address. To remedy the issue, implement STARTTLS in each email server, helping ensure that messages and sent by the server are encrypted. Additional information is available at https://en.wikipedia.org/wiki/Opportunistic_TLS. '
}

// Finding Modal
export const findingModalAttributes = {
  web_http_security_headers: {
    top_labels: ['Hostname', 'IP Address', 'hosting_provider'],
    bottom_left_labels: ['issue', 'date_first_seen', 'date_last_seen'],
    bottom_right_labels: ['severity', 'asset_value', 'risk_priority'],
    top_keys: ['host_name', 'ip_address', 'hosting_provider'],
    bottom_left_keys: ['finding_detail', 'first_seen', 'last_seen'],
    bottom_right_keys: ['severity', 'asset_value', 'priority'],
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_short',
    show_issue_description: false
  },
  malicious_code: {
    top_labels: ['Hostname', 'IP Address'],
    bottom_left_labels: ['issue', 'date_first_seen', 'date_last_seen'],
    bottom_right_labels: ['severity', 'asset_value', 'risk_priority'],
    top_keys: ['host_name', 'ip_address'],
    bottom_left_keys: ['finding_detail', 'first_seen', 'last_seen'],
    bottom_right_keys: ['severity', 'asset_value', 'priority'],
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_long',
    show_issue_description: true
  },
  unencrypted_sensitive_systems: {
    top_labels: ['Hostname', 'IP Address'],
    bottom_left_labels: ['issue', 'date_first_seen', 'date_last_seen'],
    bottom_right_labels: ['severity', 'asset_value', 'risk_priority'],
    top_keys: ['host_name', 'ip_address'],
    bottom_left_keys: ['finding_detail', 'first_seen', 'last_seen'],
    bottom_right_keys: ['severity', 'asset_value', 'priority'],
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_long',
    show_issue_description: true
  },
  'patching_app_server': {
    top_labels: ['Hostname', 'IP Address', 'hosting_provider'],
    bottom_left_labels: ['issue', 'support_status', 'vulnerability_rating'],
    bottom_right_labels: ['severity', 'asset_value', 'risk_priority'],
    top_keys: ['host_name', 'ip_address', 'hosting_provider'],
    bottom_left_keys: ['finding_detail', 'support_status', 'security_criteria_rating_numeric'],
    bottom_right_keys: ['severity', 'asset_value', 'priority'],
    show_criteria_issues: false,
    show_vulnerability_info: true,
    issue_key: 'issue_short_vuln',
    issue_description: 'issue_long_vuln',
    solution: 'solution_short',
    show_issue_description: true
  },
  'patching_openssl': {
    top_labels: ['Hostname', 'IP Address', 'hosting_provider'],
    bottom_left_labels: ['issue', 'support_status', 'vulnerability_rating'],
    bottom_right_labels: ['severity', 'asset_value', 'risk_priority'],
    top_keys: ['host_name', 'ip_address', 'hosting_provider'],
    bottom_left_keys: ['finding_detail', 'support_status', 'security_criteria_rating_numeric'],
    bottom_right_keys: ['severity', 'asset_value', 'priority'],
    show_criteria_issues: false,
    show_vulnerability_info: true,
    issue_key: 'issue_short_vuln',
    issue_description: 'issue_long_vuln',
    solution: 'solution_short',
    show_issue_description: true
  },
  'patching_vuln_open_ssl': {
    top_labels: ['Hostname', 'IP Address', 'hosting_provider'],
    bottom_left_labels: ['issue', 'support_status', 'vulnerability_rating'],
    bottom_right_labels: ['severity', 'asset_value', 'risk_priority'],
    top_keys: ['host_name', 'ip_address', 'hosting_provider'],
    bottom_left_keys: ['finding_detail', 'support_status', 'security_criteria_rating_numeric'],
    bottom_right_keys: ['severity', 'asset_value', 'priority'],
    show_criteria_issues: false,
    show_vulnerability_info: true,
    issue_key: 'issue_short_vuln',
    issue_description: 'issue_long_vuln',
    solution: 'solution_short',
    show_issue_description: true
  },
  'patching_web_cms': {
    top_labels: ['Hostname', 'IP Address', 'hosting_provider'],
    bottom_left_labels: ['issue', 'support_status', 'vulnerability_rating'],
    bottom_right_labels: ['severity', 'asset_value', 'risk_priority'],
    top_keys: ['host_name', 'ip_address', 'hosting_provider'],
    bottom_left_keys: ['finding_detail', 'support_status', 'security_criteria_rating_numeric'],
    bottom_right_keys: ['severity', 'asset_value', 'priority'],
    show_criteria_issues: false,
    show_vulnerability_info: true,
    issue_key: 'issue_short_eol',
    issue_description: 'issue_long_eol',
    solution: 'solution_short',
    show_issue_description: true
  },
  'patching_web_server': {
    top_labels: ['Hostname', 'IP Address', 'hosting_provider'],
    bottom_left_labels: ['issue', 'support_status', 'vulnerability_rating'],
    bottom_right_labels: ['severity', 'asset_value', 'risk_priority'],
    top_keys: ['host_name', 'ip_address', 'hosting_provider'],
    bottom_left_keys: ['finding_detail', 'support_status', 'security_criteria_rating_numeric'],
    bottom_right_keys: ['severity', 'asset_value', 'priority'],
    show_criteria_issues: false,
    show_vulnerability_info: true,
    issue_key: 'issue_short_eol',
    issue_description: 'issue_long_eol',
    solution: 'solution_short',
    show_issue_description: true
  },
  'web_encryption_date_expire': {
    top_labels: ['Hostname', 'IP Address', 'hosting_provider'],
    bottom_left_labels: ['issue', 'date_first_seen', 'date_last_seen'],
    bottom_right_labels: ['severity', 'asset_value', 'risk_priority'],
    top_keys: ['host_name', 'ip_address', 'hosting_provider'],
    bottom_left_keys: ['finding_data_value', 'first_seen', 'last_seen'],
    bottom_right_keys: ['severity', 'asset_value', 'priority'],
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_long',
    show_issue_description: false
  },
  'web_encryption_date_valid': {
    top_labels: ['Hostname', 'IP Address', 'hosting_provider'],
    bottom_left_labels: ['issue', 'date_first_seen', 'date_last_seen'],
    bottom_right_labels: ['severity', 'asset_value', 'risk_priority'],
    top_keys: ['host_name', 'ip_address', 'hosting_provider'],
    bottom_left_keys: ['finding_data_value', 'first_seen', 'last_seen'],
    bottom_right_keys: ['severity', 'asset_value', 'priority'],
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_long',
    show_issue_description: false
  },
  'web_encryption_hash': {
    top_labels: ['Hostname', 'IP Address', 'hosting_provider'],
    bottom_left_labels: ['issue', 'date_first_seen', 'date_last_seen'],
    bottom_right_labels: ['severity', 'asset_value', 'risk_priority'],
    top_keys: ['host_name', 'ip_address', 'hosting_provider'],
    bottom_left_keys: ['finding_data_value', 'first_seen', 'last_seen'],
    bottom_right_keys: ['severity', 'asset_value', 'priority'],
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_long',
    show_issue_description: false
  },
  'web_encryption_key_length': {
    top_labels: ['Hostname', 'IP Address', 'hosting_provider'],
    bottom_left_labels: ['issue', 'date_first_seen', 'date_last_seen'],
    bottom_right_labels: ['severity', 'asset_value', 'risk_priority'],
    top_keys: ['host_name', 'ip_address', 'hosting_provider'],
    bottom_left_keys: ['finding_data_value', 'first_seen', 'last_seen'],
    bottom_right_keys: ['severity', 'asset_value', 'priority'],
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_long',
    show_issue_description: false
  },
  'web_encryption_protocol': {
    top_labels: ['Hostname', 'IP Address', 'hosting_provider'],
    bottom_left_labels: ['issue', 'date_first_seen', 'date_last_seen'],
    bottom_right_labels: ['severity', 'asset_value', 'risk_priority'],
    top_keys: ['host_name', 'ip_address', 'hosting_provider'],
    bottom_left_keys: ['finding_extra_data_value', 'first_seen', 'last_seen'],
    bottom_right_keys: ['severity', 'asset_value', 'priority'],
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_short',
    show_issue_description: false
  },
  'web_encryption_subject': {
    top_labels: ['Hostname', 'IP Address', 'hosting_provider'],
    bottom_left_labels: ['issue', 'date_first_seen', 'date_last_seen'],
    bottom_right_labels: ['severity', 'asset_value', 'risk_priority'],
    top_keys: ['host_name', 'ip_address', 'hosting_provider'],
    bottom_left_keys: ['domain_name', 'first_seen', 'last_seen'],
    bottom_right_keys: ['severity', 'asset_value', 'priority'],
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_short',
    show_issue_description: false
  },
  'threatintel_cc_server': {
    top_labels: [],
    bottom_left_labels: ['Hostname', 'IP Address'],
    bottom_right_labels: ['asset_value', 'Shared IP'],
    top_keys: [],
    bottom_left_keys: ['host_name', 'ip_address'],
    bottom_right_keys: ['asset_value', 'shared_ip'],
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_short_vuln',
    issue_description: 'issue_long_vuln',
    solution: 'solution_short',
    show_issue_description: false
  },
  'threatintel_botnet_host': {
    top_labels: [],
    bottom_left_labels: ['Hostname', 'IP Address'],
    bottom_right_labels: ['asset_value', 'Shared IP'],
    top_keys: [],
    bottom_left_keys: ['host_name', 'ip_address'],
    bottom_right_keys: ['asset_value', 'shared_ip'],
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_short_vuln',
    issue_description: 'issue_long_vuln',
    solution: 'solution_short',
    show_issue_description: false
  },
  'threatintel_hostile_host_hacking': {
    top_labels: [],
    bottom_left_labels: ['Hostname', 'IP Address'],
    bottom_right_labels: ['asset_value', 'Shared IP'],
    top_keys: [],
    bottom_left_keys: ['host_name', 'ip_address'],
    bottom_right_keys: ['asset_value', 'shared_ip'],
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_short_vuln',
    issue_description: 'issue_long_vuln',
    solution: 'solution_short',
    show_issue_description: false
  },
  'threatintel_hostile_host_scanning': {
    top_labels: [],
    bottom_left_labels: ['Hostname', 'IP Address'],
    bottom_right_labels: ['asset_value', 'Shared IP'],
    top_keys: [],
    bottom_left_keys: ['host_name', 'ip_address'],
    bottom_right_keys: ['asset_value', 'shared_ip'],
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_short_vuln',
    issue_description: 'issue_long_vuln',
    solution: 'solution_short',
    show_issue_description: false
  },
  'threatintel_phishing_site': {
    top_labels: [],
    bottom_left_labels: ['Hostname', 'IP Address'],
    bottom_right_labels: ['asset_value', 'Shared IP'],
    top_keys: [],
    bottom_left_keys: ['host_name', 'ip_address'],
    bottom_right_keys: ['asset_value', 'shared_ip'],
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_short_vuln',
    issue_description: 'issue_long_vuln',
    solution: 'solution_short',
    show_issue_description: false
  },
  'threatintel_other': {
    top_labels: [],
    bottom_left_labels: ['Hostname', 'IP Address'],
    bottom_right_labels: ['asset_value', 'Shared IP'],
    top_keys: [],
    bottom_left_keys: ['host_name', 'ip_address'],
    bottom_right_keys: ['asset_value', 'shared_ip'],
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_short_vuln',
    issue_description: 'issue_long_vuln',
    solution: 'solution_short',
    show_issue_description: false
  },
  'threatintel_spamming_host': {
    top_labels: [],
    bottom_left_labels: ['Hostname', 'IP Address'],
    bottom_right_labels: ['asset_value', 'Shared IP'],
    top_keys: [],
    bottom_left_keys: ['host_name', 'ip_address'],
    bottom_right_keys: ['asset_value', 'shared_ip'],
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_short_vuln',
    issue_description: 'issue_long_vuln',
    solution: 'solution_short',
    show_issue_description: false
  },
  'shared_hosting': {
    top_labels: ['Hostname', 'IP Address'],
    bottom_left_labels: ['issue', 'date_first_seen', 'date_last_seen'],
    bottom_right_labels: ['severity', 'asset_value', 'risk_priority'],
    top_keys: ['host_name', 'ip_address'],
    bottom_left_keys: ['host_name', 'first_seen', 'last_seen'],
    bottom_right_keys: ['severity', 'asset_value', 'priority'],
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_long',
    show_issue_description: false
  },
  'config_web_cms_authentication': {
    top_labels: ['Hostname', 'IP Address', 'hosting_provider'],
    bottom_left_labels: ['issue', 'date_first_seen', 'date_last_seen'],
    bottom_right_labels: ['severity', 'asset_value', 'risk_priority'],
    top_keys: ['host_name', 'ip_address', 'hosting_provider'],
    bottom_left_keys: ['finding_detail', 'first_seen', 'last_seen'],
    bottom_right_keys: ['severity', 'asset_value', 'priority'],
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_short',
    show_issue_description: false
  },
  'web_threat_intel_alert_external': {
    top_labels: ['Hostname', 'IP Address'],
    bottom_left_labels: ['issue', 'date_first_seen', 'date_last_seen'],
    bottom_right_labels: [],
    top_keys: ['host_name', 'ip_address'],
    bottom_left_keys: ['host_name', 'first_seen', 'last_seen'],
    bottom_right_keys: [],
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_long',
    show_issue_description: false
  },
  'dns_hijacking_protection': {
    top_labels: [],
    bottom_left_labels: ['issue', 'date_first_seen', 'date_last_seen'],
    bottom_right_labels: ['severity', 'asset_value', 'risk_priority'],
    top_keys: [],
    bottom_left_keys: ['domain_name', 'first_seen', 'last_seen'],
    bottom_right_keys: ['severity', 'asset_value', 'priority'],
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_long',
    show_issue_description: false
  },
  'email_authentication': {
    top_labels: [],
    bottom_left_labels: ['issue', 'date_first_seen', 'date_last_seen'],
    bottom_right_labels: ['severity', 'asset_value', 'risk_priority'],
    top_keys: [],
    bottom_left_keys: ['domain_name', 'first_seen', 'last_seen'],
    bottom_right_keys: ['severity', 'asset_value', 'priority'],
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_long',
    show_issue_description: false
  },
  'email_encryption_enabled': {
    top_labels: [],
    bottom_left_labels: ['issue', 'date_first_seen', 'date_last_seen'],
    bottom_right_labels: ['severity', 'asset_value', 'risk_priority'],
    top_keys: [],
    bottom_left_keys: ['host_name', 'first_seen', 'last_seen'],
    bottom_right_keys: ['severity', 'asset_value', 'priority'],
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_long',
    show_issue_description: false
  },
  'domain_hijacking_protection': {
    top_labels: [],
    bottom_left_labels: ['issue', 'date_first_seen', 'date_last_seen'],
    bottom_right_labels: ['severity', 'asset_value', 'risk_priority'],
    top_keys: [],
    bottom_left_keys: ['domain_name', 'first_seen', 'last_seen'],
    bottom_right_keys: ['severity', 'asset_value', 'priority'],
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_long',
    show_issue_description: false
  },
  'unsafe_network_services': {},
  'iot_devices': {}
}

export const threatIntelLanguage = {
  issue_short_vuln: 'These systems have recently appeared in threat intelligence feeds and blacklists of malicous actors due to exhibiting malicious behavior on the internet, such as serving malware, botnet infections, spamming, phishing, or aggressively scanning other systems.',
  solution_short: 'All systems with threat intelligence alerts should be investigated and remediated for the issues indicated in the alerts. Other resources for checking site reputation include www.netcraft.com, www.virustotal.com, and brightcloud.com.',
  solution_owner_short: 'System Administration',
  solution_owner_long: 'Threat Intelligence issues are typically invesigated by the information security team and addressed by the system administration teams.',
  issue_long_intro: 'These systems have recently appeared in threat intelligence feeds and blacklists of malicous actors due to exhibiting malicious behavior on the internet, such as serving malware, botnet infections, spamming, phishing, or aggressively scanning other systems.'
}

export const threatIntelIssueLastColumnName = {
  'threatintel_cc_server': 'C&C Server Hostname',
  'threatintel_botnet_host': 'Destination',
  'threatintel_hostile_host_hacking': 'Other',
  'threatintel_hostile_host_scanning': 'Other',
  'threatintel_phishing_site': 'Other',
  'threatintel_other': 'Other',
  'threatintel_spamming_host': 'Other'
}

export const valueSeverities = [
  'medium_medium', 'medium_low', 'medium_high', 'medium_critical',
  'low_medium', 'low_low', 'low_high', 'low_critical',
  'idle_medium', 'idle_low', 'idle_high', 'idle_critical',
  'high_medium', 'high_low', 'high_high', 'high_critical'
]

export const riskPriorityLevels = {
  1: ['high_critical'],
  2: ['high_high', 'medium_critical'],
  3: ['high_medium', 'medium_high', 'low_critical'],
  4: ['high_low', 'medium_medium', 'low_high, idle_critical'],
  5: ['medium_low', 'low_medium', 'idle_high'],
  6: ['low_low', 'idle_medium', 'idle_low']
}

export const matrixCountSkeleton = {
  'high~low': 0,
  'high~medium': 0,
  'high~high': 0,
  'high~critical': 0,
  'medium~low': 0,
  'medium~medium': 0,
  'medium~high': 0,
  'medium~critical': 0,
  'low~low': 0,
  'low~medium': 0,
  'low~high': 0,
  'low~critical': 0,
  'idle~low': 0,
  'idle~medium': 0,
  'idle~high': 0,
  'idle~critical': 0
}

export const domainCountsSkeleton = {
  'software_patching': 0,
  'web_app_security': 0,
  'web_encryption': 0,
  'threat_intell': 0,
  'defensibility': 0,
  'email_security': 0,
  'dns_security': 0
}

export const domainFindingsSkeleton = {
  software_patching: {
    findings: [],
    links: {
      next: '',
      self: ''
    },
    meta: {
      current_page: 1,
      next_page: 2,
      prev_page: null,
      total_count: 0,
      total_pages: 0
    }
  },
  web_app_security: {
    findings: [],
    links: {
      next: '',
      self: ''
    },
    meta: {
      current_page: 1,
      next_page: 2,
      prev_page: null,
      total_count: 0,
      total_pages: 0
    }
  },
  web_encryption: {
    findings: [],
    links: {
      next: '',
      self: ''
    },
    meta: {
      current_page: 1,
      next_page: 2,
      prev_page: null,
      total_count: 0,
      total_pages: 0
    }
  },
  threat_intell: {
    findings: [],
    links: {
      next: '',
      self: ''
    },
    meta: {
      current_page: 1,
      next_page: 2,
      prev_page: null,
      total_count: 0,
      total_pages: 0
    }
  },
  defensibility: {
    findings: [],
    links: {
      next: '',
      self: ''
    },
    meta: {
      current_page: 1,
      next_page: 2,
      prev_page: null,
      total_count: 0,
      total_pages: 0
    }
  },
  email_security: {
    findings: [],
    links: {
      next: '',
      self: ''
    },
    meta: {
      current_page: 1,
      next_page: 2,
      prev_page: null,
      total_count: 0,
      total_pages: 0
    }
  },
  dns_security: {
    findings: [],
    links: {
      next: '',
      self: ''
    },
    meta: {
      current_page: 1,
      next_page: 2,
      prev_page: null,
      total_count: 0,
      total_pages: 0
    }
  },
  email_authentication: {
    findings: [],
    links: {
      next: '',
      self: ''
    },
    meta: {
      current_page: 1,
      next_page: 2,
      prev_page: null,
      total_count: 0,
      total_pages: 0
    }
  },
  email_encryption_enabled: {
    findings: [],
    links: {
      next: '',
      self: ''
    },
    meta: {
      current_page: 1,
      next_page: 2,
      prev_page: null,
      total_count: 0,
      total_pages: 0
    }
  },
  selectorChanged: false

}

// export const criteriaKeys = {
//   "software_patching": ["patching_app_server", "patching_openssl", "patching_web_cms", "patching_web_server"],
//   "web_encryption": ["web_encryption_date_expire", "web_encryption_date_valid", "web_encryption_hash", "web_encryption_key_length", "web_encryption_protocol", "web_encryption_subject"],
//   "threat_intell": ["threatintel_cc_server", "threatintel_botnet_host", "threatintel_hostile_host_hacking", "threatintel_hostile_host_scanning", "threatintel_phishing_site", "threatintel_other", "threatintel_spamming_host"],
//   "defensibility": ["shared_hosting"],
//   "web_app_security": ["config_web_cms_authentication"],
//   "dns_security": ["dns_hijacking_protection"],
//   "email_security": ["email_authentication", "email_encryption_enabled"],
// }

export const criteriaFindingsSkeleton = {
  config_web_cms_authentication: {
    findings: [],
    links: {
      next: '',
      self: ''
    },
    meta: {
      current_page: 1,
      next_page: 2,
      prev_page: null,
      total_count: 0,
      total_pages: 0
    }
  },
  web_http_security_headers: {
    findings: [],
    links: {
      next: '',
      self: ''
    },
    meta: {
      current_page: 1,
      next_page: 2,
      prev_page: null,
      total_count: 0,
      total_pages: 0
    }
  },
  malicious_code: {
    findings: [],
    links: {
      next: '',
      self: ''
    },
    meta: {
      current_page: 1,
      next_page: 2,
      prev_page: null,
      total_count: 0,
      total_pages: 0
    }
  },
  shared_hosting: {
    findings: [],
    links: {
      next: '',
      self: ''
    },
    meta: {
      current_page: 1,
      next_page: 2,
      prev_page: null,
      total_count: 0,
      total_pages: 0
    }
  },
  email_authentication: {
    findings: [],
    links: {
      next: '',
      self: ''
    },
    meta: {
      current_page: 1,
      next_page: 2,
      prev_page: null,
      total_count: 0,
      total_pages: 0
    }
  },
  email_encryption_enabled: {
    findings: [],
    links: {
      next: '',
      self: ''
    },
    meta: {
      current_page: 1,
      next_page: 2,
      prev_page: null,
      total_count: 0,
      total_pages: 0
    }
  },
  dns_hijacking_protection: {
    findings: [],
    links: {
      next: '',
      self: ''
    },
    meta: {
      current_page: 1,
      next_page: 2,
      prev_page: null,
      total_count: 0,
      total_pages: 0
    }
  },
  unsafe_network_services: {
    findings: [],
    links: {
      next: '',
      self: ''
    },
    meta: {
      current_page: 1,
      next_page: 2,
      prev_page: null,
      total_count: 0,
      total_pages: 0
    }
  },
  iot_devices: {
    findings: [],
    links: {
      next: '',
      self: ''
    },
    meta: {
      current_page: 1,
      next_page: 2,
      prev_page: null,
      total_count: 0,
      total_pages: 0
    }
  },
  unencrypted_sensitive_systems: {
    findings: [],
    links: {
      next: '',
      self: ''
    },
    meta: {
      current_page: 1,
      next_page: 2,
      prev_page: null,
      total_count: 0,
      total_pages: 0
    }
  }
}

// Half of the domains we want flattened and have the findings grouped by domain and not by criteria
export const findingsByDomainsOnly = ['software_patching', 'web_encryption', 'threat_intell']

export const domainsByCriteria = ['web_app_security', 'defensibility', 'email_security', 'dns_security']

export const criteriaIssueMapping = {
  web_encryption_date_expire: 'Expired Certificate',
  web_encryption_date_valid: 'Premature Certificate',
  web_encryption_hash: 'Weak Algorithm',
  web_encryption_key_length: 'Weak Key Length',
  web_encryption_protocol: 'Insecure Protocol',
  web_encryption_subject: 'Invalid Subject',
  threatintel_cc_server: 'Command and Control',
  threatintel_botnet_host: 'Botnet Activity',
  threatintel_hostile_host_hacking: 'Hacking Activity',
  threatintel_hostile_host_scanning: 'Scanning Activity',
  threatintel_phishing_site: 'Phishing Activity',
  threatintel_other: 'Blacklist Other',
  threatintel_spamming_host: 'Spamming'
}

export const overviewPriorityFindingsSkeleton = {
  1: [],
  2: [],
  3: [],
  4: [],
  5: [],
  6: []
}

export const overviewPriorityFindingsCountsSkeleton = {
  1: 0,
  2: 0,
  3: 0,
  4: 0,
  5: 0,
  6: 0
}

export const loadingDomainFindingsSkeleton = {
  software_patching: false,
  web_app_security: false,
  web_encryption: false,
  threat_intell: false,
  defensibility: false,
  email_security: false,
  dns_security: false
}

export const domainFindingsFailuresSkeleton = {
  software_patching: false,
  web_app_security: false,
  web_encryption: false,
  threat_intell: false,
  defensibility: false,
  email_security: false,
  dns_security: false
}
export const criteriaFindingsFailuresSkeleton = {
  config_web_cms_authentication: false,
  shared_hosting: false,
  email_authentication: false,
  email_encryption_enabled: false,
  dns_hijacking_protection: false
}
export const toeSummarySkeleton = {
  data: {
    id: '',
    type: '',
    attributes: {
      toe_id: '',
      short_name: 'Loading',
      open_issues_count: 0,
      customers_count: 0,
      issue_categories_counts: {
        no_action: 0,
        investigating: 0,
        fixing: 0,
        reported_compensating_control: 0,
        not_fixing: 0,
        reported_false_positive: 0
      },
      sharing_customers: []
    }
  }
}

export const loadingCriteriaFindingsSkeleton = {
  config_web_cms_authentication: false,
  shared_hosting: false,
  email_authentication: false,
  email_encryption_enabled: false,
  dns_hijacking_protection: false

}

export const loadingDomainMatrixCountsSkeleton = {
  web_app_security: false,
  defensibility: false,
  email_security: false,
  dns_security: false
}

// export const domainKeys = ["software_patching", "web_app_security", "web_encryption", "threat_intell", "defensibility", "email_security", "dns_security"];

// export const domainCardKeys = ['software_patching', 'web_app_security_cms', 'web_encryption', 'threat_intell', 'defensibility_shared_ip', 'email_security_email_auth', 'email_security_email_encryption', 'dns_security_domain_hijacking']

export const domainCardKeys = ['software_patching', 'network_filtering_unsafe_network_services', 'network_filtering_iot_devices', 'web_app_security_cms', 'web_app_security_http', 'unencrypted_sensitive_systems', 'web_app_security_malicious_code', 'web_encryption', 'threat_intell', 'defensibility_shared_ip', 'email_security_email_auth', 'email_security_email_encryption', 'dns_security_domain_hijacking']
export const domainCardKeyMappings = {
  software_patching: {
    domain: 'software_patching',
    title: 'Software Patching',
    issue: 'RiskRecon used passive analysis to identify systems running end-of-support software that have security vulnerabilities. Operating unpatched software increases the likelihood of unauthorized access or degradation of system performance. From the software patching issues identified, your customers selected the issues detailed in this section as important to address due to the issue severity and the sensitivity of the system in which the issue exists.',
    recommendation: 'Upgrade all systems running end-of-life software to current, vendor-supported software to ensure that software does not contain currently known vulnerabilities and that the software can be quickly patched to address new vulnerabilities. Issue details and recommendations for each issue are provided below (click the issue).',
    tableHeaders: ['issue', 'IP Address', 'Hostname', 'first_seen', 'asset_value', 'severity', 'priority', 'your_status'],
    tableSortKeys: ['finding_detail', 'ip_address', 'host_name', 'first_seen', 'asset_value', 'severity', 'priority'],
    tableSortableColumnHeaders: ['finding_detail', 'ip_address', 'host_name', 'first_seen', 'asset_value', 'severity', 'priority'],
    expressionAttributes: ['analysis_id', 'finding_id', 'finding_detail', 'ip_address', 'host_name', 'first_seen', 'asset_value', 'severity', 'priority', 'customer_request_count', 'vendor_status_name', 'security_criteria_key', 'security_domain_key', 'reporting_status', 'found_in_customer_action_plans']
  },
  web_app_security_cms: {
    domain: 'web_app_security',
    title: 'Application Security - CMS Authentication',
    issue: 'RiskRecon used passive techniques to analyze web applications to identify publicly accessible content management system administration interfaces secured only with a user ID and password. Administration interfaces with weak authentication mechanisms are susceptible to compromise through credential guessing or credential theft. From the CMS authentication issues identified, your customers selected those detailed in this section as important to address due to the issue severity and the sensitivity of the system in which the issue exists.',
    recommendation: 'Restrict access to content management system administration interfaces using two-factor authentication or limiting access to trusted network sources. A common two-factor authentication method requires a user ID, password, and a one-time passcode, such as Google Authenticator.',
    criteria: 'config_web_cms_authentication',
    tableHeaders: ['issue', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority', 'your_status'],
    tableSortKeys: ['finding_data_value', 'first_seen', 'first_seen', 'asset_value', 'severity', 'priority'],
    tableSortableColumnHeaders: ['finding_data_value', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority'],
    expressionAttributes: ['analysis_id', 'finding_id', 'finding_data_value', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority', 'customer_request_count', 'vendor_status_name', 'security_criteria_key', 'security_domain_key', 'reporting_status', 'found_in_customer_action_plans']
  },
  web_app_security_http: {
    domain: 'web_app_security',
    title: 'Application Security - HTTP Security Headers',
    issue: 'RiskRecon used passive techniques to assess the web server HTTP security header configurations. RiskRecon identified one or more web servers missing HTTP security headers. HTTP security headers provide instructions to the browser for secure interaction with the web server, such as always encrypting browser requests, not allowing site content to be framed in other systems, and measures to prevent cross-site scripting.',
    recommendation: "RiskRecon recommends addressing all HTTP security header issues identified. RiskRecon's recommendations are based on the sensitivity of the data processed by the web server. For example, RiskRecon does not recommend implementing the secure-transport-security header for brochure sites that do not transmit sensitive data (systems flagged as asset value of medium, low, or idle).",
    criteria: 'web_http_security_headers',
    tableHeaders: ['host_name', 'missing_headers', 'days_open', 'asset_value', 'severity', 'priority', 'your_status'],
    tableSortKeys: ['host_name', 'finding_data_value', 'days_open', 'asset_value', 'severity', 'priority', 'vendor_status_name'],
    tableSortableColumnHeaders: ['host_name', 'finding_data_value', 'days_open', 'asset_value', 'severity', 'priority', 'vendor_status_name'],
    expressionAttributes: ['reporting_status', 'found_in_customer_action_plans', 'finding_id', 'analysis_id', 'first_seen', 'last_seen', 'finding_detail', 'finding', 'finding_subtype', 'finding_data_value', 'finding_data_description', 'finding_auto_comments', 'asset_value_numeric', 'priority', 'severity_numeric', 'finding_extra_data_value', 'finding_type', 'form_field_types_detected', 'service', 'scan', 'expires_on', 'false_positive', 'auth_detected', 'ip_address', 'is_web', 'finding_context', 'shared_ip', 'host_name', 'hosting_provider', 'hosting_type', 'name_server', 'state_name', 'analyst_status', 'domain_name', 'auto_status', 'industry', 'latitude', 'longitude', 'metro_code', 'postal_code', 'state_abbrev', 'city_name', 'country_name', 'country_iso_code', 'continent_name', 'continent_code', 'vendor_due_date', 'vendor_status_response_key', 'vendor_comment', 'security_domain_id', 'security_criteria_key', 'security_domain_key', 'vendor_status_name', 'vendor_status', 'customer_request_count', 'asset_value', 'severity', 'cotenant_count', 'days_open', 'support_status', 'criteria_issue', 'additional_info', 'customer_due_date', 'found_in_customer_action_plans']
  },
  unencrypted_sensitive_systems: {
    domain: 'web_app_security',
    criteria: 'unencrypted_sensitive_systems',
    title: 'Application Security - High Value System Encryption',
    issue: 'RiskRecon used passive techniques to identify High Value systems that do not implement communications encryption.  The lack of encryption on these systems exposes sensitive data in transit to access by unauthorized persons.  This also increases the likelihood of system users falling victim to miscreants tricking them in to submitting sensitive data to a fraudulent system imitating the genuine system. Encryption of communications on High Value systems is essential to protect sensitive data communications from interception.',
    recommendation: 'RiskRecon recommends the implementation of HTTPS encryption services for all High Value systems that collect or transmit sensitive data identified in this section. This will ensure that sensitive communications cannot be intercepted and that users can validate the authenticity of the site.  Engage with the administrator of the system to implement the encryption service and related configuration. In implementing HTTPS, RiskRecon recommends obtaining the certificates from a reputable certificate authority who will provide recommendations for secure configuration if those are known to the system administrator. ',
    tableHeaders: ['hostname', 'days_open', 'asset_value', 'severity', 'priority', 'your_status'],
    tableSortKeys: ['host_name', 'days_open', 'asset_value', 'severity', 'priority', 'vendor_status_name'],
    tableSortableColumnHeaders: ['host_name', 'days_open', 'asset_value', 'severity', 'priority', 'vendor_status_name'],
    expressionAttributes: ['reporting_status', 'found_in_customer_action_plans', 'finding_id', 'analysis_id', 'first_seen', 'last_seen', 'finding_detail', 'finding', 'finding_subtype', 'finding_data_value', 'finding_data_description', 'finding_auto_comments', 'asset_value_numeric', 'priority', 'severity_numeric', 'finding_extra_data_value', 'finding_type', 'form_field_types_detected', 'service', 'scan', 'expires_on', 'false_positive', 'auth_detected', 'ip_address', 'is_web', 'finding_context', 'shared_ip', 'host_name', 'hosting_provider', 'hosting_type', 'name_server', 'state_name', 'analyst_status', 'domain_name', 'auto_status', 'industry', 'latitude', 'longitude', 'metro_code', 'postal_code', 'state_abbrev', 'city_name', 'country_name', 'country_iso_code', 'continent_name', 'continent_code', 'vendor_due_date', 'vendor_status_response_key', 'vendor_comment', 'security_domain_id', 'security_criteria_key', 'security_domain_key', 'vendor_status_name', 'vendor_status', 'customer_request_count', 'asset_value', 'severity', 'cotenant_count', 'days_open', 'support_status', 'criteria_issue', 'additional_info', 'customer_due_date', 'found_in_customer_action_plans']
  },
  web_app_security_malicious_code: {
    domain: 'web_app_security',
    title: 'Application Security - Malicious Code',
    issue: 'RiskRecon used passive techniques to assess the presence of injected malicious code on public facing websites. The passive detection was combined with threat intelligence to identify known malicious code used for the purpose of data theft. The presence of malicious code is an indicator that the website is compromised and that any data entered into the website by customers is at risk.',
    recommendation: 'The detection of malicious code is a strong indicator that the monitored website has been compromised. Quick action should be taken to remove the detected code in order to stop theft of personal and/or payment card data. Once the malicious code has been removed, additional actions are needed to prevent future code injections. These actions include but are not limited to patching of the web and application server, removing and/or patching application plugins and restricting access to admin portals.',
    criteria: 'malicious_code',
    tableHeaders: ['URL', 'IOC', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority', 'your_status'],
    tableSortKeys: ['finding_extra_data_value', 'finding_data_value', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority', 'vendor_status_name'],
    tableSortableColumnHeaders: ['finding_extra_data_value', 'finding_data_value', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority', 'vendor_status_name'],
    expressionAttributes: ['finding_extra_data_value', 'finding_id', 'finding_data_value', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority', 'customer_request_count', 'vendor_status_name', 'security_criteria_key', 'security_domain_key', 'reporting_status', 'found_in_customer_action_plans']
  },
  web_encryption: {
    domain: 'web_encryption',
    title: 'Web Encryption',
    issue: 'RiskRecon used passive techniques to analyze web encryption security configurations. Correctly configured web encryption is essential to ensuring that communications are protected from interception. It also prevents encryption errors from being displayed to users and enables users to verify the authenticity of the system. From the web encryption issues identified, your customers selected those detailed in this section as important to address due to the issue severity and the sensitivity of the system in which the issue exists.',
    recommendation: 'Fix the encryption configuration issues in this section to ensure that communications cannot be intercepted and that users can validate the authenticity of the site. Issue details and recommendations are provided below (click the issue).',
    tableHeaders: ['issue', 'Hostname', 'detail', 'days_open', 'asset_value', 'severity', 'priority', 'your_status'],
    tableSortKeys: ['security_criteria', 'host_name', 'domain_name', 'first_seen', 'asset_value', 'severity', 'priority'],
    tableSortableColumnHeaders: ['security_criteria', 'host_name', 'first_seen', 'asset_value', 'severity', 'priority'],
    expressionAttributes: ['analysis_id', 'finding_id', 'criteria_issue', 'host_name', 'finding_detail', 'finding_data_value', 'finding_data_description', 'finding_extra_data_value', 'domain_name', 'days_open', 'last_seen', 'shared_ip', 'asset_value', 'severity', 'priority', 'customer_request_count', 'vendor_status_name', 'security_criteria_key', 'security_domain_key', 'reporting_status', 'found_in_customer_action_plans']
  },
  threat_intell: {
    domain: 'threat_intell',
    title: 'System Reputation',
    issue: 'RiskRecon identified systems displaying suspicious activity based on analysis of IP reputation and threat intelligence databases. Observed malicious activity may indicate the system is compromised or is being used for unauthorized purposes. From the IP reputation issues identified, your customers selected those detailed in this section as important to investigate due to the issue severity and the sensitivity of the system in which the issue exists.',
    recommendation: 'Investigate the identified malicious system activity listed in this section. In cases where the system is sharing an IP address with other systems, the malicious activity may be due to activity of another system tenant. In such cases, it is recommended that the system use a dedicated IP address to help ensure the positive reputation and accessibility of the system going forward. Issue details and recommendations for each issue are provided below (click the issue).',
    tableHeaders: ['issue', 'IP Address', 'Hostname', 'days_active', 'Shared IP', 'asset_value', 'severity', 'priority', 'your_status'],
    tableSortKeys: ['security_criteria', 'ip_address', 'host_name', 'first_seen', 'shared_ip', 'asset_value', 'severity', 'priority'],
    tableSortableColumnHeaders: ['security_criteria', 'ip_address', 'host_name', 'first_seen', 'shared_ip', 'asset_value', 'severity', 'priority'],
    expressionAttributes: ['analysis_id', 'finding_id', 'criteria_issue', 'ip_address', 'host_name', 'days_open', 'shared_ip', 'asset_value', 'severity', 'priority', 'customer_request_count', 'vendor_status_name', 'security_criteria_key', 'security_domain_key', 'reporting_status', 'found_in_customer_action_plans']
  },
  defensibility_shared_ip: {
    domain: 'defensibility',
    title: 'System Hosting - Cotenant IP Hosting',
    issue: 'RiskRecon used passive techniques to identify systems using an IP address that is shared with other organizations. Hosting systems using shared IP addresses exposes the organization\'s systems to the impacts of security failures and malicious behavior of others using the IP address, such as IP blacklisting, denial of service attack, and potentially system compromise. From the issues identified, your customers selected those detailed in this section as important to consider hosting on a dedicated IP address.',
    recommendation: 'To migrate a system to a dedicated IP address, simply contact your system hosting provider and modify the system configuration to use a dedicated IP address assigned to you. The additional cost for a dedicated IP address is typically minimal.',
    criteria: 'shared_hosting',
    tableHeaders: ['IP Address', 'Hostname', 'cotenant_count', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority', 'your_status'],
    tableSortKeys: ['ip_address', 'host_name', 'finding_extra_data_value', 'first_seen', 'first_seen', 'asset_value', 'severity', 'priority'],
    tableSortableColumnHeaders: ['ip_address', 'host_name', 'finding_extra_data_value', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority'],
    expressionAttributes: ['analysis_id', 'finding_id', 'ip_address', 'host_name', 'cotenant_count', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority', 'customer_request_count', 'vendor_status_name', 'security_criteria_key', 'security_domain_key', 'reporting_status', 'found_in_customer_action_plans']
  },
  email_security_email_auth: {
    domain: 'email_security',
    title: 'Email Security - Email Authentication (DMARC)',
    issue: 'RiskRecon used passive techniques to analyze the configuration of email authentication configuration settings. Lack of email authentication allows people to send spoofed emails using your domain. Criminals commonly abuse domains that do not implement email authentication to commit online fraud, leveraging the insecure domain to coerce people into falling victim to the malicious email message. From the email authentication issues identified, your customers selected those detailed in this section as important to address.',
    recommendation: 'Email authentication is implemented through the Domain-based Message Authentication, Reporting and Conformance framework. In deploying DMARC, senders of email specify  the email servers that are authorized to send email on behalf of the domain. A recipient of an email can then check associated domain records for email authentication instructions. If the email message fails those checks, then the recipient simply deletes the message and logs the event.',
    criteria: 'email_authentication',
    tableHeaders: ['email_domain', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority', 'your_status'],
    tableSortKeys: ['domain_name', 'first_seen', 'first_seen', 'asset_value', 'severity', 'priority'],
    tableSortableColumnHeaders: ['domain_name', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority'],
    expressionAttributes: ['analysis_id', 'finding_id', 'domain_name', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority', 'customer_request_count', 'vendor_status_name', 'security_criteria_key', 'security_domain_key', 'reporting_status', 'found_in_customer_action_plans']
  },
  email_security_email_encryption: {
    domain: 'email_security',
    title: 'Email Security - Email Encryption (STARTTLS)',
    issue: 'RiskRecon used passive techniques to analyze email server encryption settings. Not implementing email encryption causes email servers to send email messages unencrypted, exposing the messages to interception during transmission. From the email encryption issues identified, your customers selected those detailed in this section as important to address.',
    recommendation: 'Email encryption is implemented in the email server using STARTTLS. STARTTLS is a protocol by which you can upgrade an otherwise unencrypted channel to an encrypted channel, similar to the way web browsers establish encrypted communications with web servers. STARTTLS is an extension of the SMTP protocol and is commonly supported by email servers.',
    criteria: 'email_encryption_enabled',
    tableHeaders: ['email_server', 'domains_impacted', 'example_domain', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority', 'your_status'],
    tableSortKeys: ['host_name', 'domains_impacted', 'example_domains', 'first_seen', 'first_seen', 'asset_value', 'severity', 'priority'],
    tableSortableColumnHeaders: ['host_name', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority'],
    expressionAttributes: ['analysis_id', 'finding_id', 'host_name', 'domains_impacted', 'example_domain', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority', 'customer_request_count', 'vendor_status_name', 'security_criteria_key', 'security_domain_key', 'additional_info', 'reporting_status', 'found_in_customer_action_plans']
  },
  dns_security_domain_hijacking: {
    domain: 'dns_security',
    title: 'DNS Security - Domain Hijacking Protection',
    issue: 'RiskRecon used passive techniques to analyze domain hijacking configurations. Not implementing domain hijacking protections makes it easier for criminals to gain control of a domain. From the domain hijacking protection issues identified, your customers selected those detailed in this section as important to address.',
    recommendation: ' To remedy the issue, work with the domain name registrar to implement the \'clientTransferProhibited\' domain status flag for each of the domains. The \'clientTransferProhibited\' flag directs the domain registrar to perform strong authentication of any agent attempting to modify the domain, helping prevent unauthorized domain configuration changes.',
    criteria: 'dns_hijacking_protection',
    tableHeaders: ['domain', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority', 'your_status'],
    tableSortKeys: ['domain_name', 'first_seen', 'first_seen', 'asset_value', 'severity', 'priority'],
    tableSortableColumnHeaders: ['domain_name', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority'],
    expressionAttributes: ['analysis_id', 'finding_id', 'domain_name', 'first_seen', 'days_open', 'asset_value', 'severity', 'priority', 'customer_request_count', 'vendor_status_name', 'security_criteria_key', 'security_domain_key', 'reporting_status', 'found_in_customer_action_plans']
  },
  network_filtering_unsafe_network_services: {
    domain: 'network_filtering',
    criteria: 'unsafe_network_services',
    title: 'Network Filtering - Unsafe Network Services',
    issue: 'RiskRecon used passive techniques to analyze systems for unsafe network services. Unsafe network services consist of products and protocols such as database servers and remote access services. These network services are widely considered to be insecure and unnecessary to operate on the Internet.',
    recommendation: 'RiskRecon recommends blocking Internet access to each network service listed below. Internet access can be blocked by implementing access control rules in the system’s network firewall or router. Limit Internet-accessible network services to those that are required to be Internet-accessible, such as HTTP for web servers and SMTP for email services.',
    tableHeaders: ['issue', 'IP Address', 'hostname', 'first_seen', 'asset_value', 'severity', 'priority', 'your_status'],
    tableSortKeys: ['finding_detail', 'ip_address', 'host_name', 'first_seen', 'asset_value', 'severity', 'priority'],
    tableSortableColumnHeaders: ['finding_detail', 'ip_address', 'host_name', 'first_seen', 'asset_value', 'severity', 'priority'],
    expressionAttributes: ['reporting_status', 'finding_id', 'analysis_id', 'first_seen', 'last_seen', 'finding_detail', 'finding', 'finding_subtype', 'finding_data_value', 'finding_data_description', 'finding_auto_comments', 'asset_value_numeric', 'priority', 'severity_numeric', 'finding_extra_data_value', 'finding_type', 'form_field_types_detected', 'service', 'scan', 'expires_on', 'false_positive', 'auth_detected', 'ip_address', 'is_web', 'finding_context', 'shared_ip', 'host_name', 'hosting_provider', 'hosting_type', 'name_server', 'state_name', 'analyst_status', 'domain_name', 'auto_status', 'industry', 'latitude', 'longitude', 'metro_code', 'postal_code', 'state_abbrev', 'city_name', 'country_name', 'country_iso_code', 'continent_name', 'continent_code', 'vendor_due_date', 'vendor_status_response_key', 'vendor_comment', 'security_domain_id', 'security_criteria_key', 'security_domain_key', 'vendor_status_name', 'vendor_status', 'customer_request_count', 'asset_value', 'severity', 'cotenant_count', 'days_open', 'support_status', 'criteria_issue', 'additional_info', 'customer_due_date', 'found_in_customer_action_plans']
  },
  network_filtering_iot_devices: {
    domain: 'network_filtering',
    criteria: 'iot_devices',
    title: 'Network Filtering - IOT Devices',
    issue: 'RiskRecon used passive techniques to analyze the organization’s networks and systems for Internet of Things (IOT) devices. IOT devices consist of network-accessible devices that control physical systems such as storage arrays, elevators, cameras, and HVAC infrastructure. IOT devices should not be accessible to the general Internet as they are commonly not sufficiently secured to resist compromise. Compromise of the IOT device could result in unauthorized access to and control of the related physical device. It could also provide a path to compromise other systems that reside on the same network. ',
    recommendation: 'As a general rule, IOT devices should not be accessible directly from the Internet. Place all IOT devices behind a network firewall that blocks Internet access to the device. If an IOT device must be internet accessible, place the device on its own network to ensure that if the device is compromised that it cannot be used as a pivot point to gain access to other systems. Further, ensure that the device does not contain any sensitive data or control any important infrastructure.',
    tableHeaders: ['issue', 'IP Address', 'hostname', 'first_seen', 'asset_value', 'severity', 'priority', 'your_status'],
    tableSortKeys: ['finding_detail', 'ip_address', 'host_name', 'first_seen', 'asset_value', 'severity', 'priority'],
    tableSortableColumnHeaders: ['finding_detail', 'ip_address', 'host_name', 'first_seen', 'asset_value', 'severity', 'priority'],
    expressionAttributes: ['reporting_status', 'found_in_customer_action_plans', 'finding_id', 'analysis_id', 'first_seen', 'last_seen', 'finding_detail', 'finding', 'finding_subtype', 'finding_data_value', 'finding_data_description', 'finding_auto_comments', 'asset_value_numeric', 'priority', 'severity_numeric', 'finding_extra_data_value', 'finding_type', 'form_field_types_detected', 'service', 'scan', 'expires_on', 'false_positive', 'auth_detected', 'ip_address', 'is_web', 'finding_context', 'shared_ip', 'host_name', 'hosting_provider', 'hosting_type', 'name_server', 'state_name', 'analyst_status', 'domain_name', 'auto_status', 'industry', 'latitude', 'longitude', 'metro_code', 'postal_code', 'state_abbrev', 'city_name', 'country_name', 'country_iso_code', 'continent_name', 'continent_code', 'vendor_due_date', 'vendor_status_response_key', 'vendor_comment', 'security_domain_id', 'security_criteria_key', 'security_domain_key', 'vendor_status_name', 'vendor_status', 'customer_request_count', 'asset_value', 'severity', 'cotenant_count', 'days_open', 'support_status', 'criteria_issue', 'additional_info', 'customer_due_date']
  },
}
