import React from 'react'
import PropTypes from 'prop-types'

const AddCircleSVG = ({
  size,
  ...rest
}) =>
  <svg {...rest} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill="none" d="M0 0h24v24H0V0z"/>
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm4 11h-3v3c0 .55-.45 1-1 1s-1-.45-1-1v-3H8c-.55 0-1-.45-1-1s.45-1 1-1h3V8c0-.55.45-1 1-1s1 .45 1 1v3h3c.55 0 1 .45 1 1s-.45 1-1 1z"/>
  </svg>

AddCircleSVG.defaultProps = {
  size: '24',
}

AddCircleSVG.propTypes = {
  size: PropTypes.string.isRequired,
}

export default AddCircleSVG
