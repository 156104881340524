/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import resetPasswordValidator from '../../validators/resetPassword'
import axios from 'axios'
import Container from '../shared/Container'
import { Header, Button } from '../shared'
import ErrorMessage from '../shared/ErrorMessage'
import TextInput from '../shared/TextInput'
import EyeNotVisibleSVG from '../../assets/svg/EyeNotVisibleSVG'
import EyeVisibleSVG from '../../assets/svg/EyeVisibleSVG'
import CheckCircleSVG from '../../assets/svg/CheckCircleSVG'
import RemoveCircleSVG from '../../assets/svg/RemoveCircleSVG'
import { Link } from 'react-router-dom'
import Loading from '../loaders/Loading'
import { authURL } from '../../utils/environmentHelpers'
import "./LoginWithToken.scss";

function LoginWithToken({ match, history }) {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    reset_password_token: '',
  })
  const [errors, setErrors] = useState({})
  const [validationSteps, setValidationSteps] = useState({})
  const [formValid, setFormValid] = useState(false)
  const [showPasswordValidation, setShowPasswordValidation] = useState(false)
  const [showConfirmPasswordValidation, setShowConfirmPasswordValidation] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    axios({
      method: 'GET',
      url: `${authURL(window.location.host)}vendor/shared_plan_link?reset_password_token=${match.params.new_user_token}`,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        if(res.request.response.toLowerCase().includes('expired')) {
          history.push(`/login/token-expired/${match.params.new_user_token}`)
        }
        setFormData({...formData, email: res?.data?.user_email, reset_password_token: res?.data?.reset_password_token})
        setIsLoading(false)
      })
      .catch(error => {
        setIsLoading(false)
        history.push('/login')
        console.warn(error)
      })
  }, [match.params.new_user_token])

  const { password, confirmPassword, email } = formData
  const onChange = (event) => {
    event.preventDefault();
    setFormData({ ...formData, [event.target.name]: event.target.value })
    return setErrors({ ...errors, [event.target.name]: '' })
  }

  useEffect(() => {
    const { validations, valid } = resetPasswordValidator(formData)
    setValidationSteps(validations)
    setFormValid(valid)
  }, [formData.password, formData.confirmPassword])

  const submitForm = async (event) => {
    event.preventDefault()
    axios({
      method: 'PUT',
      url: `${authURL(window.location.host)}vendor/shared_plan_link`,
      data: {password_confirmation: formData.confirmPassword, reset_password_token: formData.reset_password_token, password: formData.password},
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((res) => {
        history.push('/login')
      })
      .catch(error => {
        console.warn(error)
        setIsLoading(false)
      })
  }

  const passwordConfirmationSteps = [
    { name: 'length', text: 'Length between 8 and 64 characters' },
    { name: 'oneUpper', text: 'One uppercase letter' },
    { name: 'oneLower', text: 'One lowercase letter' },
    { name: 'oneNumerical', text: 'One number' },
    { name: 'oneSpecial', text: 'One special character (!?#%...)' }
  ]

  const validationCheckItems = Object.values(passwordConfirmationSteps).map(k => (
    <div key={k.name} className='validation-item'>
      {validationSteps[k.name] ? <CheckCircleSVG className='satisfied' /> : <RemoveCircleSVG className='unsatisfied' />}
      <p>{k.text}</p>
    </div>
  ))

  return (
    <div className="vendor-portal-login-container">
      <Loading isLoading={isLoading} overlay />
      <div className="vendor-portal-login-section">
        <div className="vendor-portal-login-section-left">
          <Container>
            <Header as="h2">Welcome</Header>
            <p>Please fill out form below to view and remediate issues.</p>
            <form
              name="newUserForm"
              id='unauth-reset-password-form'
              onSubmit={submitForm}
            >
              <TextInput
                label='Email'
                type='text'
                ariaAutoComplete='none'
                value={email}
                name='email'
                className='email'
                onChange={onChange}
                error={errors.email}
                disabled
              />
              <TextInput
                label='New Password'
                type={`${showPassword ? 'text' : 'password'}`}
                value={password}
                name='password'
                className={'password'}
                onChange={onChange}
                error={errors.password}
                autoComplete='none'
                onFocus={() => setShowPasswordValidation(true)}
                onBlur={() => setShowPasswordValidation(false)}
                icon={showPassword
                  ? <EyeVisibleSVG className='show-hide-password' size='16' />
                  : <EyeNotVisibleSVG className='show-hide-password' size='16' />}
                onIconClick={() => setShowPassword(!showPassword)}
              />
              <div className={`unauth-password-validation ${showPasswordValidation ? 'active' : ''}`}>
                <Header as='h2'>New Password Criteria</Header>
                <ul>
                  <li>Needs to have at least one character added / deleted / or changed when reset.</li>
                  <li>Should not match any previous passwords.</li>
                </ul>
                <hr />
                <Header as='h2' className='composition'>Your password composition must contain:</Header>
                <div className='unauth-validation-checks'>
                  {validationCheckItems}
                </div>
              </div>
              <div className={`password-triangle ${showPasswordValidation ? 'active' : ''}`}>
                <div className='inner-triangle' />
              </div>
              <TextInput
                label='Confirm Password'
                type={`${showPasswordConfirmation ? 'text' : 'password'}`}
                ariaAutoComplete='none'
                value={confirmPassword}
                name='confirmPassword'
                className='confirmPassword'
                onChange={onChange}
                error={errors.confirmPassword}
                onFocus={() => setShowConfirmPasswordValidation(true)}
                onBlur={() => setShowConfirmPasswordValidation(false)}
                icon={showPasswordConfirmation
                  ? <EyeVisibleSVG className='show-hide-password' size='16' />
                  : <EyeNotVisibleSVG className='show-hide-password' size='16' />}
                onIconClick={() => setShowPasswordConfirmation(!showPasswordConfirmation)}
              />
              <div className={`unauth-password-confirmation-validation ${showConfirmPasswordValidation ? 'active' : ''}`}>
                <div className='unauth-validation-checks'>
                  <div className='validation-item'>
                    {validationSteps.passwordsMatch && validationSteps.fieldsNotEmpty ? <CheckCircleSVG className='satisfied' /> : <RemoveCircleSVG className='unsatisfied' />}
                    <p>Passwords match</p>
                  </div>
                </div>
              </div>
              <div className={`confirm-password-triangle ${showConfirmPasswordValidation ? 'active' : ''}`}>
                <div className='inner-triangle' />
              </div>
              {errors.equalPassword &&
                <ErrorMessage message={errors.equalPassword} />
              }
              <Button disabled={!formValid} className='unauth-submit' type='submit' color='blue outline'>
                Sign Up
              </Button>
              <div className="already-signed-up">
                <span>Already signed up?</span>
                <Link to="/login">Log in here</Link>
              </div>
            </form>
          </Container>
        </div>
        <div className="vendor-portal-login-section-right">
          <Header>Vendor Invitation to Collaborate</Header>
          <p className="vendor-portal-login-section-right-subheading">Welcome - you have been asked by a customer(s) to remediate one or more cybersecurity issues identified via RiskRecon by Mastercard.</p>
          <p>Please fill out the &#39;Welcome&#39; form to view the security findings your customer(s) has asked you to address through our Vendor Portal.</p>
          <p>Access to RiskRecon&apos;s Vendor Portal is provided complimentary to you by your customer(s). And it grants you non-time restricted access to RiskRecon&apos;s platform and Support Team to better assist you in the remediation of these issues.</p>
          <p className="vendor-portal-login-section-right-subheading">How Were These Findings Determined?</p>
          <p>RiskRecon assesses cybersecurity using passive techniques. Of the total issues identified by RiskRecon, your customers have identified the issues listed here as a high priority to investigate and address.</p>
          <div className="demo-and-more-button">
            <a className="demo-video" target="_blank" rel="noreferrer" href="https://youtu.be/IGcxUxerBhA">Vendor Portal Demo Video</a>
            <a className="more-about" target="_blank" rel="noreferrer" href="https://www.riskrecon.com/cybersecurity-risk-rating-model">More About RiskRecon Methodology</a>
          </div>
        </div>
      </div>
    </div>
  )
}

LoginWithToken.propTypes = {
}

export default LoginWithToken