import React, { useState } from 'react'
import Container from '../shared/Container'
import Header from '../shared/Header'
import { Link } from 'react-router-dom'
import Button from '../shared/Button'
import axios from 'axios'
import Loading from '../loaders/Loading'
import './TokenExpired.scss'
import { authURL } from '../../utils/environmentHelpers'

const TokenExpired = ({ match, history }) => {
  const [isLoading, setIsLoading] = useState(false)

  const sendNewLink = () => {
    setIsLoading(true)
    axios({
      method: 'PUT',
      url: `${authURL(window.location.host)}vendor/user_refresh_token`,
      data: { reset_password_token: match.params.new_user_token },
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((res) => {
        history.push('/login')
      })
      .catch(error => {
        console.warn(error)
        setIsLoading(false)
      })
  }

  return (
    <div>
      <Loading isLoading={isLoading} overlay />
      <Container>
        <div className="token-expired-container">
          <div className="token-expired-content-left">
            <img src={require('../../assets/images/link-expired.png')} alt='' />
          </div>
          <div className="token-expired-content-right">
            <Header as="h1">Link Expired</Header>
            <p>This link has expired. To receive a new link, click the “Send New Link” button below. If you already have an account created, click the “Log In” button below.</p>
            <div className="token-expired-content-right-buttons">
              <Link to="/login">Log In</Link>
              <Button onClick={() => sendNewLink()} color="blue">Send New Link</Button>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default TokenExpired
