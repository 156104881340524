import { configureStore } from '@reduxjs/toolkit'
import throttle from 'lodash/throttle'
import { loadState, saveState } from './reference/localStorage'
import rootReducer from './reducers'

// get initial state from localStorage
const initialState = loadState()

const store = configureStore({
  reducer: rootReducer,
  preloadedState: initialState,
})

// subscribe the store to a function that writes specified reducers
// to localStorage in order to persist reloads/logouts
store.subscribe(throttle(() => {
  saveState({
    flash: store.getState().flash
  })
}), 1000)

export default store
