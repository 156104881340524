import React, { Component } from 'react'
// import '../../../../assets/styles/Report/Shared/FindingSidebar/IssueDetailsPane.css';
// import { hostProfileSections } from '../../../../constants/Report/FindingSidebar';
import HostSummarySection from './HostProfile/HostSummarySection'
import AssetValueSection from './HostProfile/AssetValueSection'
import GeolocationSection from './HostProfile/GeolocationSection'
import DimensionSection from './HostProfile/DimensionSection'
import DomainSection from './HostProfile/DomainSection'
// import NetworkServicesSection from './HostProfile/NetworkServicesSection'
import { findingSidebarAttributes } from '../../../constants/FindingSidebar'

class HostProfilePane extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount () {
  }

  render () {
    const { finding } = this.props
    return (
      <div className='issue-details-pane-container'>
        <HostSummarySection finding={finding} hostSummaryAttributes={findingSidebarAttributes[finding.attributes.security_criteria_key || 'it-profile'].host_summary_attributes} />
        <AssetValueSection finding={finding} />
        {finding.dimensions &&
          <DimensionSection finding={finding} />
        }
        <GeolocationSection finding={finding} />
        <DomainSection finding={finding} />
        {/* <NetworkServicesSection finding={finding} /> */}
      </div>
    )
  }
}

HostProfilePane.propTypes = {

}

export default HostProfilePane
