import React, { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { Route, useLocation } from 'react-router-dom'
import { portalEnv } from '../utils/environmentHelpers'

const formatURL = (url = '') => {
  const regex = /\/\d+/
  return url?.replace(regex, '')
}

export const GoogleAnalytics = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    ReactGA.set({
      page: formatURL(pathname),
    })
    ReactGA.send({ hitType: 'pageview', page: formatURL(pathname) })
  }, [pathname])

  return <div />
}

export const RouteTracker = () => <Route children={<GoogleAnalytics />} />

export const initGoogleAnalytics = () => {
  const currentEnv = portalEnv(window.location.host)

  if (currentEnv === 'production') {
    ReactGA.initialize([
      {
        trackingId: 'G-S7P88F2FYR',
        gtagOptions: { send_page_view: false },
        gaOptions: { send_page_view: false },
      },
    ])
    return true
  }
  console.log(
    'In ' +
      currentEnv.substring(0, currentEnv?.length) +
      ' environment, no google analytics acquired'
  )
  return false
}
