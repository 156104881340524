import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './SelectButton.scss'

/**
 * Use `SelectButton` when you need to hold a 'selected' state with the button UI.
 */
const SelectButton = ({
  children,
  onClick,
  type,
  disabled,
  color,
  selected,
  ...rest
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={classnames('v3-select-button', color, { selected })}
      {...rest}
    >
      {children}
    </button>
  )
}

SelectButton.defaultProps = {
  type: 'button',
  color: 'green',
  disabled: false,
  selected: false,
}

SelectButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.array]),
  color: PropTypes.oneOf(['green', 'red', 'blue', 'dark-blue']).isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  type: PropTypes.string.isRequired,
}

export default SelectButton
