import axios from 'axios'

const api = axios.create()
const actualSubdomain = { present: false, value: '' }
const possibleSubdomain = document.location.hostname.split('.')[1]

let globalLoginSet = false
let redirectInProgress = false
const setGlobalLoginURL = () => ({
  type: 'SET_GLOBAL_LOGIN_URL'
})

const xhrMiddleware = store => next => async (action) => {
  if (action.type === 'SET_GLOBAL_LOGIN_URL') {
    // This is the interceptor that loads when actions/CurrentUser's fetch user info call succeeeds
    const { loginURL } = await store.getState().CurrentUser
    globalLoginSet = true
    api.interceptors.response.use(response => response, (error) => {
      if (error.response !== undefined && error.response.status === 401 && redirectInProgress === false) {
        redirectInProgress = true
        window.location = loginURL
      }
      return Promise.reject(error)
    })
  }
  return next(action)
}

switch (possibleSubdomain) {
  case 'dev':
    actualSubdomain.value = 'dev'
    actualSubdomain.present = true
    break
  case 'staging':
    actualSubdomain.value = 'staging'
    actualSubdomain.present = true
    break
  case 'demo':
    actualSubdomain.value = 'demo'
    actualSubdomain.present = true
    break
  default:
    break
}

const authLink = actualSubdomain.present ? `https://auth.${actualSubdomain.value}.riskrecon.com/users/sign_in` : 'https://auth.riskrecon.com/users/sign_in'

// This is our default interceptor until the async request in actions/CurrentUser finishes
api.interceptors.response.use(response => response, (error) => {
  if (error.response !== undefined && error.response.status === 401 && globalLoginSet === false && redirectInProgress === false) {
    redirectInProgress = true
    window.location = authLink
  }
  return Promise.reject(error)
})

export { api, xhrMiddleware, setGlobalLoginURL }
