import React from 'react'

const Eula = () => {
  return (
    <>
      <br/>
      <p>This Agreement sets forth the terms and conditions under which the Hosted Services will be made available and under which Vendor will access and use the Hosted Services, all of which will be subject to the terms and conditions of this Agreement.</p>
      <ol>
        <li>
          <strong>DEFINITIONS</strong>
          <p>The following definitions shall be used for purposes of this Agreement.</p>
          <ol>
            <li><strong>“Affiliate”</strong> means, in relation to a party, any other entity which directly or indirectly Controls, is Controlled by, or is under direct or indirect common Control with that party from time to time.</li>
            <li><strong>“Control”</strong> means, in relation to a corporate entity, the power, by operation of law or as a matter of fact, to exercise, whether directly or indirectly, a decisive influence on the orientation of such entity’s management or the appointment of the majority of its directors; “<strong>Controls</strong>” and “<strong>Controlled</strong>” will be interpreted accordingly.</li>
            <li><strong>“Documentation”</strong> means the program guides, manuals, release notes, reference guides, specifications or other documents relating to the Hosted Services provided or made available by Mastercard or its Affiliates to the Vendor, from time to time.</li>
            <li><strong>“Effective Date”</strong> means, the date that Mastercard first makes the Hosted Services available to Vendor.</li>
            <li><strong>“Error”</strong> means a material failure of the Hosted Services to conform to its functional specifications, excluding failures resulting from Vendor’s negligence or improper use of the Hosted Services. </li>
            <li><strong>"Government Vendor"</strong> means a government, whether at supranational, federal, state, provincial or municipal, an agency, body, authority or instrumentality of a government and includes any entity, including a commercial entity, where any government or combination of governments hold the controlling equity interest or other means of control.</li>
            <li><strong>“Hosted Service(s)”</strong> means software, data, and services and updates thereto developed and owned by RiskRecon Inc. (“<strong>RiskRecon”</strong>) that are made available by Mastercard or its Affiliates to Vendor over a network.</li>
            <li><strong>“Intellectual Property Rights”</strong> means any and all now or hereafter known tangible and intangible: (a) rights associated with works of authorship throughout the world, including copyrights or works of copyright, moral rights, and mask-works; (b) Marks and similar rights; (c) trade secret rights; (d) patents, designs, algorithms, and other industrial property rights; (e) all other intellectual and industrial property rights of every kind and nature throughout the world and however designated (including domain names, logos, “rental” rights, and rights to remuneration), whether arising by operation of law, contract, license, or otherwise; and (f) all registrations, initial applications, renewals, extensions, continuations, divisions, or reissues thereof currently or hereafter in force (including any derivative rights in any of the foregoing).</li>
            <li><strong>“Marks”</strong> means trademarks and service marks (whether registered or at common law), trade names, business names, logos, symbols, and Internet domain names, or any abbreviation or contraction thereof.</li>
            <li><strong>“Mastercard”</strong> means <strong>MASTERCARD INTERNATIONAL INCORPORATED</strong>, a Delaware corporation with offices at 2000 Purchase Street, Purchase, New York, 10577, or if the Vendor is located outside of North America, the Affiliate of Mastercard International Incorporated that is the Mastercard contracting party for the jurisdiction in which Vendor is located  (“<strong>Mastercard”</strong>).</li>
            <li><strong>“Mastercard Binding Corporate Rules”</strong> means the Mastercard Binding Corporate Rules as approved by the EEA data protection authorities and available at <a href="https://www.mastercard.us/content/dam/mccom/global/documents/mastercard-bcrs.pdf" target="_blank" rel="noreferrer">https://www.mastercard.us/content/dam/mccom/global/documents/mastercard-bcrs.pdf</a>. </li>
            <li><strong>“Mastercard Group”</strong> means Mastercard and its Affiliates. </li>
            <li><strong>“Personal Data”</strong> means any information relating to an identified or identifiable natural person. An identifiable natural person is one who can be identified, directly, or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier, or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural, or social identity of that natural person.</li>
            <li><strong>“Processing of Personal Data”</strong> (or “<strong>Processing/Process</strong>”) means any operation or set of operations which is performed on Personal Data or on sets of Personal Data, whether or not by automated means, such as collection, recording, organization, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.</li>
            <li><strong>“Report”</strong> means any output resulting from the Hosted Services data which is available online for viewing via the Hosted Services and downloadable, including in electronic format such as .pdf.</li>
            <li><strong>“Term”</strong> means  as set forth in Section 2.1 of this Agreement.</li>
            <li><strong>“Territory”</strong> means, unless otherwise specifically provided for in writing by Mastercard, the jurisdiction where the principal place of business of Vendor is located.</li>
            <li><strong>“User”</strong> means an individual who is authorized by Subscriber to use the Hosted Services, for whom subscriptions to the Hosted Services have been purchased, and who have been supplied user identifications and passwords by Subscriber (or by Mastercard or its Affiliates, at Subscriber's request).  Users may include but are not limited to employees, consultants, contractors and agents of Subscriber. Without limiting the foregoing, Users may also include Vendors authorized by Subscriber and permitted to access the Hosted Services to view Reports on a limited basis via an invitation-only process managed by Mastercard or its Affiliates for the purposes of understanding such Vendor’s own cybersecurity risk profile in order to mitigate Subscriber’s cybersecurity risk management concerns in relation to such Vendor.</li>
            <li><strong>“Vendor”</strong> means a third-party technology provider or service provider Subscriber monitors or intends to monitor via the Hosted Services.</li>
          </ol>
        </li>
        <li>
          <strong>TERM AND TERMINATION</strong>
          <ol>
            <li>
                <u>Term</u>The term of this Agreement shall commence on the Effective Date and shall expire on the date access to the Hosted Services is terminated by Mastercard, in its sole discretion, at any time and for any reason.
                <ul>
                    <li><u>Vendor Access</u>. The term of this Agreement shall commence on the Effective Date and shall expire on the date access to the Hosted Services is terminated by Mastercard, in its sole discretion, at any time and for any reason.</li>
                </ul>
            </li>
            <li>In the event a change in law or regulation prohibits or impairs the Mastercard Group’s ability to provide the Hosted Services, or any portion thereof (“Adverse Change”), Mastercard may suspend the provision of, and Vendor’s right to access and use, the Hosted Services, or such affected portion thereof, for the duration of the Adverse Change, as may be necessary for the Mastercard Group to address the Adverse Change. If Mastercard, in its sole discretion, reasonably determines that it is unable to modify the Hosted Services to address the Adverse Change, Mastercard may terminate such Hosted Services or this Agreement upon written notice to Vendor with no further liability to Vendor.</li>
            <li>At any time, the Mastercard Group may terminate any Hosted Service or this Agreement: (a) upon ninety (90) days’ notice, if the Mastercard Group discontinues such Hosted Service in one or more of the jurisdictions in which such Hosted Service is provided under this Agreement; (b) upon thirty (30) days’ notice or earlier, if required by applicable law or the relevant authority, if the Mastercard Group is required by such law or governing authority to cease providing such Hosted Service in one or more of the countries in which such Hosted Service is provided under this Agreement; (c) immediately, if the Mastercard Group receives a claim or notice alleging that such Hosted Service infringes or violates a third party’s Intellectual Property Rights; or (d) immediately, if Vendor breaches any of <u>Sections 4.4</u> or <u>4.5</u> hereof.</li>
            <li><u>Sections 1, 2.3, 2.4, 4.4, 5, 6, 7, 8, 9 </u> and <u>12</u> shall survive expiration or termination of this Agreement according to their terms.</li>
          </ol>
        </li>
        <li>
          <strong>HOSTED SERVICES</strong>
          <ol>
            <li>Vendor agrees that Mastercard’s Affiliates, including but not limited to RiskRecon, may perform any Hosted Services under this Agreement and any services in support of the Hosted Services </li>
            <li>Mastercard or its Affiliates shall make the applicable Hosted Services available to Vendor and its Users pursuant to this Agreement during the Term. </li>
            <li>Mastercard or its Affiliates shall use commercially reasonable efforts to make the Hosted Services available 24 hours a day, 7 days a week, except for: (a) planned downtime (of which Mastercard or its Affiliates shall use commercially reasonable efforts to provide Vendor at least 72 hours’ notice via the Hosted Services); or (b) any unavailability caused by circumstances beyond the Mastercard Group’s reasonable control including, without limitation, acts of nature, pandemics, acts of government, floor, fire, earthquakes, civil unrest, acts of terror, strikes or other labor problems (other than those involving Mastercard employees), computer, telecommunications, Internet service provider or hosting facility failures or delays involving hardware, software or power systems not within the Mastercard Group’s possession or reasonable control, and denial of service attacks.</li>
          </ol>
        </li>
        <li>
          <strong>TERMS OF USE</strong>
          <ol>
            <li>Vendor agrees that it will use the Hosted Services solely as contemplated by and in accordance with the terms of this Agreement and the Documentation.</li>
            <li>Mastercard grants to Vendor a limited, non-exclusive, non-transferable, non-sublicensable, revocable right to access and use the Hosted Services made available in the Territory for Vendor’s legitimate internal business purpose of  reviewing and correcting, if applicable,  Vendor’s cybersecurity risk profiles in order to mitigate Subscriber’s Vendor cybersecurity risk management concerns only (“<strong>Legitimate Internal Business Purpose</strong>”), subject to the terms of this Agreement. Vendor agrees and acknowledges that any input Vendor includes in the Hosted Services relating to the correction of Vendor’s cybersecurity risk profile will be shared by Mastercard to Subscriber.  Mastercard and Vendor agree and acknowledge that Subscriber may invite a Vendor to be a User of the Hosted Services for the purpose of accessing the Reports, so long as such access by Vendor is for Subscriber’s Legitimate Internal Business Purpose.</li>
            <li>Mastercard hereby grants to Vendor a limited, non-exclusive, non-transferable, non-sublicensable, revocable right to use the Reports in the Territory solely for Vendor’s Legitimate Internal Business Purpose, subject to the terms of this Agreement.</li>
            <li>Except to the extent such restriction is expressly prohibited by applicable law, and other than as expressly set forth in this Agreement, Vendor and its Users shall not, and will not assist or permit any third party to: (a) disassemble, reverse engineer, decompile or otherwise attempt to derive source code of the Hosted Services or any component thereof, (b) copy, reproduce, modify, alter or otherwise create any derivative works of, the Hosted Services or any Reports, (c) license, sublicense, sell, resell, rent, lease, transfer, assign, distribute, time share or otherwise commercially exploit or make the Hosted Services or any Reports available to any third party, other than to Users or as otherwise contemplated by this Agreement, (d) use the Hosted Services or Reports to violate, misappropriate, or infringe the rights of any third party, (e) interfere with, disrupt or circumvent the integrity or performance of, or any feature of the Hosted Services or the data contained therein, including any security or access control mechanism, (f) attempt to gain unauthorized access to the Hosted Services or its related systems or networks, or (g) attempt to do any of the foregoing.</li>
            <li>Vendor and its Users shall access and/or use the Hosted Services and Reports solely for Vendor’s Legitimate Internal Business Purpose as contemplated by this Agreement and shall not use the Hosted Services or Reports in any way not expressly permitted in this Agreement.  Vendor is responsible for all activities that occur in User accounts and for Users’ compliance with this Agreement and the Documentation.  Vendor shall also be responsible for maintaining an accurate list of active Users with Mastercard.  Vendor shall access, use, and accept provision of the Hosted Services solely in the Territory.  Vendor shall also use commercially reasonable efforts to prevent unauthorized access to, or use of, the Hosted Services, and shall notify Mastercard promptly of any such unauthorized access or use.  Vendor further warrants that its use of the Hosted Services in connection with the performance of this Agreement complies with all applicable local, state, federal and foreign laws.  To the extent that Vendor becomes aware that the provision by Mastercard or Mastercard’s Affiliate(s) of the Hosted Services, or Vendor’s use of the Hosted Services, does not comply with applicable law, Vendor shall immediately communicate the same to Mastercard, and the parties agree to cooperate in good faith to resolve the matter. The foregoing does not relieve Mastercard of any liability under this Agreement.</li>
          </ol>
        </li>
        <li>
          <strong>OWNERSHIP</strong>
          <ol>
            <li>The Mastercard Group is, and shall remain the sole owner of, and shall retain all right, title and interest in and to the Hosted Services and any related documentation, and any modifications, or improvements thereto or derivative works thereof, whether or not made by the Mastercard Group. The Mastercard Group reserves all rights in and to the foregoing, and Vendor gains no rights or licenses hereunder, except as expressly granted in this Agreement.</li>
            <li>Vendor recognizes and agrees that the Mastercard Group owns the Reports created for specific Vendors identified by Subscriber and may use such Reports for its own purposes and for other Subscribers.</li>
          </ol>
        </li>
        <li>
          <strong>CONFIDENTIALITY</strong>
          <ol>
            <li>Each party will regard any information (in writing, orally, or in any other form) provided to it by the other party and designated in writing, or if orally provided, indicated verbally by the disclosing party, as proprietary or confidential, to be confidential (“Confidential Information”).  Confidential Information shall also include (i) information which, to a reasonable person familiar with the disclosing party’s business and the industry in which it operates, is of a confidential or proprietary nature, regardless of whether designated as such in writing (including, without limitation, trade secrets, and, for Mastercard, Mastercard’s Intellectual Property Rights or any data and information contained therein); and (ii) any documents prepared by the receiving party that contain, otherwise reflect, or, in whole or in part, are generated from disclosed Confidential Information.  The parties expressly agree that the Hosted Services, Reports and the terms and pricing in this Agreement are the Confidential Information of the Mastercard Group. Vendor will not remove or destroy any proprietary markings or restrictive legends placed upon or contained in the Hosted Services or Reports. Information will not be deemed Confidential Information hereunder if such information: (i) is known by the receiving party prior to receipt from the disclosing party, without any obligation of confidentiality, as evidenced by receiving party’s tangible records; (ii) becomes known to the receiving party directly or indirectly from a source other than one having an obligation of confidentiality to the disclosing party; (iii) becomes publicly known or otherwise publicly available, except through the acts or failure to act by the receiving party in breach of this Agreement; or (iv) is independently developed by the receiving party without reference to the Confidential Information as evidenced by receiving party’s tangible records.</li>
            <li>Each party shall protect the other party’s Confidential Information in the same manner as it protects its own valuable confidential information, but in no event shall less than reasonable care be used.  A party will not disclose the other party’s Confidential Information to any third party, except (i) as permitted in writing by the disclosing party prior to any such disclosure, (ii) to employees, consultants, agents and subcontractors that have a need to know such information, provided that the receiving party shall advise each such third party of their obligations to keep such information confidential, and (iii) to the extent receiving party is legally compelled to disclose such Confidential Information pursuant to subpoena or the order of any governmental authority, provided that where possible and permitted by applicable law, the receiving party shall give advance notice of such compelled disclosure to the disclosing party, and shall cooperate with the disclosing party in connection with efforts to prevent or limit the scope of such disclosure and/or use of the Confidential Information.  Each party accepts responsibility for the actions of its agents, subcontractors, consultants or employees. </li>
            <li>Neither party shall make use of any of the other party’s Confidential Information except in its performance under this Agreement. Except as otherwise provided in this Agreement, at the end of the Term, or such earlier time as the disclosing party requests, the receiving party shall return to the disclosing party, or, at the disclosing party’s request, securely destroy all Confidential Information of the disclosing party in the possession of the receiving party.  Notwithstanding the foregoing, the receiving party is not obligated to destroy Confidential Information (i) commingled with other information of the receiving party if it would be a substantial administrative burden to excise such Confidential Information; (ii) contained in an archived computer system backup made in accordance with the receiving party's security or disaster recovery procedures; or (iii) required to be retained pursuant to applicable law, regulatory requirements, or post-termination obligations as stated in this Agreement, provided in each case that such Confidential Information remains subject to the obligations of confidentiality in this <u>Section 6</u> until the eventual destruction.</li>
            <li>Notwithstanding anything to the contrary in the foregoing, the Mastercard Group may share Confidential Information contained in the Reports with governmental bodies and other public authorities as permitted or required under applicable law.</li>
            <li>The confidentiality obligations in this <u>Section 6</u> shall apply during the Term and for a period of three (3) years thereafter.</li>
          </ol>
        </li>
        <li>
          <strong>INDEMNIFICATION</strong>
          <ol>
            <li>
            Vendor will, at its own expense, defend, indemnify and hold harmless the Mastercard Group and each of their employees, officers, directors, agents, representatives and contractors (“<strong>Mastercard Indemnitees</strong>”) from and against:
              <ol>
                <li>any third-party claim against the Mastercard Indemnitees arising out of or related to: (1) Vendor’s  or its Users’ access to or use of the Hosted Services or a Report not in accordance with the terms of this Agreement, or (2) Vendor’s or its Users’  (i) breach of this Agreement, or (ii) gross negligence or willful misconduct in the performance of its obligations under this Agreement, provided that Mastercard: (x) promptly notifies Vendor of such claim (provided that failure to do so shall not waive Vendor’s obligations hereunder unless such failure materially hinders  Vendor’s  defense of such claim), (y) reasonably cooperates with Vendor in defense of the claim as reasonably requested by Vendor and at Vendor’s  cost, and (z) gives full control and sole authority over the defense and settlement of such proceeding, provided that Vendor may not settle any such claim or action without Mastercard’s prior written consent. Mastercard may participate in the defense and settlement of such claim or action with legal counsel of its own choosing and at its own cost; and </li>
                <li>all liability, costs, expenses, losses (including but not limited to reasonable attorneys’ fees and all other professional costs and expenses) suffered or incurred by the Mastercard Indemnitees arising out of or in connection with a breach of <u>Section 4.4</u> or <u>4.5</u> by Vendor or Vendor’s Users . </li>
              </ol>
            </li>
            <li>
              Mastercard, at its own expense, will defend, indemnify or, at its sole option, settle any claim or action brought by a third party against  Vendor  or its officers, directors, employees or agents (“<strong>Vendor Indemnitees</strong>”) to the extent alleging that the Hosted Services, when used within the scope of this Agreement, infringe any patent, trademark or copyright of a third party (“<strong>Claim</strong>”). 
              <ol>
                <li>Mastercard’s obligations to indemnification are subject to Vendor: (a) notifying Mastercard of any such Claim promptly after it obtains knowledge of such Claim (provided that failure to do so shall not waive Mastercard’s obligations hereunder unless such failure materially hinders Mastercard’s defense of such Claim), (b) providing Mastercard with reasonable assistance, information and cooperation in defending the lawsuit or proceeding, and (c) giving Mastercard full control and sole authority over the defense and settlement of such claim, provided that such settlement fully releases Vendor and is solely for monetary damages and does not admit any liability on behalf of Vendor.</li>
                <li>Mastercard shall have no obligation to indemnify, defend or hold harmless hereunder to the extent that a Claim arises from or is based on any use of the Hosted Services: (a) not in accordance with this Agreement or the Documentation or for purposes not intended by Mastercard and not specifically authorized under this Agreement; or (b) in combination with any other product or service that is not supplied or expressly approved by the Mastercard Group for use with the Hosted Services.</li>
                <li>Following notice of a Claim or upon any facts which in Mastercard’s sole opinion are likely to give rise to such Claim, Mastercard shall in its sole discretion and at its sole option, elect to: (a) procure for Vendor the right to continue to use the Hosted Services, at no additional cost to Vendor ; (b) replace or modify the Hosted Services so that it becomes non-infringing, but remains functionally equivalent; (c) terminate the affected Hosted Service..</li>
                <li>This <u>Section 7.2.4</u> sets forth the sole and exclusive liability of the Mastercard Group, and Vendor’s sole and exclusive remedy, for the actual or alleged infringement or misappropriation of any third-party Intellectual Property Rights by the Hosted Services or use thereof.</li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <strong>DATA PROTECTION, INFORMATION SECURITY AND BUSINESS CONTINUITY</strong>
          <ol>
            <li>Vendor and Mastercard shall comply and shall have any subcontractor comply with all Privacy and Information Security Requirements to the extent applicable to the Hosted Services. As used herein, “<strong>Privacy and Information Security Requirements</strong>” means all applicable international, federal, state, provincial and local laws, rules, regulations, directives and governmental requirements relating in any way to the privacy, data protection, confidentiality, or security of Personal Data, including, without limitation, the EU General Data Protection Regulation 2016/679 (“<strong>GDPR</strong>”); the e-Privacy Directive 2002/58/EC (as amended by Directive 2009/136/EC, and as amended and replaced from time to time) and their national implementing legislations; the Gramm-Leach-Bliley Act; laws regulating unsolicited email, telephone, and text message communications; security breach notification laws; laws imposing minimum security requirements; laws requiring the secure disposal of records containing certain Personal Data; laws regulating banking secrecy and outsourcing requirements; laws regulating international data transfers and/or on-soil requirements; laws regulating incident reporting and data breach notification requirements, including guidelines and recommendations from the competent regulators; all other similar international, federal, state, provincial, and local requirements; and all applicable provisions of the parties’ written information security policies, procedures and guidelines. Capitalized terms not defined herein have the meaning set forth in the relevant Privacy and Information Security Requirements.</li>
            <li>
              To the extent that Hosted Services under this Agreement involve the Processing of Personal Data, the following provisions will apply.
              <ol>
                <li>Vendor shall be solely responsible for ensuring that it provides notice and relies on a valid legal ground to enable the Mastercard Group to provide the Hosted Services, including for data transfers, as required under Privacy and Information Security Requirements. Vendor shall be solely responsible for handling individuals’ requests in connection with withdrawing their consent or their rights to access, rectify, restrict or erase their Personal Data or exercise their right to data portability, if applicable, with regard to any Personal Data, object to the Processing of any Personal Data, or exercise their rights related to automated decision-making and profiling, if applicable, in connection with the Hosted Services.</li>
                <li>Both parties shall develop, maintain and implement a comprehensive written information security program that complies with all applicable Privacy and Information Security Requirements. Without limitation, each party’s information security program shall include technical, physical, and administrative/organizational safeguards designed to: (a) ensure the security and confidentiality of Personal Data; (b) protect against any anticipated threats or hazards to the security and integrity of Personal Data; and (c) protect against any actual or suspected unauthorized Processing, loss, use, disclosure or acquisition of or access to any Personal Data (hereinafter “Information Security Incident”).  Each party’s information security program shall, among other things, include regular testing or otherwise monitoring of the effectiveness of each party’s information safeguards.</li>
                <li>To the extent required by applicable legal, regulatory or law enforcement requirements, each party shall inform the other in writing of any Information Security Incident involving a party’s Personal Data. Such notice shall summarize in reasonable detail the effect on the other party, if known, of the Information Security Incident and the corrective action taken or to be taken. The applicable party shall promptly take all necessary corrective actions and shall cooperate fully with the other in all reasonable and lawful efforts to mitigate the effects such Information Security Incident.  Except to the extent prohibited by applicable legal, regulatory or law enforcement requirements, Vendor must obtain the approval of Mastercard prior to the publication or communication of any filings, communications, notices, press releases or reports related to any Information Security Incident that expressly mention Mastercard or any of its Affiliates.</li>
                <li>All data including Personal Data Processed in connection with this Agreement shall be transferred to and stored by RiskRecon and  Mastercard in the United States, in accordance with the Mastercard Binding Corporate Rules, the APEC Cross-Border Privacy Rules System, and applicable Privacy and Information Security Requirements.</li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <strong>LIMITED WARRANTY; DISCLAIMER OF LIABILITY</strong>
          <ol>
            <li>During any applicable Term, Mastercard warrants that the applicable Hosted Services will function in material accordance with the specifications for such Hosted Services, as solely determined by Mastercard and as may be updated by the Mastercard Group from time to time. The Mastercard Group provides no warranty regarding any use of the Hosted Services not in accordance with this Agreement and not specifically licensed pursuant to this Agreement.</li>
            <li>EXCEPT AS EXPRESSLY PROVIDED IN SECTION 7.1, AND TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE MASTERCARD GROUP DISCLAIMS ALL REPRESENTATIONS, WARRANTIES, TERMS AND CONDITIONS, WHETHER EXPRESS OR IMPLIED, REGARDING THE HOSTED SERVICES, RELATED DOCUMENTATION OR INFORMATION, AND OTHER MATERIALS AND SERVICES, AND SPECIFICALLY DISCLAIM THE IMPLIED WARRANTIES OF FITNESS FOR A PARTICULAR PURPOSE, SATISFACTORY QUALITY, MERCHANTABLE QUALITY, NONINFRINGEMENT AND THOSE ARISING FROM COURSE OF PERFORMANCE, DEALING, USAGE OR TRADE. THE HOSTED SERVICES ARE PROVIDED “AS IS” AND ON AN “AS AVAILABLE” BASIS AND THE MASTERCARD GROUP DOES NOT WARRANT THAT THE FUNCTIONS OR INFORMATION CONTAINED IN THE HOSTED SERVICES OR IN ANY UPDATE WILL MEET THE REQUIREMENTS OF VENDOR OR THAT THE OPERATION OF THE HOSTED SERVICES WILL BE UNINTERRUPTED OR FREE FROM ERRORS OR OTHER PROGRAM LIMITATIONS. THE INFORMATION PROVIDED BY THE HOSTED SERVICES AND/OR CONTAINED IN THE REPORTS MAY CONTAIN TECHNICAL OR TYPOGRAPHICAL ERRORS. THE MASTERCARD GROUP DOES NOT GUARANTEE ITS ACCURACY OR COMPLETENESS. ALL INFORMATION PROVIDED BY THE MASTERCARD GROUP IS PROVIDED FOR INFORMATIONAL PURPOSES ONLY, AND VENDOR ACKNOWLEDGES THAT VENDOR USES ANY SUCH INFORMATION AT ITS OWN RISK.</li>
            <li>To the maximum extent permitted by applicable law, Mastercard’s sole and exclusive obligation and Vendor’s sole and exclusive remedy for any failure of the Hosted Services, including the Hosted Services’ failure to meet the warranty in <u>Section 9.1</u>, is limited to the correction, adjustment or replacement of the failed Hosted Service which examination indicates, to Mastercard’s satisfaction, to be defective or, at Mastercard’s sole option, termination of subscription and access rights to the failed Hosted Service and a refund of the pro-rata amount of any pre-paid Subscription Fees paid by Vendor to Mastercard for the failed Hosted Service for the remainder of the Term of the affected Hosted Service.</li>
            <li>THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL EITHER VENDOR OR THE MASTERCARD GROUP BE LIABLE FOR INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES, OR ANY PENALTIES, CLAIMS FOR LOST DATA, REVENUE, PROFITS, COSTS OF PROCUREMENT OR SUBSTITUTE GOODS OR SERVICES OR BUSINESS OPPORTUNITIES, ARISING OUT OF OR RELATED TO THE SUBJECT MATTER OF THIS AGREEMENT, UNDER ANY CAUSE OF ACTION OR THEORY OF LIABILITY, WHETHER IN CONTRACT OR IN TORT INCLUDING NEGLIGENCE, EVEN IF A PARTY HAS BEEN ADVISED OF SUCH DAMAGES.</li>
            <li>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL THE AGGREGATE LIABILITY OF EACH PARTY AND ITS AFFILIATES HEREUNDER ARISING OUT OF OR RELATED TO THE SUBJECT MATTER OF THIS AGREEMENT, REGARDLESS OF THE FORUM, AND REGARDLESS WHETHER ANY CAUSE OF ACTION OR CLAIM IS BASED ON CONTRACT, TORT, OR OTHERWISE, EXCEED TWO HUNDRED FIFTY THOUSAND DOLLARS ($250,000).  FOR CLARITY, THIS LIMIT ALSO APPLY TO MASTERCARD’S AFFILIATES.  IT IS THE MAXIMUM FOR WHICH MASTERCARD AND ITS AFFILIATES ARE COLLECTIVELY RESPONSIBLE.</li>
          </ol>
        </li>
        <li>
          <strong>GOVERNMENTAL VENDOR – ADDITIONAL TERMS</strong>
          <p>To the extent that the Vendor is a Government Vendor, the following additional terms shall apply.</p>
          <ol>
            <li>1.	The parties acknowledge as follows: (a) Mastercard or one of its Affiliates is providing the Hosted Services as contemplated herein at the request of Vendor, and (b) Vendor is a Government Vendor, which is bound to follow mandates of procurement policies or laws in its sourcing of services, and the parties acknowledge that (i) procurement of Hosted Services herein is in compliance with such laws and policies, (ii) provision of Hosted Services is not a gift and is not intended to personally benefit any individual nor is it transferrable in any way, and (iii) by providing the Hosted Services, the Mastercard Group has no expectations of future favorable treatment by Vendor, (c) Vendor  may use the Reports for its Legitimate Internal Business Purpose only, and the same shall not be disseminated to third parties or published in a public domain medium, except with the written permission of Mastercard, (d) the Hosted Services will be provided during the Term or as permitted under this Agreement, and (e) for the avoidance of doubt, Vendor hereby agrees that provision of Hosted Services shall not preclude, hinder, waive, advantage or restrict in any way the Mastercard Group’s eligibility or fitness to qualify, compete for or be awarded future procurement, investment or grant opportunities.</li>
          </ol>
        </li>
        <li>
            <strong>SANCTIONS; COMPLIANCE</strong>
            <ol>
                <li>Each Party shall fulfill its obligations as stated in this Agreement in accordance with all applicable laws and regulations, including, without limitation, the Foreign Corrupt Practices Act, the U.K. Bribery Act, and all other applicable anti-corruption and anti-bribery laws. In connection with Vendor’s use of the Hosted Services and cross-border transfer of all reports, data, materials, documents, or other deliverables provided by the Mastercard Group to Vendor in connection with the Hosted Services (“Deliverables”), the Customer shall comply with all applicable export, re-export, and import control laws and regulations of all applicable jurisdictions, and Vendor shall not export or re-export the Hosted Services or Deliverables. Vendor shall not engage in any activities related to these this Agreement with a Person who is identified on the lists of specially designated nationals or blocked parties maintained by the U.S. Treasury Department’s Office of Foreign Assets Control, or other relevant jurisdiction. Such list is currently accessible at: <a href="http://www.treasury.gov/ofac" target="_blank" rel="noreferrer">http://www.treasury.gov/ofac</a>.</li>
                <li>Each Party hereby guarantees that it is neither on any sanctions list of the European Union, the United States, or the United Nations, nor are they subject to a corresponding embargo, and that the execution of this Agreement does not otherwise violate export control regulations during the term of this Agreement. The Parties shall not engage in dealings, directly or indirectly, with any entity or person or in any jurisdiction subject to European Union, the United States, or the United Nations sanctions regulations.</li>
                <li>The Parties represent, warrant and covenant that each of it and its Staff, subcontractors, agents and other third parties acting on its behalf: (a) is not named on any U.S. Department of Treasury Office of Foreign Asset Control Sanctions lists or any applicable foreign sanctions lists; (b) shall not, directly or indirectly, access, use, sell, export, reexport, transfer, divert, or otherwise dispose of all or any part of the Services or Documentation to any country (or national thereof) that is subject to antiterrorism controls or U.S. embargo, or to any other person or entity or destination prohibited by the laws of the U.S. or the laws of the Territory or any other applicable jurisdiction, without obtaining, at its own expense, prior authorization from the competent government authorities as required by those laws.</li>
                <li>The Parties shall not engage in dealings, directly or indirectly, with any entity or person or in any jurisdiction subject to U.S. OFAC sanctions regulations. Each Party represents and warrants that it is currently not on any OFAC List, nor on any similar restricted party listings, including those maintained by other governments pursuant to applicable United Nations, regional or national trade or financial sanctions.  </li>
                <li>The Parties shall comply with all trade and economic sanctions programs relevant to where they do business, including trade and economic sanctions maintained by the Office of Foreign Assets Control (“<strong>OFAC</strong>”) and similar laws of the countries where the Parties are located. The Parties shall not engage in any conduct that would cause the other Party to violate applicable sanctions programs. The Parties shall notify the other party immediately if it becomes aware that it, its subcontractors, or related parties engage in activity prohibited by applicable sanctions. To the extent applicable, the Parties shall comply and shall ensure that each of its subcontractors and personnel complies, with all applicable laws (e.g., anti-bribery, corruption, export controls, sanctions) in connection with this Agreement. Each Party agrees to comply with all applicable laws and regulations in connection with this Agreement.</li>
                <li>Vendor acknowledges that the Hosted Services, or a portion thereof, are subject to the Export Administration Regulations, 15 C.F.R. Parts 730-774, of the United States and may be subject to other applicable country export control and trade sanctions laws (“Export Control and Sanctions Laws”). Vendor shall not, and shall not permit any of its end users, to access, use, export, re-export, divert, transfer, or disclose any part of the Hosted Services or any related technical information or materials, directly or indirectly, in violation of any applicable export control or trade sanctions law or regulation. Vendor represents and warrants that: (i) Vendor and Vendor’s end users (a) are not citizens of, or located within, a country or territory that is subject to U.S. trade sanctions or other significant trade restrictions (including without limitation Cuba, Iran, North Korea, Syria, and the Crimea, Donetsk and Luhansk regions of Ukraine) and that Vendor and Vendor’s Users will not access or use the Hosted Services, or export, re-export, divert, or transfer the Hosted Services, in or to such countries or territories; (b) are not persons, or owned 50% or more, individually or in the aggregate by persons, identified on the U.S. Department of the Treasury’s Specially Designated Nationals and Blocked Persons List or Foreign Sanctions Evaders Lists; and (c) are not persons on the U.S. Department of Commerce’s Denied Persons List, Entity List, or Unverified List, or U.S. Department of State proliferation-related lists; and (ii) Vendor and Vendor’s Users located in China, Russia, or Venezuela are not Military Users and will not put the Hosted Services to a Military end user, as defined in 15 C.F.R. 744.21. Vendor is solely responsible for complying with the Export Control and Sanctions Laws and monitoring them for any modifications. </li>
                <li>At all times during the Term of the Agreement, the Parties shall comply with all applicable laws relating to anti-money laundering (including, in the U.S., the Bank Secrecy Act, Title III of the USA PATRIOT Act, and the implementing regulations promulgated by the Financial Crimes Enforcement Network) and any related or similar applicable laws, issued, administered, or enforced by any government authority.  </li>
            </ol>
        </li>
        <li>
          <strong>GENERAL PROVISIONS</strong>
          <ol>
            <li>
              This Agreement shall be governed solely by the governing law listed in the chart below for the Mastercard Affiliate that is a party to this Agreement (the “Mastercard Contracting Party”) without regard to such jurisdiction’s principles of conflicts of law.  The application of the United Nations Convention of Contracts for the International Sale of Goods or other international laws is expressly excluded. Each party consents to the personal and exclusive jurisdiction of the courts located in the jurisdiction listed in the chart below for the Mastercard Contracting Party, in connection with all proceedings related to the Hosted Services or this Agreement.
              <table>
                <tr>
                  <td><strong>Mastercard Contracting Party</strong></td>
                  <td><strong>Governing Law</strong></td>
                  <td><strong>Jurisdiction</strong></td>
                </tr>
                <tr>
                  <td>Mastercard International Inc.</td>
                  <td>State of New York, US</td>
                  <td>Westchester County, New York, US</td>
                </tr>
                <tr>
                  <td>Mastercard Brasil Soluções de Pagamento Ltda.</td>
                  <td>Brazil</td>
                  <td>Sao Paolo, Brazil</td>
                </tr>
                <tr>
                  <td>Mastercard Cono Sur S.R.L.</td>
                  <td>Argentina</td>
                  <td>Buenos Aires, Argentina</td>
                </tr>
                <tr>
                  <td>Mastercard Europe SA</td>
                  <td>Belgium</td>
                  <td>Waterloo, Belgium</td>
                </tr>
                <tr>
                  <td>Mastercard Asia/Pacific Pte. Ltd.</td>
                  <td>Singapore</td>
                  <td>Singapore</td>
                </tr>
                <tr>
                  <td>Mastercard Technology Private Limited</td>
                  <td>India</td>
                  <td>India</td>
                </tr>
              </table>
            </li>
            <li>This Agreement shall bind and inure to the benefit of each party’s permitted successors and assigns. Neither party may assign or transfer its rights or obligations granted under this Agreement, by operation of law, contract, or otherwise, without the other party’s prior written consent, such consent not to be unreasonably withheld; provided, however, that Mastercard may, without the consent of  Vendor, delegate or assign any obligations under this Agreement, in whole or in part, to an Affiliate capable of performing Mastercard’s obligations hereunder. Notwithstanding the foregoing, Mastercard and its Affiliates may assign any of its or their rights or obligations under this Agreement without the prior written consent of Vendor to any successor entity, including by way of a merger, acquisition, consolidation, asset or stock sale, or similar transaction. Mastercard may assign its rights to payments under this Agreement without obtaining Vendor’s consent. Any attempted assignment of this Agreement in violation of this Section will be null and void. In the event of an assignment by Vendor, Vendor shall pay Mastercard any additional agreed professional services fees, one-time deployment fees, or other reasonable costs incurred by the Mastercard Group in connection with the migration of the Hosted Services to the assignee. The parties will negotiate in good faith the scope and the payment terms of any such additional fees.</li>
            <li>Neither party shall be liable for loss or damage, or for any delay, or failure to perform its obligations under this Agreement, to the extent such loss, damage, delay, or failure is caused by any act of God, natural disaster, fire, strike, embargo, war, threat of terrorism, insurrection, riot, denial of service attack, or other cause or circumstance beyond the reasonable control of the party; provided, however, that the foregoing shall not excuse any failure by such party to take reasonable action to minimize the scope, extent, duration, and adverse effect of any such event.</li>
            <li>The Mastercard Group shall have the right to mention that Vendor is a user of the Hosted Services in the Mastercard Group’s press releases, descriptions and communications, and all related promotional and marketing materials at any time, in each case relating to the Hosted Services. Vendor hereby grants to the Mastercard Group a nonexclusive, worldwide, fully paid-up, nontransferable license, without right of sublicense, to copy, distribute, display, and use any Marks owned or used by Vendor for the purposes described in this section. All proprietary rights and goodwill associated with the Mastercard Group’s use of the Vendor’s Marks will inure to the benefit of Vendor.</li>
            <li>Nothing in this Agreement is intended to confer any rights or remedies to any persons other than the parties, their permitted successors and assigns, the Mastercard Indemnitees and Vendor Indemnitees.</li>
            <li>In the event that any provision of this Agreement conflicts with the law under which this Agreement is to be construed, or is held invalid by a court with jurisdiction over the parties to this Agreement, (a) such provision will be deemed restated to reflect as nearly as possible the original intentions of the parties in accordance with applicable law; and (b) the remaining provisions of this Agreement will remain in full force and effect.</li>
            <li>The failure of either party to insist upon or enforce strict performance by the other party any provision of this Agreement, or to exercise any right under this Agreement, shall not be construed as a waiver or relinquishment to any extent of such party’s right to assert or rely upon any such provision or right in that or any other instance; rather, the same will be and remain in full force and effect. Neither party will be deemed to have waived any of its rights under this Agreement by lapse of time or by any statement or representation other than by an explicit written waiver. No waiver of a breach of this Agreement will constitute a waiver of any prior or subsequent breach of this Agreement.</li>
            <li>This Agreement will not be construed in favor of or against either party by reason of authorship. This Agreement, including all attachments, exhibits, constitutes the entire agreement between the parties, and supersedes and replaces all prior or contemporaneous understandings or agreements, written or oral, regarding such subject matter.</li>
            <li>All notices delivered under this Agreement shall be in writing and deemed to be given (a) when actually received if delivered personally; (b) two (2) days after the date deposited with the U.S. Postal Service if sent by certified or registered mail; and (c) one (1) day after the date delivered to a reputable next-day courier service for overnight delivery. Notices shall be addressed to a party at (1) in the case of Mastercard, c/o Mastercard International Incorporated, 2000 Purchase Street, Purchase New York, 10577 USA, Attention: General Counsel, with a copy to (which shall not constitute notice) c/o Mastercard International Incorporated, 2000 Purchase Street, Purchase New York, 10577 USA, Attention: Rigo Van den Broeck.</li>
            <li>Each Party shall fulfill its obligations as stated in this Agreement in accordance with all applicable laws and regulations, including, without limitation, the Foreign Corrupt Practices Act, the U.K. Bribery Act, and all other applicable anti-corruption and anti-bribery laws. In connection with Vendor’s use of the Hosted Services and cross-border transfer of all reports, data, materials, documents, or other deliverables provided by the Mastercard Group to Vendor in connection with the Hosted Services (“Deliverables”), the Customer shall comply with all applicable export, re-export, and import control laws and regulations of all applicable jurisdictions, and Vendor shall not export or re-export the Hosted Services or Deliverables. Vendor shall not engage in any activities related to these this Agreement with a Person who is identified on the lists of specially designated nationals or blocked parties maintained by the U.S. Treasury Department’s Office of Foreign Assets Control, or other relevant jurisdiction. Such list is currently accessible at: <a href="http://www.treasury.gov/ofac" target="_blank" rel="noreferrer">http://www.treasury.gov/ofac</a>.</li>
            <li>Each Party shall maintain adequate insurance or shall self-insure at an appropriate level with respect to the business activities carried out by such Party in the ordinary course. Each Party shall furnish certificates of insurance to the other Party upon reasonable request.</li>
            <li>Except where otherwise specified, the rights and remedies granted to a party subject to this Agreement are cumulative and in addition to, and not in lieu of, any other rights or remedies which a Party may possess at law or in equity.</li>
          </ol>
        </li>
      </ol>
    </>
  )
}

export default Eula
