import {
  FETCHING_LANGUAGE_INFO,
  SHOW_FINDINGS_SIDEBAR,
  FETCH_QUERY_LANGUAGE_SUCCESS,
  FETCH_LANGUAGE_SUCCESS,
  FETCH_SOFTWARE_PATCHING_DATA_SUCCESS,
  FETCH_THREAT_INTELL_ISSUES_SUCCESS,
  FETCHING_THREAT_INTELL_ISSUES,
  FETCH_HOST_PROFILE_INFO_SUCCESS,
  FETCHING_HOST_PROFILE,
  FETCH_FINDINGS_ERROR,
  FETCHING_FINDINGS_BY_HOST,
  FETCH_FINDINGS_BY_HOST_SUCCESS,
  FETCH_FINDINGS_BY_HOST_FAILURE,
  RESET_SIDEBAR_FINDING_DATA,
  FETCHING_HOST_INFO,
  FETCH_HOST_INFO_FAILURE,
  FETCH_HOST_INFO_SUCCESS,
  FETCHING_SOFTWARE_PATCHING_DATA,
  UPDATING_FINDING,
  UPDATE_DOMAIN_FINDING_SUCCESS,
  UPDATE_CRITERIA_FINDING_SUCCESS,
  UPDATE_FINDING_FAILURE,
  FETCHING_INDIVIDUAL_FINDING,
  FETCH_INDIVIDUAL_FINDING_SUCCESS,
  FETCH_INDIVIDUAL_FINDING_FAILURE
} from '../actions/Findings'

const initialState = {
  currentFinding: null,
  loadingError: false,
  loading: false,
  fetchingLanguageInfo: true,
  fetchLanguageInfoSuccess: false,
  fetchLanguageInfoFail: false,
  fetchingHostInfo: false,
  fetchingSoftwarePatchingData: false,
  fetchingThreatIntellIssues: false,
  showFindingDetailsModal: false,
  fetchingHostProfileInfo: false,
  showHostProfileModal: false,
  fetchingFindingsByHost: false,
  fetchingFindingsByHostFailure: false,
  fetchingFindingsByHostSuccess: false,
  findings_by_host: {},
  languageFindings: {},
  queryWithLanguageFindings: {},
  threatIntellIssues: {},
  hostProfileDimension: {},
  findingDrawerOpen: false,
  selectedSidebarTopTab: 'host',
  selectedSidebarBottomTab: 'host-profile',
  fetchHostInfoFailure: {},
  fetchHost: false,
  sideBarShowHostOnly: false,
  updatingFinding: false,
  updateFindingSuccess: false,
  updateFindingFailure: false,
  fetchingIndividualFinding: false,
  fetchingIndividualFindingFailure: false
}

function Findings (state = initialState, action) {
  switch (action.type) {
    case FETCHING_LANGUAGE_INFO:
      return Object.assign({}, state, {
        fetchingLanguageInfo: true
      })
    case FETCH_HOST_INFO_SUCCESS:
      return { ...state,
        currentFinding: {
          ...state.currentFinding,
          ...action.response
        },
        fetchingHostInfo: false
      }
    case FETCH_HOST_INFO_FAILURE:
      return { ...state, fetchingHostInfoFailure: true }
    case FETCHING_HOST_INFO:
      return { ...state, fetchingHostInfo: true }
    case SHOW_FINDINGS_SIDEBAR:
      return {
        ...state,
        currentFinding: {
          ...action.finding,
          isCriteriaOnly: action.isCriteriaOnly
        },
        showHostProfileModal: !state.showHostProfileModal,
        findingDrawerOpen: true,
        sideBarShowHostOnly: action.hostOnly,
        selectedSidebarTopTab: action.selectedTopTab,
        selectedSidebarBottomTab: action.selectedBottomTab,
        fetchHost: action.fetchHost
      }
    case FETCH_QUERY_LANGUAGE_SUCCESS:
      let queryWithLanguageFindings = state.queryWithLanguageFindings
      queryWithLanguageFindings[action.query] = action.data
      return Object.assign({}, state, {
        queryWithLanguageFindings,
        fetchingLanguageInfo: false,
        fetchLanguageInfoSuccess: true
      })
    case FETCH_LANGUAGE_SUCCESS:
      let languageFindings = state.languageFindings
      languageFindings[action.securityCriteria] = {}
      languageFindings[action.securityCriteria].language = action.data
      return Object.assign({}, state, {
        languageFindings,
        fetchingLanguageInfo: false,
        fetchLanguageInfoSuccess: true
      })
    case FETCHING_THREAT_INTELL_ISSUES:
      return Object.assign({}, state, {
        fetchingThreatIntellIssues: true
      })
    case FETCH_THREAT_INTELL_ISSUES_SUCCESS:
      return { ...state,
        currentFinding: {
          ...state.currentFinding,
          threatIntellIssues: action.data
        },
        fetchingThreatIntellIssues: false
      }
    case FETCH_SOFTWARE_PATCHING_DATA_SUCCESS:
      return { ...state,
        currentFinding: {
          ...state.currentFinding,
          ...action.data
        },
        fetchingSoftwarePatchingData: false

      }
    case FETCHING_HOST_PROFILE:
      return Object.assign({}, state, {
        fetchingHostProfileInfo: true,
      })
    case FETCH_HOST_PROFILE_INFO_SUCCESS:
      return {
        ...state,
        currentFinding: {
          ...state.currentFinding,
          dimensions: action.data._embedded['rel:dimensions']
        },
        fetchingHostProfileInfo: false,
      }
    case FETCHING_FINDINGS_BY_HOST:
      return { ...state, fetchingFindingsByHost: true }
    case FETCH_FINDINGS_BY_HOST_SUCCESS:
      return Object.assign({}, state, {
        findings_by_host: action.response,
        fetchingFindingsByHost: false,
        fetchingFindingsByHostFailure: false,
        fetchingFindingsByHostSuccess: true
      })
    case FETCH_FINDINGS_BY_HOST_FAILURE:
      return { fetchingFindingsByHostFailure: true }
    case RESET_SIDEBAR_FINDING_DATA:
      return Object.assign({}, state, {
        findings_by_host: {},
        fetchingFindingsByHost: false,
        fetchingFindingsByHostFailure: false,
        fetchingFindingsByHostSuccess: false,
        fetchingLanguageInfo: true,
        queryWithLanguageFindings: {},
        fetchLanguageDataCharacteristicsFailure: false,
        languageFindings: {},
        findingDrawerOpen: false,
        fetchingIndividualFinding: false
      })
    case FETCHING_SOFTWARE_PATCHING_DATA:
      return Object.assign({}, state, {
        fetchingSoftwarePatchingData: true
      })
    case FETCH_FINDINGS_ERROR:
      return Object.assign({}, state, {
        loadingError: true
      })
    case UPDATING_FINDING:
      return {
        ...state,
        updatingFinding: true
      }
    case UPDATE_DOMAIN_FINDING_SUCCESS:
      return {
        ...state,
        updatingFinding: false,
        updateFindingSuccess: true

      }
    case UPDATE_CRITERIA_FINDING_SUCCESS:
      return {
        ...state,
        updatingFinding: false,
        updateFindingSuccess: true

      }
    case UPDATE_FINDING_FAILURE:
      return {
        ...state,
        updatingFinding: false,
        updateFindingFailure: true
      }
    case FETCH_INDIVIDUAL_FINDING_SUCCESS:
      return {
        ...state,
        currentFinding: {
          ...state.currentFinding,
          ...action.individualFinding.data.data,
        },
        fetchingIndividualFinding: false,
        fetchingIndividualFindingFailure: false
      }
    case FETCHING_INDIVIDUAL_FINDING:
      return {
        ...state,
        fetchingIndividualFinding: true,
        fetchingIndividualFindingFailure: false

      }
    case FETCH_INDIVIDUAL_FINDING_FAILURE:
      return {
        ...state,
        fetchingIndividualFinding: false,
        fetchingIndividualFindingFailure: true

      }
    default:
      return state
  }
}

export default Findings
