import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { LoadingSVG } from '../../../assets/svg'
import './BorderlessButton.scss'

/**
 * Use `BorderlessButton` as button for actionable text. Has an <a> feel to it
 */
const BorderlessButton = ({
  children,
  onClick,
  type,
  disabled,
  color,
  className,
  loading,
  ...rest
}) => {
  return (
    <button
      type={type}
      onClick={loading ? undefined : onClick}
      disabled={disabled}
      className={classnames('v3-borderless-button', color, className, { loading })}
      {...rest}
    >
      {loading
        ? <LoadingSVG size="24"/>
        : children}
    </button>
  )
}

BorderlessButton.defaultProps = {
  type: 'button',
  disabled: false,
  color: 'dark',
  className: '',
}

BorderlessButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.element,
  ]).isRequired,
  color: PropTypes.oneOf([
    'green',
    'red',
    'blue',
    'dark-blue',
    'dark',
  ]),
  onClick: PropTypes.func,
  type: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
}

export default BorderlessButton
