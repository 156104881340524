import React, { useEffect, useRef, useCallback } from 'react'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import IconButton from './IconButton'
import CloseSVG from './CloseSVG'
import ArrowLeftSVG from './ArrowLeftSVG'

import './Modal.scss'

/**
 * `Modal` is a basic modal. State is managed outside the component. Body scroll is managed by `body-scroll-lock` library. `Esc` key triggers close prop function. Close icon is absolutely positioned so content will need to be given appropriate margin/padding to give it space. `className` is placed on modal container so height, width, and other styles can be modified with a className.
 */

const ModalContainer = ({
  isOpen,
  children,
  className,
  id,
  close,
  hideOverlay,
  maxModalHeight,
  noCloseButton,
  isBackArrow,
}) => {
  const [displayModalScrollbar, setDisplayModalScrollbar] = React.useState(false)
  const [mouseCoordinates, setMouseCoordinates] = React.useState({ x: null, y: null })
  const modalEl = useRef(null)
  const modalScrollbarDisplayedRed = useRef(false) // using ref to determine if modal scrollbar is displayed, for some reason the window resize listener can't access the updated displayModalScrollbar state
  const handleModalResize = useCallback(() => {
    if (modalEl.current) {
      const height = modalEl.current.clientHeight
      if (height < maxModalHeight && modalScrollbarDisplayedRed.current === false) {
        handleScrollbarDisplay(true)
      } else if (height >= maxModalHeight && modalScrollbarDisplayedRed.current === true) {
        modalEl.current.scroll(0, 0) // scroll to top of modal in case user scrolled down and it sets the modal shifted down
        handleScrollbarDisplay(false)
      }
    }
  }, [maxModalHeight])

  useEffect(() => {
    let timeoutId = null
    const resizeWindow = () => {
      clearTimeout(timeoutId)
      // debouncer
      timeoutId = setTimeout(() => {
        handleModalResize()
      }, 150)
    }
    window.addEventListener('resize', resizeWindow)
    return () => document.removeEventListener('resize', resizeWindow)
  }, [handleModalResize])

  useEffect(() => {
    const handleEscapeKey = ({ code }) => {
      if (code === 'Escape') return close()
    }
    document.addEventListener('keyup', handleEscapeKey)
    return () => document.removeEventListener('keyup', handleEscapeKey)
  }, [close])

  useEffect(() => {
    const modalElement = modalEl.current
    if (isOpen) {
      disableBodyScroll(modalEl.current)
      handleModalResize() // need to check if modal/screen is too small on open
    }
    return () => enableBodyScroll(modalElement)
  }, [isOpen, handleModalResize])

  const modalClick = e => {
    e.stopPropagation()
  }

  const handleScrollbarDisplay = display => {
    modalScrollbarDisplayedRed.current = display
    setDisplayModalScrollbar(display)
  }

  const isOutsideModal = (x, y) => {
    const modalPosition = modalEl.current.getBoundingClientRect()
    return x < modalPosition.left || x > modalPosition.right || y < modalPosition.top || y > modalPosition.bottom
  }

  const handleSetMouseCoordinates = (e) => {
    if (isOutsideModal(e.clientX, e.clientY)) {
      close()
    } else {
      setMouseCoordinates({ x: e.clientX, y: e.clientY })
    }
  }

  const checkCoordinates = (e) => {
    if (isOutsideModal(mouseCoordinates.x, mouseCoordinates.y)) {
      e.stopPropagation()
    }
  }

  const renderCloseButton = () => {
    let icon = CloseSVG
    let className = 'modal-close-button'
    let fill, iconSize, backText = null
    if(noCloseButton){
      return
    } else if(isBackArrow) {
      icon = ArrowLeftSVG
      className = 'modal-close-arrow'
      fill="#173A56"
      iconSize='25'
      backText=<div className='back-text'>Back</div>
    }
    return <div onClick={close} className={className}><IconButton icon={icon} onClick={close} iconSize={iconSize} fill={fill} />{backText}</div>
  }

  if (!isOpen) return null
  return (
    <div className={`v3-modal-overlay ${hideOverlay ? 'hide-overlay' : ''}`} onMouseDown={handleSetMouseCoordinates} onMouseUp={checkCoordinates}>
      <div
        ref={modalEl}
        onClick={modalClick}
        className={classnames('v3-modal', className)}
        id={id}
        style={{ overflowY: displayModalScrollbar ? 'auto' : 'hidden' }}
      >
        {renderCloseButton()}
        {children}
      </div>
    </div>
  )
}

ModalContainer.defaultProps = {
  maxModalHeight: 562,
  noCloseButton: false,
  isBackArrow: false,
}

ModalContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  close: PropTypes.func.isRequired,
  hideOverlay: PropTypes.bool,
  id: PropTypes.string,
  isOpen: PropTypes.bool,
  maxModalHeight: PropTypes.number.isRequired,
  noCloseButton: PropTypes.bool,
  isBackArrow: PropTypes.bool,
}

export default ModalContainer
