import axios, { CancelToken } from 'axios'
import { findingModalAttributes, threatIntelLanguage } from '../constants/ActionPlan'
import { isIpAddress } from '../helpers/TextHelpers'
import { setFlash } from './flashActions'
import { criteriumToExcludeHostInfo } from '../constants/FindingSidebar'
import request from '../reference/axiosWrapper'
import { portalURL } from '../utils/environmentHelpers'

// todo: Move relevant to types.js
export const FETCHING_LANGUAGE_INFO = 'FETCHING_LANGUAGE_INFO'
export const TOGGLE_SHOW_FINDING_SIDEBAR = 'TOGGLE_SHOW_FINDING_SIDEBAR'
export const FETCH_QUERY_LANGUAGE_SUCCESS = 'FETCH_QUERY_LANGUAGE_SUCCESS'
export const FETCH_LANGUAGE_SUCCESS = 'FETCH_LANGUAGE_SUCCESS'
export const FETCH_SOFTWARE_PATCHING_DATA_SUCCESS = 'FETCH_SOFTWARE_PATCHING_DATA_SUCCESS'
export const FETCH_THREAT_INTELL_ISSUES_SUCCESS = 'FETCH_THREAT_INTELL_ISSUES_SUCCESS'
export const FETCHING_THREAT_INTELL_ISSUES = 'FETCHING_THREAT_INTELL_ISSUES'
export const SHOW_FINDINGS_SIDEBAR = 'SHOW_FINDINGS_SIDEBAR'
export const FETCH_HOST_PROFILE_INFO_SUCCESS = 'FETCH_HOST_PROFILE_INFO_SUCCESS'
export const FETCHING_HOST_PROFILE = 'FETCHING_HOST_PROFILE'
export const FETCH_FINDINGS_ERROR = 'FETCH_FINDINGS_ERROR'
export const FETCHING_FINDINGS_BY_HOST = 'FETCHING_FINDINGS_BY_HOST'
export const FETCH_FINDINGS_BY_HOST_SUCCESS = 'FETCH_FINDINGS_BY_HOST_SUCCESS'
export const FETCH_FINDINGS_BY_HOST_FAILURE = 'FETCH_FINDINGS_BY_HOST_FAILURE'
export const RESET_SIDEBAR_FINDING_DATA = 'RESET_SIDEBAR_FINDING_DATA'
export const FETCH_HOST_INFO_SUCCESS = 'FETCH_HOST_INFO_SUCCESS'
export const FETCH_HOST_INFO_FAILURE = 'FETCH_HOST_INFO_FAILURE'
export const FETCHING_HOST_INFO = 'FETCHING_HOST_INFO'
export const FETCHING_SOFTWARE_PATCHING_DATA = 'FETCHING_SOFTWARE_PATCHING_DATA'
export const UPDATING_FINDING = 'UPDATING_FINDING'
export const UPDATE_DOMAIN_FINDING_SUCCESS = 'UPDATE_DOMAIN_FINDING_SUCCESS'
export const UPDATE_CRITERIA_FINDING_SUCCESS = 'UPDATE_CRITERIA_FINDING_SUCCESS'
export const UPDATE_FINDING_FAILURE = 'UPDATE_FINDING_FAILURE'
export const FETCHING_INDIVIDUAL_FINDING = 'FETCHING_INDIVIDUAL_FINDING'
export const FETCH_INDIVIDUAL_FINDING_SUCCESS = 'FETCH_INDIVIDUAL_FINDING_SUCCESS'
export const FETCH_INDIVIDUAL_FINDING_FAILURE = 'FETCH_INDIVIDUAL_FINDING_FAILURE'

// Constants
let cancel = () => false
let cancelIndividualFinding = () => false
let cancelFindingsByHost = () => false
let cancelSoftwarePatchingData = () => false
let cancelThreatIntelIssues = () => false
let cancelHostProfileInfo = () => false
let cancelQueryWithLanguage = () => false
let cancelQueryWithoutLanguage = () => false

export function updatingFinding () {
  return { type: UPDATING_FINDING }
}

export function updateDomainFindingSuccess (newFinding, finding, body, reportingStatus) {
  return { type: UPDATE_DOMAIN_FINDING_SUCCESS, newFinding, finding, body, reportingStatus }
}

export function updateCriteriaFindingSuccess (newFinding, finding, body, reportingStatus) {
  return { type: UPDATE_CRITERIA_FINDING_SUCCESS, newFinding, finding, body, reportingStatus }
}

export function fetchIndividualFindingSuccess (individualFinding) {
  return { type: FETCH_INDIVIDUAL_FINDING_SUCCESS, individualFinding }
}

export function fetchIndividualFindingFailure () {
  return { type: FETCH_INDIVIDUAL_FINDING_FAILURE }
}

export function fetchingIndividualFinding () {
  return { type: FETCHING_INDIVIDUAL_FINDING }
}

export function updateFindingFailure () {
  return { type: UPDATE_FINDING_FAILURE }
}

// Action Creators
export function updateDomainFinding (finding, body, reportingStatus) {
  return async (dispatch) => {
    dispatch(updatingFinding())
    try {
      const findingId = finding.attributes.finding_id
      const requestOptions = {
        method: 'PUT',
        url: `v2/vendor/update_finding?finding_id=${findingId}`,
        data: body
      }
      const newFinding = await request(requestOptions)
      dispatch(updateDomainFindingSuccess(newFinding.data.data, finding, body, reportingStatus))
      dispatch(setFlash(['Finding updated successfully'], 'success'))
    } catch (error) {
      dispatch(updateFindingFailure())
      dispatch(setFlash(['An error has occured, please try again'], 'error'))
    }
  }
}
export function updateCriteriaFinding (finding, body, reportingStatus) {
  return async (dispatch) => {
    dispatch(updatingFinding())
    try {
      const findingId = finding.attributes.finding_id
      const requestOptions = {
        method: 'PUT',
        url: `v2/vendor/update_finding?finding_id=${findingId}`,
        data: body
      }
      const newFinding = await request(requestOptions)
      dispatch(updateCriteriaFindingSuccess(newFinding.data.data, finding, body, reportingStatus))
      dispatch(setFlash(['Finding updated successfully'], 'success'))
    } catch (error) {
      dispatch(updateFindingFailure())
      dispatch(setFlash(['An error has occured, please try again'], 'error'))
    }
  }
}
export function fetchFindingLanguageInfo (query, securityCriteria, domain) {
  return async (dispatch) => {
    await dispatch(fetchingLanguageInfo())
    try {
      const showVulnerabilityInfo = findingModalAttributes[securityCriteria].show_vulnerability_info
      if (domain === 'threat_intell') {
        dispatch(fetchLanguageSuccess(threatIntelLanguage, showVulnerabilityInfo, query, securityCriteria))
      } else {
        let urlCriteria = securityCriteria === 'domain_hijacking_protection' ? 'dns_hijacking_protection' : securityCriteria
        if (showVulnerabilityInfo) {
          await cancelQueryWithLanguage()

          const requestOptions = {
            method: 'GET',
            url: `v0/cpe/query_with_language?q=${query}&security_criteria=${urlCriteria}&language=english`,
            cancelToken: new CancelToken(function executor (c) {
              // An executor function receives a cancel function as a parameter
              cancelQueryWithLanguage = c
            })
          }

          request(requestOptions)
            .then(response => {
              dispatch(fetchLanguageSuccess(response.data, showVulnerabilityInfo, query, securityCriteria))
            })
        } else {
          await cancelQueryWithoutLanguage()
          const urlParam = urlCriteria === 'web_threat_intel_alert_external' ? 'threat_intel_alert_external' : urlCriteria
          const isTLS = query ? query.split(' ')[0] === 'TLS' : false
          if (isTLS) {
            const requestOptions = {
              method: 'GET',
              url: `v0/cpe/raw_language?security_criteria=web_encryption_protocol_1.2&language=english`,
              cancelToken: new CancelToken(function executor (c) {
                // An executor function receives a cancel function as a parameter
                cancelQueryWithoutLanguage = c
              })
            }

            request(requestOptions)
              .then(response => {
                response.data.issue_long_intro = 'The system does not support TLS 1.2. Beginning early 2020, major browser manufacturers are mandating that encrypted HTTP browser communications use the TLS 1.2 protocol and are ending support for TLS 1.0 and 1.1. As such, browsers will no longer communicate with web servers that use HTTPS and do not use TLS 1.2 protocol. Support for protocol versions older than TLS 1.2 is being ceased due to pervasive security flaws. References for each of the major browser manufacturers are provided below.'
                dispatch(fetchLanguageSuccess(response.data, showVulnerabilityInfo, query, securityCriteria))
              })
          } else {
            const requestOptions = {
              method: 'GET',
              url: `v0/cpe/raw_language?security_criteria=${urlParam}&language=english`,
              cancelToken: new CancelToken(function executor (c) {
                // An executor function receives a cancel function as a parameter
                cancelQueryWithoutLanguage = c
              })
            }

            request(requestOptions)
              .then(response => {
                dispatch(fetchLanguageSuccess(response.data, showVulnerabilityInfo, query, securityCriteria))
              })
          }
        }
      }
    } catch (error) {
      dispatch(fetchError())
    }
  }
}

export function fetchIndividualFinding (findingId) {
  return async (dispatch) => {
    dispatch(fetchingIndividualFinding())
    await cancelIndividualFinding()

    const requestOptions = {
      method: 'GET',
      url: `v2/vendor/finding?finding_id=${findingId}`,
      cancelToken: new CancelToken(function executor (c) {
        cancelIndividualFinding = c
      })
    }

    try {
      const findingSuccess = await request(requestOptions)
      dispatch(fetchIndividualFindingSuccess(findingSuccess))
    } catch (e) {
      if (!axios.isCancel(e)) {
        dispatch(fetchIndividualFindingFailure())
      }
    }
  }
}

export function fetchFindingSidebarInfo (finding) {
  return async (dispatch, getState) => {
    try {
      const hostOnly = getState().Findings.sideBarShowHostOnly
      const fetchHost = getState().Findings.fetchHost
      const currentAnalysisId = getState().dashboard.toe.data.attributes.current_analysis_id
      const showHostTab = !criteriumToExcludeHostInfo.includes(finding.attributes.security_criteria_key)
      const shouldFetchSoftwarePatchingData = (finding.attributes.security_criteria_key === 'patching_app_server' || finding.attributes.security_criteria_key === 'patching_vuln_open_ssl' || finding.attributes.security_criteria_key === 'patching_openssl' || finding.attributes.security_criteria_key === 'patching_web_cms' || finding.attributes.security_criteria_key === 'patching_web_server')
      const shouldFetchThreatIntellIssues = (finding.attributes.security_criteria_key === 'threatintel_cc_server' || finding.attributes.security_criteria_key === 'threatintel_botnet_host' || finding.attributes.security_criteria_key === 'threatintel_hostile_host_hacking' || finding.attributes.security_criteria_key === 'threatintel_hostile_host_scanning' || finding.attributes.security_criteria_key === 'threatintel_phishing_site' || finding.attributes.security_criteria_key === 'threatintel_other' || finding.attributes.security_criteria_key === 'threatintel_spamming_host')
      dispatch(fetchIndividualFinding(finding.attributes.finding_id))
      if (shouldFetchSoftwarePatchingData) { dispatch(fetchSoftwarePatchingData(currentAnalysisId)) }
      if (shouldFetchThreatIntellIssues) { dispatch(fetchThreatIntellIssues(finding.attributes.ip_address, finding.attributes.host_name)) }
      if (!hostOnly) dispatch(fetchFindingLanguageInfo(finding.attributes.finding_detail, finding.attributes.security_criteria_key, finding.attributes.security_domain_key))
      if (finding.attributes.host_name && showHostTab) dispatch(fetchFindingsByHost(finding.attributes.host_name))
      if (finding.attributes.host_name && !isIpAddress(finding.attributes.host_name) && (showHostTab)) dispatch(fetchHostProfileInfo(finding.attributes.host_name, currentAnalysisId))
      if (hostOnly || fetchHost) dispatch(fetchHostInfo(finding.attributes.host_name, currentAnalysisId))
    } catch (error) {
      return false
    }
  }
}

export function fetchingLanguageInfo () {
  return { type: FETCHING_LANGUAGE_INFO }
}

export function fetchingSoftwarePatchingData () {
  return { type: FETCHING_SOFTWARE_PATCHING_DATA }
}

export function toggleShowFindingDetailsModal (finding = null) {
  return {
    type: TOGGLE_SHOW_FINDING_SIDEBAR,
    finding: finding
  }
}

function showFindingSidebar (finding = null, hostOnly, selectedTopTab, selectedBottomTab, fetchHost, isCriteriaOnly) {
  return {
    type: SHOW_FINDINGS_SIDEBAR,
    finding,
    hostOnly,
    selectedTopTab,
    selectedBottomTab,
    fetchHost,
    isCriteriaOnly
  }
}

export function showFindingSidebarAsync (finding = null, hostOnly = false, selectedTopTab = 'host', selectedBottomTab = 'host-profile', fetchHost = false, isCriteriaOnly = false) {
  return async (dispatch) => {
    const body = document.getElementsByTagName('BODY')[0]
    body.classList.add('no-scroll')
    dispatch(showFindingSidebar(finding, hostOnly, selectedTopTab, selectedBottomTab, fetchHost, isCriteriaOnly))
  }
}

function fetchLanguageSuccess (data, showVulnerabilityInfo, query, securityCriteria) {
  let type = showVulnerabilityInfo ? FETCH_QUERY_LANGUAGE_SUCCESS : FETCH_LANGUAGE_SUCCESS
  return {
    type: type,
    data: data,
    query: query,
    securityCriteria: securityCriteria
  }
}

export function fetchThreatIntellIssues (ipAddress, hostName) {
  return async (dispatch) => {
    await cancelThreatIntelIssues()
    dispatch(fetchingThreatIntellIssues())
    try {
      const requestOptions = {
        method: 'GET',
        url: `v0/cpe/query_threat_intel_by_ip_and_host_name?ip=${ipAddress}&host_name=${hostName}`,
        cancelToken: new CancelToken(function executor (c) {
          // An executor function receives a cancel function as a parameter
          cancelThreatIntelIssues = c
        })
      }

      request(requestOptions)
        .then(response => {
          dispatch(fetchThreatIntellIssuesSuccess(response.data, ipAddress, hostName))
        })
    } catch (error) {
      if (error.message === undefined) {
        return false
      }
      dispatch(fetchError())
    }
  }
}

function fetchingThreatIntellIssues () {
  return {
    type: FETCHING_THREAT_INTELL_ISSUES
  }
}

function fetchThreatIntellIssuesSuccess (response, ipAddress, hostName) {
  return {
    type: FETCH_THREAT_INTELL_ISSUES_SUCCESS,
    data: response,
    ipAddress: ipAddress,
    hostName: hostName
  }
}

// TODO: Use for advisor
export function fetchSoftwarePatchingData (analysisId) {
  return async (dispatch) => {
    await cancelSoftwarePatchingData()
    dispatch(fetchingSoftwarePatchingData())
    try {
      const requestOptions = {
        method: 'GET',
        url: `v0/analysis/security_profile/software_patching/${analysisId}`,
        cancelToken: new CancelToken(function executor (c) {
          // An executor function receives a cancel function as a parameter
          cancelSoftwarePatchingData = c
        })
      }

      request(requestOptions)
        .then(response => {
          dispatch(fetchSoftwarePatchingDataSuccess(response))
        })
    } catch (error) {
      if (error.message === undefined) {
        return false
      }
      dispatch(fetchError())
    }
  }
}
// TODO: Use for advisor
function fetchSoftwarePatchingDataSuccess (response) {
  return {
    type: FETCH_SOFTWARE_PATCHING_DATA_SUCCESS,
    data: response.data
  }
}

export function fetchHostProfileInfo (query, analysisId) {
  return async (dispatch) => {
    try {
      await cancelHostProfileInfo()
      dispatch(fetchingHostProfile())

      const requestOptions = {
        method: 'GET',
        url: `v0/analysis/host_profiles/${query}/${analysisId}`,
        cancelToken: new CancelToken(function executor (c) {
          // An executor function receives a cancel function as a parameter
          cancelHostProfileInfo = c
        })
      }

      request(requestOptions)
        .then(response => {
          dispatch(fetchHostProfileInfoSuccess(response.data))
        })
    } catch (error) {
      if (error.message === undefined) {
        return false
      }
      dispatch(fetchError())
    }
  }
}

function fetchingHostProfile () {
  return {
    type: FETCHING_HOST_PROFILE
  }
}

function fetchHostProfileInfoSuccess (response) {
  return {
    type: FETCH_HOST_PROFILE_INFO_SUCCESS,
    data: response
  }
}

export function fetchError () {
  return { type: FETCH_FINDINGS_ERROR }
}

// findings by host
export function fetchFindingsByHost (hostName) {
  return async (dispatch, getState) => {
    try {
      await cancelFindingsByHost()
      dispatch(fetchingFindingsByHost())
      const toeId = await getState().dashboard.toe.data.id
      const requestOptions = {
        method: 'GET',
        url: `v0/action_plan/findings_by_host/${toeId}/${hostName}`,
        cancelToken: new CancelToken(function executor (c) {
          // An executor function receives a cancel function as a parameter
          cancelFindingsByHost = c
        })
      }

      request(requestOptions, portalURL(window.location.host))
        .then(response => {
          dispatch(fetchFindingsByHostSuccess(response))
        })
    } catch (err) {
      if (err.message === undefined) {
        return false
      }
      dispatch(fetchFindingsByHostFailure())
    }
  }
}

// hostInfo
export function fetchHostInfo (hostName, analysisID) {
  return async (dispatch) => {
    try {
      if (hostName) {
        await cancel()
        dispatch(fetchingHostInfo())
        const requestOptions = {
          method: 'GET',
          url: `v0/host_info/${hostName}/${analysisID}`,
          cancelToken: new CancelToken(function executor (c) {
            // An executor function receives a cancel function as a parameter
            cancel = c
          })
        }
        request(requestOptions)
          .then(response => {
            dispatch(fetchHostInfoSuccess(response))
          })
      }
    } catch (err) {
      if (err.message === undefined) {
        return false
      }
      dispatch(fetchHostInfoFailure())
    }
  }
}

export function fetchingHostInfo () {
  return { type: FETCHING_HOST_INFO }
}

export function fetchHostInfoSuccess (response) {
  return {
    type: FETCH_HOST_INFO_SUCCESS,
    response: (response.data.hits.hits[0] || {})['_source'] || {}
  }
}

export function fetchHostInfoFailure () {
  return { type: FETCH_HOST_INFO_FAILURE }
}

export function fetchingFindingsByHost () {
  return { type: FETCHING_FINDINGS_BY_HOST }
}

export function fetchFindingsByHostSuccess (response) {
  return {
    type: FETCH_FINDINGS_BY_HOST_SUCCESS,
    response: response.data
  }
}

export function fetchFindingsByHostFailure () {
  return { type: FETCH_FINDINGS_BY_HOST_FAILURE }
}

function resetSidebarFindings () {
  return { type: RESET_SIDEBAR_FINDING_DATA }
}

export function resetAndCloseSidebarFindings () {
  return async (dispatch) => {
    await cancel()
    await cancelFindingsByHost()
    await cancelIndividualFinding()
    await cancelSoftwarePatchingData()
    await cancelThreatIntelIssues()
    await cancelHostProfileInfo()
    await cancelQueryWithLanguage()
    await cancelQueryWithoutLanguage()
    let body = document.getElementsByTagName('BODY')[0]
    body.classList.remove('no-scroll')
    body.style.cssText = 'overflow-y: auto'
    dispatch(resetSidebarFindings())
  }
}
