import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './Radio.scss'

const Radio = ({ checked, id, label, onChange, value, disabled }) => {
  return (
    <div>
      <input
        className="radio"
        checked={checked}
        id={id}
        onChange={onChange}
        type="radio"
        value={value}
        disabled={disabled}
      />
      <label htmlFor={id} className={classnames({ checked: checked })}>
        {label}
      </label>
    </div>
  )
}

Radio.defaultProps = {
  disabled: false,
}

Radio.propTypes = {
  checked: PropTypes.bool.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default Radio
