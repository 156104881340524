/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { isIpAddress } from '../helpers/TextHelpers'
import IssueDetailsPane from './FindingSidebar/IssueSection/IssueDetailsPane'
import IssueCollaborationPane from './FindingSidebar/IssueSection/IssueCollaborationPane'
import HostProfilePane from './FindingSidebar/HostSection/HostProfilePane'
import HostIssuesPane from './FindingSidebar/HostSection/HostIssuesPane'
import LoaderIcon from './loaders/LoaderIcon'
import SidebarErrorView from './FindingSidebar/SidebarErrorView'
import { criteriumToExcludeHostInfo } from '../constants/FindingSidebar'

const FindingSidebar = ({
  finding,
  findings_by_host,
  fetchFindingSidebarInfo,
  fetching_findings_by_host,
  fetching_findings_by_host_failure,
  fetchingLanguageInfo,
  fetchingSoftwarePatchingData,
  fetchingThreatIntellIssues,
  fetchingHostProfileInfo,
  queryWithLanguageFindings,
  languageFindings,
  sideBarShowHostOnly,
  fetchingHostInfo,
  updateDomainFinding,
  updateCriteriaFinding,
  fetchingIndividualFinding,
  fetchingIndividualFindingFailure,
  toe
}) => {
  const [selectedTab, setSelectedTab] = useState('issue-collaboration')

  useEffect(() => {
    fetchFindingSidebarInfo(finding)
  }, [])

  const handleTabChange = tab => {
    setSelectedTab(tab)
  }

  const showHostTab = !criteriumToExcludeHostInfo.includes(finding.attributes.security_criteria_key)

  if (fetchingIndividualFindingFailure) {
    return <SidebarErrorView fetchFindingSidebarInfo={fetchFindingSidebarInfo} finding={finding} />
  }

  return (
    <div className='finding-details-sidebar-container'>
      <div className='finding-details-top-section'>
        {finding.attributes.reporting_status && finding.attributes.reporting_status === 'fixed_resolved' && <span className='issue-resolved-span'>ISSUE RESOLVED</span>}
        <p className='criteria-header'>{finding.attributes.host_name || finding.attributes.domain_name || ''}</p>
        {!isIpAddress(finding.attributes.host_name) &&
        <p className='issue-title'>{finding.attributes.ip_address || ''}</p>
        }
      </div>
      <div className='sidebar-sub-nav'>
        {!sideBarShowHostOnly && (
          <div className='flex'>

            <div className={`sub-nav-option selected-${selectedTab === 'issue-detail'}`} onClick={() => handleTabChange('issue-detail')}>
              <p>Issue Details</p>
            </div>
            <div className={`sub-nav-option selected-${selectedTab === 'issue-collaboration'}`} onClick={() => handleTabChange('issue-collaboration')}>
              <p>Issue Management</p>
            </div>
          </div>
        )}
        {showHostTab && (
          <div className='flex'>
            <div className={`sub-nav-option selected-${selectedTab === 'host-profile'}`} onClick={() => handleTabChange('host-profile')}>
              <p>Host Profile</p>
            </div>

            { finding.attributes.host_name && (
              <div className={`sub-nav-option selected-${selectedTab === 'host-issues'}`} onClick={() => handleTabChange('host-issues')}>
                <p>Host Issues</p>
              </div>
            )}
          </div>
        )}
      </div>
      <div className='finding-details-bottom-section'>
        {(selectedTab === 'host-profile' && finding.attributes.host_name && showHostTab) && (
          (fetchingHostInfo || fetchingHostProfileInfo || fetchingIndividualFinding) ? (
            <div id='finding-sidebar-loader-wrapper'><LoaderIcon minHeight='100vh' /></div>
          ) : (
            <HostProfilePane finding={finding} />
          )
        )}
        {(selectedTab === 'host-issues' && finding.attributes.host_name && showHostTab) && <HostIssuesPane finding={finding} fetchingFindingsByHost={fetching_findings_by_host} fetchingFindingsByHostFailure={fetching_findings_by_host_failure} findingsByHost={findings_by_host} /> }
        {(!sideBarShowHostOnly && selectedTab === 'issue-detail') && (
          fetchingLanguageInfo || fetchingSoftwarePatchingData || fetchingThreatIntellIssues || fetchingIndividualFinding ? (
            <div id='finding-sidebar-loader-wrapper'><LoaderIcon minHeight='100vh' /></div>
          ) : (
            <IssueDetailsPane
              finding={finding}
              queryWithLanguageFindings={queryWithLanguageFindings}
              languageFindings={languageFindings} />
          )
        ) }
        {(selectedTab === 'issue-collaboration') && (
          fetchingLanguageInfo || fetchingSoftwarePatchingData || fetchingThreatIntellIssues || fetchingIndividualFinding ? (
            <div id='finding-sidebar-loader-wrapper'><LoaderIcon minHeight='100vh' /></div>
          ) : (
            <IssueCollaborationPane
              finding={finding}
              queryWithLanguageFindings={queryWithLanguageFindings}
              updateDomainFinding={updateDomainFinding}
              updateCriteriaFinding={updateCriteriaFinding}
              toe={toe}
              languageFindings={languageFindings} />
          )
        ) }
      </div>
    </div>
  )
}

FindingSidebar.propTypes = {
  showOnlyHost: PropTypes.bool
}

FindingSidebar.defaultProps = {
  showOnlyHost: false
}

export default FindingSidebar
