import React from 'react'

function SidebarErrorView (props) {
  const retryFetchFinding = () => {
    const { fetchFindingSidebarInfo, finding } = props
    fetchFindingSidebarInfo(finding)
  }
  return (
    <div className='sidebar-error-container'>
      <div className='sidebar-error-wrapper'>
        <h2 className='error-text'>Oops. Something went wrong.</h2>
        <p>Please try again.</p>
        <button className='btn btn--red-outline retry-button' onClick={() => retryFetchFinding()}> Retry </button>
      </div>
    </div>
  )
}

export default SidebarErrorView
