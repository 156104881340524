import React from 'react'
// import { useSelector } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import AuthContainer from '../AuthContainer'
import Dashboard from '../../components/dashboard/Dashboard'
import {
  RouteTracker,
  initGoogleAnalytics,
} from '../../components/GoogleAnalytics'
import TwoStepVerification from '../../components/twoStepVerification/TwoStepVerification'

// Import Dashboard component

const AuthRoutes = () => {
  // replace once QA testing is done.
  // const isAnalyticsExempt = useSelector((state) => state?.user?.exclude_for_google_analytics)

  return (
    <AuthContainer>
      <>
        {initGoogleAnalytics() && <RouteTracker />}
        <Switch key='auth-switch'>
          <Route exact path='/dashboard' children={<Dashboard />} />
          <Route
            path='/multi-factor-verification'
            children={<TwoStepVerification />}
          />
          <Route render={() => <Redirect to='/dashboard' />} />
        </Switch>
      </>
    </AuthContainer>
  )
}

export default AuthRoutes
