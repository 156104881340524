import React from 'react'
import { Message } from 'semantic-ui-react'

const defaultHeader = "We're sorry but we could not fetch that data"
const defaultMessage = 'Please refresh the page and try again.'

const LoadingErrorMessage = ({ header = defaultHeader, message = defaultMessage, width = 1100, maxWidth = 1100, marginBottom = 0 }) => (
  <div
    className='rr-container'
    style={{ marginTop: '20px', width: width, maxWidth: maxWidth, marginBottom: marginBottom }}
  >
    <Message negative>
      <Message.Header>{header}</Message.Header>
      <p>{message}</p>
    </Message>
  </div>
)

export default LoadingErrorMessage
