import React from 'react'
import PropTypes from 'prop-types'

function TextArea ({
  label,
  error,
  name,
  placeholder,
  value,
  onChange,
  comment
}) {
  let inputClassNames = 'form-input'
  inputClassNames = error ? inputClassNames + ' is-error' : inputClassNames

  return (
    <div className='form-group'>
      <div className='form-header'>
        <label className='form-label'>{label}</label>
        {comment && <div className='form-comment'>{comment}</div>}
      </div>
      <textarea
        className={inputClassNames}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
      />
      <span className='form-input-hint is-error'>{error}</span>
    </div>
  )
}

TextArea.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  comment: PropTypes.string,
}

export default React.memo(TextArea)
