import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
// import '../../assets/styles/Shared/SideDrawer.css';
const SideDrawer = (props) => {
  const { open, closeDrawer, children } = props
  return (
    <div className={`side-drawer-container open-${open}`}>
      <div className='side-drawer-overlay' onClick={() => closeDrawer()} />
      <div className='side-drawer-wrapper'>
        {open && <FontAwesomeIcon className='close-sidebar' icon={faTimes} onClick={() => closeDrawer()} />}
        { children }
      </div>
    </div>
  )
}

export default SideDrawer
