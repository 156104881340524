import React from "react";
import { Container,Header } from "../../../shared";
import CustomerRequestDetails from "./CustomerRequestDetails";
import "./CustomerRequests.scss";

function CustomerRequests({ finding }) {
  const customers = finding.attributes.customer_requests;

  return (
    <Container medium className="customer-request">
      <Header>Customer Requests</Header>
      <div className="table">
        <div className="header">
          <div className="column name-column">Name</div>
          <div className="column date-column">Due Date</div>
          <div className="column due-column">Overdue</div>
          <div className="column comment-column">Comment</div>
          <div className="column action-plan-column">Still in Action Plan</div>
        </div>
        <div className="customer-requests__body">
          {customers.map((customer, index) => (
            <CustomerRequestDetails key={index} customer={customer} />
          ))}
        </div>
      </div>
    </Container>
  );
}

export default CustomerRequests;
