import React, { useState } from 'react'
import PropTypes from 'prop-types'
import configuredSMS from '../../assets/svg/mfa/configuredSMS.svg'
import { COUNTRY_PHONE_CODES, validPhoneNumber } from '../../utils/phoneNumbers'
import request from '../../reference/axiosWrapper'
import Button from '../shared/Button'
import Select from '../shared/Select'
import TextInput from '../shared/TextInput'
import './ConfigureSMS.scss'
import Tooltip from '../shared/tooltips/Tooltip'

function PhoneNumberForm({ sent, setSent, setAuthyId }) {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [countryCode, setCountryCode] = useState(COUNTRY_PHONE_CODES[0])
  const [submitting, setSubmitting] = useState(false)

  const _setPhoneNumber = (e) => setPhoneNumber(e.target.value)

  const validFields = () => {
    var valid = true
    if (countryCode?.value?.length === 0) {
      valid = false
    }
    if (phoneNumber?.length === 0) {
      valid = false
    } else if (!validPhoneNumber(phoneNumber)) {
      valid = false
    }
    return valid
  }

  const trySubmit = (e) => {
    e.preventDefault()
    setSubmitting(true)
    if (validFields()) {
      submit()
    } else {
      setSubmitting(false)
    }
  }

  const submit = async () => {
    try {
      const requestOptions = {
        method: 'POST',
        url: 'v2/users/setup_sms',
        data: {
          user: {
            cellphone: phoneNumber,
            country_code: countryCode.value,
          },
        },
      }
      const response = await request(requestOptions)
      if (response.status === 200) {
        setAuthyId(response.data.authy_id)
        setSent(true)
        setSubmitting(false)
      } else {
        setSubmitting(false)
      }
    } catch {
      setSubmitting(false)
    }
  }

  const disabledButton = submitting || !validFields()
  const tooltipContent =
    'SMS verification not supported in China, please use Time Based Tokens (E.g. Google Authenticator) verification or a different Country Code'

  return (
    <form id='phone-number-form' onSubmit={trySubmit}>
      <div className='country-code'>
        <label htmlFor='country-code'>Country Code</label>
        <Select
          id='country-code'
          label='Country Code'
          options={COUNTRY_PHONE_CODES}
          isOptionDisabled={(option) => option.disableForMfa}
          getOptionLabel={(option) =>
            !option.disableForMfa ? (
              option.label
            ) : (
              <Tooltip title={tooltipContent}>
                {option.label} - SMS verification not supported
              </Tooltip>
            )
          }
          selected={countryCode}
          onChange={setCountryCode}
        />
      </div>
      <TextInput
        value={phoneNumber}
        name='phoneNumber'
        id='phoneNumber'
        label='Phone Number'
        onChange={_setPhoneNumber}
        fullBorder
      />
      <Button
        type='submit'
        color='blue outline'
        loading={submitting}
        disabled={disabledButton}
      >
        {sent ? 'Resend Code' : 'Send Code'}
      </Button>
    </form>
  )
}

PhoneNumberForm.propTypes = {
  sent: PropTypes.bool.isRequired,
  setAuthyId: PropTypes.func.isRequired,
  setSent: PropTypes.func.isRequired,
}

function VerifyCode({ sent, setConfigured, authyId }) {
  const [code, setCode] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState('')

  const trySubmit = (e) => {
    e.preventDefault()
    if (validFields()) {
      submit()
    }
  }

  const submit = async () => {
    setSubmitting(true)
    try {
      const requestOptions = {
        method: 'POST',
        url: 'v2/users/mfa_verify',
        data: { authy_id: authyId, one_time_code: code },
      }
      const response = await request(requestOptions)
      if (response.status === 204) {
        setConfigured('sms')
      } else {
        setSubmitting(false)
        setError('Error')
      }
    } catch {
      setSubmitting(false)
      setError('Error')
    }
  }

  const validFields = () => {
    var valid = true
    if (code?.length === 0) {
      valid = false
    }
    return valid
  }

  const disabledField = !sent || submitting
  const disabledButton = !validFields || !sent || submitting || !code?.length

  const _setCode = (e) => setCode(e.target.value)

  return (
    <form id='verify-form' onSubmit={trySubmit}>
      <div className='dummy-box' />
      <TextInput
        value={code}
        id='code'
        name='code'
        label='Enter Verification Code'
        error={error}
        onChange={_setCode}
        disabled={disabledField}
        fullBorder
        placeholder='Enter Code'
        autoComplete='off'
      />
      <Button
        type='submit'
        color='blue outline'
        loading={submitting}
        disabled={disabledButton}
      >
        Verify
      </Button>
    </form>
  )
}

VerifyCode.propTypes = {
  authyId: PropTypes.string.isRequired,
  sent: PropTypes.bool.isRequired,
  setConfigured: PropTypes.func.isRequired,
}

const ConfigureSMS = ({ isConfigured, setConfigured }) => {
  const [authyId, setAuthyId] = useState('')
  const [sent, setSent] = useState(false)

  if (isConfigured) {
    return (
      <div className='configured-screen'>
        <p>SMS Text Message Configured</p>
        <img
          style={{ margin: '0 auto', display: 'block' }}
          src={configuredSMS}
          alt='Configured'
        />
      </div>
    )
  }

  const onSetSent = (value) => setSent(value)

  const onSetAuthyId = (value) => setAuthyId(value)

  return (
    <>
      <PhoneNumberForm
        sent={sent}
        setSent={onSetSent}
        setAuthyId={onSetAuthyId}
      />
      <VerifyCode sent={sent} setConfigured={setConfigured} authyId={authyId} />
    </>
  )
}

ConfigureSMS.propTypes = {
  isConfigured: PropTypes.bool.isRequired,
  setConfigured: PropTypes.func.isRequired,
}

export default ConfigureSMS
