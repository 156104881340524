import React from 'react'
import PropTypes from 'prop-types'

const WarningTriangleSVG = ({ width, fill, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5301 17.5037C19.0701 17.5037 20.0301 15.8337 19.2601 14.5037L11.7301 1.49372C10.9601 0.163716 9.04012 0.163716 8.27012 1.49372L0.740121 14.5037C-0.0298788 15.8337 0.930121 17.5037 2.47012 17.5037H17.5301ZM10.0001 10.5037C9.45012 10.5037 9.00012 10.0537 9.00012 9.50372V7.50372C9.00012 6.95372 9.45012 6.50372 10.0001 6.50372C10.5501 6.50372 11.0001 6.95372 11.0001 7.50372V9.50372C11.0001 10.0537 10.5501 10.5037 10.0001 10.5037ZM9.00012 12.5037V14.5037H11.0001V12.5037H9.00012Z"
      fill={fill}
    />
  </svg>
)

WarningTriangleSVG.defaultProps = {
  width: '20',
  height: '18',
  fill: '#B30015',
}

WarningTriangleSVG.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}

export default WarningTriangleSVG
