import React from 'react'
import PropTypes from 'prop-types'

function UnauthContainer (props) {
  return (
    <div id='unauth-wrapper'>
     <div className='unauth-logo-container'> <div className='unauth-logo' /> </div>
      {props.children}
      <footer id='portal-footer'>
        <div className='content-container'>
          &copy; Copyright 2015-{new Date().getFullYear()} RiskRecon&trade; Inc. All Rights Reserved.
        </div>
      </footer>
    </div>
  )
}

UnauthContainer.propTypes = {
  children: PropTypes.element
}

export default (UnauthContainer)
