/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classnames from 'classnames'
import Select from 'react-select'
import { setSelectedTab, setSelectedValues, resetPagination, setSearchOptions } from '../../actions/dashboardActions'
import { colourStyles } from '../../helpers/SelectHelpers'

const FilterFindings = () => {
  const selectedTab = useSelector((state) => state.dashboard.selectedTab)
  const selectedValues = useSelector((state) => state.dashboard.selectedValues)
  const toe = useSelector((state) => state.dashboard.toe.data)
  const fetchingToeSummary = useSelector((state) => state.dashboard.fetchingToeSummary)
  const searchOptions = useSelector((state) => state.dashboard.searchOptions)
  const dispatch = useDispatch()

  let customerOptions = []

  useEffect(() => {
    toe.attributes.sharing_customers.forEach((customer) => {
      customerOptions.push({ label: customer.name, value: customer.id })
    })
    dispatch(setSearchOptions(customerOptions))
  }, [toe])

  const handleSelect = (value) => {
    dispatch(resetPagination(true))
    dispatch(setSelectedValues(value))
  }

  const handleButtonClick = tab => {
    dispatch(setSelectedTab(tab))
  }

  return (
    <div className='filter-findings-container'>
      <div className='filter-findings-buttons'>
        <button onClick={() => handleButtonClick('current')} className={classnames({ active: selectedTab === 'current' })}>Current Open Issues</button>
        <button onClick={() => handleButtonClick('closed')} className={classnames({ active: selectedTab === 'closed' })}>Resolved Issues</button>
      </div>
      <div className='filter-findings-wrapper'>
        <Select
          isMulti
          isDisabled={fetchingToeSummary}
          value={selectedValues}
          options={searchOptions}
          onChange={handleSelect}
          placeholder={'Filter by Customers'}
          styles={colourStyles}
          closeMenuOnSelect={false}
        />
      </div>
    </div>
  )
}

export default FilterFindings
