import PropTypes from 'prop-types'
import React from 'react'
import { NavLink } from 'react-router-dom'
import ChevronUpSVG from './shared/ChevronUpSVG'
import './NavItem.scss'

export const NavItemLink = ({ title, to }) => <NavLink tabIndex={0} className='nav-item' to={to}>{title}</NavLink>

NavItemLink.propTypes = {
  title: PropTypes.any,
  to: PropTypes.any.isRequired
}

export const NavItemDropDown = ({ title, children }) => (
  <div tabIndex={0} className='nav-item'>
    <span>{title}</span>
    <ChevronUpSVG className='spin' />
    <div className='sub-menu'>
      {children}
    </div>
  </div>
)

NavItemDropDown.propTypes = {
  children: PropTypes.any,
  title: PropTypes.any
}
