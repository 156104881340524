import React, { useState } from "react";

import {
  Header,
  Table,
  TableHead,
  TableBody,
  TableCellHeader,
  TableRow,
  Container,
} from '../../../shared'
import Pagination from '../../../shared/Pagination'
import VulnerabilityDetails from './VulnerabilityDetails'
import './VulnerabilitiesList.scss'

const noCVEText =
  'There are no vulnerability details or CVE data for this version of software. However, this version of software is no longer supported by the vendor.'

const VulnerabilitiesList = ({ vulnerabilities, finding }) => {
  const [activePage, setActivePage] = useState(1)
  const [totalPages] = useState(
    vulnerabilities !== undefined ? Math.ceil(vulnerabilities.length / 5) : 0
  )
  const [vulnerabilitiesData] = useState(
    vulnerabilities !== undefined
      ? vulnerabilities.sort((a, b) => b.score - a.score)
      : []
  )
  const [currentVulnerabilities, setCurrentVulnerabilities] = useState(
    vulnerabilities !== undefined ? vulnerabilities.slice(0, 5) : []
  )
  const [totalItems] = useState(
    vulnerabilities !== undefined ? vulnerabilities.length : 0
  )
  const [itemsPerPage] = useState(5)

  const handlePaginationChange = (e, { activePage }) => {
    const startItem = (activePage - 1) * itemsPerPage
    const endItem =
      totalItems >= startItem + itemsPerPage + 1
        ? startItem + itemsPerPage
        : totalItems
    setActivePage(activePage)
    setCurrentVulnerabilities(vulnerabilities.slice(startItem, endItem))
  }

  const renderPaginationSection = () => {
    if (totalPages > 1) {
      return (
        <div className="pagination-wrapper">
          <Pagination
            activePage={activePage}
            onPageChange={handlePaginationChange}
            totalPages={totalPages}
          />
        </div>
      )
    }
    return null
  }

  const renderVulnerabilityList = (vulnerabilitiesInfo) => {
    return vulnerabilitiesData?.length > 0 ? (
      <>
        <Table className="vulnerabilities-list">
          <TableHead>
            <TableRow>
              <TableCellHeader>CVE</TableCellHeader>
              <TableCellHeader width={100}>Score</TableCellHeader>
              <TableCellHeader>Severity</TableCellHeader>
              <TableCellHeader width={150}>Publish Date</TableCellHeader>
              <TableCellHeader width={50}></TableCellHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentVulnerabilities.map((vulnerability) => (
              <VulnerabilityDetails
                vulnerability={vulnerability}
                key={vulnerability.cve_id}
              />
            ))}
          </TableBody>
        </Table>
        {renderPaginationSection()}
      </>
    ) : (
      <p style={{ marginTop: '5px' }}>{noCVEText}</p>
    )
  }

  return (
    <Container medium className="vulnerabilities-list_container">
      <Header>{finding.attributes.finding_detail} Vulnerabilities</Header>
      {vulnerabilities.length > 0
        ? renderVulnerabilityList(currentVulnerabilities)
        : noCVEText}
    </Container>
  )
}

export default VulnerabilitiesList
