import moment from "moment";
import {
  setClassColorByConcern,
  truncateString,
} from "../../../../helpers/TextHelpers";

export function displayIssueDescriptionValue(finding, attribute) {
  let criteriaObject = {};
  if (attribute === "security_criteria_rating_numeric") {
    if (finding["_embedded"]) {
      criteriaObject = finding["_embedded"]["rel:security_criteria"].find(
        (x) => x.name === finding.attributes.security_criteria_key
      );
      if (finding.attributes.security_criteria_key === "patching_openssl") {
        criteriaObject = finding["_embedded"]["rel:security_criteria"].find(
          (x) => x.name === "patching_openssl"
        );
      }
      return {
        value: criteriaObject.security_criteria_rating_numeric,
        valueClassName: setClassColorByConcern(
          attribute,
          criteriaObject.security_criteria_rating_numeric
        ),
      };
    } else {
      return null;
    }
  }

  if (attribute === "support_status") {
    return {
      value: "End of Life",
      valueClassName: setClassColorByConcern(
        attribute,
        finding.attributes[attribute]
      ),
    };
  }

  if (attribute === "first_seen") {
    return {
      value: moment(finding.attributes[attribute]).format("MM/DD/YYYY"),
      valueClassName: setClassColorByConcern(
        attribute,
        finding.attributes[attribute]
      ),
    };
  }

  if (attribute === "last_seen") {
    return {
      value: moment(finding.attributes[attribute]).format("MM/DD/YYYY"),
      valueClassName: setClassColorByConcern(
        attribute,
        finding.attributes[attribute]
      ),
    };
  }

  return {
    value: truncateString(finding.attributes[attribute], 36),
    valueClassName: setClassColorByConcern(
      attribute,
      finding.attributes[attribute]
    ),
  };
}
