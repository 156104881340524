import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import request from '../reference/axiosWrapper'

// ASYNC FUNCTIONS - typically api calls
export const enableMFA = createAsyncThunk('mfa/enableMFA', async () => {
  const requestOptions = {
    method: 'PUT',
    url: 'v2/users/enable_mfa',
  }
  await request(requestOptions)
})

export const disableMFA = createAsyncThunk('mfa/disableMFA', async () => {
  const requestOptions = {
    method: 'PUT',
    url: 'v2/users/disable_mfa',
  }
  await request(requestOptions)
})

const initialState = {
  authy_id: '',
  authy_verified_at: '',
  configured_mfa_channel: '',
  enforce_mfa: false,
  sso_enabled: false,
  last_mfa_strategy: '',
  mfa_enabled: false,
  mfa_configuration_error: false,
  awaiting_mfa_enable_disable_response: false,
  route_to_mfa: false,
  mfa_route: '',
  temp_token: '',
}

const mfaSlice = createSlice({
  name: 'mfa',
  initialState,
  reducers: {
    setUserMfa(state, action) {
      state.authy_id = action.payload.authy_id
      state.authy_verified_at = action.payload.authy_verified_at
      state.configured_mfa_channel = action.payload.configured_mfa_channel
      state.enforce_mfa = action.payload.enforce_mfa
      state.sso_enabled = action.payload.sso_enabled
      state.last_mfa_strategy = action.payload.last_mfa_strategy
      state.mfa_enabled = action.payload.mfa_enabled
    },
    setMFAConfigurationError(state, action) {
      state.mfa_configuration_error = action.payload
    },
    setRouteToMfa(state, action) {
      state.route_to_mfa = true
      state.mfa_route = action.payload.mfa_route
      state.temp_token = action.payload.temp_token
    },
    resetMFA(state) {
      state = initialState
    },
  },
  extraReducers: (builder) => {
    // async actions using createAsyncThunk
    builder
      .addCase(enableMFA.pending, (state) => {
        state.awaiting_mfa_enable_disable_response = true
      })
      .addCase(enableMFA.fulfilled, (state) => {
        state.mfa_enabled = true
        state.awaiting_mfa_enable_disable_response = false
      })
      .addCase(enableMFA.rejected, (state) => {
        state.awaiting_mfa_enable_disable_response = false
        state.mfa_configuration_error = true
      })
      .addCase(disableMFA.pending, (state) => {
        state.awaiting_mfa_enable_disable_response = true
      })
      .addCase(disableMFA.fulfilled, (state) => {
        state.mfa_enabled = false
        state.awaiting_mfa_enable_disable_response = false
      })
      .addCase(disableMFA.rejected, (state) => {
        state.awaiting_mfa_enable_disable_response = false
        state.mfa_configuration_error = true
      })
  },
})

export const { setUserMfa, setRouteToMfa, setMFAConfigurationError, resetMFA } =
  mfaSlice.actions

export default mfaSlice.reducer
