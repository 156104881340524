import React from 'react'
import PropTypes from 'prop-types'

const EyeVisibleSVG = ({
  size,
  className
}) =>
  <svg className={className} width={size} height={size} viewBox='0 0 19 19' xmlns='http://www.w3.org/2000/svg'>
    <g>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.50032 3.5625C5.54199 3.5625 2.16158 6.02458 0.791992 9.5C2.16158 12.9754 5.54199 15.4375 9.50032 15.4375C13.4587 15.4375 16.8391 12.9754 18.2087 9.5C16.8391 6.02458 13.4587 3.5625 9.50032 3.5625ZM9.50032 13.4583C7.31532 13.4583 5.54199 11.685 5.54199 9.5C5.54199 7.315 7.31532 5.54167 9.50032 5.54167C11.6853 5.54167 13.4587 7.315 13.4587 9.5C13.4587 11.685 11.6853 13.4583 9.50032 13.4583ZM7.12533 9.5C7.12533 8.18583 8.18616 7.125 9.50033 7.125C10.8145 7.125 11.8753 8.18583 11.8753 9.5C11.8753 10.8142 10.8145 11.875 9.50033 11.875C8.18616 11.875 7.12533 10.8142 7.12533 9.5Z'
      />
    </g>
  </svg>

EyeVisibleSVG.defaultProps = {
  size: '24'
}

EyeVisibleSVG.propTypes = {
  size: PropTypes.string.isRequired,
  className: PropTypes.string
}

export default EyeVisibleSVG
