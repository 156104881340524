import isEmpty from 'lodash/isEmpty'

const updateFindingValidator = (state) => {
  let errors = {}

  // status
  if (typeof state.selectedStatus === 'string') {
    errors.selectedStatus = '*Required'
  }
  if (typeof state.selectedStatus === 'object') {
    if (state.selectedStatus.value === 'false_positive' || state.selectedStatus.value === 'compensating_control') {
      if (typeof state.selectedOption === 'string') {
        errors.selectedOption = '*Required'
      }
      if (state.selectedOption.required_comment && isEmpty(state.commentValue)) {
        errors.commentValue = '*Required'
      }
    }
  }

  return {
    errors,
    valid: isEmpty(errors)
  }
}

export default updateFindingValidator
