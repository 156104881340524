import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { setClassColorByConcern,truncateString } from '../../../../helpers/functions'
import {
  Container,
  Header,
  MetricItem,
  Tooltip,
} from '../../../shared'
import './AssetValueSection.scss'
import { QuestionMarkOSVG } from '../../../../assets/svg'

const AssetValueSection = ({ finding }) => {
  const characteristics = (finding.attributes.form_field_types_detected || '')
    .split(';')
    .map(fT => <div key={fT}>{fT || 'none'}</div>)
  const value = finding.attributes.asset_value_override_reason &&
    finding.attributes.asset_value_override_reason !== ''
    ? finding.attributes.asset_value_override_reason : 'none'
  const minLength = 25

  return (
    <Container medium className="asset-value-section">
      <Header>Asset Value</Header>
      <div>
        <div className="asset-value-metric-container">
          <MetricItem
            small
            valueClassName={setClassColorByConcern('asset_value', finding.attributes.asset_value)}
            label="Asset Value"
            value={finding.attributes.asset_value}
          />
          <MetricItem
            small
            label="Has Authentication"
            value={finding.attributes.auth_detected ? finding.attributes.auth_detected : 'n/a'}
          />
          <MetricItem
            small
            label="Data Characteristics"
            value={characteristics}
          />
          <span>
            <div className={classnames('v3-metric-item', '', 'small')}>
              <div className="metric-label">{'Additional Context'}
                {value !== 'none' &&
                  <Tooltip
                    className="additional-context-help-icon"
                    title="This information is provided by the asset owner and modifies the asset value based on the context below."
                  >
                    <QuestionMarkOSVG size="20px" />
                  </Tooltip>
                }
              </div>
              <Tooltip
                title={value}
                disabled={value == null || value?.length < minLength}
                position={'bottom'}
                style={{ cursor: value?.length >= minLength ? 'pointer' : 'text' }}
              >
                <div className={classnames('metric-value', '', { na: String(value).toLowerCase() === 'n/a' })}>{truncateString(value, minLength)}
                </div>
              </Tooltip>
            </div>
          </span>
        </div>
      </div>
    </Container>
  )
}

AssetValueSection.propTypes = {
  finding: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default AssetValueSection
