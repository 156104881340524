import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import {
  domainCardKeys,
  domainCardKeyMappings,
} from '../../constants/ActionPlan'
import { showFindingSidebarAsync } from '../../actions/Findings'
import {
  fetchDomainFindings,
  resetPagination,
  fetchCriteriaFindings,
  fetchOverviewTrends
} from '../../actions/dashboardActions'
import WidgetCard from './WidgetCard'
import DomainCard from './DomainCard'
import BannerCard from './BannerCard'
import LoadingIcon from '../loaders/LoaderIcon'
import IssueStatusSummary from './IssueStatusSummary'
import CustomerRequestSummary from './CustomerRequestSummary'
import OverviewLineChart from './OverviewLineChart'
import RatingGauge from '../shared/rating-items/RatingGauge'
import { ReactComponent as ErrorOutlineSvg } from '../../assets/images/error_outline.svg'
import FilterFindings from './FilterFindings'
import { fetchBanners } from '../../actions/displayBanners'
import ReactGA from 'react-ga4'
// todo: prop types

const Dashboard = React.memo(function Dashboard(props) {
  const {
    overviewTrends,
    analysisTrendsWithDataLoss,
    showFindingSidebarAsync,
    domainFindingInfo,
    criteriaFindingInfo,
    loadingCriteriaFindings,
    fetchDomainFindings,
    fetchOverviewTrends,
    fetchCriteriaFindings,
    loadingDomainFindings,
    fetchingToeSummary,
    user,
    toe,
    selectedTab,
    selectedValues,
    banners,
    displayBanners,
    user: { loggedIn = false },
    fetchBanners,
    customerId,
    loadingRatingData,
  } = props

  const [hashLineVisible, setHashLineVisible] = useState(false)
  const [analysisDetailsVisible, setAnalysisDetailsVisible] = useState(false)
  const [analysisDetails, setAnalysisDetails] = useState({})

  const showInlineFilter = useSelector(
    (state) => state.dashboard.showInlineFilter
  )

  useEffect(() => {
    if (loggedIn && !displayBanners) {
      fetchBanners()
    }
  }, [loggedIn, displayBanners, fetchBanners])

  useEffect(() => {
    ReactGA.set({ dimension1: customerId })
  }, [customerId])

  useEffect(() => {
    if (toe && toe.id !== "") {
      fetchOverviewTrends(toe)
    }
  }, [toe])

  const showAnalysisDetails = (analysis) => {
    setAnalysisDetailsVisible(true)
    setAnalysisDetails(analysis.data)
    setHashLineVisible(true)
  }

  const hideAnalysisDetails = () => {
    setAnalysisDetailsVisible(false)
    setHashLineVisible(false)
  }


  const rating = parseFloat(overviewTrends.overall_rating_numeric) || 0.0

  const tooltipTitle = "<b>RiskRecon Cybersecurity Ratings:</b><br /> RiskRecon assesses the cybersecurity hygiene of organizations by using passive techniques that observe and analyze the security configuration and operational state of their systems. Areas that RiskRecon monitors include areas such as software patching, network filtering, application security, and encryption deployment and configuration. RiskRecon rates cybersecurity hygiene on an A – F scale, with A being the best. RiskRecon’s ratings are strongly correlated with risk outcomes, where A and B rated organizations have dramatically lower rates of breach events compared with D and F rated companies."
  return (
    <div id="page-dashboard" className="page-wrapper">
      {!fetchingToeSummary && toe.attributes.customers_count < 1 && (
        <div className="no-customers-wrapper">
          <ErrorOutlineSvg />
          No customers are sharing action plans with you at this time.
          Previously shared action plans were retracted.
        </div>
      )}
      <div>
        {!!banners.length ? <BannerCard /> : null}
        <div className="dashboard-widget-cards">

          <WidgetCard
            title="RiskRecon Rating"
            cardType="trendline"
            showToolTip
            toolTipText={tooltipTitle}
          >
                      {loadingRatingData && <LoadingIcon minHeight='220' />}
                      {!loadingRatingData && (
            <div className="dashboard-rating-card">
          <RatingGauge rating={rating}/>
          <OverviewLineChart 
            showHoverData
            x={d => new Date(d.date_updated)}
            y={d => parseFloat(d.overall_rating_numeric)}
            width={930}
            height={185}
            data={
              [...analysisTrendsWithDataLoss] 
            }
            margin={{ left: 30, right: 10, top: 10, bottom: 25 }}
            gradientId="line-chart-gradient"
            onClick={showAnalysisDetails}
            hashLineVisible={hashLineVisible}
            hideAnalysisDetails={hideAnalysisDetails}
          />
          </div>
                      )}
          </WidgetCard>
        </div>

        <div className="dashboard-widget-cards">
          <WidgetCard
            title="Issue Status Summary"
            centerTitle
            cardType="widget-card-issues"
          >
            <IssueStatusSummary
              toe={toe}
              toeSummary={toe.attributes}
              fetchingToeSummary={fetchingToeSummary}
            />
          </WidgetCard>
          <WidgetCard
            title="Summary of Customer Requests"
            centerTitle
            cardType="widget-card-summary"
          >
            <CustomerRequestSummary
              toeSummary={toe.attributes}
              fetchingToeSummary={fetchingToeSummary}
            />
          </WidgetCard>
        </div>
        {showInlineFilter && <FilterFindings />}
        {domainCardKeys.map((domain, idx) => (
          <DomainCard
            selectedTab={selectedTab}
            key={idx}
            domainFindingInfo={domainFindingInfo[domain]}
            criteriaFindingInfo={criteriaFindingInfo}
            domainCardKeyMappings={domainCardKeyMappings[domain]}
            showFindingSidebarAsync={showFindingSidebarAsync}
            loadingDomainFindings={loadingDomainFindings[domain]}
            loadingCriteriaFindings={loadingCriteriaFindings}
            fetchDomainFindings={fetchDomainFindings}
            fetchCriteriaFindings={fetchCriteriaFindings}
            selectedValues={selectedValues}
            user={user}
          />
        ))}
      </div>
    </div>
  )
})
const mapStateToProps = (state) => {
  return {
    analysisTrendsWithDataLoss: state.dashboard.analysisTrendsWithDataLoss,
    user: state.user,
    toe: state.dashboard.toe.data,
    fetchToeSummary: state.dashboard.fetchToeSummary,
    fetchingToeSummary: state.dashboard.fetchingToeSummary,
    domainFindingInfo: state.dashboard.domainFindingInfo,
    fetchToeSummaryFailure: state.dashboard.fetchToeSummaryFailure,
    criteriaFindingInfo: state.dashboard.criteriaFindingInfo,
    loadingDomainFindings: state.dashboard.loadingDomainFindings,
    loadingCriteriaFindings: state.dashboard.loadingCriteriaFindings,
    domainFindingsFailures: state.dashboard.domainFindingsFailures,
    criteriaFindingsFailures: state.dashboard.criteriaFindingsFailures,
    selectedTab: state.dashboard.selectedTab,
    selectedValues: state.dashboard.selectedValues,
    banners: state.displayBanners.banners,
    displayBanners: state.displayBanners.bannerCount,
    customerId: state.user.userInfo.customer_id,
    overviewTrends: state.dashboard.overviewTrends,
    loadingRatingData: state.dashboard.loadingRatingData,
  }
}

const mapDispatchToProps = {
  showFindingSidebarAsync,
  fetchDomainFindings,
  fetchCriteriaFindings,
  resetPagination,
  fetchBanners,
  fetchOverviewTrends,
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
