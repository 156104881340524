import React from 'react'
import LoadingIcon from '../loaders/LoaderIcon'

const CustomerRequestSummary = React.memo(function CustomerRequestSummary (props) {
  const { toeSummary, fetchingToeSummary } = props
  const WidgetStatusRows = toeSummary.sharing_customers.map((customer, key) => (
    <div key={key} className='widget-summary-stats-row'>
      <span className='customer-name-span'>{customer.name}</span>
      <span className='customer-issue-span'>{customer.issue_count}</span>
    </div>
  ))

  if (fetchingToeSummary) {
    return <LoadingIcon minHeight='220' />
  }

  return (
    <div>
      <div className='widget-count centered'>{toeSummary.customers_count}</div>
      <div className='widget-subheading centered'>Customers</div>
      <div className='widget-divider' />
      <div className='widget-stats-wrapper'>
        <div className='widget-summary-stats-row heading'>
          <span className='customer-name-span'>Customer</span>
          <span className='customer-issue-span'>Issues</span>
        </div>
        {/* map over these once we get the data */}
        { WidgetStatusRows }
        {/* We gotta change this to 5 once we get live data */}
        {toeSummary.customers_count > 10 &&
          <div>
            <div className='widget-divider' />
            <div className='widget-stats-row'>
              <span>{`Showing 5 of ${toeSummary.customers_count}`}</span>
              <span className='show-more'>Show More</span>
            </div>
          </div>
        }
      </div>
    </div>
  )
})

export default CustomerRequestSummary
