
import { format } from 'date-fns'
import * as Sentry from '@sentry/react'

export const removeUnderscores = (string = '') => string?.replace(/_/g, ' ')


export const getRatingType = rating => {
  if (typeof rating !== 'number' || rating < 0) return 'info'
  if (rating < 4) return 'f'
  if (rating < 5.5) return 'd'
  if (rating < 7) return 'c'
  if (rating < 8.5) return 'b'
  return 'a'
}
export const hostDimensionTranslation = key => {
  const values = {
    'Shared IP Hosting': 'Cotenant IP Hosting',
    Defensibility: 'System Hosting',
    'Threat Intelligence': 'System Reputation',
    'Web Applications': 'Application Security',
    'Data Loss': 'Breach Events',
  }
  return values[key] !== undefined ? values[key] : key
}

export const toFixedExceptTen = value => {
  const newValue = parseFloat(value)
  return newValue === 10 ? '10' : newValue.toFixed(1)
}

export function truncateString(string = '', maxCharacters = 100) {
  // if null the default value is not being set - in this case, we simply return an empty string
  return string ? string.toString()?.length < maxCharacters ? string : string.toString().substr(0, maxCharacters - 3) + '...' : ''
}

export function cveScoreToRating(score) {
  const rating = parseFloat(score)
  let prettyName = ''
  if (rating < 4) {
    prettyName = 'Low'
  } else if (rating >= 4 && rating < 7) {
    prettyName = 'Medium'
  } else if (rating >= 7 && rating < 9) {
    prettyName = 'High'
  } else if (rating >= 9) {
    prettyName = 'Critical'
  }
  return prettyName
}

export const formatDate = (date, frmt) => {
  try {
    return format(date, frmt)
  } catch (error) {
    console.error(`Date value supplied: ${date}`, 'Please supply valid date.', error)
    Sentry.captureException(error)
    return 'N/A'
  }
}

export const formatPriority = priority => {
  switch (priority) {
    case 0.1:
      return 'Key 1'
    case 0.2:
      return 'Key 2'
    case 0.3:
      return 'Key 3'
    case 0.4:
      return 'Key 4'
    default:
      return priority
  }
}

export const setClassColorByConcern = (key, value = '') => {
    let colorClass = ''
    const ratingClasses = ['red', 'red', 'red', 'blue', 'green', 'green', 'green']
    switch (key) {
      case 'asset_value':
        if (value.toUpperCase() === 'HIGH') {
          colorClass = 'v3-red'
        } else if (value.toUpperCase() === 'MEDIUM') {
          colorClass = 'v3-blue'
        } else if (value.toUpperCase() === 'KEY') {
          colorClass = 'v3-default'
        } else {
          colorClass = 'v3-green'
        }
        break
      case 'severity':
        if (
          value.toUpperCase() === 'CRITICAL' ||
          value.toUpperCase() === 'HIGH'
        ) {
          colorClass = 'v3-red'
        } else if (value.toUpperCase() === 'MEDIUM') {
          colorClass = 'v3-blue'
        } else {
          colorClass = 'v3-green'
        }
        break
      case 'priority':
        if (value < 1) {
          colorClass = `v3-${ratingClasses[1]}`
        } else {
          colorClass = `v3-${ratingClasses[value]}`
        }
        break
      case 'cvss_rating':
        if (
          value.toUpperCase() === 'CRITICAL' ||
          value.toUpperCase() === 'HIGH'
        ) {
          colorClass = 'v3-red'
        } else if (value.toUpperCase() === 'MEDIUM') {
          colorClass = 'v3-yellow'
        } else {
          colorClass = 'v3-blue'
        }
        break
      case 'cvss_score':
        if (value > 7) {
          colorClass = 'v3-red'
        } else if (value > 4) {
          colorClass = 'v3-yellow'
        } else {
          colorClass = 'v3-blue'
        }
        break
      case 'security_criteria_rating_numeric':
        if (value > 7.5) {
          colorClass = 'v3-green'
        } else if (value > 5.0) {
          colorClass = 'v3-blue'
        } else if (value > 2.5) {
          colorClass = 'v3-yellow'
        } else {
          colorClass = 'v3-red'
        }
        break
      case 'in_action_plan':
        colorClass = value ? 'v3-green' : 'v3-red'
        break
      default:
        colorClass = ''
    }
    return colorClass
  }