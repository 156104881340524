import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './TableCellHeader.scss'
import AngleDown from '../../../assets/svg/AngleDown'

const TableCellHeader = ({
  children,
  className,
  header,
  sortable,
  sorted,
  sortDirection,
  align,
  sticky,
  ...rest
}) => {
  if (sortable) {
    return (
      <th
        className={classnames('v3-th', className, align, {
          sortable,
          sticky,
        })}
        {...rest}
      >
        <div className="th-wrapper">
          <div className={classnames('th-text-svg-wrapper', sortDirection, align, {
            sorted,
          })}>
            {children}
            <AngleDown
              className="v3-th-angle-svg"
              fill={sorted ? '#173A56' : '#B5CDE3'}
            />
          </div>
        </div>
      </th>
    )
  }
  return (
    <th
      className={classnames('v3-th', className, align)}
      {...rest}
    >
      {children}
    </th>
  )
}

TableCellHeader.defaultProps = {
  header: false,
  align: 'left',
  className: '',
  sortable: false,
  sorted: false,
  sortDirection: 'asc',
  sticky: false,
}

TableCellHeader.propTypes = {
  align: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element, PropTypes.array]),
  className: PropTypes.string,
  header: PropTypes.bool,
  sortDirection: PropTypes.oneOf(['asc', 'desc']).isRequired,
  sortable: PropTypes.bool.isRequired,
  sorted: PropTypes.bool.isRequired,
  sticky: PropTypes.bool.isRequired,
}

export default TableCellHeader
