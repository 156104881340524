import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
// import TableCellData from './TableCellData' // had to replace this with td due to odd text-align class problem -SP
import RatingPill from '../../rating-items/RatingPill'
import './PillTableCell.scss'

const PillTableCell = ({
  className,
  value,
  align,
  width,
  composite,
  displaySibling,
  sibling,
  ...rest
}) => {
  return (
    <td
      className={classnames('v3-td v3-pill-td', className)}
      width={width}
      align={align}
      {...rest}
    >
      <RatingPill
        rating={value}
        composite={composite}
      />
      {
        displaySibling &&
        <span className="pill-sibling">{sibling}</span>
      }
    </td>
  )
}

PillTableCell.defaultProps = {
  className: '',
  align: 'center',
  width: '67',
  displaySibling: false,
  composite: false,
  sibling: <></>,
}

PillTableCell.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']).isRequired,
  className: PropTypes.string,
  composite: PropTypes.bool.isRequired,
  displaySibling: PropTypes.bool.isRequired,
  sibling: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  width: PropTypes.string.isRequired,
}

export default PillTableCell
