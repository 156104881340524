import {
  FETCH_DOMAIN_FINDINGS_SUCCESS,
  FETCH_DOMAIN_FINDINGS_FAILURE,
  FETCH_CRITERIA_FINDINGS_SUCCESS,
  FETCH_CRITERIA_FINDINGS_FAILURE,
  FETCHING_DOMAIN_FINDINGS,
  FETCHING_CRITERIA_FINDINGS,
  FETCHING_OVERVIEW_OBJECT,
  FETCHING_TOE_SUMMARY,
  FETCH_OVERVIEW_ERROR,
  FETCH_OVERVIEW_TRENDS_SUCCESS,
  FETCH_TOE_SUMMARY_SUCCESS,
  FETCH_TOE_SUMMARY_FAILURE,
  PDF_SUCCESS,
  DOWNLOADING_PDF,
  RESET_DOMAIN_FINDINGS_PAGINATION,
  SET_SELECTED_TAB,
  SET_SELECTED_VALUES,
  SHOW_INLINE_FILTER,
  SET_SEARCH_OPTIONS,
  SET_FETCHING_OVERVIEW_TRENDS
} from '../actions/Types'

import {
  UPDATE_DOMAIN_FINDING_SUCCESS,
  UPDATE_CRITERIA_FINDING_SUCCESS
} from '../actions/Findings'

import {
  domainFindingsSkeleton,
  loadingDomainFindingsSkeleton,
  loadingCriteriaFindingsSkeleton,
  domainFindingsFailuresSkeleton,
  criteriaFindingsFailuresSkeleton,
  criteriaFindingsSkeleton,
  toeSummarySkeleton
} from '../constants/ActionPlan'

const initialState = {
  domainFindingInfo: Object.assign({}, domainFindingsSkeleton),
  criteriaFindingInfo: Object.assign({}, criteriaFindingsSkeleton),
  loadingDomainFindings: Object.assign({}, loadingDomainFindingsSkeleton),
  loadingCriteriaFindings: Object.assign({}, loadingCriteriaFindingsSkeleton),
  domainFindingsFailures: Object.assign({}, domainFindingsFailuresSkeleton),
  criteriaFindingsFailures: Object.assign({}, criteriaFindingsFailuresSkeleton),
  downloadingPdf: false,
  fetchingToeSummary: false,
  fetchToeSummaryFailure: false,
  toe: Object.assign({}, toeSummarySkeleton),
  selectedTab: 'current',
  selectedValues: [],
  showInlineFilter: true,
  searchOptions: [],
  overviewTrends: {},
  analysisTrendsWithDataLoss: [],
  loadingRatingData: true,
}

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case SET_FETCHING_OVERVIEW_TRENDS: {
      return {
        ...state,
        loadingRatingData: action.loadingRatingData
      }
    }
    case SET_SEARCH_OPTIONS: {
      return {
        ...state,
        searchOptions: action.searchOptions
      }
    }
    case SHOW_INLINE_FILTER: {
      return {
        ...state,
        showInlineFilter: action.show
      }
    }
    case SET_SELECTED_TAB:
      return {
        ...state,
        selectedTab: action.tab
      }
    case SET_SELECTED_VALUES:
      return {
        ...state,
        selectedValues: action.values
      }
    case RESET_DOMAIN_FINDINGS_PAGINATION:
      return {
        ...state,
        domainFindingInfo: {
          ...state.domainFindingInfo,
          selectorChanged: action.changeStatus
        }
      }
    case FETCH_DOMAIN_FINDINGS_SUCCESS:
      return {
        ...state,
        domainFindingInfo: {
          ...state.domainFindingInfo,
          [`${action.domain}`]: {
            findings: action.data,
            links: action.links,
            meta: action.meta
          }
        },
        loadingDomainFindings: {
          ...state.loadingDomainFindings,
          [`${action.domain}`]: false
        }
      }
    case FETCH_DOMAIN_FINDINGS_FAILURE:
      return {
        ...state,
        domainFindingsFailures: {
          ...state.domainFindingsFailures,
          [`${action.domain}`]: true
        }

      }
    case FETCH_CRITERIA_FINDINGS_SUCCESS:
      return {
        ...state,
        criteriaFindingInfo: {
          ...state.criteriaFindingInfo,
          [`${action.criteria}`]: {
            findings: action.data,
            links: action.links,
            meta: action.meta
          }
        },
        loadingCriteriaFindings: {
          ...state.loadingCriteriaFindings,
          [`${action.criteria}`]: false
        }
      }
    case FETCH_CRITERIA_FINDINGS_FAILURE:
      return {
        ...state,
        criteriaFindingsFailures: {
          ...state.criteriaFindingsFailures,
          [`${action.criteria}`]: true
        }

      }
    case FETCHING_DOMAIN_FINDINGS:
      return {
        ...state,
        loadingDomainFindings: {
          ...state.loadingDomainFindings,
          [`${action.domain}`]: true
        }

      }
    case FETCHING_CRITERIA_FINDINGS:
      return {
        ...state,
        loadingCriteriaFindings: {
          ...state.loadingCriteriaFindings,
          [`${action.criteria}`]: true
        }

      }
    case FETCHING_OVERVIEW_OBJECT:
      return Object.assign({}, state, {
        [action.fetchingVariable]: true,
      })
    case FETCH_OVERVIEW_ERROR:
      return Object.assign({}, state, {
        loadingError: true,
      })
    case FETCH_OVERVIEW_TRENDS_SUCCESS:
      return {
        ...state,
        overviewTrends: action.overviewTrends,
        analysisTrendsWithDataLoss: action.analysisTrendsWithDataLoss,
        fetchingOverviewTrends: false,
        fetchedOverviewTrends: true,
      }
    case FETCHING_TOE_SUMMARY:
      return {
        ...state,
        fetchingToeSummary: true
      }
    case FETCH_TOE_SUMMARY_SUCCESS:
      return {
        ...state,
        toe: {
          ...action.data
        },
        fetchingToeSummary: false,
        fetchToeSummaryFailure: false
      }
    case FETCH_TOE_SUMMARY_FAILURE:
      return {
        ...state,
        fetchToeSummaryFailure: true
      }
    case UPDATE_DOMAIN_FINDING_SUCCESS:
      let newDomainFinding = action.newFinding
      const oldDomainStatus = action.finding.attributes.vendor_status === 'compensating_control' || action.finding.attributes.vendor_status === 'false_positive' ? `reported_${action.finding.attributes.vendor_status}` : action.finding.attributes.vendor_status
      let newDomainStatus = action.newFinding.attributes.vendor_status === 'compensating_control' || action.newFinding.attributes.vendor_status === 'false_positive' ? `reported_${action.newFinding.attributes.vendor_status}` : action.newFinding.attributes.vendor_status
      newDomainFinding.attributes = Object.assign(action.finding.attributes, newDomainFinding.attributes)
      const newDomainFindings = state.domainFindingInfo[`${newDomainFinding.attributes.security_domain_key}`].findings.map(finding => { return finding.id === newDomainFinding.id ? newDomainFinding : finding })

      return {
        ...state,
        domainFindingInfo: {
          ...state.domainFindingInfo,
          [`${action.finding.attributes.security_domain_key}`]: {
            ...state.domainFindingInfo[`${action.finding.attributes.security_domain_key}`],
            findings: newDomainFindings
          }
        },
        toe: {
          ...state.toe,
          data: {
            ...state.toe.data,
            attributes: {
              ...state.toe.data.attributes,
              issue_categories_counts: {
                ...state.toe.data.attributes.issue_categories_counts,
                [`${oldDomainStatus}`]: state.toe.data.attributes.issue_categories_counts[`${oldDomainStatus}`] - 1,
                [`${newDomainStatus}`]: state.toe.data.attributes.issue_categories_counts[`${newDomainStatus}`] + 1
              },
              resolved_findings_counts: {
                ...state.toe.data.attributes.resolved_findings_counts,
                [`${action.reportingStatus}`]: state.toe.data.attributes.resolved_findings_counts[action.reportingStatus] - 1,
                [`${action.newFinding.attributes.reporting_status}`]: state.toe.data.attributes.resolved_findings_counts[`${action.newFinding.attributes.reporting_status}`] + 1
              }
            }
          }
        }
      }
    case UPDATE_CRITERIA_FINDING_SUCCESS:
      let newCriteriaFinding = action.newFinding
      const oldCriteriaStatus = action.finding.attributes.vendor_status === 'compensating_control' || action.finding.attributes.vendor_status === 'false_positive' ? `reported_${action.finding.attributes.vendor_status}` : action.finding.attributes.vendor_status
      let newCriteriaStatus = action.newFinding.attributes.vendor_status === 'compensating_control' || action.newFinding.attributes.vendor_status === 'false_positive' ? `reported_${action.newFinding.attributes.vendor_status}` : action.newFinding.attributes.vendor_status
      newCriteriaFinding.attributes = Object.assign(action.finding.attributes, newCriteriaFinding.attributes)
      const newCriteriaFindings = state.criteriaFindingInfo[`${newCriteriaFinding.attributes.security_criteria_key}`].findings.map(finding => { return finding.id === newCriteriaFinding.id ? newCriteriaFinding : finding })
      return {
        ...state,
        criteriaFindingInfo: {
          ...state.criteriaFindingInfo,
          [`${action.finding.attributes.security_criteria_key}`]: {
            ...state.criteriaFindingInfo[`${action.finding.attributes.security_criteria_key}`],
            findings: newCriteriaFindings
          }
        },
        toe: {
          ...state.toe,
          data: {
            ...state.toe.data,
            attributes: {
              ...state.toe.data.attributes,
              issue_categories_counts: {
                ...state.toe.data.attributes.issue_categories_counts,
                [`${oldCriteriaStatus}`]: state.toe.data.attributes.issue_categories_counts[`${oldCriteriaStatus}`] - 1,
                [`${newCriteriaStatus}`]: state.toe.data.attributes.issue_categories_counts[`${newCriteriaStatus}`] + 1
              },
              resolved_findings_counts: {
                ...state.toe.data.attributes.resolved_findings_counts,
                [`${action.finding.attributes.reporting_status}`]: state.toe.data.attributes.resolved_findings_counts[`${action.finding.attributes.reporting_status}`] - 1,
                [`${action.newFinding.attributes.reporting_status}`]: state.toe.data.attributes.resolved_findings_counts[`${action.newFinding.attributes.reporting_status}`] + 1
              }
            }
          }
        }
      }
    case PDF_SUCCESS:
      return {
        ...state,
        downloadingPdf: false
      }
    case DOWNLOADING_PDF:
      return {
        ...state,
        downloadingPdf: true
      }
    default:
      return state
  }
}

export default dashboard
