import React from 'react'
import Header from './shared/Header'
import Modal from './shared/Modal'
import './IntroductionModal.scss'
import request from '../reference/axiosWrapper'

const IntroductionModal = ({ setHasDismissedModal }) => {
  const handleDismissClick = async () => {
    try {
      const requestOptions = {
        method: 'PUT',
        url: `v2/users/save_event`,
        data: { identifier: 'dismissed-vendor-portal-intro' }
      }
      await request(requestOptions)
      setHasDismissedModal(true)
    } catch (error) {
      console.warn(error)
    }
  }

  return (
    <Modal close={() => setHasDismissedModal(true)} isOpen className='intro-modal'>
      <div className='intro-modal-content-wrapper'>
        <Header>Introduction</Header>
        <p>Some of your customers leverage RiskRecon to help them better manage their vendor risk. RiskRecon assesses cybersecurity using passive techniques. Of the total issues identified by RiskRecon, your customers have identified the issues listed here as a high priority to investigate and address.</p>
        <p>This portal details all the issues your customers have asked that you address. It also provides you information to aid in remediation. You can access RiskRecon support through this portal.</p>
        <div className='intro-modal-button-container'>
          <button onClick={() => handleDismissClick()}>Don't Show Again</button>
          <button onClick={() => setHasDismissedModal(true)}>Okay</button>
        </div>
      </div>
    </Modal>
  )
}

export default IntroductionModal
