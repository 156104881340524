import React from 'react'
import PropTypes from 'prop-types'

const EyeNotVisibleSVG = ({
  size,
  className
}) =>
  <svg className={className} width={size} height={size} viewBox='0 0 17 17' xmlns='http://www.w3.org/2000/svg'>
    <g>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.9195 3.2157C1.64325 2.93945 1.64325 2.4932 1.9195 2.21695C2.19575 1.9407 2.64909 1.9407 2.92534 2.21695L14.4783 13.784C14.7545 14.0603 14.7545 14.5065 14.4783 14.7828C14.202 15.059 13.7558 15.059 13.4795 14.7828L11.5528 12.8561C10.6037 13.2245 9.57659 13.437 8.49992 13.437C4.95825 13.437 1.93367 11.234 0.708252 8.12445C1.25367 6.72904 2.16742 5.52487 3.31492 4.61112L1.9195 3.2157ZM12.0416 8.12443C12.0416 6.16943 10.4549 4.58277 8.49989 4.58277C8.13864 4.58277 7.79155 4.6536 7.45864 4.75277L5.92155 3.21568C6.73614 2.9536 7.6003 2.81193 8.49989 2.81193C12.0416 2.81193 15.0661 5.01485 16.2916 8.11735C15.8028 9.36402 15.0236 10.4548 14.0391 11.3261L11.8716 9.1586C11.9707 8.83277 12.0416 8.48568 12.0416 8.12443ZM8.49992 11.6661C6.54492 11.6661 4.95825 10.0795 4.95825 8.12447C4.95825 7.57906 5.08575 7.06197 5.30534 6.60864L6.41742 7.72072C6.39617 7.84822 6.37492 7.98281 6.37492 8.12447C6.37492 9.30031 7.32408 10.2495 8.49992 10.2495C8.64158 10.2495 8.76908 10.2282 8.90367 10.1999L10.0158 11.312C9.55534 11.5386 9.04533 11.6661 8.49992 11.6661ZM10.6036 7.89067C10.4974 6.89901 9.71823 6.12692 8.73365 6.02067L10.6036 7.89067Z'
      />
    </g>
  </svg>

EyeNotVisibleSVG.defaultProps = {
  size: '24'
}

EyeNotVisibleSVG.propTypes = {
  size: PropTypes.string.isRequired,
  className: PropTypes.string
}

export default EyeNotVisibleSVG
