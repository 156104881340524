// for reference - mr. abramov's tutorial https://egghead.io/lessons/javascript-redux-persisting-the-state-to-the-local-storage
export const loadState = () => {
  try {
    const serializedState = window.localStorage.getItem('state')
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state)
    window.localStorage.setItem('state', serializedState)
  } catch (err) {
    console.log(err, 'couldnt save state')
  }
}
