import React, { Component } from "react";
import HostSummarySection from "../HostSection/HostProfile/HostSummarySection";
import VulnerabilitiesList from "./IssueDetails/VulnerabilitiesList";
import ThreatIntellIssueRow from "../ThreatIntellIssueRow";
import { displayIssueDescriptionValue } from "./IssueDetails/DisplayIssueDescriptionValue";
import {
  removeUnderscores,
  isIpAddress,
  prettyCriteriaName,
  listifyOnNewLine,
} from "../../../helpers/TextHelpers";
import { findingSidebarAttributes } from "../../../constants/FindingSidebar";
import PropTypes from "prop-types";
import { criteriaKeys } from "../../../constants/ActionPlan";
import "./IssueDetailsPane.scss";
import { Container, Header, MetricItem } from "../../shared";

class IssueDetailsPane extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderHostSummary(criteria, hostName) {
    if (
      findingSidebarAttributes[criteria].host_summary_attributes !== null &&
      !isIpAddress(hostName)
    ) {
      return (
        <HostSummarySection
          finding={this.props.finding}
          hostSummaryAttributes={
            findingSidebarAttributes[
              this.props.finding.attributes.security_criteria_key
            ].host_summary_attributes
          }
        />
      );
    } else {
      return null;
    }
  }

  render() {
    const { finding, languageFindings, queryWithLanguageFindings } = this.props;
    if (Object.keys(languageFindings).length > 0) {
      const languageDetails =
        languageFindings[finding.attributes.security_criteria_key];
      if (languageDetails !== undefined) {
        return (
          <div className='issue-details-pane-container'>
            {this.renderHostSummary(
              finding.attributes.security_criteria_key,
              finding.attributes.host_name
            )}
            <IssueDescription
              finding={finding}
              language={languageDetails.language || {}}
              languageOptions={
                findingSidebarAttributes[
                  finding.attributes.security_criteria_key
                ]
              }
            />
            {finding.attributes.threatIntellIssues && (
              <div>
                <p className='finding-details-header'>Issues</p>
                <div className='rr-card finding-details-card finding-issue'>
                  {criteriaKeys["threat_intell"].map((threatCriteria) => (
                    <ThreatIntellIssueRow
                      issues={
                        finding.attributes.threatIntellIssues[
                          prettyCriteriaName(threatCriteria)
                        ]
                      }
                      prettyCriteriaName={prettyCriteriaName(threatCriteria)}
                      key={threatCriteria}
                      criteria={threatCriteria}
                    />
                  ))}
                </div>
              </div>
            )}

            <IssueSolution
              finding={finding}
              language={languageDetails.language}
              languageOptions={
                findingSidebarAttributes[
                  finding.attributes.security_criteria_key
                ]
              }
            />
          </div>
        );
      }
    }

    if (Object.keys(queryWithLanguageFindings).length > 0) {
      const languageDetails =
        queryWithLanguageFindings[finding.attributes.finding_detail];
      if (languageDetails !== undefined) {
        return (
          <div className='issue-details-pane-container'>
            {this.renderHostSummary(
              finding.attributes.security_criteria_key,
              finding.attributes.host_name
            )}
            <IssueDescription
              finding={finding}
              language={languageDetails.language || {}}
              languageOptions={
                findingSidebarAttributes[
                  finding.attributes.security_criteria_key
                ]
              }
            />
            <VulnerabilitiesList
              vulnerabilities={languageDetails.vulnerabilities}
              finding={finding}
            />
            <IssueSolution
              finding={finding}
              language={languageDetails.language}
              languageOptions={
                findingSidebarAttributes[
                  finding.attributes.security_criteria_key
                ]
              }
            />
          </div>
        );
      }
    }
    return <div className='issue-details-pane-container' />;
  }
}

const IssueDescription = ({ finding, language, languageOptions }) => {
  const isTLSIssue = finding.attributes.finding_data_description === "TLS 1.2";

  return (
    <Container medium className={"issue-description"}>
      <Header>Issue Description</Header>
      <div className='metric-container'>
        {Object.keys(languageOptions.issue_description_attributes).map(
          (key) => {
            const valueData = displayIssueDescriptionValue(finding, key);
            return (
              <MetricItem
                key={key}
                small
                label={removeUnderscores(
                  languageOptions.issue_description_attributes[key]
                )}
                value={valueData.value}
                valueClassName={valueData.valueClassName}
              />
            );
          }
        )}
      </div>

      <div className='row-wrapper issue-text'>
        <MetricItem
          small
          label={"Issue"}
          value={(language[languageOptions.issue_key] || "n/a")
            .split("$text")
            .join(
              finding.attributes[
                Object.keys(languageOptions.issue_description_attributes).find(
                  (key) =>
                    languageOptions.issue_description_attributes[key] ===
                    "issue"
                )
              ]
            )}
        />
        {/* The missing TLS1.2 finding has URL's at the bottom of its description response with all sorts of unnecessary markup necessitating putting this here */}
        {isTLSIssue ? (
          <React.Fragment>
            <a
              style={{ display: "block" }}
              href='https://blogs.windows.com/msedgedev/2018/10/15/modernizing-tls-edge-ie11/'
            >
              https://blogs.windows.com/msedgedev/2018/10/15/modernizing-tls-edge-ie11/
            </a>
            <a
              style={{ display: "block" }}
              href='https://blog.mozilla.org/security/2018/10/15/removing-old-versions-of-tls/'
            >
              https://blog.mozilla.org/security/2018/10/15/removing-old-versions-of-tls/
            </a>
            <a
              style={{ display: "block" }}
              href='https://webkit.org/blog/8462/deprecation-of-legacy-tls-1-0-and-1-1-versions/'
            >
              https://webkit.org/blog/8462/deprecation-of-legacy-tls-1-0-and-1-1-versions/
            </a>
            <a
              style={{ display: "block" }}
              href='https://security.googleblog.com/2018/10/modernizing-transport-security.html'
            >
              https://security.googleblog.com/2018/10/modernizing-transport-security.html
            </a>
          </React.Fragment>
        ) : (
          <React.Fragment></React.Fragment>
        )}
      </div>
      {languageOptions.show_issue_description && (
        <MetricItem
          small
          label={"Description"}
          className="issue-description"
          value={language[languageOptions.issue_description] || "n/a"}
        />
      )}
    </Container>
  );
};

const IssueSolution = ({ finding, language, languageOptions }) => {
  return (
    Object.keys(language)?.length > 0 && (
      <Container medium className='how-to-fix-container'>
        <Header>How to Fix this Issue</Header>
        <div className='how-to-fix-content'>
          {language.solution_owner_short && (
            <div className='who-should-fix-container'>
              <p className='how-to-fix-label'>Who Should Fix It</p>
              <ul className='finding-details-value'>
                {listifyOnNewLine(language.solution_owner_short) || "n/a"}
              </ul>
            </div>
          )}
          {language.solution_references && (
            <div className='support-site-container'>
              <p className='how-to-fix-label'>Support Site</p>
              <div className='finding-details-value'>
                {language.solution_references
                  .split(/(?=http:|https:)/g)
                  .map((ref, idx) => {
                    return (
                      <div key={idx} className='href-wrapper'>
                        <a
                          href={`${ref}`}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {ref}
                        </a>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
          <div className='how-to-fix-it-container'>
            <p className='how-to-fix-label'>How to Fix It</p>
            <p className='finding-details-value'>
              {(language[languageOptions.solution] || "n/a").replace(
                "$text",
                finding.attributes.finding_detail
              )}
            </p>
          </div>
        </div>
      </Container>
    )
  );
};

IssueDetailsPane.propTypes = {
  finding: PropTypes.objectOf(PropTypes.any).isRequired,
  queryWithLanguageFindings: PropTypes.objectOf(PropTypes.any).isRequired,
  languageFindings: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default IssueDetailsPane;
