import React from 'react'
import { removeUnderscores } from '../../helpers/TextHelpers'
import LoadingIcon from '../loaders/LoaderIcon'
import PendingScanSVG from '../shared/PendingScanSVG'
import CheckSVG from '../shared/CheckSVG'
import CancelSVG from '../shared/CancelSVG'
import Tooltip from '../shared/ToolTip'

const IssueStatusSummary = React.memo(function IssueStatusSummary (props) {
  const { toeSummary, fetchingToeSummary } = props
  const { fixed_not_resolved: fixedNotResolved, fixed_pending_scan: fixedPendingScan, fixed_resolved: fixedResolved } = toeSummary.resolved_findings_counts || {}

  const renderFixedStatus = (k) => (
    <>
      <Tooltip className='issue-status-tooltip' title='<span>Not Resolved</span><br /><span>After you provided a status of Fixed, the latest scan still identifies the issue.</span>'>
        <div className='fixed-not-resolved'>
          <CancelSVG /> {fixedNotResolved}
        </div>
      </Tooltip>
      <Tooltip className='issue-status-tooltip' title='<span>Pending Scan</span><br /><span>There hasn&apos;t been a new scan since you provided the status of Fixed.</span>'>
        <div className='fixed-pending-scan'>
          <PendingScanSVG /> {fixedPendingScan}
        </div>
      </Tooltip>
      <Tooltip className='issue-status-tooltip' title='<span>Resolved</span><br /><span>The latest scan identifies the issue as resolved.</span>'>
        <div className='fixed-resolved'>
          <CheckSVG /> {fixedResolved}
        </div>
      </Tooltip>
      </>
  )

  const WidgetStatusRows = Object.keys(toeSummary.issue_categories_counts).map((k, index) => (
    <div key={index} className='widget-stats-row'>
      <span className='stats-status'>{removeUnderscores(k)}</span>
      {k === 'fixed' ? <div className='fixed-row'>{renderFixedStatus(k)}  <span>{toeSummary.issue_categories_counts[k]}</span></div> : <span className='stats-count'>{toeSummary.issue_categories_counts[k]}</span>}
    </div>
  ))

  if (fetchingToeSummary) {
    return <LoadingIcon minHeight='220' />
  }

  return (
    <div>
      <div className='widget-count centered'>{toeSummary.open_issues_count}</div>
      <div className='widget-subheading centered'>Shared Issues</div>
      <div className='widget-stats-wrapper'>
        <div className='widget-stats-row heading'>
          <span>Status</span>
          <span>Issues</span>
        </div>

        {WidgetStatusRows}

      </div>
    </div>
  )
})

export default IssueStatusSummary
