import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './IconButton.scss'

/**
 * Use `IconButton` when you want button functionality with an svg icon. Use the hoverIcon prop to toggle (i.e. a 'solid' and 'outline' svg icon) on hover.
 */
const IconButton = ({
  onClick,
  type,
  disabled,
  color,
  icon: Icon,
  hoverIcon: HoverIcon,
  iconSize,
  className,
  fill,
  ...rest
}) => {
  const [isHovered, setIsHovered] = useState(false)
  if (!HoverIcon) {
    return (
      <button
        type={type}
        onClick={onClick}
        disabled={disabled}
        className={classnames('v3-icon-button', color, className)}
        {...rest}
      >
        <Icon size={iconSize} fill={fill} />
      </button>
    )
  }
  return (
    <button
      type={type}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      disabled={disabled}
      className={classnames('v3-icon-button', { [color]: color, [className]: className })}
      {...rest}
    >
      {
        (HoverIcon && isHovered && !disabled)
          ? <HoverIcon/>
          : <Icon size={iconSize} />
      }
    </button>
  )
}

IconButton.defaultProps = {
  type: 'button',
  disabled: false,
  iconSize: '24',
}

IconButton.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(['green', 'red', 'blue', 'dark-blue', 'dark']),
  disabled: PropTypes.bool,
  hoverIcon: PropTypes.func,
  icon: PropTypes.func,
  iconSize: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  fill: PropTypes.string,
}

export default IconButton
