import {
  CLEAR_FLASH,
  SET_FLASH
} from './Types'

export const setFlash = (messages, kind = 'success') => dispatch => {
  dispatch({ type: CLEAR_FLASH })
  dispatch({ type: SET_FLASH, payload: { messages, kind } })
}

export const clearFlash = () => dispatch => {
  dispatch({ type: CLEAR_FLASH })
}
