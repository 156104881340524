import React from 'react'
import PropTypes from 'prop-types'
import './ContentContainer.scss'

const ContentContainer = React.forwardRef(({ children }, ref) => (
  <div ref={ref} id="content-container">
    {children}
  </div>
))

ContentContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
}

ContentContainer.displayName = 'ContentContainer'

export default ContentContainer
