import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Header from './shared/Header'
import Modal from './shared/Modal'
import Checkbox from './shared/Checkbox'
import { NavLink } from 'react-router-dom'
import { setCurrentEulaAccepted } from '../actions/UserActions'
import classnames from 'classnames'
import './EulaAcceptModal.scss'

const EulaAcceptModal = ({ onClose }) => {
  const [isValidationError, setIsValidationError] = useState(false)
  const [isEulaAcceptChecked, setIsEulaAcceptChecked] = useState(false)
  const dispatch = useDispatch()

  const onChange = () => {
    setIsValidationError(false)
    return setIsEulaAcceptChecked(!isEulaAcceptChecked)
  }

  const handleButtonClick = () => {
    if (!isEulaAcceptChecked) {
      return setIsValidationError(true)
    }
    dispatch(setCurrentEulaAccepted())
    return setIsValidationError(false)
  }

  return (
    <Modal close={() => onClose()} isOpen={true} className="eula-modal" isBackArrow={true}>
      <div className="eula-modal-content-wrapper">
        <Header>End User License Agreement</Header>
        <span>*Before your access is granted, you must agree to RiskRecon’s <NavLink target="_blank" rel='noopener noreferrer' to="/eula">Terms of Use</NavLink> by checking the box below.</span>
        <label className={classnames('eula-accept-label', { 'validation-error': isValidationError })} htmlFor='eula-modal-checkbox'>
          <Checkbox
            id='eula-modal-checkbox'
            onChange={onChange}
            checked={isEulaAcceptChecked}
          />
          <span>*  I agree</span>
        </label>
        {isValidationError && <span className="validation-error-text">You must agree to the Terms of Use before your access is granted.</span>}
        <button className={classnames('eula-continue-button', { disabled: !isEulaAcceptChecked })} onClick={handleButtonClick}>Continue</button>
      </div>
    </Modal>
  )
}

export default EulaAcceptModal
