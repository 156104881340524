import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import IconButton from './IconButton'
import ArrowRightSVG from './ArrowRightSVG.js'
import ArrowLeftSVG from './ArrowLeftSVG.js'
import range from 'ramda/src/range'
// import min from 'ramda/src/min'
// import max from 'ramda/src/max'
import './Pagination.scss'

const Pagination = ({
  activePage,
  totalPages,
  onPageChange,
  endSiblings,
}) => {
  const handlePageChange = (event, page) => {
    onPageChange(event, {
      activePage: page,
      totalPages,
    })
  }

  const renderItem = n => (
    <button
      key={n}
      onClick={e => handlePageChange(e, n)}
      className={classnames('reset item')}
    >
      {n}
    </button>
  )

  if (totalPages < 2) return null

  const renderAllPageItems = () => range(1, totalPages + 1).map(n => {
    const selected = activePage === n
    return (
      <button
        key={n}
        onClick={e => handlePageChange(e, n)}
        disabled={selected}
        className={classnames('reset item', { selected })}
      >
        {n}
      </button>
    )
  })

  const renderBeforeSelected = () => {
    if (activePage < endSiblings) return range(1, activePage).map(renderItem)
    if (activePage > totalPages - endSiblings + 1) {
      return (
        <>
          {renderItem(1)}
          <span className="item ellipsis">...</span>
          {range(totalPages - endSiblings + 1, activePage).map(renderItem)}
        </>
      )
    }

    return (
      <>
        {renderItem(1)}
        <span className="item ellipsis">...</span>
        {renderItem(activePage - 1)}
      </>
    )
  }

  const renderAfterSelected = () => {
    if (activePage > totalPages - endSiblings + 1) return range(activePage + 1, totalPages + 1).map(renderItem)
    if (activePage < endSiblings) {
      return (
        <>
          {range(activePage + 1, endSiblings + 1).map(renderItem)}
          <span className="item ellipsis">...</span>
          {renderItem(totalPages)}
        </>
      )
    }

    return (
      <>
        {renderItem(activePage + 1)}
        <span className="item ellipsis">...</span>
        {renderItem(totalPages)}
      </>
    )
  }

  const renderItems = () => {
    if (totalPages <= endSiblings + 2) return renderAllPageItems()

    return (
      <>
        {renderBeforeSelected()}
        <span className={classnames('item selected')}>{activePage}</span>
        {renderAfterSelected()}
      </>
    )
  }

  return (
    <div className="v3-pagination-container">
      <IconButton
        icon={ArrowLeftSVG}
        onClick={e => handlePageChange(e, activePage - 1)}
        disabled={activePage === 1}
        className="next-previous"
      />
      {renderItems()}
      <IconButton
        icon={ArrowRightSVG}
        onClick={e => handlePageChange(e, activePage + 1)}
        disabled={activePage === totalPages}
        className="next-previous"
      />
    </div>
  )
}

Pagination.defaultProps = {
  endSiblings: 5,
}

Pagination.propTypes = {
  activePage: PropTypes.number.isRequired,
  endSiblings: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
}

export default Pagination
