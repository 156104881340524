import React, { forwardRef } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import './TableRow.scss'

const TableRow = forwardRef(({
  children,
  className,
  hoverable,
  accordionParent,
  accordionChild,
  expanded,
  noBorder,
  show,
  ...rest
}, ref) => {
  if (show) {
    return (
      <tr
        className={classnames('v3-tr', className, {
          'no-border': noBorder,
          'accordion-parent': accordionParent,
          'accordion-child': accordionChild,
          expanded,
          hoverable,
        })}
        ref={ref}
        {...rest}
      >
        {children}
      </tr>
    )
  }
  return null
})

TableRow.defaultProps = {
  hoverable: false,
  noBorder: false,
  expanded: false,
  accordionParent: false,
  accordionChild: false,
  show: true,
}

TableRow.propTypes = {
  accordionChild: PropTypes.bool.isRequired,
  accordionParent: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.bool, PropTypes.array]),
  className: PropTypes.string,
  expanded: PropTypes.bool.isRequired,
  hoverable: PropTypes.bool.isRequired,
  noBorder: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
}

TableRow.displayName = 'TableRow'

export default TableRow
