import React from 'react'
import PropTypes from 'prop-types';
import {
  Container,
  Header,
  MetricItem,
} from '../../../shared'
import './GeolocationSection.scss'

const GeolocationSection = ({ finding }) => {
  const geoLocationSectionAttributes = { Country: "country_name", State: "state_name", City: "city_name", Longitude: "longitude", Latitude: "latitude" };
  return (
    <Container medium className="host-profile-geolocation">
      <Header>Geolocation</Header>
      <div className="geolocation-metrics">
        {Object.keys(geoLocationSectionAttributes).map(key => {
          return <MetricItem
            small
            label={key}
            value={finding.attributes[geoLocationSectionAttributes[key]] ? finding.attributes[geoLocationSectionAttributes[key]] : 'n/a'}
          />
        })}

      </div>
    </Container >
  )
}

GeolocationSection.propTypes = {
  finding: PropTypes.objectOf(PropTypes.any).isRequired
}

export default GeolocationSection
