import React from "react";
import "./EventLogItem.scss";
import moment from "moment";

function EventLogItem({ logItem }) {
  return (
    <div className="event-log-item">
      <div className="status-indicator"/>
      <div className="content">
        <div className="log-item-date">
          {moment(logItem.created_at).format("ll")} . {moment(logItem.created_at).format("LT").toLocaleLowerCase()}
        </div>
        <div className="log-item-message">{logItem.message}.</div>
      </div>
    </div>
  );
}


export default EventLogItem;
