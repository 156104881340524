import React from 'react'
import PropTypes from 'prop-types'

const PendingScanSVG = ({
  className,
  fill,
  size,
  ...rest
}) =>
  <svg width={size} height={size} viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M0 6C0 2.7 2.7 0 6 0C9.3 0 12 2.7 12 6C12 9.3 9.3 12 6 12C2.7 12 0 9.3 0 6ZM5.682 6.774L8.13 8.28C8.346 8.412 8.628 8.346 8.76 8.136C8.892 7.92 8.82 7.638 8.604 7.506L6.3 6.12V3.45C6.3 3.204 6.096 3 5.85 3C5.604 3 5.4 3.204 5.394 3.45V6.264C5.394 6.474 5.502 6.666 5.682 6.774Z' fill={fill} />
  </svg>

PendingScanSVG.defaultProps = {
  className: '',
  fill: '#435A70',
  size: '12'
}

PendingScanSVG.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  fill: PropTypes.string
}

export default PendingScanSVG
